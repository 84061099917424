import { transformFilterParamsToApiFilterSelectionsFactory } from '../utils/insightsPortalSchemas.utils'
import {
  buildTimeIntervalDimensionsConfig,
  buildTimeIntervalDimensionsGrouping,
  createSchemaConfig,
  PrivateSchemaPropertyType,
} from '../helpers/schema.helpers'
import { FormatterType } from '../types/formatter.types'
import { insightsPortalERVMetricAccessControl } from '../../access-control/insightsPortalERVMetricAccess.configuration'
import { InsightsMetricDesiredDirection } from '../types/schema.types'
import { GroupBys, Metrics, Dimensions } from './schema.types'
import { collectionSchemaFilterParamsConfig } from './schema.hooks'

export { Metrics, GroupBys, Dimensions }

export const schemaConfig = createSchemaConfig({
  metrics: Metrics,
  dimensions: Dimensions,
  metricsConfig: {
    [Metrics.ADD_TO_CARTS]: {
      label: 'insightsPortalDomain.collections.metrics.addToCarts',
      tooltip: 'insightsPortalDomain.collections.metrics.addToCarts.tooltip',
      formatter: FormatterType.Integer,
      desiredDirection: InsightsMetricDesiredDirection.POSITIVE,
    },
    [Metrics.CART_CONVERSIONS]: {
      label: 'insightsPortalDomain.collections.metrics.cartConversions',
      tooltip: 'insightsPortalDomain.collections.metrics.cartConversions.tooltip',
      formatter: FormatterType.Percentage,
      desiredDirection: InsightsMetricDesiredDirection.POSITIVE,
    },
    [Metrics.GMV]: {
      label: 'insightsPortalDomain.collections.metrics.gmv',
      tooltip: 'insightsPortalDomain.collections.metrics.gmv.tooltip',
      formatter: FormatterType.Currency,
      desiredDirection: InsightsMetricDesiredDirection.POSITIVE,
    },
    [Metrics.ERV]: {
      label: 'insightsPortalDomain.collections.metrics.erv',
      tooltip: 'insightsPortalDomain.collections.metrics.erv.tooltip',
      formatter: FormatterType.Currency,
      desiredDirection: InsightsMetricDesiredDirection.POSITIVE,
      private: insightsPortalERVMetricAccessControl,
    },
    [Metrics.IMPRESSIONS]: {
      label: 'insightsPortalDomain.collections.metrics.impressions',
      tooltip: 'insightsPortalDomain.collections.metrics.impressions.tooltip',
      formatter: FormatterType.Integer,
      desiredDirection: InsightsMetricDesiredDirection.POSITIVE,
    },
    [Metrics.LAST_ETL_UPDATE]: {
      private: true,
      formatter: FormatterType.Date,
    },
    [Metrics.UNITS_SOLD]: {
      label: 'insightsPortalDomain.collections.metrics.unitsSold',
      formatter: FormatterType.Integer,
      desiredDirection: InsightsMetricDesiredDirection.POSITIVE,
    },
    [Metrics.NUM_COLLECTIONS]: {
      label: 'insightsPortalDomain.collections.metrics.numCollections',
      formatter: FormatterType.Integer,
      desiredDirection: InsightsMetricDesiredDirection.POSITIVE,
    },
  },
  dimensionsConfig: {
    [Dimensions.COLLECTION]: {
      private: PrivateSchemaPropertyType.DataExplorerFilters,
      label: 'insightsPortalDomain.collections.groupBys.collectionName',
    },
    [Dimensions.COLLECTION_AND_WAREHOUSE]: {
      private: true,
      label: 'insightsPortalDomain.collections.groupBys.collectionName',
    },
    [Dimensions.COLLECTION_NAME]: {
      private: true,
      label: 'insightsPortalDomain.collections.groupBys.collectionName',
    },
    [Dimensions.COLLECTION_ID]: {
      private: true,
      label: 'insightsPortalDomain.collections.groupBys.collectionId',
    },
    [Dimensions.COLLECTION_SOURCE]: {
      private: PrivateSchemaPropertyType.DataExplorerFilters,
      label: 'insightsPortalDomain.collections.groupBys.collectionSource',
    },
    [Dimensions.RETAILER_COLLECTION_ID]: {
      private: true,
      label: 'insightsPortalDomain.collections.groupBys.retailerCollectionId',
    },
    [Dimensions.LAST_UPDATED]: {
      private: true,
      label: 'insightsPortalDomain.collections.groupBys.lastUpdated',
      formatter: FormatterType.Date,
    },
    [Dimensions.DEVICES]: {
      label: 'insightsPortalDomain.collections.groupBys.devices',
    },
    [Dimensions.NAV_COLLECTION_LEVEL]: {
      private: true,
      label: 'insightsPortalDomain.collections.groupBys.navCollectionLevel',
    },
    [Dimensions.L1]: {
      private: PrivateSchemaPropertyType.DataExplorerFilters,
      label: 'insightsPortalDomain.collections.groupBys.l1Id',
    },
    [Dimensions.L2]: {
      private: PrivateSchemaPropertyType.DataExplorerFilters,
      label: 'insightsPortalDomain.collections.groupBys.l2Id',
    },
    [Dimensions.L3]: {
      private: PrivateSchemaPropertyType.DataExplorerFilters,
      label: 'insightsPortalDomain.collections.groupBys.l3Id',
    },
    [Dimensions.L1_ID]: {
      private: true,
      label: 'insightsPortalDomain.collections.groupBys.l1Id',
    },
    [Dimensions.L2_ID]: {
      private: true,
      label: 'insightsPortalDomain.collections.groupBys.l2Id',
    },
    [Dimensions.L3_ID]: {
      private: true,
      label: 'insightsPortalDomain.collections.groupBys.l3Id',
    },
    [Dimensions.L1_NAME]: {
      private: true,
      label: 'insightsPortalDomain.collections.groupBys.l1Name',
    },
    [Dimensions.L2_NAME]: {
      private: true,
      label: 'insightsPortalDomain.collections.groupBys.l2Name',
    },
    [Dimensions.L3_NAME]: {
      private: true,
      label: 'insightsPortalDomain.collections.groupBys.l3Name',
    },
    [Dimensions.WAREHOUSE]: {
      label: 'insightsPortalDomain.collections.groupBys.warehouse',
    },
    [Dimensions.INSIGHTS_PORTAL_ORDER_SOURCE]: {
      label: 'insightsPortalDomain.collections.filters.orderSource',
    },
    ...buildTimeIntervalDimensionsConfig,
  },
  dimensionGroupings: [
    buildTimeIntervalDimensionsGrouping,
    {
      label: 'insightsPortalDomain.filters.stores',
      dimensions: [Dimensions.WAREHOUSE],
    },
    {
      label: 'insightsPortalDomain.filters.groups.orders',
      dimensions: [Dimensions.INSIGHTS_PORTAL_ORDER_SOURCE],
    },
    {
      label: 'insightsPortalDomain.filters.devices',
      dimensions: [Dimensions.DEVICES],
    },
    {
      label: 'insightsPortalDomain.collections.groupBys.collections',
      dimensions: [
        Dimensions.COLLECTION,
        Dimensions.COLLECTION_SOURCE,
        Dimensions.NAV_COLLECTION_LEVEL,
        Dimensions.L1,
        Dimensions.L2,
        Dimensions.L3,
      ],
    },
  ],
  // TODO: deprecate
  filterConfigs: collectionSchemaFilterParamsConfig,
  transformFilterParamsToApiFilterSelections: transformFilterParamsToApiFilterSelectionsFactory(
    collectionSchemaFilterParamsConfig
  ),
  groupBys: GroupBys,
  groupBysConfig: {
    [GroupBys.CREATED_BY]: {
      private: true,
    },
    [GroupBys.COLLECTION_NAME]: {
      label: 'insightsPortalDomain.collections.groupBys.collectionName',
    },
    [GroupBys.COLLECTION_ID]: {
      label: 'insightsPortalDomain.collections.groupBys.collectionId',
    },
    [GroupBys.RETAILER_COLLECTION_ID]: {
      label: 'insightsPortalDomain.collections.groupBys.retailerCollectionId',
    },
    [GroupBys.START_DATE]: {
      label: 'insightsPortalDomain.collections.groupBys.startDate',
      formatter: FormatterType.Date,
    },
    [GroupBys.LAST_UPDATED]: {
      label: 'insightsPortalDomain.collections.groupBys.lastUpdated',
      formatter: FormatterType.Date,
    },
    [GroupBys.DATE_PT]: {
      label: 'insightsPortalDomain.pillTabs.days.label',
      formatter: FormatterType.Date,
    },
    [GroupBys.WEEK_SAT_PT]: {
      label: 'insightsPortalDomain.pillTabs.weeks.label',
      formatter: FormatterType.Date,
    },
    [GroupBys.WEEK_SUN_PT]: {
      label: 'insightsPortalDomain.pillTabs.weeks.label',
      formatter: FormatterType.Date,
    },
    [GroupBys.WEEK_MON_PT]: {
      label: 'insightsPortalDomain.pillTabs.weeks.label',
      formatter: FormatterType.Date,
    },
    [GroupBys.MONTH_PT]: {
      label: 'insightsPortalDomain.pillTabs.months.label',
      formatter: FormatterType.Date,
    },
    [GroupBys.DEVICES]: {
      label: 'insightsPortalDomain.collections.groupBys.devices',
    },
    [GroupBys.CATALOG_PRODUCT_ID]: {
      label: 'insightsPortalDomain.collections.groupBys.catalogProductId',
    },
    [GroupBys.RETAILER_PRODUCT]: {
      label: 'insightsPortalDomain.collections.groupBys.retailerProduct',
    },
    [GroupBys.PRODUCT_UPC_CODE]: {
      label: 'insightsPortalDomain.collections.groupBys.productUpcCode',
    },
    [GroupBys.COLLECTION_SOURCE]: {
      label: 'insightsPortalDomain.collections.groupBys.collectionSource',
    },
    [GroupBys.NAV_COLLECTION_LEVEL]: {
      label: 'insightsPortalDomain.collections.groupBys.navCollectionLevel',
    },
    [GroupBys.L1_ID]: {
      label: 'insightsPortalDomain.collections.groupBys.l1Id',
    },
    [GroupBys.L2_ID]: {
      label: 'insightsPortalDomain.collections.groupBys.l2Id',
    },
    [GroupBys.L3_ID]: {
      label: 'insightsPortalDomain.collections.groupBys.l3Id',
    },
    [GroupBys.L1_NAME]: {
      label: 'insightsPortalDomain.collections.groupBys.l1Name',
    },
    [GroupBys.L2_NAME]: {
      label: 'insightsPortalDomain.collections.groupBys.l2Name',
    },
    [GroupBys.L3_NAME]: {
      label: 'insightsPortalDomain.collections.groupBys.l3Name',
    },
    [GroupBys.COLLECTION_AND_WAREHOUSE]: {
      label: 'insightsPortalDomain.collections.groupBys.collectionName',
    },
    [GroupBys.WAREHOUSE]: {
      label: 'insightsPortalDomain.collections.groupBys.warehouse',
    },
  },
})
