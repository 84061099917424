import type { PropsWithChildren, ChangeEvent, ComponentType, FunctionComponent } from 'react'
import { Fragment, useState } from 'react'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import {
  SelectField,
  TextField,
  Button,
  Text,
  Flex,
  Alert,
} from '@retailer-platform/shared-components/src/tds'
import { Box } from '@instacart/ids-core'
import Modal from '../../../../../components/Modal'
import { errors } from '../../../../../../utils/error-handling/errors'
import instacart from '../../../../../common/instacart'
import Slash from '../../../../../components/Slash'
import {
  withNotificationsContext,
  type NotificationsContextValue,
} from '../../../../../contexts/notifications/NotificationsContext'
import deprecatedAnalytics from '../../../../../common/deprecatedAnalytics'
import { YES_NO_OPTIONS, STRING_TO_BOOLEAN_MAP } from '../../../../../common/constants'
import { withTrackEventOnMount } from '../../../../../../utils/events/hocs'
import { COST_UNIT_OPTIONS } from '../../../../../common/utils/catalog/units'
import moment from 'moment'
import { DateRangePicker, type FocusedInputShape } from 'react-dates'
import { css, Global } from '@emotion/react'
import { useUpdateAvailabilityScoreOverrideMutation } from '@retailer-platform/domain-products/availabilityScoreOverride'
import { NINETY_DAYS } from './ProductLocations'
import {
  AvailabilityScoreOverrideDateError,
  notifyUpdateErrors,
} from './components/AvailabilityScoreOverrideErrors'

const buttonStyle = {
  marginLeft: '10px',
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 380px;
`
const AvailabilityScoreOverrideContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const AvailabilityScoreOverrideSelector = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 5px;
`

interface Props extends NotificationsContextValue {
  onClose: () => void
  itemIds: number[]
  partnerId: string
  warehouseId: string
  productId: string
  showAvailabilityScoreOverride: boolean
}

interface State {
  isBusy: boolean
  isError: boolean
  taxable: boolean
  available: boolean
  priced_by: string
  costPricePerUnit: string
}

interface AvailabilityScoreOverrideState {
  enable: boolean
  startAt: Date
  endAt: Date
  dateError: boolean
}

const BulkEditProductLocationsModal: FunctionComponent<PropsWithChildren<Props>> = ({
  onClose,
  itemIds,
  partnerId,
  warehouseId,
  productId,
  showAvailabilityScoreOverride,
  notify,
}) => {
  const [state, setState] = useState<State>({
    isBusy: false,
    isError: false,
    taxable: false,
    available: false,
    priced_by: '',
    costPricePerUnit: '',
  })
  const [availabilityScoreOverrideState, setAvailabilityScoreOverrideState] =
    useState<AvailabilityScoreOverrideState>({
      enable: false,
      startAt: null,
      endAt: null,
      dateError: false,
    })
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null)

  const [updateAvailabilityScoreOverrideMutation] = useUpdateAvailabilityScoreOverrideMutation()

  const onSubmit = async () => {
    const { available, taxable, costPricePerUnit, priced_by } = state

    // Cast empty string as undefined so it doesn't serialize.
    const cost =
      costPricePerUnit != null && costPricePerUnit.length > 0 ? costPricePerUnit : undefined

    setState({
      ...state,
      isBusy: true,
      isError: false,
    })

    try {
      await instacart.go.put(
        `/v1/partners/${partnerId}/warehouses/${warehouseId}/products/${productId}/items/bulk_update`,
        {
          data: {
            item_ids: itemIds,
            available,
            taxable,
            cost_price_per_unit: cost,
            priced_by,
          },
        }
      )

      if (showAvailabilityScoreOverride) {
        const {
          enable: availabilityScoreOverride,
          startAt: availabilityScoreOverrideStartAt,
          endAt: availabilityScoreOverrideEndAt,
        } = availabilityScoreOverrideState
        const itemEntityIds = itemIds.map(itemId => {
          return {
            itemId: {
              itemId: itemId.toString(),
            },
          }
        })
        const data = {
          entityId: itemEntityIds,
          retailerId: warehouseId,
          enable: availabilityScoreOverride,
          overrideStartAt: availabilityScoreOverride ? availabilityScoreOverrideStartAt : null,
          overrideEndAt: availabilityScoreOverride ? availabilityScoreOverrideEndAt : null,
        }
        const resp = await updateAvailabilityScoreOverrideMutation({
          variables: {
            data,
          },
        })

        const errors =
          resp.data?.retailerProductAttributeEditServiceUpdateAvailabilityScoreOverride?.errors
        if (errors) {
          errors.forEach(notifyUpdateErrors)
          setState({
            ...state,
            isBusy: false,
          })
          return
        }
      }
      deprecatedAnalytics.track('product.bulk_edit', {
        productId,
        itemIds,
      })
      notify(<FormattedMessage id="catalog.products.locations.requestSubmitted" />)
      onClose()
    } catch (e) {
      errors.captureException(e)

      setState({
        ...state,
        isError: true,
        isBusy: false,
      })
    }
  }

  const handleAvailableChange = (available: boolean) => {
    setState({
      ...state,
      available,
    })
  }

  const handleAvailabilityScoreOverrideChange = (enable: boolean) => {
    setAvailabilityScoreOverrideState({
      ...availabilityScoreOverrideState,
      enable,
    })
  }

  const handleAvailabilityScoreOverrideDateChange = ({ startDate, endDate }) => {
    const start = startDate?.toDate()
    const end = endDate?.toDate()
    setAvailabilityScoreOverrideState({
      ...availabilityScoreOverrideState,
      dateError: start && end && end.getTime() - start.getTime() > NINETY_DAYS,
      startAt: start,
      endAt: end,
    })
  }

  const handleTaxableChange = (taxable: boolean) => {
    setState({
      ...state,
      taxable,
    })
  }

  const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      costPricePerUnit: e.target.value,
    })
  }

  const handlePricedByChange = (priced_by: string) => {
    setState({
      ...state,
      priced_by,
    })
  }

  const disableSave = () => {
    if (state.isBusy) {
      return true
    } else if (availabilityScoreOverrideState.dateError) {
      return true
    } else if (
      availabilityScoreOverrideState.enable &&
      (availabilityScoreOverrideState.startAt == null ||
        availabilityScoreOverrideState.endAt == null)
    ) {
      return true
    }
    return false
  }

  return (
    <Modal height={showAvailabilityScoreOverride ? 660 : 560} onClose={onClose}>
      {(
        Header: ComponentType<PropsWithChildren<unknown>>,
        Body: ComponentType<PropsWithChildren<unknown>>,
        Footer: ComponentType<PropsWithChildren<unknown>>
      ) => [
        <Header>
          <div>
            <Text typography="subtitle">Bulk Edit</Text>
            <Text typography="bodyRegular">({itemIds.length} store locations)</Text>
          </div>
        </Header>,
        <Body>
          <Container>
            <InnerContainer>
              <Flex.Column gap="8px">
                {state.isError && <Alert variant="error">Please try again later&quot;</Alert>}
                <SelectField
                  compact
                  value={state.available.toString()}
                  onChange={v => handleAvailableChange(STRING_TO_BOOLEAN_MAP[v])}
                  options={YES_NO_OPTIONS}
                  labelProps={{
                    label: 'Available',
                  }}
                />

                {showAvailabilityScoreOverride && (
                  <AvailabilityScoreOverrideContainer>
                    <AvailabilityScoreOverrideSelector>
                      <SelectField
                        compact
                        value={availabilityScoreOverrideState.enable.toString()}
                        onChange={v =>
                          handleAvailabilityScoreOverrideChange(STRING_TO_BOOLEAN_MAP[v])
                        }
                        options={YES_NO_OPTIONS}
                        labelProps={{
                          label: 'Disable Availability Prediction',
                        }}
                      />

                      {availabilityScoreOverrideState.enable && (
                        <Fragment>
                          <Global
                            styles={css`
                              .DateRangePicker_picker {
                                z-index: 300;
                              }
                            `}
                          />
                          <DateRangePicker
                            startDate={
                              availabilityScoreOverrideState.startAt
                                ? moment(availabilityScoreOverrideState.startAt)
                                : null
                            }
                            startDateId="start_date_picker"
                            endDate={
                              availabilityScoreOverrideState.endAt
                                ? moment(availabilityScoreOverrideState.endAt)
                                : null
                            }
                            endDateId="end_date_picker"
                            onDatesChange={handleAvailabilityScoreOverrideDateChange}
                            focusedInput={focusedInput}
                            onFocusChange={setFocusedInput}
                            small
                            showClearDates
                            withPortal
                          />
                        </Fragment>
                      )}
                    </AvailabilityScoreOverrideSelector>
                    {availabilityScoreOverrideState.enable &&
                      availabilityScoreOverrideState.dateError && (
                        <AvailabilityScoreOverrideDateError />
                      )}
                  </AvailabilityScoreOverrideContainer>
                )}

                <SelectField
                  compact
                  value={state.taxable.toString()}
                  onChange={v => handleTaxableChange(STRING_TO_BOOLEAN_MAP[v])}
                  options={YES_NO_OPTIONS}
                  labelProps={{
                    label: 'Taxable',
                  }}
                />

                <Flex.Row alignItems="end">
                  <TextField
                    compact
                    name="costPricePerUnit"
                    prefix="$"
                    value={state.costPricePerUnit}
                    onChange={handlePriceChange}
                    labelProps={{
                      label: 'Price',
                    }}
                  />
                  <Box css={{ marginBottom: '8px' }}>
                    <Slash />
                  </Box>
                  <SelectField
                    compact
                    placeholder="Select Unit"
                    value={state.priced_by}
                    onChange={handlePricedByChange}
                    options={COST_UNIT_OPTIONS}
                  />
                </Flex.Row>
              </Flex.Column>
            </InnerContainer>
          </Container>
        </Body>,
        <Footer>
          <Box css={{ marginLeft: 'auto' }}>
            <Button.Group>
              <Button
                style={buttonStyle}
                onClick={onClose}
                disabled={state.isBusy}
                variant="secondary"
              >
                Cancel
              </Button>
              <Button
                style={buttonStyle}
                onClick={onSubmit}
                disabled={disableSave()}
                variant="primary"
              >
                Save
              </Button>
            </Button.Group>
          </Box>
        </Footer>,
      ]}
    </Modal>
  )
}

export default withTrackEventOnMount({
  id: 'catalog.products.store_locations.bulk_edit.viewed',
  description: 'Viewed the bulk edit section of the product store locations page',
})(withNotificationsContext(BulkEditProductLocationsModal))
