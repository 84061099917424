import type { PropsWithChildren, FC } from 'react'
import styled from '@emotion/styled'
import { EditIcon, TrashIcon, RatingIcon } from '@instacart/ids-core'
import { IconButton, Tooltip } from '@retailer-platform/shared-components/src/tds'
import TableLoader from '../../../../../components/Table/TableLoader'
import TableActionsConfirmDismiss, {
  TableActionsConfirmDismissTypes,
} from '../../../../../components/Table/TableActionsConfirmDismiss'
// eslint-disable-next-line workspaces/no-relative-imports
import { useDomainMessages } from '../../../../../../../../../domains/products/src/utils/domain/intl'

interface Props {
  isMainAisle: boolean
  isEditingAisle: boolean
  isDeletingAisle: boolean
  isBusyAisle: boolean
  onUpdatePrimaryAisleClick: () => void
  onEditClick: () => void
  onDeleteClick: () => void
  onConfirmUpdateClick: () => void
  onConfirmDeleteClick: () => void
  onCancelClick: () => void
}

const Container = styled.div`
  width: 140px;
`

const FlexContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
`

const ActionContainer = styled.div`
  width: 40px;
`

const ProductAislesActions: FC<PropsWithChildren<Props>> = props => {
  const { isBusyAisle, isMainAisle, isEditingAisle, isDeletingAisle } = props

  const i18n = useDomainMessages({
    setPrimary: 'productsDomain.catalog.product.tooltip.setPrimary',
    edit: 'productsDomain.catalog.product.tooltip.edit',
    delete: 'productsDomain.catalog.product.tooltip.delete',
    deletePrimary: 'productsDomain.catalog.product.tooltip.deletePrimary',
  })

  if (isBusyAisle) {
    return (
      <Container data-testid="busy">
        <TableLoader />
      </Container>
    )
  }

  return (
    <FlexContainer>
      {isMainAisle ? (
        <ActionContainer />
      ) : (
        <ActionContainer>
          <Tooltip position="left" label={i18n.setPrimary}>
            <IconButton
              icon={RatingIcon}
              variant="secondary"
              onClick={props.onUpdatePrimaryAisleClick}
              data-testid="make-primary"
            />
          </Tooltip>
        </ActionContainer>
      )}
      {isEditingAisle || isDeletingAisle ? (
        <TableActionsConfirmDismiss
          actionType={
            isEditingAisle
              ? TableActionsConfirmDismissTypes.EDIT
              : TableActionsConfirmDismissTypes.DELETE
          }
          onConfirmClick={isEditingAisle ? props.onConfirmUpdateClick : props.onConfirmDeleteClick}
          onDismissClick={props.onCancelClick}
        />
      ) : (
        <>
          <ActionContainer>
            <Tooltip position="left" label={i18n.edit}>
              <IconButton
                icon={EditIcon}
                variant="secondary"
                onClick={props.onEditClick}
                data-testid="edit"
              />
            </Tooltip>
          </ActionContainer>
          <ActionContainer>
            <Tooltip position="left" label={isMainAisle ? i18n.deletePrimary : i18n.delete}>
              <IconButton
                icon={TrashIcon}
                variant="detrimental-secondary"
                onClick={props.onDeleteClick}
                data-testid="delete"
                disabled={isMainAisle}
              />
            </Tooltip>
          </ActionContainer>
        </>
      )}
    </FlexContainer>
  )
}

export default ProductAislesActions
