import React from 'react'
import {
  AttributeManagementCategoryEnum,
  AttributeManagementTypeEnum,
} from '../../../types/attributeManagement.types'
import { LLMPromptTemplateForm } from './LLMPromptTemplateForm'
import SnowflakeQueryInputForm from './SnowflakeQueryInputForm'
import PipelineForm from './PipelineForm'

export type FormMode = 'view' | 'edit' | 'override'

interface Props {
  type: AttributeManagementTypeEnum
  attributes: any
  mode: FormMode
  onChange: (attributes: any) => void
}

export interface FormProps {
  attributes: any
  mode: FormMode
  onChange: (attributes: any) => void
}

export const validateAttributes = (
  category: AttributeManagementCategoryEnum,
  type: AttributeManagementTypeEnum,
  attributes: any
) => {
  if (category === AttributeManagementCategoryEnum.ExtractionModel) {
    if (type === AttributeManagementTypeEnum.LLMPromptTemplate) {
      return attributes.prompt && attributes.model && attributes.properties.length > 0
    }
    return false
  }
  if (category === AttributeManagementCategoryEnum.Input) {
    if (type === AttributeManagementTypeEnum.SnowflakeQueryInput) {
      return attributes.query?.length
    }
    return false
  }
  if (category === AttributeManagementCategoryEnum.Pipeline) {
    if (type === AttributeManagementTypeEnum.Pipeline) {
      return attributes.steps.filter((step: any) => step !== null).length > 0
    }
    return false
  }
  return false
}

const StepAttributeForm: React.FC<Props> = ({ type, attributes, mode, onChange }) => {
  switch (type) {
    case AttributeManagementTypeEnum.SnowflakeQueryInput:
      return <SnowflakeQueryInputForm attributes={attributes} mode={mode} onChange={onChange} />
    case AttributeManagementTypeEnum.LLMPromptTemplate:
      return <LLMPromptTemplateForm attributes={attributes} mode={mode} onChange={onChange} />
    case AttributeManagementTypeEnum.Pipeline:
      return <PipelineForm attributes={attributes} mode={mode} onChange={onChange} />
    default:
      return <UnsupportedType type={type} />
  }
}

const UnsupportedType: React.FC<{ type: string }> = ({ type }) => (
  <div>Unsupported Type: {type}</div>
)

export default StepAttributeForm
