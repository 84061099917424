import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { FeatureToggle } from './featureToggles'
import { Permission } from './permissions'

export const onboardingAccessControl: DomainAccessControlConfig = {
  featureToggles: [FeatureToggle.launchOnboarding],
}

export const onboardingSiteSetupChecklistAccessControl: DomainAccessControlConfig = {
  warehouseFeatureToggles: [FeatureToggle.OnboardingSiteSetupChecklist],
}

export const onboardingRetailerProfileViewAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.OnboardingRetailerProfileView],
}

export const onboardingRetailerProfileModifyAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.OnboardingRetailerProfileModify],
}

export const adminStorefrontSetupViewAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.AdminStorefrontSetupView],
}

export const adminStorefrontSetupModifyAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.AdminStorefrontSetupModify],
}

export const partnerStorefrontSetupViewAccessControl: DomainAccessControlConfig = [
  {
    permissions: [Permission.PartnerStorefrontSetupView],
  },
  {
    permissions: [Permission.AdminStorefrontSetupView],
  },
]

export const partnerStorefrontSetupModifyAccessControl: DomainAccessControlConfig = [
  {
    permissions: [Permission.PartnerStorefrontSetupModify],
  },
  {
    permissions: [Permission.AdminStorefrontSetupModify],
  },
]
