import React, { type FunctionComponent, useMemo, useRef } from 'react'
import {
  Breadcrumb,
  Breadcrumbs,
  NotificationLevel,
  notify,
  PageHeader,
} from '@retailer-platform/shared-components'
import faker from 'faker'
import { type DashRouteComponentProps } from '@retailer-platform/dashboard/routing'
import { DocumentTitle, LoadingPage } from '@retailer-platform/dashboard/gin-and-tonic'
import { useUrlSearchParams } from '@retailer-platform/dashboard/utils'
import { Container, Page } from '../../utils/common.styles.tsx'
import { useDomainScopedPathname } from '../../utils/domain/routing.ts'
import { useDomainMessages } from '../../utils/domain/intl.ts'
import { CatalogAttributeManagementPlatformDetails } from '../../components/attribute-management-details/CatalogAttributeManagementPlatformDetails.tsx'
import {
  Instacart_Catalog_Catflow_V1_StepCategory,
  useGetStepVersionHistoryQuery,
  useListStepsQuery,
} from '../../api/index.ts'
import {
  AttributeManagementCategoryEnum,
  type AttributeManagementTypeEnum,
  type AttributeManagementStepVersion,
  GraphQLEnumToAttributeManagementCategoryEnum,
} from '../../types/attributeManagement.types.ts'

interface Props
  extends DashRouteComponentProps<{
    /*
    Add here any properties that you expect to come from the route. For instance,

    id: string
  */
  }> {}

const CatalogAdminAttributeManagementDetailsPage: FunctionComponent<
  React.PropsWithChildren<Props>
> = () => {
  const { value: stepId } = useUrlSearchParams('stepId')
  const platformPath = useDomainScopedPathname('app-admin-catalog-admin-attribute-management')
  const detailsPath = useDomainScopedPathname(
    'app-admin-catalog-admin-attribute-management-details',
    {
      stepId: stepId,
    }
  )

  const i18n = useDomainMessages({
    landingTitle: 'catalogAdminDomain.attributeManagement.nav.title',
  })

  const idempotencyKey = useRef(faker.random.uuid())
  const versionIdempotencyKey = useRef(faker.random.uuid())

  const { data: stepData, loading } = useListStepsQuery({
    variables: {
      clientId: 'catalog_camp_ipp',
      idempotencyKey: idempotencyKey.current,
      id: stepId,
    },
    onError: () => {
      notify({
        level: NotificationLevel.Error,
        contents: 'Error loading step',
      })
    },
  })

  const { data: versionData, loading: versionLoading } = useGetStepVersionHistoryQuery({
    variables: {
      clientId: 'catalog_camp_ipp',
      idempotencyKey: versionIdempotencyKey.current,
      stepId: stepId,
    },
    onError: () => {
      notify({
        level: NotificationLevel.Error,
        contents: 'Error loading version',
      })
    },
  })

  const versions: Array<AttributeManagementStepVersion> = useMemo(
    () =>
      versionData?.instacart_catalog_catflow_v1_CatflowAPI_GetStepVersionHistory?.stepVersion
        ?.map(version => ({
          createdWhy: version.createdWhy || '',
          createdAt: version.createdAt || '',
          version: version.version || '',
          id: version.id || '',
          isLive: false,
          state: version.state || '',
          stepId: version.stepId || '',
          attributes: version.attributes,
        }))
        .sort((a, b) => (parseInt(a.version ?? '0') || 0) - (parseInt(b.version ?? '0') || 0)) ||
      [],
    [versionData]
  )

  const step = stepData?.instacart_catalog_catflow_v1_CatflowAPI_ListSteps?.steps?.[0]
    ? {
        id: stepData?.instacart_catalog_catflow_v1_CatflowAPI_ListSteps?.steps?.[0]?.id || '',
        name: stepData?.instacart_catalog_catflow_v1_CatflowAPI_ListSteps?.steps?.[0]?.name || '',
        category:
          GraphQLEnumToAttributeManagementCategoryEnum[
            stepData?.instacart_catalog_catflow_v1_CatflowAPI_ListSteps?.steps?.[0]?.stepCategory ||
              Instacart_Catalog_Catflow_V1_StepCategory.Unknown
          ],
        type: (stepData?.instacart_catalog_catflow_v1_CatflowAPI_ListSteps?.steps?.[0]?.type ||
          'LLMPromptTemplate') as AttributeManagementTypeEnum,
        description:
          stepData?.instacart_catalog_catflow_v1_CatflowAPI_ListSteps?.steps?.[0]?.description ||
          '',
        owner: stepData?.instacart_catalog_catflow_v1_CatflowAPI_ListSteps?.steps?.[0]?.owner || '',
        versions: versions,
      }
    : undefined

  return (
    <Page>
      <DocumentTitle titleId="catalogAdminDomain.attributeManagement.nav.title" />
      <PageHeader
        addBottomPadding
        title={
          <Breadcrumbs>
            <Breadcrumb path={platformPath}>{i18n.landingTitle}</Breadcrumb>
            <Breadcrumb path={detailsPath}>{step?.name || ''}</Breadcrumb>
          </Breadcrumbs>
        }
      />
      <Container style={{ alignItems: 'stretch' }}>
        {loading || versionLoading || !step ? (
          <LoadingPage css={{ position: 'unset' }} />
        ) : (
          <CatalogAttributeManagementPlatformDetails step={step} />
        )}
      </Container>
    </Page>
  )
}

export default CatalogAdminAttributeManagementDetailsPage
