import React from 'react'
import { NotificationLevel, notify } from '@retailer-platform/shared-components'
import { IconButton } from '@instacart/ids-customers'
import { DownloadIcon } from '@instacart/ids-core'
import faker from 'faker'
import { useGetStepRunResultsLazyQuery } from '../../../../api'
type Props = {
  stepRunId: string
}

export const ResultDownloadButton = ({ stepRunId }: Props) => {
  const [triggerDownload] = useGetStepRunResultsLazyQuery({
    onError: (error: any) => {
      notify({
        contents: 'Error fetching step run results: ' + error.message,
        level: NotificationLevel.Error,
      })
    },
    onCompleted: (data: any) => {
      if (!data.instacart_catalog_catflow_v1_CatflowAPI_GetStepRunResults.results) {
        notify({
          contents: 'No results found.',
          level: NotificationLevel.Error,
        })
        return
      }
      if (
        data.instacart_catalog_catflow_v1_CatflowAPI_GetStepRunResults.results
          .toLowerCase()
          .startsWith('http')
      ) {
        window.open(
          data.instacart_catalog_catflow_v1_CatflowAPI_GetStepRunResults.results,
          '_blank'
        )
        return
      }
      const fileData = data.instacart_catalog_catflow_v1_CatflowAPI_GetStepRunResults.results
      const blob = new Blob([fileData], { type: 'text/plain' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.download = 'results.csv'
      link.href = url
      link.click()
    },
  })
  return (
    <IconButton
      icon={DownloadIcon}
      accessibleLabel="Download"
      onPress={() => {
        triggerDownload({
          variables: {
            clientId: 'catalog_camp_ipp',
            idempotencyKey: faker.random.uuid(),
            stepRunId: stepRunId,
          },
        })
      }}
    />
  )
}
