import { useState } from 'react'
import styled from '@emotion/styled'
import { SVGIcon, colors } from 'ic-snacks'
import { Button, LoadingDots, Text } from '@retailer-platform/shared-components/src/tds'
import {
  Tooltip,
  Link as TDSLink,
  SelectField,
  TDS_DEFAULT_COLORS,
} from '@retailer-platform/shared-components/src/tds'
import { FormattedMessage } from 'react-intl'
import { type RowInfo } from 'react-table'

import {
  withNotificationsContext,
  type NotificationsContextValue,
} from '../../../../../contexts/notifications/NotificationsContext'
import {
  type WarehouseContextValue,
  withWarehouseContext,
} from '../../../../../../utils/contexts/warehouse/WarehouseContext'
import withApi, { type WithApiInjectedProps } from '../../../../../common/withApi'
import withDepartmentAisles from '../../../../../common/withDepartmentAisles'
import deprecatedAnalytics from '../../../../../common/deprecatedAnalytics'
import { type RetailerRouteComponentProps } from '../../../../../components/RetailerRoute'

import FilterBar from '../../../../../components/FilterBar'
import Table from '../../../../../components/Table/Table'
import { trackEvent } from '../../../../../../utils/events/trackEvent'
import Link from '../../../../../components/Link'

import { withTrackEventOnMount } from '../../../../../../utils/events/hocs'
import { type ProductResponseBody, type Aisle, type MainAisle } from '../../../types/product.types'

// @FIXME refactor to TS
import ProductDetailsChangeAisleModal from './ProductDetailsChangeAisleModal'
import ProductAislesActions from './ProductAislesActions'
import { AccessGated } from '../../../../../../exports/access-control.ts'
import { Permission } from '../../../../../common/types.ts'
import { useRetailerProductAggregateDataMesh } from '../../../../../sections/catalog/graphql/retailerProductAggregateDataMesh.hooks.ts'

const Container = styled.div`
  border-left: 1px solid ${colors.GRAY_93};
  flex: 1;
`

const ICON_CELL_WIDTH = 50

interface DepartmentAndAisleProps {
  departments: { id: number; name: string }[] | null
  aisles: { id: number; name: string }[] | null
  aislesLoading: boolean
  departmentsLoading: boolean
}

type Props = DepartmentAndAisleProps &
  WithApiInjectedProps &
  NotificationsContextValue &
  WarehouseContextValue &
  RetailerRouteComponentProps & {
    partnerId: string
    warehouseId: string
    productId: string
    product: ProductResponseBody
    onDepartmentSelected?: (id: number) => void
    className?: string
  }

const sortPrimaryAisleFirst = (
  mainAisle: MainAisle | null,
  aisles: { id: number; name: string }[]
): { id: number; name: string }[] => {
  if (!mainAisle || !mainAisle.id) return aisles

  return [...aisles].sort(a => (mainAisle.id === a.id ? -1 : 1))
}

const ProductAisles = (props: Props) => {
  const [draft, setDraft] = useState<{
    departmentId: number | null
    aisleId: number | null
  }>({
    departmentId: null,
    aisleId: null,
  })
  const [currentEditAisleId, setCurrentEditAisleId] = useState<number | null>(null)
  const [currentDeleteAisleId, setCurrentDeleteAisleId] = useState<number | null>(null)
  const [busyAisleId, setBusyAisleId] = useState<number | null>(null)
  const [showAddAisleModal, setShowAddAisleModal] = useState(false)
  const [aisles, setAisles] = useState<Partial<Aisle>[]>(
    sortPrimaryAisleFirst(props.product.main_aisle, props.product.aisles || [])
  )

  const { apiLoading: availabilityLoading, apiResult } = useRetailerProductAggregateDataMesh({
    retailerId: props.warehouseId,
    productIds: [props.productId],
  })

  const availableItemsCount = parseInt(apiResult?.retailerProductData?.[0].availableItemsCount, 10)

  const filterAvailableAisles = (data: { id: number; name: string }[]) =>
    data.filter(
      ({ id }) =>
        !props.product.aisles.some(
          ({ id: existingId }) => existingId === id && existingId !== currentEditAisleId
        )
    )

  const optionify = (data: { id: number; name: string }[]) =>
    data.map(({ id, name }) => ({
      label: name,
      value: id.toString(),
    }))

  const columns = [
    {
      header: null,
      width: ICON_CELL_WIDTH,
      Cell: ({ original }: RowInfo) => {
        const mainAisleId = props.product.main_aisle && props.product.main_aisle.id

        if (original.id === mainAisleId) {
          return (
            <Tooltip
              position="right"
              // TODO: TDS Tooltip only support string labels
              // @ts-ignore
              label={<FormattedMessage id="catalog.product.tooltip.primary" />}
            >
              <SVGIcon name="starFilled" color={TDS_DEFAULT_COLORS.colors.brandPrimaryDark} />
            </Tooltip>
          )
        }
        return null
      },
    },
    {
      header: (
        <Text typography="bodyRegular">
          {<FormattedMessage id="catalog.product.head.department" />}
        </Text>
      ),
      Cell: ({ original }: RowInfo) => {

        if (original.id === currentEditAisleId) {
          return (
            <SelectField
              width="180px"
              value={draft.departmentId?.toString()}
              isLoading={props.departmentsLoading}
              onChange={v => {
                handleDepartmentChange(Number(v))
              }}
              options={optionify(props.departments || [])}
            />
          )
        }

        return (
          <AccessGated
            accessControlConfig={{ permissions: Permission.CatalogProductsModify }}
            accessBlockedResult={'disable'}
          >
            <Link route={{ name: 'aisles', params: { department_id: original.department.id } }}>
              <TDSLink>{original.department.name}</TDSLink>
            </Link>
          </AccessGated>
        )
      },
    },
    {
      header: (
        <Text typography="bodyRegular">{<FormattedMessage id="catalog.product.head.aisle" />}</Text>
      ),
      Cell: ({ original }: RowInfo) => {
        if (original.id === currentEditAisleId) {
          const filteredOptions = filterAvailableAisles(props.aisles || [])

          return (
            <SelectField
              width="180px"
              value={draft.aisleId?.toString()}
              isLoading={props.aislesLoading}
              onChange={v => {
                handleAisleChange(Number(v))
              }}
              options={optionify(filteredOptions)}
            />
          )
        }

        return (
          <AccessGated
            accessControlConfig={{ permissions: Permission.CatalogProductsModify }}
            accessBlockedResult={'disable'}
          >
            <Link
              route={{
                name: 'aisle',
                params: {
                  department_id: original.department.id,
                  aisle_id: original.id,
                },
              }}
            >
              <TDSLink>{original.name}</TDSLink>
            </Link>
          </AccessGated>
        )
      },
    },
    {
      header: (
        <Text typography="bodyRegular">
          {<FormattedMessage id="catalog.product.head.availability" />}
        </Text>
      ),
      Cell: ({ original }: RowInfo) => (
        <Text typography="bodyRegular">
          <FormattedMessage
            id="storeLocations.count"
            values={{
              count: availableItemsCount ? availableItemsCount : '—',
            }}
          />
        </Text>
      ),
    },
    {
      header: null,
      width: ICON_CELL_WIDTH * 3,
      Cell: ({ original }: RowInfo) => {
        const mainAisleId = props.product.main_aisle && props.product.main_aisle.id
        const isMainAisle = original.id === mainAisleId
        const isEditingAisle = original.id === currentEditAisleId
        const isDeletingAisle = original.id === currentDeleteAisleId
        const isBusyAisle = original.id === busyAisleId

        return (
          <AccessGated accessControlConfig={{ permissions: Permission.CatalogProductsModify }}>
            <ProductAislesActions
              isMainAisle={isMainAisle}
              isEditingAisle={isEditingAisle}
              isDeletingAisle={isDeletingAisle}
              isBusyAisle={isBusyAisle}
              onUpdatePrimaryAisleClick={() => handleUpdatePrimaryAisle(original.id)}
              onEditClick={() => handleEditClick(original.id, original.department.id)}
              onDeleteClick={() => handleDeleteClick(original.id)}
              onConfirmUpdateClick={handleConfirmUpdate}
              onConfirmDeleteClick={handleConfirmDeleteClick}
              onCancelClick={handleCancelClick}
            />
          </AccessGated>
        )
      },
    },
  ]

  const handleAisleChange = (aisleId: number) => {
    setDraft(prev => ({
      ...prev,
      aisleId,
    }))
  }

  const handleDepartmentChange = (departmentId: number) => {
    props.onDepartmentSelected?.(departmentId)
    setDraft({
      departmentId,
      aisleId: null,
    })
  }

  const handleCancelClick = () => {
    setCurrentDeleteAisleId(null)
    setCurrentEditAisleId(null)
    setDraft({
      departmentId: null,
      aisleId: null,
    })
  }

  const handleEditClick = (currentEditAisleId: number, currentEditDepartmentId: number) => {
    props.onDepartmentSelected?.(currentEditDepartmentId)
    setCurrentEditAisleId(currentEditAisleId)
    setDraft({
      departmentId: currentEditDepartmentId,
      aisleId: currentEditAisleId,
    })
  }

  const handleDeleteClick = (aisleId: number) => {
    setCurrentDeleteAisleId(aisleId)
  }

  const handleUpdatePrimaryAisle = async (id: number) => {
    const { partnerId, warehouseId, productId, warehouse } = props
    const localeCode = warehouse.defaultLocale.localeCode.toUpperCase()
    const payload = {
      to_aisle_id: id,
      product_ids: productId,
      locale_code: localeCode,
    }

    setBusyAisleId(id)

    try {
      await props.api.put(
        `/v1/partners/${partnerId}/warehouses/${warehouseId}/products/bulk_set_main_aisle`,
        { data: payload }
      )

      trackEvent({
        id: 'catalog.products.departments_and_aisles.updated_primary_aisle',
        description: 'Updated a primary aisle',
        data: {
          aisleId: id,
          productId: parseInt(productId, 10),
        },
      })

      props.notify(<FormattedMessage id="catalog.product.aisle.updatePrimary.success" />)
    } catch (e) {
      props.notifyError(<FormattedMessage id="notification.request.failed" />)
    } finally {
      handleAisleAsyncDone()
    }
  }

  const handleConfirmDeleteClick = async () => {
    const aisleId = currentDeleteAisleId
    const aisle = aisles.find(({ id }) => id === aisleId) || {
      name: 'Unknown',
    }

    setBusyAisleId(aisleId)

    try {
      const { productId, partnerId, warehouseId, api } = props
      await api.put(
        `/v1/partners/${partnerId}/warehouses/${warehouseId}/products/${productId}/remove_aisle`,
        {
          data: {
            aisle_id: aisleId,
          },
        }
      )

      trackEvent({
        id: 'catalog.products.departments_and_aisles.deleted_aisle',
        description: 'Deleted an aisle',
        data: {
          aisleId,
          productId: parseInt(productId, 10),
        },
      })

      props.notify(
        <FormattedMessage
          id="catalog.product.aisles.deleteSuccess"
          values={{ aisleName: aisle.name }}
        />
      )

      setCurrentDeleteAisleId(null)
      setAisles(prev => prev.filter(({ id }) => id !== aisleId))
    } catch (e) {
      props.notifyError(
        <FormattedMessage
          id="catalog.product.aisles.deleteFailure"
          values={{ aisleName: aisle.name }}
        />
      )
      setCurrentDeleteAisleId(null)
    } finally {
      handleAisleAsyncDone()
    }
  }

  const handleConfirmUpdate = async () => {
    const { aisleId, departmentId } = draft
    const { partnerId, warehouseId, productId, aisles: propsAisles, departments } = props

    if (!aisleId || !departments || !propsAisles) return

    const payload = {
      from_aisle_id: currentEditAisleId,
      to_aisle_id: aisleId,
    }

    setBusyAisleId(currentEditAisleId)

    try {
      await props.api.put(
        `/v1/partners/${partnerId}/warehouses/${warehouseId}/products/${productId}/change_aisle`,
        { data: payload }
      )

      deprecatedAnalytics.track('product.aisle_change', {
        product_id: productId,
        source_type: 'change_aisle',
        source_value: aisleId,
      })

      trackEvent({
        id: 'catalog.products.departments_and_aisles.change_aisle_success',
        description: 'Edited an aisle',
        data: {
          aisleId,
          productId: parseInt(productId, 10),
        },
      })

      const newAisle = propsAisles.find(({ id }) => id === aisleId)
      const newDepartment = departments.find(({ id }) => id === departmentId)

      if (!newAisle || !newDepartment) return

      setCurrentEditAisleId(null)
      setAisles(prev =>
        prev.map(aisle =>
          aisle.id === currentEditAisleId
            ? { ...aisle, ...newAisle, department: { ...newDepartment } }
            : aisle
        )
      )
      setDraft({
        departmentId: null,
        aisleId: null,
      })

      props.notify(<FormattedMessage id="catalog.product.aisles.updateSuccess" />)
    } catch (e) {
      setCurrentEditAisleId(null)
      setDraft({
        departmentId: null,
        aisleId: null,
      })

      props.notifyError(<FormattedMessage id="catalog.product.aisles.updateFailure" />)
    } finally {
      handleAisleAsyncDone()
    }
  }

  const handleAddAisleClick = () => {
    setShowAddAisleModal(true)
  }

  const handleCloseModal = () => {
    setShowAddAisleModal(false)
  }

  const handleAddAisleSuccess = (aisle: Aisle) => {
    setShowAddAisleModal(false)
    setAisles(prev => [{ ...aisle }, ...prev])
  }

  const handleAisleAsyncDone = () => {
    setBusyAisleId(null)
  }

  return (
    <Container className={props.className}>
      {availabilityLoading ? (
        <LoadingDots />
      ) : (
        <>
          <FilterBar padding="15px 20px" style={{ justifyContent: 'flex-end' }}>
            <AccessGated
          accessControlConfig={{ permissions: Permission.CatalogProductsModify }}
          accessBlockedResult={'disable'}
        >
          <Button variant="primary" onClick={handleAddAisleClick}>
            <FormattedMessage id="catalog.product.aisles.add" />
          </Button>
        </AccessGated>
      </FilterBar>
      <Table
        columns={columns}
        data={aisles}
        paginated={false}
        noDataText="No Product Department & Aisles"
      />
      {showAddAisleModal && (
        <ProductDetailsChangeAisleModal
          product={props.product}
          onCloseSuccess={handleAddAisleSuccess}
            onClose={handleCloseModal}
          />
        )}
        </>
      )}
    </Container>
  )
}

export default withTrackEventOnMount({
  id: 'catalog.products.departments_and_aisles.viewed',
  description: 'Viewed the departments and aisles page for a specific product',
})(
  // @ts-expect-error, all these legacy decorators have broken types
  withNotificationsContext(withDepartmentAisles(withApi(withWarehouseContext(ProductAisles))))
) as any
