import { ICPlatformVersion } from '@retailer-platform/dashboard/access-control'
import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'

export const widgetsConfigViewAccessControl: DomainAccessControlConfig = {
  minICPlatformVersion: ICPlatformVersion.r2025_02_0,
  permissions: [Permission.StoreConfigsView],
}

export const widgetsConfigModifyAccessControl: DomainAccessControlConfig = {
  minICPlatformVersion: ICPlatformVersion.r2025_02_0,
  permissions: [Permission.StoreConfigsModify],
}
