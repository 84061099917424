import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { FeatureEntitlements } from './featureEntitlements'
import { Permission } from './permissions'

export const creativeManagerV2AccessControl: DomainAccessControlConfig = [
  {
    permissions: [Permission.CreativeManagerView],
    featureEntitlementIds: [FeatureEntitlements.PageBuilder],
  },
]

export const creativeManagerModifyAccessControl: DomainAccessControlConfig = [
  {
    permissions: [Permission.CreativeManagerModify],
    featureEntitlementIds: [FeatureEntitlements.PageBuilder],
  },
]
