import {
  spacing,
  ModalContainer,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
  colors,
  borderRadius,
  notify,
  NotificationLevel,
} from '@retailer-platform/shared-components'
import {
  TextField,
  TextArea,
  SelectField,
  Button,
  ButtonText,
} from '@retailer-platform/shared-components/src/tds'
import React, { type FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'
import { IssueIcon } from '@instacart/ids-core'
import { LoadingGenericBase } from '@instacart/ids-customers'
import styled from '@emotion/styled'
import { fontSize } from '@retailer-platform/dashboard/gin-and-tonic'
import { useCurrentAccountContext } from '@retailer-platform/dashboard/utils'
import { useDomainMessages } from '../../../utils/domain/intl'
import {
  type DetectionVersionAttributes,
  type DetectionVersionAttributesInput,
  type GetDetectionResponseDetectionVersion,
  useCreateDetectionVersionMutation,
  useGetDetectionQuery,
} from '../../../api'
import { useHasPermission } from '../../../utils/hasPermission.hooks.ts'
import { Permission } from '../../../access-control/permissions.ts'
import { cssTableFilterTextButtonStyle } from './MdPlatformListFilterContainer.tsx'
import { Property, PropertyContainer, PropertySection } from './MdPlatformListTableRow.tsx'

interface Props {
  id: string
  onClose: () => void
}

export const TextInput = styled(TextArea)<{ changed?: boolean; error?: boolean }>(
  ({ error, changed }) => ({
    height: '100%',
    boxShadow: 'none',
    background: changed ? colors.LOYALTY.LIGHT : error ? colors.DETRIMENTAL.LIGHT : 'inherit',
    border: error ? `2px solid ${colors.DETRIMENTAL.REGULAR}` : `1px solid ${colors.GRAYSCALE.X30}`,
    padding: spacing.X12,
    fontSize: fontSize.X15,
    color: colors.GRAYSCALE.X70,
    ':focus': {
      border: `1px solid ${colors.GRAYSCALE.X30}`,
      outline: `2px solid ${colors.GRAYSCALE.X70}`,
      boxShadow: 'none',
      outlineOffset: '1px',
    },
  })
)

export const Input = styled.input<{ changed?: boolean; error?: boolean }>(({ error, changed }) => ({
  boxShadow: 'none',
  border: error ? `2px solid ${colors.DETRIMENTAL.REGULAR}` : `1px solid ${colors.GRAYSCALE.X30}`,
  background: changed
    ? colors.LOYALTY.LIGHT
    : error
    ? colors.DETRIMENTAL.LIGHT
    : colors.GRAYSCALE.X0,
  padding: spacing.X12,
  fontSize: fontSize.X15,
  color: colors.GRAYSCALE.X70,
  borderRadius: borderRadius.X8,
  ':focus': {
    outline: `2px solid ${colors.GRAYSCALE.X70}`,
    outlineOffset: '1px',
  },
  ':disabled': {
    background: colors.GRAYSCALE.X10,
  },
}))

const PROPERTY_WARNING_CHANGE = '• Change Requires Review'

const PROPERTY_REQUIRED = '• Value Required'

export const MdPlatformModifyModal: FunctionComponent<React.PropsWithChildren<Props>> = ({
  id,
  onClose,
}) => {
  const [detectionAttributesState, setDetectionAttributesState] =
    useState<DetectionVersionAttributes | null>(null)
  const originalDetectionAttributes = useRef<DetectionVersionAttributes | null>(null)

  const currentAccount = useCurrentAccountContext()
  const hasManagerAccess = useHasPermission(
    currentAccount,
    Permission.CatalogDetectionManagementModifyAccess
  )

  const i18n = useDomainMessages({
    title: 'catalogAdminDomain.mdPlatformList.modal.title',
    submit: 'catalogAdminDomain.mdPlatformList.modal.submit',
    cancel: 'catalogAdminDomain.mdPlatformList.modal.cancel',
    reset: 'catalogAdminDomain.mdPlatformList.modal.reset',
    reason: 'catalogAdminDomain.mdPlatformList.modal.input.reason',
    reasonPlaceholder: 'catalogAdminDomain.mdPlatformList.modal.input.reason.placeholder',
    limit: 'catalogAdminDomain.mdPlatformList.modal.input.limit',
    enabled: 'catalogAdminDomain.mdPlatformList.modal.input.enabled',
    query: 'catalogAdminDomain.mdPlatformList.modal.input.query',
    defectTag: 'catalogAdminDomain.mdPlatformList.modal.input.defectTag',
    cadence: 'catalogAdminDomain.mdPlatformList.modal.input.cadence',
    owner: 'catalogAdminDomain.mdPlatformList.modal.input.owner',
    description: 'catalogAdminDomain.mdPlatformList.modal.input.description',
    createError: 'catalogAdminDomain.mdPlatformList.modal.error.create',
    getError: 'catalogAdminDomain.mdPlatformList.modal.error.get',
  })

  const [
    apiTriggerCreateDetectionVersion,
    { loading: apiCreateDetectionVersionLoading, error: apiCreateDetectionVersionError },
  ] = useCreateDetectionVersionMutation()

  const {
    data: apiGetDetectionQueryData,
    error: apiGetDetectionQueryError,
    loading: apiGetDetectionQueryLoading,
  } = useGetDetectionQuery({
    variables: {
      id: id,
    },
    fetchPolicy: 'network-only',
  })

  const versions = useMemo(() => {
    const versions: GetDetectionResponseDetectionVersion[] =
      apiGetDetectionQueryData?.demeterServiceGetDetection?.versions ?? []
    return versions
  }, [apiGetDetectionQueryData?.demeterServiceGetDetection?.versions])

  useEffect(() => {
    let lastSubmittedDetection: GetDetectionResponseDetectionVersion

    for (const version of versions) {
      if (!lastSubmittedDetection) {
        lastSubmittedDetection = version
        break
      }
    }

    let attributes: DetectionVersionAttributes
    if (lastSubmittedDetection) {
      attributes = {
        ...lastSubmittedDetection.attributes,
        createdWhy: '',
        createdBy: '',
      }
      setDetectionAttributesState(attributes)
    } else {
      attributes = {
        owner: '',
        limit: '0',
        query: '',
        description: '',
        cadence: '0 0 * * *',
        enabled: false,
        fixDefectTag: '',
        createdWhy: '',
        createdBy: '',
      }
      setDetectionAttributesState(attributes)
    }
    originalDetectionAttributes.current = attributes
  }, [versions])

  const hasError = apiGetDetectionQueryError || apiCreateDetectionVersionError
  const isLoading = apiGetDetectionQueryLoading || apiCreateDetectionVersionLoading

  const hasChanged = (property: string) =>
    detectionAttributesState[property] != originalDetectionAttributes.current[property]

  const warningFlags = useMemo(
    () => ({
      limit:
        parseInt(detectionAttributesState?.limit) >
        parseInt(originalDetectionAttributes.current?.limit),
      enabled:
        originalDetectionAttributes.current?.enabled == false &&
        detectionAttributesState?.enabled != originalDetectionAttributes.current?.enabled,
      fixDefectTag:
        detectionAttributesState?.fixDefectTag != originalDetectionAttributes.current?.fixDefectTag,
      query: detectionAttributesState?.query != originalDetectionAttributes.current?.query,
      cadence: detectionAttributesState?.cadence != originalDetectionAttributes.current?.cadence,
    }),
    [originalDetectionAttributes, detectionAttributesState]
  )

  const changedAttributes: DetectionVersionAttributesInput = useMemo(
    () =>
      Object.keys(detectionAttributesState ?? {}).reduce((previousValue, attributeName) => {
        if (
          originalDetectionAttributes.current[attributeName] !=
          detectionAttributesState[attributeName]
        ) {
          return {
            ...previousValue,
            [attributeName]: detectionAttributesState[attributeName],
          }
        }
        return previousValue
      }, {}),
    [detectionAttributesState]
  )

  useEffect(() => {
    if (apiGetDetectionQueryError && !isLoading) {
      notify({
        icon: <IssueIcon />,
        level: NotificationLevel.Error,
        contents: i18n.getError,
      })
    }
  }, [apiGetDetectionQueryError, i18n.getError, isLoading])

  useEffect(() => {
    if (apiCreateDetectionVersionError && !isLoading) {
      notify({
        icon: <IssueIcon />,
        level: NotificationLevel.Error,
        contents: i18n.createError,
      })
    }
  }, [apiCreateDetectionVersionError, i18n.createError, isLoading])

  const onSubmit = () => {
    apiTriggerCreateDetectionVersion({
      variables: {
        id: id,
        attributes: changedAttributes,
      },
    }).then(value => {
      if (value?.errors?.length > 0) return
      onClose()
    })
  }

  return (
    <Modal maxWidth={'90%'} maxHeight={'90%'} isOpen={true} hideCloseButton={true}>
      <ModalContainer>
        <ModalHeader>{i18n.title}</ModalHeader>
        {detectionAttributesState && (
          <ModalBody>
            <PropertyContainer>
              <PropertySection style={{ width: 'min-content', flex: 0, minWidth: '30%' }}>
                <Property>
                  <TextField
                    error={detectionAttributesState?.createdWhy?.length === 0}
                    value={detectionAttributesState?.createdWhy}
                    onChange={e => {
                      setDetectionAttributesState({
                        ...detectionAttributesState,
                        createdWhy: e.target.value,
                      })
                    }}
                    placeholder={i18n.reasonPlaceholder}
                    labelProps={{
                      label: i18n.reason,
                    }}
                    description={
                      detectionAttributesState?.createdWhy?.length === 0
                        ? PROPERTY_REQUIRED
                        : undefined
                    }
                  />
                </Property>
                <Property>
                  <TextField
                    onChange={e => {
                      setDetectionAttributesState({
                        ...detectionAttributesState,
                        limit: `${e.target.value}`,
                      })
                    }}
                    type={'number'}
                    value={detectionAttributesState?.limit}
                    labelProps={{
                      label: i18n.limit,
                      hint: warningFlags.limit ? PROPERTY_WARNING_CHANGE : undefined,
                    }}
                  />
                </Property>
                <Property>
                  <TextField
                    onChange={e => {
                      setDetectionAttributesState({
                        ...detectionAttributesState,
                        fixDefectTag: `${e.target.value}`,
                      })
                    }}
                    value={detectionAttributesState?.fixDefectTag}
                    labelProps={{
                      label: i18n.defectTag,
                    }}
                  />
                </Property>
                <Property>
                  <SelectField
                    value={JSON.stringify(detectionAttributesState.enabled)}
                    onChange={value => {
                      setDetectionAttributesState({
                        ...detectionAttributesState,
                        enabled: value === 'true',
                      })
                    }}
                    options={[
                      { label: 'True', value: 'true' },
                      { label: 'False', value: 'false' },
                    ]}
                    labelProps={{
                      label: i18n.enabled,
                      hint: warningFlags.enabled ? PROPERTY_WARNING_CHANGE : undefined,
                    }}
                  />
                </Property>
                {hasManagerAccess && (
                  <Property>
                    <TextField
                      onChange={e => {
                        setDetectionAttributesState({
                          ...detectionAttributesState,
                          cadence: e.target.value,
                        })
                      }}
                      value={detectionAttributesState?.cadence}
                      labelProps={{
                        label: i18n.cadence,
                        hint: warningFlags.cadence ? PROPERTY_WARNING_CHANGE : undefined,
                      }}
                    />
                  </Property>
                )}
                <Property>
                  <TextField
                    onChange={e => {
                      setDetectionAttributesState({
                        ...detectionAttributesState,
                        owner: e.target.value,
                      })
                    }}
                    value={detectionAttributesState?.owner}
                    labelProps={{
                      label: i18n.owner,
                    }}
                  />
                </Property>
              </PropertySection>
              <PropertySection style={{ width: '75%', flex: 1, height: '800px' }}>
                <Property>
                  <TextArea
                    style={{ height: '150px' }}
                    value={detectionAttributesState?.description}
                    onChange={e => {
                      setDetectionAttributesState({
                        ...detectionAttributesState,
                        description: e.target.value,
                      })
                    }}
                    labelProps={{
                      label: i18n.description,
                    }}
                  />
                </Property>
                <Property style={{ height: '100%' }}>
                  <TextArea
                    onChange={e => {
                      setDetectionAttributesState({
                        ...detectionAttributesState,
                        query: e.target.value,
                      })
                    }}
                    value={detectionAttributesState.query}
                    labelProps={{
                      label: i18n.query,
                      hint: warningFlags.query ? PROPERTY_WARNING_CHANGE : undefined,
                    }}
                  />
                </Property>
              </PropertySection>
            </PropertyContainer>
          </ModalBody>
        )}
        <ModalFooter>
          {isLoading && (
            <LoadingGenericBase
              // @ts-expect-error, this accepted but not properly typed
              css={{ backgroundColor: 'white' }}
            />
          )}
          <Button.Group>
            <ButtonText
              css={cssTableFilterTextButtonStyle}
              onClick={() => {
                setDetectionAttributesState(originalDetectionAttributes.current)
              }}
            >
              {i18n.reset}
            </ButtonText>
            <Button variant={'secondary'} onClick={() => onClose()} disabled={isLoading}>
              {i18n.cancel}
            </Button>
            <Button
              onClick={() => onSubmit()}
              disabled={
                isLoading ||
                detectionAttributesState?.createdWhy?.length === 0 ||
                Object.keys(changedAttributes).length <= 1
              }
            >
              {i18n.submit}
            </Button>
          </Button.Group>
        </ModalFooter>
      </ModalContainer>
    </Modal>
  )
}
