import styled from '@emotion/styled'
import { CloseIcon } from '@instacart/ids-core'
import { Text } from '@instacart/ids-customers'
import { useDashMessages } from '../../../intl/intl.hooks'
import { ViewType } from '../retailer-scope-wrapper/RetailerScopeWrapper'
import { CARROT_LOGO } from './RetailerAndSitePicker'

const StyledLogoImage = styled.img({
  width: 20,
  height: 20,
  background: 'white',
  borderRadius: '50%',
  objectFit: 'cover',
})

const CloseButtonContainer = styled.div({
  display: 'flex',
  marginLeft: 'auto',
  marginRight: 0,
})

export const PickerLabel = ({
  currentSelectedRetailer,
  currentSelectedStoreConfig,
  changeRetailerId,
  retailerSelectionOptional,
  changeStoreConfigId,
  selectionType,
}) => {
  const pickerLabels = []

  const messages = useDashMessages({
    allBanners: 'retailerPicker.allBanners',
    selectBanner: 'retailerPicker.selectBanner',
    selectBannerAndSite: 'retailerPicker.selectBannerAndSite',
  })

  if (currentSelectedRetailer) {
    pickerLabels.push(
      <StyledLogoImage key="logo" src={currentSelectedRetailer.logo?.url || CARROT_LOGO} />
    )
    pickerLabels.push(
      <Text key="retailerName" typography="bodyMedium1">
        {currentSelectedRetailer.label}
      </Text>
    )
  }
  if (currentSelectedStoreConfig) {
    pickerLabels.push(
      <Text key="dash" typography="bodySmall2">
        {'-'}
      </Text>
    )
    pickerLabels.push(
      <Text key="storeConfigName" typography="bodyMedium2">
        {currentSelectedStoreConfig.brandedName || ''}
      </Text>
    )
  }
  if (pickerLabels.length > 0 && retailerSelectionOptional) {
    pickerLabels.push(
      <CloseButtonContainer
        key="closeButton"
        onClick={e => {
          changeStoreConfigId('0')
          changeRetailerId('0')
          e.stopPropagation()
        }}
      >
        <CloseIcon width="20px" height="20px" />
      </CloseButtonContainer>
    )
  }

  const selectText =
    selectionType == ViewType.RETAILER ? messages.selectBanner : messages.selectBannerAndSite
  const defaultText = retailerSelectionOptional ? messages.allBanners : selectText
  return <>{pickerLabels.length > 0 ? pickerLabels : defaultText}</>
}
