const EN_US = {
  // TODO: move to shared component
  'actionBar.default.primaryBtnText': 'Confirm',
  'actionBar.default.cancelBtnText': 'Cancel',

  'onboardingDomain.title': 'Onboarding',
  'onboardingDomain.featureNotReady': 'Sorry, the feature is not fully rolled out yet.',
  'onboardingDomain.notApplicable': 'n/a',
  'onboardingDomain.navItem.storeManagement': 'Store Management',
  'onboardingDomain.navItem.retailerProfile': 'Retailer profile',

  'onboardingDomain.error.common':
    'An error has occurred. Please try again or contact Instacart support.',
  'onboardingDomain.error.featureNotAvailableForBanner':
    'This feature is not available for the selected banner. Please switch to a different banner and try again, or contact Instacart support',
  'onboardingDomain.error.failedToLoadData':
    'Failed to load data. Please try again or contact Instacart support.',
  'onboardingDomain.error.failedToLoadSomeTypeOfData':
    'Failed to load {dataType} data. Please try again or contact Instacart support.',
  'onboardingDomain.error.failedToLoadWarehouseLocation':
    'Failed to load warehouse location data. Please try again or contact Instacart support.',
  'onboardingDomain.error.invalidWarehouseLocationId':
    'Invalid warehouse location ID. Please try again or contact Instacart support.',
  'onboardingDomain.error.invalidAddress':
    'Address is invalid. Please correct your address and try again.',
  'onboardingDomain.error.invalidAddressWithReason':
    'Address is invalid{reason}. Please correct your address or try it with a different format. If you still receive an error please contact retailer-onboarding@instacart.com for further assistance.',
  'onboardingDomain.error.invalidLocationWithReason':
    'Store location is invalid{reason}. Please correct your address or try it with a different format. If you still receive an error please contact retailer-onboarding@instacart.com for further assistance.',
  'onboardingDomain.error.unserviceable':
    "We're sorry, this postal code is not currently within Instacart's serviceable area. Please confirm the postal code has been entered correctly. For further assistance, please contact retailer-onboarding@instacart.com.",
  'onboardingDomain.error.postalCodeNotFound':
    'This postal code is not found. Please confirm the postal code has been entered correctly. For further assistance, please contact retailer-onboarding@instacart.com.',
  'onboardingDomain.error.addressHasBeenTaken':
    'Address has already been taken. Please correct your address or try it with a different format. If you still receive an error please contact retailer-onboarding@instacart.com for further assistance.',
  'onboardingDomain.error.locationCodeHasBeenTaken':
    'Location code has already been taken. Please correct your location code. If you still receive an error please contact retailer-onboarding@instacart.com for further assistance.',
  'onboardingDomain.error.storeNameNotUnique':
    'Store name must be unique. Please update the store name and try again.',
  'onboardingDomain.validation.invalidPostalCode': 'Invalid postal code',
  'onboardingDomain.validation.invalidCertifiedDeliveryThreshold':
    'Invalid certified delivery threshold. Please enter a number.',
  'onboardingDomain.alert.loadingData': 'Loading data...',

  'onboardingDomain.supportMessage':
    'Please reach out to retailer-onboarding@instacart.com if you have questions.',

  'onboardingDomain.progressBar.progressMessage': '{current} of {total} steps completed',
  'onboardingDomain.accountSetup.header': 'Welcome to Instacart Platform Portal',
  'onboardingDomain.accountSetup.header.content': 'Create your Instacart Platform Portal account',
  'onboardingDomain.accountSetup.submit': 'Create account',
  'onboardingDomain.accountSetup.form.fields.partnerName': 'Partner name',
  'onboardingDomain.accountSetup.form.fields.email': 'Email Address',
  'onboardingDomain.accountSetup.form.fields.givenName': 'First name',
  'onboardingDomain.accountSetup.form.fields.surname': 'Last name',
  'onboardingDomain.accountSetup.form.fields.password': 'Password',
  'onboardingDomain.accountSetup.form.fields.password.hint': 'At least 8 characters.',
  'onboardingDomain.accountSetup.form.fields.password.placeholder': 'Set up password',
  'onboardingDomain.accountSetup.form.fields.confirmPassword': 'Confirm password',
  'onboardingDomain.form.error.required': '{fieldName} cannot be blank.',
  'onboardingDomain.form.error.email.pattern': 'Invalid Email',
  'onboardingDomain.form.error.password.minLength': 'Password must be at least 8 characters long',
  'onboardingDomain.form.error.confirmPassword.validate': 'Passwords must match',
  'onboardingDomain.form.error.insecurePassword': 'Please pick a more secure password',
  'onboardingDomain.accountSetup.error.expiredToken':
    'Sorry - your account setup token is expired. Please request a new one!',
  'onboardingDomain.accountSetup.error.notFound':
    'Sorry - your account setup token is invalid. Please contact Instacart support.',
  'onboardingDomain.accountSetup.error.insecurePassword':
    'Password is too easy to guess. Please try a more unique password (at least 8 characters long).',
  'onboardingDomain.accountSetup.error.common':
    'An error has occurred. Please try again or contact Instacart support.',
  'onboardingDomain.accountSetup.error.invalidPartnerIds':
    'Sorry - the partner Id associated with your account is invalid. Please contact Instacart support.',
  'onboardingDomain.accountSetup.errorAction': 'Send Me Another Account Setup Email',
  'onboardingDomain.accountSetup.form.error.insecurePassword': 'Please pick a more secure password',
  'onboardingDomain.accountSetup.form.error.existingEmail':
    'An account with that email already exists',
  'onboardingDomain.requestAccountSetup.form.error.emailNotFound': 'No account matches that email.',
  'onboardingDomain.requestAccountSetup.form.error.accountAlreadySetup':
    'Account is already setup with this email. Please log in instead.',
  'onboardingDomain.requestAccountSetup.form.error.common': 'Account Setup Email Request Failed.',

  'onboardingDomain.accountSetup.form.error.common': 'Sign Up Failed',
  'onboardingDomain.requestAccountSetup.header': 'Request a new link',
  'onboardingDomain.requestAccountSetup.submitBtnText': 'Send me a new link',
  'onboardingDomain.requestAccountSetup.goToLogin': 'Go to log in',
  'onboardingDomain.requestAccountSetup.success': 'New link sent, please check your inbox',
  'onboardingDomain.requestAccountSetup.tokenExpired.header':
    'The link is no longer available or expired',
  'onboardingDomain.requestAccountSetup.tokenExpired.message':
    'You can request a new link to set up your account or contact your Instacart account manager for further support.',

  'onboardingDomain.getStarted.header': 'Get started',
  'onboardingDomain.getStarted.contentHeader': 'Next steps',
  'onboardingDomain.getStarted.contentDescription': 'Estimated time: 10 minutes',
  'onboardingDomain.getStarted.subtitle1': '1. Set up your retailer profile',
  'onboardingDomain.getStarted.content1':
    'Your profile determines how your stores are shown on the Instacart home page.',
  'onboardingDomain.getStarted.content1.list1': 'Add your logo and choose your category',
  'onboardingDomain.getStarted.content1.list2': 'Logo image requirements:',
  'onboardingDomain.getStarted.content1.list2.sublist1': 'Minimum 468 px by 468 px',
  'onboardingDomain.getStarted.content1.list2.sublist2': 'Ratio: 1:1 (square)',
  'onboardingDomain.getStarted.content1.list2.sublist3': 'Filetype: AI, EPS, jpeg, png',
  'onboardingDomain.getStarted.content1.list2.sublist4':
    'Refer to Logo image best practices for more details',
  'onboardingDomain.getStarted.subtitle2': '2. Add your store locations',
  'onboardingDomain.getStarted.content2':
    'Your profile determines how your stores are shown on the Instacart home page.',
  'onboardingDomain.getStarted.content2.list1':
    'Add up to 5 stores to start for Instacart to review',
  'onboardingDomain.getStarted.content2.list2':
    'You can add more stores later once you’re in the Instacart Platform Portal',
  'onboardingDomain.getStarted.content2.list3':
    'Start adding products to your storefront when at least 1 location is approved by Instacart',

  'onboardingDomain.getStarted.submit': 'Get started',
  'onboardingDomain.retailerProfile.warningMessage':
    'The changes you requested here will have an impact on your storefront at the banner level. Please review your changes carefully before submitting.',
  'onboardingDomain.retailerProfile.header': 'Set up your retailer profile',
  'onboardingDomain.retailerProfile.contentHeader': 'Retailer profile',
  'onboardingDomain.retailerProfile.contentDescription':
    'Your retailer profile is what your storefront is based on.',
  'onboardingDomain.retailerProfile.back': 'Back',
  'onboardingDomain.retailerProfile.continue': 'Continue',
  'onboardingDomain.retailerProfile.infoDemo': 'Where do my customers see this information?',
  'onboardingDomain.retailerProfile.infoDemo.pricingPolicy':
    'Your pricing policy can be viewed when customers click on ‘view pricing policy’ under your logo.',
  'onboardingDomain.retailerProfile.infoDemo.categories.desktop':
    'On desktop, the categories are under the store name when the customers browse all the retailers in their region. On mobile, customers can filter the retailers by categories.',
  'onboardingDomain.retailerProfile.infoDemo.categories.mobile':
    'On mobile app, your category, pricing policy and description shows under your logo when customers tab on your logo.',
  'onboardingDomain.retailerProfile.infoDemo.description':
    'On mobile app, your description shows under your logo when customers tab on your logo.',
  'onboardingDomain.retailerProfile.form.retailerName': 'Retailer name',
  'onboardingDomain.retailerProfile.form.retailerName.hint':
    'This is the name customers will see on Instacart',
  'onboardingDomain.retailerProfile.form.retailerName.placeholder': 'Enter your storefront name',
  'onboardingDomain.retailerProfile.form.businessType': 'Business type',
  'onboardingDomain.retailerProfile.form.businessType.placeholder': 'Select...',
  'onboardingDomain.retailerProfile.form.businessType.hint':
    'This helps categorize your storefront correctly',
  'onboardingDomain.retailerProfile.form.country': 'Country',
  'onboardingDomain.retailerProfile.form.country.placeholder': 'Select...',
  'onboardingDomain.retailerProfile.form.country.hint': 'Tell us where your store is located',
  'onboardingDomain.retailerProfile.form.allowAlcohol': 'Are you selling alcohol on Instacart?',
  'onboardingDomain.retailerProfile.form.allowAlcohol.text':
    'Yes, I’m going to sell alcohol on Instacart',
  'onboardingDomain.retailerProfile.form.allowAlcohol.hint':
    'Selling alcohol on Instacart will require extra steps.',
  'onboardingDomain.retailerProfile.form.logo': 'Logo',
  'onboardingDomain.retailerProfile.form.logo.required': 'Please upload a logo',
  'onboardingDomain.retailerProfile.form.logo.imageBestPractice': 'Logo image best practices.',
  'onboardingDomain.retailerProfile.form.logo.text':
    'A square logo image is required because it is used in many places, such as the Instacart store directory, the storefront, and the page that shows the status of customer orders.',
  'onboardingDomain.retailerProfile.form.logoImage': 'Logo image',
  'onboardingDomain.retailerProfile.form.logoImage.description':
    'Minimum size: 468 pixels by 468 pixels \n' +
    'Ratio: 1:1 (square) \n' +
    'File type: .pngs, .jpegs, .ai, .eps\n' +
    'Maximum file size: 900KB\n' +
    'Contents: Logo/mark only — the name of the store is displayed nearby in addition to the logo in most cases. Extend a solid color background to the edges of the square file.',
  'onboardingDomain.retailerProfile.form.logoBackgroundColor': 'Logo Background Color',
  'onboardingDomain.retailerProfile.form.logoBackgroundColor.hint':
    'In order to maintain consistency, please provide a HEX color code (e.g., #FFFFFF) with your logo to fill the background of the logo container. This color should match the background of your logo. Any asset without a provided HEX code will default to white (#FFFFFFF).',
  'onboardingDomain.addStoreLocations.header': 'Add your store locations',
  'onboardingDomain.addStoreLocations.contentHeader': 'Store locations',
  'onboardingDomain.addStoreLocations.contentDescription':
    'You can add up to 10 store locations for now for Instacart to review and approve. You’ll be able to add more stores on the Instacart Platform Portal.',
  'onboardingDomain.addStoreLocations.back': 'Back',
  'onboardingDomain.addStoreLocations.continue': 'Submit for review',

  'onboardingDomain.addStoreLocations.addStore': 'Add another store',
  'onboardingDomain.addStoreLocations.form.header.new': 'Add New Store',
  'onboardingDomain.addStoreLocations.form.header.edit': 'Edit Store',
  'onboardingDomain.addStoreLocations.form.storeId': 'Store ID',
  'onboardingDomain.addStoreLocations.form.storeId.hint':
    'This is the unique numbering system you use to differentiate your stores. This does not show up to customers or impact the Instacart storefront, but can help with organizing your locations. If you only have one store, feel free to leave this field blank and we will populate this for you. Store ID cannot be edited once the store is fully approved.',
  'onboardingDomain.addStoreLocations.form.storeName': 'Store name',
  'onboardingDomain.addStoreLocations.form.storeName.hint':
    'This is the unique name you use to differentiate your stores. This does not show up to customers or impact the Instacart storefront, but can help with organizing your locations. This store name cannot be the same as another store name that already exists.',
  'onboardingDomain.addStoreLocations.form.address': 'Address',
  'onboardingDomain.addStoreLocations.form.address.line1': 'Address',
  'onboardingDomain.addStoreLocations.form.address.line2': 'Address line 2',
  'onboardingDomain.addStoreLocations.form.streetAddress': 'Street address',
  'onboardingDomain.addStoreLocations.form.apartmentNumber': 'Apartment number',
  'onboardingDomain.addStoreLocations.form.city': 'City',
  'onboardingDomain.addStoreLocations.form.state': 'State',
  'onboardingDomain.addStoreLocations.form.postalCode': 'Postal code/ZIP',
  'onboardingDomain.addStoreLocations.form.submit': 'Save location',

  'onboardingDomain.country.usa': 'USA',
  'onboardingDomain.country.can': 'Canada',

  // TODO: refactor with partner onboarding domain
  'onboardingDomain.addStoreLocations.form.cancel': 'Cancel',
  'onboardingDomain.usa.states.alabama': 'Alabama',
  'onboardingDomain.usa.states.alaska': 'Alaska',
  'onboardingDomain.usa.states.arizona': 'Arizona',
  'onboardingDomain.usa.states.arkansas': 'Arkansas',
  'onboardingDomain.usa.states.california': 'California',
  'onboardingDomain.usa.states.colorado': 'Colorado',
  'onboardingDomain.usa.states.connecticut': 'Connecticut',
  'onboardingDomain.usa.states.delaware': 'Delaware',
  'onboardingDomain.usa.states.districtOfColumbia': 'District Of Columbia',
  'onboardingDomain.usa.states.florida': 'Florida',
  'onboardingDomain.usa.states.georgia': 'Georgia',
  'onboardingDomain.usa.states.guam': 'Guam',
  'onboardingDomain.usa.states.hawaii': 'Hawaii',
  'onboardingDomain.usa.states.idaho': 'Idaho',
  'onboardingDomain.usa.states.illinois': 'Illinois',
  'onboardingDomain.usa.states.indiana': 'Indiana',
  'onboardingDomain.usa.states.iowa': 'Iowa',
  'onboardingDomain.usa.states.kansas': 'Kansas',
  'onboardingDomain.usa.states.kentucky': 'Kentucky',
  'onboardingDomain.usa.states.louisiana': 'Louisiana',
  'onboardingDomain.usa.states.maine': 'Maine',
  'onboardingDomain.usa.states.maryland': 'Maryland',
  'onboardingDomain.usa.states.massachusetts': 'Massachusetts',
  'onboardingDomain.usa.states.michigan': 'Michigan',
  'onboardingDomain.usa.states.minnesota': 'Minnesota',
  'onboardingDomain.usa.states.mississippi': 'Mississippi',
  'onboardingDomain.usa.states.missouri': 'Missouri',
  'onboardingDomain.usa.states.montana': 'Montana',
  'onboardingDomain.usa.states.nebraska': 'Nebraska',
  'onboardingDomain.usa.states.nevada': 'Nevada',
  'onboardingDomain.usa.states.newHampshire': 'New Hampshire',
  'onboardingDomain.usa.states.newJersey': 'New Jersey',
  'onboardingDomain.usa.states.newMexico': 'New Mexico',
  'onboardingDomain.usa.states.newYork': 'New York',
  'onboardingDomain.usa.states.northCarolina': 'North Carolina',
  'onboardingDomain.usa.states.northDakota': 'North Dakota',
  'onboardingDomain.usa.states.ohio': 'Ohio',
  'onboardingDomain.usa.states.oklahoma': 'Oklahoma',
  'onboardingDomain.usa.states.oregon': 'Oregon',
  'onboardingDomain.usa.states.pennsylvania': 'Pennsylvania',
  'onboardingDomain.usa.states.rhodeIsland': 'Rhode Island',
  'onboardingDomain.usa.states.southCarolina': 'South Carolina',
  'onboardingDomain.usa.states.southDakota': 'South Dakota',
  'onboardingDomain.usa.states.tennessee': 'Tennessee',
  'onboardingDomain.usa.states.texas': 'Texas',
  'onboardingDomain.usa.states.utah': 'Utah',
  'onboardingDomain.usa.states.vermont': 'Vermont',
  'onboardingDomain.usa.states.virginia': 'Virginia',
  'onboardingDomain.usa.states.washington': 'Washington',
  'onboardingDomain.usa.states.westVirginia': 'West Virginia',
  'onboardingDomain.usa.states.wisconsin': 'Wisconsin',

  'onboardingDomain.usa.states.wyoming': 'Wyoming',
  'onboardingDomain.canada.states.alberta': 'Alberta',
  'onboardingDomain.canada.states.britishColumbia': 'British Columbia',
  'onboardingDomain.canada.states.manitoba': 'Manitoba',
  'onboardingDomain.canada.states.newBrunswick': 'New Brunswick',
  'onboardingDomain.canada.states.newfoundlandAndLabrador': 'Newfoundland and Labrador',
  'onboardingDomain.canada.states.northwestTerritories': 'Northwest Territories',
  'onboardingDomain.canada.states.novaScotia': 'Nova Scotia',
  'onboardingDomain.canada.states.nunavut': 'Nunavut',
  'onboardingDomain.canada.states.ontario': 'Ontario',
  'onboardingDomain.canada.states.princeEdwardIsland': 'Prince Edward Island',
  'onboardingDomain.canada.states.quebec': 'Quebec',
  'onboardingDomain.canada.states.saskatchewan': 'Saskatchewan',

  'onboardingDomain.canada.states.yukonTerritory': 'Yukon Territory',
  'onboardingDomain.initialStepsCompleted.header': 'Congrats! You’ve completed your inital setup',
  'onboardingDomain.initialStepsCompleted.subtitle': 'Next steps',
  'onboardingDomain.initialStepsCompleted.step1':
    'We’ll review your retailer profile and store locations. This usually takes around 2 business days.',
  'onboardingDomain.initialStepsCompleted.step2':
    'You can continue adding stores on your Instacart Platform Portal. ',

  'onboardingDomain.initialStepsCompleted.step3':
    'Check out our launch checklist to get your stores ready.',
  'onboardingDomain.modal.close': 'Close',

  'onboardingDomain.initialStepsCompleted.modal.confirm': 'View launch checklist',
  'onboardingDomain.launchChecklist': 'Launch Checklist',
  'onboardingDomain.launchChecklist.header': 'Welcome to Instacart',
  'onboardingDomain.launchChecklist.completeRetailerProfile.header': 'Complete retailer profile',
  'onboardingDomain.launchChecklist.completeRetailerProfile.content':
    'Complete your profile before launch date',
  'onboardingDomain.launchChecklist.completeRetailerProfile.incomplete': 'Not completed',
  'onboardingDomain.launchChecklist.addStoreLocations.header': 'Add store locations',
  'onboardingDomain.launchChecklist.addStoreLocations.content':
    'Add stores locations to start adding your products to the catalog',
  'onboardingDomain.launchChecklist.addStoreLocations.complete.single': '1 location added',
  'onboardingDomain.launchChecklist.addStoreLocations.complete.plural': '{count} locations added',
  'onboardingDomain.launchChecklist.setStoreHours.header': 'Complete store setup',
  'onboardingDomain.launchChecklist.setStoreHours.content':
    'Let us know your regular store hours to get ready for launch. You can also edit store detail or add a new store location.',
  'onboardingDomain.launchChecklist.setStoreHours.pendingApproval.single':
    '1 store is still pending approval. You can add store hours once a store is fully approved.',
  'onboardingDomain.launchChecklist.setStoreHours.pendingApproval.plural':
    '{count} stores are still pending approval. You can add store hours once a store is fully approved.',
  'onboardingDomain.launchChecklist.setStoreHours.refresh':
    'The store approval could take some time. Please refresh later to see the latest status.',
  'onboardingDomain.launchChecklist.setStoreHours.complete.single':
    '1 store has completed store hours setup.',
  'onboardingDomain.launchChecklist.setStoreHours.complete.plural':
    '{count} stores have completed store hours setup.',
  'onboardingDomain.launchChecklist.setStoreHours.incomplete.single':
    '1 store is fully approved and requires store hours setup.',
  'onboardingDomain.launchChecklist.setStoreHours.incomplete.plural':
    '{count} stores are fully approved and require store hours setup.',
  'onboardingDomain.launchChecklist.setStoreHours.additionalStores':
    'There may be additional stores that require action. Please visit the store management page for further details.',
  'onboardingDomain.launchChecklist.addProducts.header':
    'Add products to your catalog (file ingestion required)',
  'onboardingDomain.launchChecklist.addProducts.incomplete': '0 product in your catalog',
  'onboardingDomain.launchChecklist.addProducts.complete': '{totalCount} products in your catalog',
  'onboardingDomain.launchChecklist.addProducts.loading': 'Loading data for catalog',
  'onboardingDomain.launchChecklist.addProducts.content':
    'This is done outside of this portal. \n' +
    'Review our inventory file guidelines and POS integration partners. ',
  'onboardingDomain.launchChecklist.connectStripe.header': 'Connect Stripe',
  'onboardingDomain.launchChecklist.connectStripe.content':
    'Connect Stripe to get your payment system configured.',
  'onboardingDomain.launchChecklist.connectStripe.incomplete': 'Not connected',
  'onboardingDomain.launchChecklist.otherThings': 'Other Things to Help You Succeed',
  'onboardingDomain.launchChecklist.inviteOthers.header': 'Invite team members',
  'onboardingDomain.launchChecklist.inviteOthers.content':
    'Invite your team members to Instacart Portal',
  'onboardingDomain.launchChecklist.optInEmail.header': 'Opt in for email notifications',
  'onboardingDomain.launchChecklist.optInEmail.content':
    'Receive email notifications for system alerts and reportings',
  'onboardingDomain.launchChecklist.readyForLaunch.header1': 'Congrats!',
  'onboardingDomain.launchChecklist.readyForLaunch.header2': 'You are ready for launch.',
  'onboardingDomain.launchChecklist.readyForLaunch.subtitle': 'What’s next?',
  'onboardingDomain.launchChecklist.readyForLaunch.content':
    'Instacart will reach out and schedule your launch with you. In the meantime, you can still make changes.',

  'onboardingDomain.retailerProfileSetting.header': 'Retailer Profile Setting',
  'onboardingDomain.retailerProfileSetting.header.updateProfile': 'Update Profile',
  'onboardingDomain.retailerProfileSetting.basicInformation': 'Basic Information',
  'onboardingDomain.retailerProfileSetting.otherInformation': 'Additional Information',
  'onboardingDomain.retailerProfileSetting.actionBar.primaryBtnText': 'Save',

  'onboardingDomain.retailerProfileSetting.actionBar.info':
    'We will review your change after you save the information, it will take up to 2 business days to approve.',
  'onboardingDomain.retailerProfile.form.categories': 'Categories',
  'onboardingDomain.retailerProfile.form.categories.hint':
    'Are there any other categories that fit your storefront? These will be shown to customers under the store name to share the types of things you offer. Select up to 3 categories. ',
  'onboardingDomain.retailerProfile.form.categories.placeholder': 'Select up to 3 categories...',
  'onboardingDomain.retailerProfile.form.field.error': 'Error occurred for this field',
  'onboardingDomain.retailerProfile.form.description': 'Description',
  'onboardingDomain.retailerProfile.form.description.hint':
    "Please tell us a little about your store or your brand. This description will be shown to customers if they'd like to learn more information about your store. ",
  'onboardingDomain.retailerProfile.form.description.placeholder': 'Retailer description',
  'onboardingDomain.retailerProfile.form.pricingPolicy': 'Pricing Policy',
  'onboardingDomain.retailerProfile.form.pricingPolicyText': 'Pricing Policy Text',
  'onboardingDomain.retailerProfile.form.pricingPolicy.required': 'Please select a pricing policy',
  'onboardingDomain.retailerProfile.form.pricingPolicy.hint':
    'Please select how your prices will be displayed on the Instacart platform. This pricing policy and description help customers understand how prices are set at your store on Instacart. Customers will be able to view this information under the "view pricing policy" section on your storefront.',
  'onboardingDomain.retailerProfile.form.certifiedDeliveryThreshold':
    'Certified Delivery Threshold',
  'onboardingDomain.retailerProfile.form.certifiedDeliveryThreshold.hint':
    'Any order with an item over this amount will require a customer signature at time of delivery',
  'onboardingDomain.retailerProfile.form.certifiedDeliveryThreshold.placeholder': 'Enter an amount',
  'onboardingDomain.retailerProfile.form.alcohol': 'Alcohol',
  'onboardingDomain.retailerProfile.approval.pending':
    'There are changes pending approval. Instacart reviews all changes made to the retailer profile.',

  // category options
  'onboardingDomain.categories.alcohol': 'Alcohol',
  'onboardingDomain.categories.bakery': 'Bakery',
  'onboardingDomain.categories.butcherShop': 'Butcher Shop',
  'onboardingDomain.categories.coOp': 'Co-op',
  'onboardingDomain.categories.drugstore': 'Drugstore',
  'onboardingDomain.categories.ethnic': 'Ethnic',
  'onboardingDomain.categories.farmersMarket': "Farmer's Market",
  'onboardingDomain.categories.groceries': 'Groceries',
  'onboardingDomain.categories.meals': 'Meals',
  'onboardingDomain.categories.organic': 'Organic',
  'onboardingDomain.categories.petSupplies': 'Pet Supplies',
  'onboardingDomain.categories.preparedMeals': 'Prepared Meals',
  'onboardingDomain.categories.specialty': 'Specialty',
  'onboardingDomain.categories.vegetarian': 'Vegetarian',
  'onboardingDomain.categories.wholesale': 'Wholesale',
  'onboardingDomain.categories.backToSchool': 'Back to School',
  'onboardingDomain.categories.books': 'Books',
  'onboardingDomain.categories.stationery': 'Stationery',
  'onboardingDomain.categories.home': 'Home',
  'onboardingDomain.categories.convenience': 'Convenience',
  'onboardingDomain.categories.essentials': 'Essentials',
  'onboardingDomain.categories.snacks': 'Snacks',
  'onboardingDomain.categories.preparedFood': 'Prepared Food',
  'onboardingDomain.categories.supplements': 'Supplements',
  'onboardingDomain.categories.healthWellness': 'Health & Wellness',
  'onboardingDomain.categories.electronics': 'Electronics',
  'onboardingDomain.categories.generalMerchandise': 'General Merchandise',
  'onboardingDomain.categories.beauty': 'Beauty',
  'onboardingDomain.categories.bed': 'Bed',
  'onboardingDomain.categories.extremeValue': 'Extreme Value',
  'onboardingDomain.categories.gamesToys': 'Games & Toys',
  'onboardingDomain.categories.tech': 'Tech',
  'onboardingDomain.categories.homeOffice': 'Home & Office',
  'onboardingDomain.categories.retail': 'Retail',
  'onboardingDomain.categories.lifestyle': 'Lifestyle',
  'onboardingDomain.categories.fashion': 'Fashion',
  'onboardingDomain.categories.sportingGoods': 'Sporting Goods',
  'onboardingDomain.categories.outdoors': 'Outdoors',
  'onboardingDomain.categories.costumeStores': 'Costume Stores',

  // Pricing policy options
  'onboardingDomain.retailerProfile.form.pricingPolicy.higher': 'Higher than in-store',
  'onboardingDomain.retailerProfile.form.pricingPolicy.higher.hint':
    'Select this option if a blanket markup amount is being applied to all item prices.',
  'onboardingDomain.retailerProfile.form.pricingPolicy.higher.defaultText':
    '{retailerName} sets the price of items on the Instacart marketplace. Item prices for this retailer are higher than in-store prices in your area to cover the cost of personal shopping. Items on sale in the store may not be on sale through the Instacart platform. Prices and availability are based on available data feeds, subject to delays or errors and may change without notice.',
  'onboardingDomain.retailerProfile.form.pricingPolicy.viewPricingPolicy': 'Other',
  'onboardingDomain.retailerProfile.form.pricingPolicy.viewPricingPolicy.hint':
    'Select this option if you are applying a markup, but only on specific items.',
  'onboardingDomain.retailerProfile.form.pricingPolicy.viewPricingPolicy.defaultText':
    '{retailerName} sets the price of items on the Instacart marketplace. Item prices may vary from in-store prices in your area. Prices may be higher than in-store prices to cover the cost of personal shopping.',
  'onboardingDomain.retailerProfile.form.pricingPolicy.everydayStorePrices':
    'Everyday Store Prices',
  'onboardingDomain.retailerProfile.form.pricingPolicy.everydayStorePrices.hint':
    'Select this option if your non-sale item prices are the same as in-store and no markup is being applied.',
  'onboardingDomain.retailerProfile.form.pricingPolicy.everydayStorePrices.defaultText':
    'Item prices for {retailerName} reflect the average in-store prices in your area. Items on sale in the store may not be on sale through the Instacart platform. Prices and availability are based on available data feeds, subject to delays or errors and may change without notice. As a result, the in-store price of a particular item at the time a purchase is made, including for weighted items, may differ from what is available through the Instacart platform.',

  'onboardingDomain.component.imageUploader.add': 'add image',
  'onboardingDomain.component.imageUploader.remove': 'remove image',
  'onboardingDomain.component.imageUploader.uploadError': 'Image upload error, please try again',
  'onboardingDomain.component.imageUploader.validation.invalidFile':
    'Invalid file, please try again',
  'onboardingDomain.component.imageUploader.validation.invalidFileType': 'Invalid file type',
  'onboardingDomain.component.imageUploader.validation.sizeExceedsLimit':
    'File size exceeds maximum limit of 900KB',
  'onboardingDomain.component.imageUploader.validation.invalidDimensions':
    'Minimum image size: 468 pixels by 468 pixels',
  'onboardingDomain.component.imageUploader.validation.notASquare':
    'Image ratio must be 1:1 (square)',

  'onboardingDomain.storeManagement.header': 'Store Management',
  'onboardingDomain.storeManagement.header.addStore': 'Add New Store Location',
  'onboardingDomain.storeManagement.header.editStore': 'Edit Information for',
  'onboardingDomain.storeManagement.requestNewStore': 'Request New Store',
  'onboardingDomain.storeManagement.storeId': 'Store ID',
  'onboardingDomain.storeManagement.storeName': 'Store Name',
  'onboardingDomain.storeManagement.storeAddress': 'Store Address',
  'onboardingDomain.storeManagement.status': 'Status',
  'onboardingDomain.storeManagement.lastUpdated': 'Last Updated',
  'onboardingDomain.storeManagement.table.error.failToLoad': 'Fail to load data',
  'onboardingDomain.storeManagement.form.header': 'Store Information',
  'onboardingDomain.storeManagement.form.actionBar.primaryBtnText': 'Save',
  'onboardingDomain.storeManagement.form.actionBar.info':
    'We will review the detail and approve the information within 2 business days.',

  'onboardingDomain.storeDetail.header': 'Store Detail',
  'onboardingDomain.storeDetail.editStoreDetail': 'Edit Store Detail',
  'onboardingDomain.storeDetail.storeHours.header': 'Store Hours',
  'onboardingDomain.storeDetail.storeHours.edit': 'Edit Store Hours',
  'onboardingDomain.storeDetail.storeHours.add': 'Add Store Hours',
  'onboardingDomain.storeDetail.storeHours.pendingApproval':
    'You can add store hours once this store is fully approved.',
  'onboardingDomain.storeDetail.storeHours.refresh':
    'The store approval could take some time. Please refresh later to see the store hours.',
  'onboardingDomain.storeDetail.approval.pending':
    'There are changes pending approval. Instacart reviews all changes made to the store detail.',
  'onboardingDomain.storeDetail.status.pending': 'Pending approval',
  'onboardingDomain.storeDetail.status.active': 'Launched',
  'onboardingDomain.storeDetail.status.inactive': 'Ready for Launch',
  'onboardingDomain.storeDetail.status.actionRequired': 'Action required',

  'onboardingDomain.catalogLaunchProcess.header':
    'Add products to your catalog (file ingestion required)',
  'onboardingDomain.catalogLaunchProcess.subtitle': 'Launch process for your catalog',
  'onboardingDomain.catalogLaunchProcess.inventoryFile': 'Prepare your inventory file',
  'onboardingDomain.catalogLaunchProcess.inventoryFile.link':
    'Instacart catalog inventory setup FAQ',
  'onboardingDomain.catalogLaunchProcess.inventoryFile.details':
    'There are 2 options to add products to your catalog on Instacart.',
  'onboardingDomain.catalogLaunchProcess.inventoryFile.option1':
    'Option 1: Integration with our POS partners',
  'onboardingDomain.catalogLaunchProcess.inventoryFile.option1.posGuideline':
    'Current POS partners and integration guidelines',
  'onboardingDomain.catalogLaunchProcess.inventoryFile.option1.details':
    'We support multiple POS integration. Each POS has its own guidelines.',
  'onboardingDomain.catalogLaunchProcess.inventoryFile.option2':
    'Option 2: Inventory file ingestion',
  'onboardingDomain.catalogLaunchProcess.inventoryFile.option2.groceryFileTemplate':
    'Grocery file template',
  'onboardingDomain.catalogLaunchProcess.inventoryFile.option2.groceryFileGuideline':
    'Grocery file guideline',
  'onboardingDomain.catalogLaunchProcess.inventoryFile.option2.alcoholFileTemplate':
    'Alcohol file template',
  'onboardingDomain.catalogLaunchProcess.inventoryFile.option2.alcoholFileGuideline':
    'Alcohol file guideline',
  'onboardingDomain.catalogLaunchProcess.inventoryFile.option2.details':
    'If your current POS system is not yet our partners, you can create the inventory file manually or pull an existing file from your POS following our templates and guidelines',
  'onboardingDomain.catalogLaunchProcess.inventoryFile.option2.steps':
    '1. Work with your Technical Account Manager to create a inventory file.\n' +
    '2. Drop your inventory file in the SFTP folder we will provide, manually or via a script.',
  'onboardingDomain.catalogLaunchProcess.step2': 'Storefront product population',
  'onboardingDomain.catalogLaunchProcess.step2.details':
    'This typically takes 2-3 days. We will inform you when the storefront is ready for QA. \n' +
    'Products are added to the catalog and the launch checklist for catalog are complete.',
  'onboardingDomain.catalogLaunchProcess.step3': 'Catalog QA',
  'onboardingDomain.catalogLaunchProcess.step3.details':
    'This typically takes 2-3 weeks. We work with you to ensure your storefront is populated correctly and your catalog is populated correctly.',
  'onboardingDomain.catalogLaunchProcess.step4': 'Retailer approval',
  'onboardingDomain.catalogLaunchProcess.step4.details':
    'Once you are happy with your storefront, you can sign off and we can get ready for launch.',
  'onboardingDomain.catalogLaunchProcess.step5': 'Schedule launch',
  'onboardingDomain.catalogLaunchProcess.errorFetchingFile':
    'Could not download file. Please try again. If the issue persists, please contact support.',

  'onboardingDomain.logoImageBestPractice.header': 'Logo Image Best Practice',
  'onboardingDomain.logoImageBestPractice.content':
    "We can now put your logo in the marketplace's top bar navigation, allowing your store a better branding experience as customers explore your store online. \n" +
    '\n' +
    'On iPhone or Android we can show the same vibrant logo image with confidence to your customers.\n' +
    '\n' +
    'A square logo is the most standard and consistent experience for customers — and provides the opportunity to use the logo in more places. \n' +
    '\n' +
    'In order to maintain consistency, please also provide a HEX color code (e.g., #FFFFFF) with your logo to fill the background of the logo container. This color should match the background of your logo. Any asset without a provided HEX code will default to white.\n',
  'onboardingDomain.logoImageBestPractice.subtitle1': 'Image Requirement',
  'onboardingDomain.logoImageBestPractice.subtitle2': 'Dos & Don’ts',
  'onboardingDomain.logoImageBestPractice.sample.standard':
    'Use the most iconic thing about your brand if opting to use a symbol for representation',
  'onboardingDomain.logoImageBestPractice.sample.text':
    'Text logos are perfect representations, so long as the text meets the edges of the asset container',
  'onboardingDomain.logoImageBestPractice.sample.crowded':
    'Do not include complex backgrounds as part of logo assets; they will not be accepted',
  'onboardingDomain.logoImageBestPractice.sample.notFilling':
    'Avoid not filling the entire space of the asset dimension size',
  'onboardingDomain.logoImageBestPractice.sample.tooMuch':
    'Avoid including too much in your logo asset which could detract from your brand.',
  'onboardingDomain.logoImageBestPractice.sample.differentBackground':
    'Do not supply a different background color fill than what is provided with your asset.',

  'onboardingDomain.approvalRequest.pending.info': 'The content update is pending',
  'onboardingDomain.approvalRequest.pending.modal.header': 'Pending Request',
  'onboardingDomain.approvalRequest.pending.modal.message':
    'There are changes that are pending approval.\nYou can still continue to update the content.',
  'onboardingDomain.approvalRequest.denied.info': 'The content update was declined',
  'onboardingDomain.approvalRequest.denied.modal.header': 'Declined Request',
  'onboardingDomain.approvalRequest.denied.modal.updateMessage':
    'Please update this content for us to review.',
  'onboardingDomain.approvalRequest.denied.modal.updateStoreDetail': 'Update store detail',
  'onboardingDomain.approvalRequest.viewDetail': 'View detail',
  'onboardingDomain.approvalRequest.viewComments': 'View comments',
  'onboardingDomain.approvalRequest.requestedAttribute': 'Requested attribute',
  'onboardingDomain.approvalRequest.requestedChange': 'Requested change',
  'onboardingDomain.approvalRequest.declineComments': 'Decline comments',
  'onboardingDomain.action.updateProfile': 'Update profile',

  // Whitelabel site states
  'onboardingDomain.siteState.initialized': 'Initialized',
  'onboardingDomain.siteState.initialEmailSent': 'Initial email sent',
  'onboardingDomain.siteState.inProgress': 'In progress',
  'onboardingDomain.siteState.inReview': 'In review',
  'onboardingDomain.siteState.published': 'Published',

  // whitelabel site setup pages
  'onboardingDomain.siteSetup.navLabel': 'Storefront site setup',
  'onboardingDomain.siteSetup.title': 'Storefront site setup',

  // Site Setup Table
  'onboardingDomain.siteManagement.table.forms': 'Forms',
  'onboardingDomain.siteManagement.table.updatedAt': 'Last Updated',
  'onboardingDomain.siteManagement.table.submitted': 'Submitted',
  'onboardingDomain.siteManagement.table.status': 'Status',
  'onboardingDomain.siteManagement.table.poc': 'POC',

  'onboardingDomain.siteManagement.table.forms.iosSetUp': 'iOS Set up',
  'onboardingDomain.siteManagement.table.forms.androidSetUp': 'Android Set up',
  'onboardingDomain.siteManagement.table.forms.webSetUp': 'Web Set up',

  'onboardingDomain.siteSetupForm.error':
    'An error has occurred. Please try again or contact Instacart support.',
  'onboardingDomain.siteSetupForm.failedToLoadSiteData':
    'Failed to load site setup data. Please try again or contact Instacart support.',

  'onboardingDomain.setupStepsTitle': 'Storefront Pro setup',
  'onboardingDomain.step.appDetails.title': 'App details',
  'onboardingDomain.step.ios.title': 'iOS setup',
  'onboardingDomain.step.android.title': 'Android setup',
  'onboardingDomain.step.domain.title': 'Domain setup',
  'onboardingDomain.step.fulfillment.title': 'Fulfillment',
  'onboardingDomain.step.identity.title': 'Identity',
  'onboardingDomain.step.privacy.title': 'Privacy',
  'onboardingDomain.step.customerExperience.title': 'Customer experience',
  'onboardingDomain.step.cms.title': 'CMS',
  'onboardingDomain.step.customerPaymentOptions.title': 'Customer Payment options',
  'onboardingDomain.step.legal.title': 'Legal information',
  'onboardingDomain.step.email.title': 'Email configuration',
  'onboardingDomain.step.cartCheckout.title': 'Cart & Checkout',
  'onboardingDomain.step.analytics.title': 'Analytics',
  'onboardingDomain.step.customerOptions.title': 'Customer Options',
  'onboardingDomain.step.landingPage.title': 'Landing Page',
  'onboardingDomain.step.marketing.title': 'Marketing',
  'onboardingDomain.step.shopping.title': 'Shopping',
  'onboardingDomain.step.ippConfiguration.title': 'IPP Configuration',
  'onboardingDomain.step.review.title': 'Review and submit',

  'onboardingDomain.step.fulfillment.subtitle':
    'By default, your fulfillment model will match what you currently have on the Instacart App. If this is a concern, please reach out to your account manager.',

  'onboardingDomain.yes': 'Yes',
  'onboardingDomain.no': 'No',
  'onboardingDomain.other': 'Other',
  'onboardingDomain.enterUrl': 'Enter URL',
  'onboardingDomain.urlPatternError': 'Please enter a valid URL',
  'onboardingDomain.domainPatternError': 'Please enter a valid domain',

  'onboardingDomain.minLengthError': 'Minimum length is {minLength} characters',
  'onboardingDomain.maxLengthError': 'Max length is {maxLength} characters',
  'onboardingDomain.patternError': 'Does not match correct pattern',

  'onboardingDomain.form.validationErrorTitle': 'Validation Error',
  'onboardingDomain.form.validationErrorBody': 'Please correct the highlighted fields.',
  'onboardingDomain.form.correctFields': 'Please correct the following fields:',

  // Domain Setup Form
  'onboardingDomain.webSetup.form.domainHostingQuestion':
    'Will you be hosting your site on Instacart or on a custom domain?',
  'onboardingDomain.webSetup.form.hostOnInstacart': 'Host on Instacart',
  'onboardingDomain.webSetup.form.hostOnCustomDomain': 'Host on custom domain',
  'onboardingDomain.webSetup.form.domainNameLabel': 'URL',
  'onboardingDomain.webSetup.form.domainNameLabelTooltip':
    'Provide the URL for your custom domain.',
  'onboardingDomain.webSetup.form.domainNamePattern': 'Invalid domain name format.',
  'onboardingDomain.webSetup.form.cnameLabel': 'CNAME',
  'onboardingDomain.webSetup.form.cnameLabelTooltip': 'Provide the CNAME record for your domain.',
  'onboardingDomain.webSetup.form.cnameRequired': 'CNAME is required.',
  'onboardingDomain.webSetup.form.cnamePattern': 'Invalid CNAME format.',

  // Fulfillment Form
  'onboardingDomain.webSetup.form.fulfillmentMatchesWhitelabelQuestion':
    'Will you have the same fulfillment model on SFP that you have on Marketplace?',
  'onboardingDomain.webSetup.form.fulfillmentPickupLabourQuestion':
    'To pick your orders, will you be using Instacart shoppers or In store labor?',
  'onboardingDomain.webSetup.form.useInstacartShoppers': 'Instacart shoppers',
  'onboardingDomain.webSetup.form.useInStoreLabor': 'In store labor',
  'onboardingDomain.webSetup.form.fulfillmentInStoreOrderTypesQuestion':
    'What types of orders will your team be shopping?',
  'onboardingDomain.webSetup.form.fulfillmentInStoreOrderTypes.pickup': 'Pickup',
  'onboardingDomain.webSetup.form.fulfillmentInStoreOrderTypes.delivery': 'Delivery',
  'onboardingDomain.webSetup.form.fulfillmentInStoreOrderTypes.both': 'Both',
  'onboardingDomain.webSetup.form.fulfillmentMatchesAllStoresQuestion':
    'Will all locations have the same fulfillment models?',
  'onboardingDomain.webSetup.form.fulfillmentCustomPerStoreQuestion':
    'Which stores will have different fulfillment models than what has been specified here?',
  'onboardingDomain.webSetup.form.fulfillmentCustomPerStore.list.addStore': 'Add store',
  'onboardingDomain.webSetup.form.fulfillmentCustomPerStore.list.getStoresError':
    'An error occurred while fetching stores. Please try again or contact support if the issue persists.',
  'onboardingDomain.webSetup.form.fulfillmentInstacartLaunchDateQuestion':
    'What date are you expecting to go live with fulfillment? (optional)',
  'onboardingDomain.webSetup.form.fulfillmentPosProvider': 'What POS system do you use? (optional)',
  'onboardingDomain.webSetup.form.review.fulfillmentCustomPerStore.storeHeader': 'Store',
  'onboardingDomain.webSetup.form.review.fulfillmentCustomPerStore.laborTypeHeader': 'Labor type',
  'onboardingDomain.webSetup.form.review.fulfillmentCustomPerStore.orderTypeHeader': 'Order type',
  'onboardingDomain.webSetup.form.review.deliveryAndPickup': 'Delivery and Pickup',

  // Identity Form
  'onboardingDomain.webSetup.form.identityQuestion':
    'Will your site authenticate against an existing identity management solution?',
  'onboardingDomain.webSetup.form.useInstacartIdentity': "Use Instacart's identity solution",
  'onboardingDomain.webSetup.form.useCustomIdentity': 'Use custom identity solution',
  'onboardingDomain.webSetup.form.identitySolutionName': 'Name of identity solution',
  'onboardingDomain.webSetup.form.identitySolutionName.placeholder':
    'Enter name of identity solution',
  'onboardingDomain.webSetup.form.identitySolutionTooltip': 'Name of identity solution',
  'onboardingDomain.webSetup.form.identitySolutionMinLength':
    'The identity solution name must be at least 3 characters long.',
  'onboardingDomain.webSetup.form.identitySolutionPattern':
    'The identity solution name can only contain letters, numbers, spaces, dots, and hyphens.',

  // Privacy Form
  'onboardingDomain.webSetup.cookieManagement.subtitle': 'Cookie Management',
  'onboardingDomain.webSetup.accountDeleteUrl.subtitle': 'Delete Account URL',

  'onboardingDomain.webSetup.form.privacyQuestion': 'Does your region require CPRA?',
  'onboardingDomain.webSetup.form.cookieManagementQuestion':
    "Will you use Instacart's solution for cookie management, or will you use a third party vendor?",
  'onboardingDomain.webSetup.form.useInstacartCookieManagement': "Instacart's solution",
  'onboardingDomain.webSetup.form.useOneTrustCookieManagement': 'OneTrust',
  'onboardingDomain.webSetup.form.useTrustArcCookieManagement': 'TrustArc',
  'onboardingDomain.webSetup.form.useOtherCookieManagement': 'Other',
  'onboardingDomain.webSetup.form.useOtherCookieManagement.placeholder':
    'Enter name of cookie management solution',
  'onboardingDomain.webSetup.form.contactEmail': 'Contact email',
  'onboardingDomain.webSetup.form.contactEmail.placeholder': 'Enter email address of contact',
  'onboardingDomain.webSetup.form.thirdPartyVendorName': 'Please provide more details',
  'onboardingDomain.webSetup.form.thirdPartyVendorName.placeholder':
    'Enter name of third party vendor',
  'onboardingDomain.webSetup.form.contactEmail.tooltip':
    "Contact of person that needs to receive Instacart's CPRA material and training",
  'onboardingDomain.webSetup.form.thirdPartyVendorName.tooltip':
    'This will help us correctly setup your environment',
  'onboardingDomain.webSetup.form.accountDeleteUrlQuestion':
    'Please provide a URL for user account deletion. This should allow users to delete their account on both web and mobile.',
  'onboardingDomain.webSetup.form.accountDeleteUrlLabel': 'Account deletion URL',
  'onboardingDomain.webSetup.form.accountDeleteUrl.placeholder': 'Enter URL',
  'onboardingDomain.webSetup.form.contactEmailPattern': 'Please provide a valid email address.',
  'onboardingDomain.webSetup.form.accountDeleteUrlPattern': 'Please provide a valid URL.',
  'onboardingDomain.webSetup.form.thirdPartyVendorMinLength':
    'Vendor name must be at least 3 characters.',

  'onboardingDomain.webSetup.form.oneTrustAllowCookieOptOut': 'Allow cookie opt-out?',
  'onboardingDomain.webSetup.form.oneTrustIncludeAutoBlockScript': 'Include auto-block script?',
  'onboardingDomain.webSetup.form.oneTrustDomainUuid': 'What is the OneTrust domain uuid?',
  'onboardingDomain.webSetup.form.oneTrustGoogleTagManagerScriptCategoryClassName':
    'What is the Google Tag Manager script category class name for OneTrust?',
  'onboardingDomain.webSetup.form.oneTrustGoogleMapsScriptCategoryClassName':
    'What is the Google Maps script category class name for OneTrust?',
  'onboardingDomain.webSetup.form.oneTrustReverseBannerButtonPosition':
    'What is the reverse banner button position for OneTrust?',
  'onboardingDomain.webSetup.form.oneTrustWaHealthCheck': 'WA Health Check?',
  'onboardingDomain.webSetup.form.oneTrustAssetDomain':
    'What is the domain where the OneTrust assets are stored?',

  'onboardingDomain.webSetup.form.trustArcClientId': 'What is the Client ID for TrustArc?',
  'onboardingDomain.webSetup.form.trustArcIsDomainBased':
    'Are the TrustArc settings domain-based for the Client ID?',

  'onboardingDomain.webSetup.form.securitiAiDomainUuid': 'What is the Securiti.ai domain uuid?',
  'onboardingDomain.webSetup.form.securitiAiTenantUuid': 'What is the Securiti.ai tenant uuid?',

  'onboardingDomain.webSetup.form.osanoCustomerId': 'What is the Osano Customer ID?',
  'onboardingDomain.webSetup.form.osanoConfigId': 'What is the Osano Config ID?',

  'onboardingDomain.webSetup.form.cookieYesClientId': 'What is the CookieYes Client ID?',

  'onboardingDomain.webSetup.form.transcendBundleId': 'What is the Transcend Bundle ID?',

  // Customer Experience Form
  'onboardingDomain.webSetup.form.inStoreModeQuestion': 'Will you be using in-store mode?',
  'onboardingDomain.webSetup.form.weeklyFlyerQuestion': 'Will you be using weekly flyers?',
  'onboardingDomain.webSetup.form.flyerAvailableForAllStoresQuestion':
    'Will they be available for all stores? (optional)',
  'onboardingDomain.webSetup.form.loyaltyProgramQuestion': 'Do you have a loyalty program?',
  'onboardingDomain.webSetup.form.loyaltyProgramLabel': 'Enter HEX value for loyalty color',
  'onboardingDomain.webSetup.form.loyaltyProgramTooltip':
    'Retailer loyalty offerings like loyalty clubs, discount cards, or special member pricing are special cases which override existing promotional color.',
  'onboardingDomain.webSetup.form.customerExperienceFollowUpMessage':
    'If you answer Yes to any of the following questions, we will follow up with you about next steps.',
  'onboardingDomain.webSetup.form.cartCheckout.subtitle':
    'If you answer Yes to any questions below, after submission, we will follow up with you about next steps for each of these questions.',
  'onboardingDomain.webSetup.form.loyaltyProgramColorPattern':
    'Invalid HEX color. Use format #RRGGBB.',
  'onboardingDomain.webSetup.form.loyaltyProgramColor.placeholder':
    'Enter HEX value of loyalty color',
  'onboardingDomain.webSetup.form.specialLoyaltyPricingQuestion':
    'Do you offer your customers special pricing if they are loyalty members?',
  'onboardingDomain.webSetup.form.loyaltyProgramOnboardingDescription':
    'Describe how customers enroll in your loyalty program.',
  'onboardingDomain.webSetup.form.loyaltyProgramOnboardingDescriptionHint':
    'Provide links where applicable.',
  'onboardingDomain.webSetup.form.loyaltyProgramOnboardingDescription.placeholder':
    'Enter description of loyalty program enrollment',
  'onboardingDomain.webSetup.form.loyaltyProgramBenefitsSectionQuestion':
    'What are the Loyalty program benefits provided?',
  'onboardingDomain.webSetup.form.loyaltyPointsAccrualLabel': 'Points accrual',
  'onboardingDomain.webSetup.form.loyaltyPointsRedemptionLabel': 'Points redemption',
  'onboardingDomain.webSetup.form.loyaltyMemberCouponsLabel': 'Member coupons',
  'onboardingDomain.webSetup.form.loyaltyFuelRewardsLabel': 'Fuel rewards',
  'onboardingDomain.webSetup.form.loyaltyProgramBenefitsLabel':
    'Describe other loyalty benefits if applicable',
  'onboardingDomain.webSetup.form.loyaltyProgramBenefitsHint':
    'e.g. Points earned, Fuel Rewards, Digital Coupons, etc.',
  'onboardingDomain.webSetup.form.loyaltyProgramBenefits.placeholder':
    'Enter loyalty program benefits',
  'onboardingDomain.webSetup.form.markupPercentageQuestion':
    'Specify the desired markup percentage for Storefront Pro.',
  'onboardingDomain.webSetup.form.markupPercentageLabel': 'Enter markup percentage',
  'onboardingDomain.webSetup.form.markupPercentage.placeholder': 'Enter markup percentage',
  'onboardingDomain.webSetup.form.recipesQuestion': 'Are you planning on publishing recipes?',

  // CMS Form
  'onboardingDomain.webSetup.form.cmsSolutionQuestion':
    'What CMS solution would you prefer to use?',
  'onboardingDomain.webSetup.form.useInstacartCMS': 'Instacart CMS',
  'onboardingDomain.webSetup.form.useCustomCMS': 'Custom CMS',
  'onboardingDomain.webSetup.form.noCMS': 'I will not be using a CMS',
  'onboardingDomain.webSetup.form.cmsSolutionName': 'Name of CMS solution',
  'onboardingDomain.webSetup.form.cmsSolutionName.placeholder': 'Enter name of CMS solution',
  'onboardingDomain.webSetup.form.cmsSolutionMinLength':
    'The CMS solution name must be at least 3 characters.',
  'onboardingDomain.webSetup.form.cmsSolutionPattern':
    'The CMS solution name contains invalid characters.',
  'onboardingDomain.webSetup.form.cmsWidgetRequiredQuestion':
    'Will you need CMS widgets to integrate with the current CMS site?',

  // Payment Form
  'onboardingDomain.webSetup.form.paymentQuestion': 'Will you enable EBT/SNAP?',
  'onboardingDomain.webSetup.form.ebtSnapLiveMarketplaceQuestion':
    'Is EBT/SNAP live on Marketplace?',

  // Legal Form
  'onboardingDomain.webSetup.form.customTermsAndConditionsQuestion':
    'Do you provide a custom terms & conditions for your users?',
  'onboardingDomain.webSetup.form.customPrivacyPolicyQuestion':
    'Do you provide a custom privacy policy for your users?',
  'onboardingDomain.webSetup.form.customPrivacyPolicyLabel': 'Enter URL to your privacy policy',
  'onboardingDomain.webSetup.form.urlForCustomersQuestion':
    'Please provide a URL for customers to contact you for help and support',
  'onboardingDomain.webSetup.form.urlForCustomersLabel': 'Support URL',
  'onboardingDomain.webSetup.form.customTermsAndConditionsUrlEnter': 'Enter as URL',
  'onboardingDomain.webSetup.form.customTermsAndConditionsUrl.placeholder': 'Enter URL',
  'onboardingDomain.webSetup.form.customTermsAndConditionsUrlPaste': 'Paste in text',
  'onboardingDomain.webSetup.form.customTermsAndConditionsUrlEnter.label':
    'Enter URL to terms & conditions',
  'onboardingDomain.webSetup.form.customTermsAndConditionsRequired':
    'Providing custom terms & conditions is required.',
  'onboardingDomain.webSetup.form.customTermsAndConditionsUrlPattern':
    'Please enter a valid URL for terms & conditions.',
  'onboardingDomain.webSetup.form.privacyPolicyUrlPattern':
    'Please enter a valid privacy policy URL.',
  'onboardingDomain.webSetup.form.privacyPolicyUrl.placeholder': 'Enter URL',
  'onboardingDomain.webSetup.form.supportUrlPattern': 'Please enter a valid support URL.',
  'onboardingDomain.webSetup.form.supportUrl.placeholder': 'Enter URL used for help & support',
  'onboardingDomain.webSetup.form.ageRestrictedProductsQuestion':
    'Will Alcohol or 18+ products be sold at the store?',
  'onboardingDomain.webSetup.form.customCreditCardNameQuestion':
    "Is there a specific credit card name you'd like to be displayed on the bank statement?",
  'onboardingDomain.webSetup.form.customCreditCardName.label':
    "Provide the name you'd like for us to display.",
  'onboardingDomain.webSetup.form.customCreditCardName.placeholder':
    "Enter the name you'd like for us to display",

  // Email Configuration Form
  'onboardingDomain.webSetup.form.fromEmailQuestion':
    'Please provide a "From" email address we should use to send emails on the retailer’s behalf.',
  'onboardingDomain.webSetup.form.noreplyEmailQuestion':
    'Please provide a "No-reply" email address',
  'onboardingDomain.webSetup.form.replytoEmailQuestion':
    'Please provide a "Reply-to" email address',
  'onboardingDomain.webSetup.form.emailAddressLabel': 'Email address',
  'onboardingDomain.webSetup.form.emailAddressTooltip':
    'This is the email address used for communication.',
  'onboardingDomain.webSetup.form.emailPattern': 'Please provide a valid email address.',
  'onboardingDomain.webSetup.form.useInstacartNoreply': 'Use "no-reply@instacart.com"',
  'onboardingDomain.webSetup.form.useInstacartReplyto': 'Use "reply-to@instacart.com"',
  'onboardingDomain.webSetup.form.enterEmail.placeholder': 'Enter email',

  // Analytics Form
  'onboardingDomain.webSetup.form.googleTagManagerQuestion':
    'Do you want to use Google Tag Manager and Google Analytics for tracking?',
  'onboardingDomain.webSetup.form.googleTagManager':
    'Do you want to use Google Tag Manager and Google Analytics for tracking? ' +
    'Instacart will host a GTM container for you to setup tags and triggers.',
  'onboardingDomain.webSetup.form.googleTagManagerExtraInfo':
    'After submission, we will follow up with you about next steps',

  // Cart & Checkout Form
  'onboardingDomain.webSetup.priorityService.subtitle': 'Priority Service',
  'onboardingDomain.webSetup.noRushService.subtitle': 'No Rush Service',
  'onboardingDomain.webSetup.initialFreeDelivery.subtitle': 'Initial Free Delivery',
  'onboardingDomain.webSetup.cartHeading.subtitle': 'Cart Heading',
  'onboardingDomain.webSetup.checkoutRecommendations.subtitle': 'Checkout Recommendations',
  'onboardingDomain.webSetup.smsMarketingOptIn.subtitle': 'SMS Marketing Opt-in',
  'onboardingDomain.webSetup.multiStepTipping.subtitle': 'Multi-step Tipping',
  'onboardingDomain.webSetup.gifting.subtitle': 'Gifting',
  'onboardingDomain.webSetup.checkoutTotalsConfiguration.subtitle': 'Checkout Totals Configuration',

  'onboardingDomain.webSetup.form.priorityService.question':
    'Will Priority service be provided to the customers?',
  'onboardingDomain.webSetup.form.noRushService.question': 'Will "No Rush Service" be provided?',
  'onboardingDomain.webSetup.form.noRushService.tooltip': 'There are associated service fees.',
  'onboardingDomain.webSetup.form.certifiedDelivery.question': 'Is certified delivery required?',
  'onboardingDomain.webSetup.form.certifiedDelivery.tooltip':
    "Orders with high value items which require a customer's signature.",
  'onboardingDomain.webSetup.form.freeDeliveries.question':
    'Will you offer first 1 or 3 free deliveries to customers on the new Storefront Pro?',

  'onboardingDomain.webSetup.form.disableChurnUserFreeDelivery.question':
    'Disable free delivery for churn users?',
  'onboardingDomain.webSetup.form.disableNewUserFreeDelivery.question':
    'Disable free delivery for new users?',

  'onboardingDomain.webSetup.form.customCartHeaderRequired.question': 'Use a custom cart header?',
  'onboardingDomain.webSetup.form.customCartHeader.question': 'What is the custom cart header?',
  'onboardingDomain.webSetup.form.customCartHeader.placeholder': 'Custom Cart Header',

  'onboardingDomain.webSetup.form.checkoutRecommendations.question':
    'Enable checkout recommendations?',
  'onboardingDomain.webSetup.form.smsMarketingOptIn.question': 'Enable SMS marketing opt-in?',
  'onboardingDomain.webSetup.form.multiStepTipping.question': 'Enable multi step tipping?',
  'onboardingDomain.webSetup.form.giftingEnabled.question': 'Gifting enabled?',
  'onboardingDomain.webSetup.form.giftingCertifiedDelivery.question':
    'Enable certified delivery when gifting?',
  'onboardingDomain.webSetup.form.giftingRecipientScheduledDelivery.question':
    'Enable gifting Recipient Scheduled Delivery?',
  'onboardingDomain.webSetup.form.buildItemDiscounts.question':
    'Build item discounts during checkout?',
  'onboardingDomain.webSetup.form.buildZeroDeliveryFee.question':
    'Build zero delivery fee during checkout?',
  'onboardingDomain.webSetup.form.buildIcPlusSavings.question':
    'Build IC+ savings during checkout?',
  'onboardingDomain.webSetup.form.showEcoFeeDisclaimer.question': 'Show eco_fee disclaimer?',
  'onboardingDomain.webSetup.form.showNoticeDisclaimer.question': 'Show the notice disclaimer?',
  'onboardingDomain.webSetup.form.showDoNotSellInfoDisclaimer.question':
    'Show the dont_sell_info disclaimer?',

  // Customer Options
  'onboardingDomain.webSetup.icPlusEnablement.subtitle': 'IC+ Enablement',
  'onboardingDomain.webSetup.giftCards.subtitle': 'Gift Cards',
  'onboardingDomain.webSetup.loyaltyCards.subtitle': 'Loyalty Cards',

  'onboardingDomain.webSetup.customerOptions.enterpriseUpsellEnabled.question':
    'Enable enterprise upsell?',
  'onboardingDomain.webSetup.customerOptions.enableExpress.question': 'Enable express?',
  'onboardingDomain.webSetup.customerOptions.enableLinkExpress.question': 'Enable link express?',
  'onboardingDomain.webSetup.customerOptions.expressEnabledFeatures.question':
    'What are the enabled express features?',
  'onboardingDomain.webSetup.customerOptions.enableExpressPlacement.question':
    'Enable express placement?',
  'onboardingDomain.webSetup.customerOptions.showExpressMultipleStoresPerk.question':
    'Show express multiple stores perk?',
  'onboardingDomain.webSetup.customerOptions.enableExpressGiftCards.question':
    'Enable express gift cards?',
  'onboardingDomain.webSetup.customerOptions.hideGiftCardLabelEnabled.question':
    'Hide gift card labels?',
  'onboardingDomain.webSetup.customerOptions.userAccountSettingsHideLoyalty.question':
    'Hide loyalty cards in Accounts menu?',

  // Landing Page
  'onboardingDomain.webSetup.guestUserMode.subtitle': 'Guest User Mode',
  'onboardingDomain.webSetup.skipPostalCode.question': 'Skip postal code?',

  // Marketing
  'onboardingDomain.webSetup.smsShortLinks.subtitle': 'SMS Short Links',
  'onboardingDomain.webSetup.dynamicSlots.subtitle': 'Dynamic Slots',

  'onboardingDomain.webSetup.marketing.smsShortLinks.question': 'What is SMS short link domain?',
  'onboardingDomain.webSetup.marketing.smsShortLinks.label': 'Short Link Domain',
  'onboardingDomain.webSetup.marketing.dynamicSlots.question': 'Enable dynamic slots?',

  // Shopping
  'onboardingDomain.webSetup.scanningItems.subtitle': 'Scanning Items',

  'onboardingDomain.webSetup.scanItemBarcodeEnabled.question': 'Enable scan_item_barcode?',

  // IPP Configuration
  'onboardingDomain.webSetup.oopDashboard.subtitle': 'Orders Operational Dashboard',

  'onboardingDomain.webSetup.oopDashboardEnabled.question': 'Enable Orders Operational Dashboard?',

  // SetupLayout Translations
  'onboardingDomain.button.back': 'Back',
  'onboardingDomain.button.previous': 'Previous',
  'onboardingDomain.button.next': 'Next',
  'onboardingDomain.button.submit': 'Submit',
  'onboardingDomain.button.cancel': 'Cancel',
  'onboardingDomain.button.update': 'Update',

  // Mobile App Form
  'onboardingDomain.form.mobileAppSetup.header': 'Mobile app setup',
  'onboardingDomain.form.mobileAppSetup.iosSetup.header': 'iOS setup',
  'onboardingDomain.form.mobileAppSetup.applicationName': 'Application name',
  'onboardingDomain.form.mobileAppSetup.applicationName.placeholder': 'Enter application name',
  'onboardingDomain.form.mobileAppSetup.storefrontUrl': 'Storefront URL',
  'onboardingDomain.form.mobileAppSetup.storefrontUrl.placeholder': 'Enter URL',
  'onboardingDomain.form.mobileAppSetup.bundleIdentifier': 'Bundle identifier',
  'onboardingDomain.form.mobileAppSetup.bundleIdentifier.placeholder': 'Enter bundle identifier',
  'onboardingDomain.form.mobileAppSetup.scheme': 'Scheme',
  'onboardingDomain.form.mobileAppSetup.scheme.placeholder': 'Enter scheme',
  'onboardingDomain.form.mobileAppSetup.applePayMerchantId': 'Apple pay merchant ID',
  'onboardingDomain.form.mobileAppSetup.applePayMerchantId.placeholder': 'Enter pay merchant ID',
  'onboardingDomain.form.mobileAppSetup.appVersion': 'App version',
  'onboardingDomain.form.mobileAppSetup.appVersion.placeholder': 'Enter app version',
  'onboardingDomain.form.mobileAppSetup.teamId': 'Team ID',
  'onboardingDomain.form.mobileAppSetup.teamId.placeholder': 'Enter Team ID',
  'onboardingDomain.form.mobileAppSetup.appStoreConnectId': 'App store connect ID',
  'onboardingDomain.form.mobileAppSetup.appStoreConnectId.placeholder':
    'Enter app store connect ID',
  'onboardingDomain.form.mobileAppSetup.isSfxApp': 'Is this an SFX app',
  'onboardingDomain.form.mobileAppSetup.needIpadSupport': 'Do you need iPad support',

  // Save for later modal
  'saveForLaterModal.header': 'Your answers will be saved for later',
  'saveForLaterModal.body':
    'If you exit this form, your progress will still be here when you return.',
  'saveForLaterModal.continueButton': 'Continue setup',
  'saveForLaterModal.exitButton': 'Exit',

  // Change attribute modal
  'changeAttributeGroupModal.header': 'Change Attribute Group',
  'changeAttributeGroupModal.body':
    'Are you sure you want to change the attribute group? Any unsaved progress will be lost.',
  'changeAttributeGroupModal.cancelButton': 'Cancel',
  'changeAttributeGroupModal.confirmButton': 'Confirm',

  // Onboarding attribute group review
  'onboardingDomain.attributeGroupReview.updateButton.label': 'Update',
  'onboardingDomain.attributeGroupReview.updateButton.disabledTooltip':
    'Please contact your account manager to update this value.',
  'onboardingDomain.attributeGroupReview.deniedValue.message':
    'The following value was denied. Please update it and resubmit.',

  // Mobile App Upload Fields
  'onboardingDomain.mobileAppSetup.form.uploadText': 'Upload',
  'onboardingDomain.mobileAppSetup.form.uploadButton': 'Upload button',
  'onboardingDomain.mobileAppSetup.form.or': 'or',
  'onboardingDomain.mobileAppSetup.form.retry': 'retry',
  'onboardingDomain.mobileAppSetup.form.error': 'error',
  'onboardingDomain.mobileAppSetup.form.error.fileTypeError': 'File type error',
  'onboardingDomain.mobileAppSetup.form.error.fileSizeError': 'File size error',
  'onboardingDomain.mobileAppSetup.form.error.fileContentError': 'File content error',
  'onboardingDomain.mobileAppSetup.form.error.uploadError': 'Upload error',
  'onboardingDomain.mobileAppSetup.form.matchingSpecs':
    'The uploaded file does not match the specifications.',
  'onboardingDomain.mobileAppSetup.form.dropFile': 'Drop a file here',

  // Shared Mobile Form (App Details)
  'onboardingDomain.mobileSetup.form.appName': 'App name',
  'onboardingDomain.mobileSetup.form.appName.placeholder': 'Enter app name',
  'onboardingDomain.mobileSetup.form.appName.description': '30 char limit',
  'onboardingDomain.mobileSetup.form.appSubtitle': 'App subtitle',
  'onboardingDomain.mobileSetup.form.appSubtitle.placeholder': 'Enter app subtitle',
  'onboardingDomain.mobileSetup.form.appSubtitle.description': '30 char limit',
  'onboardingDomain.mobileSetup.form.appSubtitle.tooltip':
    "Your app's subtitle is intended to summarize your app in a concise phrase. Consider using this, rather than your app's name, to explain the value of your app in greater detail.",
  'onboardingDomain.mobileSetup.form.appDescription': 'Full app description',
  'onboardingDomain.mobileSetup.form.appDescription.placeholder': 'Enter app description',
  'onboardingDomain.mobileSetup.form.appDescription.description': '4000 char limit',
  'onboardingDomain.mobileSetup.form.appDescription.tooltip':
    'A description of your app, detailing features and functionality.',
  'onboardingDomain.mobileSetup.form.appKeywords': 'App keywords',
  'onboardingDomain.mobileSetup.form.appKeywords.placeholder': 'Enter keywords',
  'onboardingDomain.mobileSetup.form.appKeywords.description':
    'Separate keywords with a comma, 100 char limit',
  'onboardingDomain.mobileSetup.form.appKeywords.tooltip':
    'Keywords help determine where your app displays in search results, so choose them carefully to ensure your app is easily discoverable.',
  'onboardingDomain.mobileSetup.form.displayFont': 'Display font',
  'onboardingDomain.mobileSetup.form.fontRequirements':
    'File Types: OTF, TTF\n' +
    'Requirements: Typefaces need to support: Bold, semibold, and normal weights.\n' +
    'Uppercase, lowercase, alphabet, and numberic characters.\n' +
    'Upload up to 5 supported files. Max 10 MB per file',
  'onboardingDomain.mobileSetup.form.bodyFont': 'Body font',
  'onboardingDomain.mobileSetup.form.copyright': 'Copyright',
  'onboardingDomain.mobileSetup.form.copyright.placeholder': 'Enter copyright text',
  'onboardingDomain.mobileSetup.form.copyright.tooltip':
    'The name of the person or entity that owns the exclusive rights to your app, preceded by the year the rights were obtained (for example: "2008 Acme Inc")',
  'onboardingDomain.mobileSetup.form.airshipEnabled':
    'Will airship feature be enabled for the apps?',
  'onboardingDomain.mobileSetup.form.bazaarvoiceEnabled':
    'Is BazaarVoice enabled for customer reviews and ratings?',
  'onboardingDomain.mobileSetup.form.otherAppsExist': 'Are there existing apps?',
  'onboardingDomain.mobileSetup.form.bazaarvoiceId': 'BazaarVoice ID',
  'onboardingDomain.mobileSetup.form.bazaarvoiceId.placeholder': 'Enter BazaarVoice ID',
  'onboardingDomain.mobileSetup.form.reviewsRatingsServiceName': 'Reviews and ratings service name',
  'onboardingDomain.mobileSetup.form.reviewsRatingsServiceName.placeholder':
    'Enter Reviews and Ratings service name',
  'onboardingDomain.mobileSetup.form.otherAppPocEmail': 'Point of contact email',
  'onboardingDomain.mobileSetup.form.otherAppPocEmail.placeholder': 'Enter email address',
  'onboardingDomain.mobileSetup.form.otherAppBundleId': 'Bundle ID',
  'onboardingDomain.mobileSetup.form.otherAppBundleId.placeholder': 'Enter bundle ID',
  'onboardingDomain.mobileSetup.form.otherAppPackageName': 'Application package name',
  'onboardingDomain.mobileSetup.form.otherAppPackageName.placeholder': 'Enter package name',

  // iOS App Form
  'onboardingDomain.iosSetup.setupStepsTitle': 'iOS app setup',

  'onboardingDomain.iosSetup.form.appName.tooltip':
    'Your app name plays a critical role in how users discover it on the App Store. Choose a simple, memorable name that is easy to spell and hits at what your app does. Avoid names that use generic terms or are too similar to existing app names.',

  'onboardingDomain.iosSetup.form.otherAppBundleId.tooltip':
    'A bundle ID or bundle identifier uniquely identifies an application in Apple’s ecosystem.\n' +
    'No two applications can have the same bundle identifier. If there is an existing app, please provide the existing Bundle ID.',

  'onboardingDomain.iosSetup.form.otherAppPackageName.tooltip':
    'The unique ID for the app within App store.  If there is an existing app, please provide the existing application package name.',

  'onboardingDomain.iosSetup.form.appstoreIcon': 'App store icon',
  'onboardingDomain.iosSetup.form.appstoreIcon.description':
    'Dimensions: 1024px X 1024px\n' +
    'File format: PNG (no alpha transparency)\n' +
    'Max size: 1MB',

  'onboardingDomain.iosSetup.form.logoLight': 'Logo - Light version',
  'onboardingDomain.iosSetup.form.logoLight.description':
    'Dimensions: Max width 256px\n' +
    'File format: PDF, SVG (in vector format, with transparent background)\n' +
    'Max size: 1MB',

  'onboardingDomain.iosSetup.form.logoDark': 'Logo - Dark version',
  'onboardingDomain.iosSetup.form.logoDark.description':
    'Dimensions: Max width 256px\n' +
    'File format: PDF, SVG (in vector format, with transparent background)\n' +
    'Max size: 1MB',

  // Android App Form
  'onboardingDomain.androidSetup.setupStepsTitle': 'Android app setup',

  'onboardingDomain.androidSetup.form.appName.tooltip':
    'Your app name plays a critical role in how users discover it on the Play Store. Choose a simple, memorable name that is easy to spell and hits at what your app does. Avoid names that use generic terms or are too similar to existing app names.',

  'onboardingDomain.androidSetup.form.otherAppBundleId.tooltip':
    'A bundle ID or bundle identifier uniquely identifies an application in the Play Store’s ecosystem.\n' +
    'No two applications can have the same bundle identifier. If there is an existing app, please provide the existing Bundle ID.',

  'onboardingDomain.androidSetup.form.otherAppPackageName.tooltip':
    'The unique ID for the app within Google Play store.  If there is an existing app, please provide the existing application package name.',

  'onboardingDomain.androidSetup.form.playstoreIcon': 'Play Store icon',
  'onboardingDomain.androidSetup.form.playstoreIcon.description':
    'Dimensions: 512px X 512px\n' + 'File format: SVG\n' + 'Max size: 1MB',

  'onboardingDomain.androidSetup.form.iconBackgroundColorHex': 'Icon background color HEX',
  'onboardingDomain.androidSetup.form.iconBackgroundColorHex.placeholder': 'Enter HEX',
  'onboardingDomain.androidSetup.form.iconBackgroundColorHex.tooltip':
    'In order to maintain consistency, please provide a HEX color code (e.g., #FFFFFF) with your logo to fill the background of the logo container. This color should match the background of your logo. Any asset without a provided HEX code will default to white (#FFFFFFF).',

  'onboardingDomain.androidSetup.form.splashscreenLogo': 'Logo splash screens',
  'onboardingDomain.androidSetup.form.splashscreenLogo.description':
    'Dimensions: 1024px x 500px\n' + 'File format: JPG, 24-bit PNG\n' + 'Max size: 1MB',

  'onboardingDomain.androidSetup.form.splashscreenBackgroundColorHex':
    'Splash screen background color HEX',
  'onboardingDomain.androidSetup.form.splashscreenBackgroundColorHex.placeholder': 'Enter HEX',
  'onboardingDomain.androidSetup.form.splashscreenBackgroundColorHex.tooltip':
    'In order to maintain consistency, please provide a HEX color code (e.g., #FFFFFF) with your splash screen to fill the background of the splash screen container. This color should match the background of your splash screen. Any asset without a provided HEX code will default to white (#FFFFFFF).',

  'onboardingDomain.androidSetup.form.notificationIcon': 'Notification icon',
  'onboardingDomain.androidSetup.form.notificationIcon.description':
    'Dimensions: 512px X 512px\n' +
    'File format: SVG, AI (in vector format), PNG (with transparent background)\n' +
    'Max size: 1MB',

  // Validation Messages
  'onboardingDomain.mobileAppSetup.actionRequired': 'Action required',

  'onboardingDomain.siteSetupChecklist.description':
    'Complete initial setup to get Storefront Pro up and running.',
  'onboardingDomain.siteSetupChecklist.completeInitialSetup': 'Complete initial setup',
  'onboardingDomain.siteSetupChecklist.configureBrandTheme': 'Configure brand theme',
  'onboardingDomain.siteSetupChecklist.setupIosApp': 'Setup iOS app',
  'onboardingDomain.siteSetupChecklist.setupAndroidApp': 'Setup Android app',

  // Site overview
  'onboardingDomain.siteOverview.navLabel': 'Storefront site overview',
  'onboardingDomain.siteOverview.title': 'Storefront site overview',
  'onboardingDomain.siteOverview.failedToLoadSiteData': 'Failed to load site data',

  'onboardingDomain.siteOverview.table.brandedName': 'Branded name',
  'onboardingDomain.siteOverview.table.domainName': 'URL',
  'onboardingDomain.siteOverview.table.setupDate': 'Setup date',
  'onboardingDomain.siteOverview.table.updatedAt': 'Last updated',
  'onboardingDomain.siteOverview.table.status': 'Status',

  // Common utils
  'onboardingDomain.common.formatHoursAgoDate.updatedOn': 'Updated on {date}',
  'onboardingDomain.common.formatHoursAgoDate.updatedLessThanOneHourAgo':
    'Updated less than 1 hour ago',
  'onboardingDomain.common.formatHoursAgoDate.updatedHoursAgo': 'Updated {hours} hours ago',

  // site setup common
  'onboardingDomain.siteSetup.emptyState': 'No site setup in progress',
  'onboardingDomain.siteSetup.emptyState.description':
    'Please contact the retailer onboarding team to get started',
  'onboardingDomain.siteSetup.emptyState.action': 'Return to site overview',
} as const

export default EN_US
