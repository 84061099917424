import {
  buildTimeIntervalDimensionsConfig,
  buildTimeIntervalDimensionsGrouping,
  buildTimeIntervalGroupBysConfig,
  createSchemaConfig,
} from '../helpers/schema.helpers'
import { FormatterType } from '../types/formatter.types'
import { insightsPortalERVMetricAccessControl } from '../../access-control/insightsPortalERVMetricAccess.configuration'
import { InsightsMetricDesiredDirection } from '../types/schema.types'
import { Dimensions, GroupBys, Metrics } from './insightsPortalPlacements.types'
import {
  placementSchemaFilterParamsConfig,
  transformFilterSelectionsToPlacementSchemaFilters,
} from './insightsPortalPlacementsSchema.hooks'

export { GroupBys, Metrics, Dimensions }

export const schemaConfig = createSchemaConfig({
  metrics: Metrics,
  dimensions: Dimensions,
  metricsConfig: {
    [Metrics.ADD_TO_CARTS]: {
      label: 'insightsPortalDomain.placements.metrics.addToCarts',
      formatter: FormatterType.Integer,
      desiredDirection: InsightsMetricDesiredDirection.POSITIVE,
      tooltip: 'insightsPortalDomain.placements.metrics.addToCarts.tooltip',
    },
    [Metrics.CART_CONVERSIONS]: {
      label: 'insightsPortalDomain.placements.metrics.cartConversions',
      formatter: FormatterType.Percentage,
      desiredDirection: InsightsMetricDesiredDirection.POSITIVE,
      tooltip: 'insightsPortalDomain.placements.metrics.cartConversions.tooltip',
    },
    [Metrics.CLICKS]: {
      label: 'insightsPortalDomain.placements.metrics.clicks',
      formatter: FormatterType.Integer,
      desiredDirection: InsightsMetricDesiredDirection.POSITIVE,
      tooltip: 'insightsPortalDomain.placements.metrics.clicks.tooltip',
    },
    [Metrics.CLICK_THROUGH_RATE]: {
      label: 'insightsPortalDomain.placements.metrics.clickThroughRate',
      formatter: FormatterType.Percentage,
      desiredDirection: InsightsMetricDesiredDirection.POSITIVE,
      tooltip: 'insightsPortalDomain.placements.metrics.clickThroughRate.tooltip',
    },
    [Metrics.GMV]: {
      label: 'insightsPortalDomain.placements.metrics.gmv',
      formatter: FormatterType.Currency,
      desiredDirection: InsightsMetricDesiredDirection.POSITIVE,
      tooltip: 'insightsPortalDomain.collections.metrics.gmv.tooltip',
    },
    [Metrics.ERV]: {
      label: 'insightsPortalDomain.placements.metrics.erv',
      formatter: FormatterType.Currency,
      desiredDirection: InsightsMetricDesiredDirection.POSITIVE,
      tooltip: 'insightsPortalDomain.collections.metrics.erv.tooltip',
      private: insightsPortalERVMetricAccessControl,
    },
    [Metrics.IMPRESSIONS]: {
      label: 'insightsPortalDomain.placements.metrics.impressions',
      formatter: FormatterType.Integer,
      desiredDirection: InsightsMetricDesiredDirection.POSITIVE,
      tooltip: 'insightsPortalDomain.placements.metrics.impressions.tooltip',
    },
    [Metrics.LAST_ETL_UPDATE]: {
      private: true,
      formatter: FormatterType.Date,
    },
    [Metrics.NUM_PLACEMENTS]: {
      label: 'insightsPortalDomain.placements.metrics.numPlacements',
      formatter: FormatterType.Integer,
      desiredDirection: InsightsMetricDesiredDirection.POSITIVE,
      tooltip: 'insightsPortalDomain.placements.metrics.numPlacements.tooltip',
    },
  },
  dimensionGroupings: [
    buildTimeIntervalDimensionsGrouping,
    {
      label: 'insightsPortalDomain.filters.stores',
      dimensions: [Dimensions.WAREHOUSE, Dimensions.DIM_STORE_CONFIGURATION],
    },
    {
      label: 'insightsPortalDomain.filters.devices',
      dimensions: [Dimensions.DEVICES],
    },
    {
      label: 'insightsPortalDomain.creatives.nav',
      dimensions: [
        Dimensions.PLACEMENT,
        Dimensions.PLACEMENT_TYPE,
        Dimensions.PLACEMENT_STATUS,
        Dimensions.DATE_START,
        Dimensions.DATE_END,
        Dimensions.INSIGHTS_PORTAL_ORDER_SOURCE,
        Dimensions.USER_SEGMENT,
      ],
    },
  ],
  dimensionsConfig: {
    [Dimensions.PLACEMENT]: {
      label: 'insightsPortalDomain.placements.groupBys.placementName',
    },
    [Dimensions.DIM_STORE_CONFIGURATION]: {
      label: 'insightsPortalDomain.campaigns.groupBys.DIM_STORE_CONFIGURATION_ID',
    },
    [Dimensions.PLACEMENT_NAME]: {
      label: 'insightsPortalDomain.placements.groupBys.placementName',
      private: true,
    },
    [Dimensions.PLACEMENT_ID]: {
      label: 'insightsPortalDomain.placements.groupBys.placementId',
      private: true,
    },
    [Dimensions.ACTIVE_PLACEMENT_ID]: {
      private: true,
      label: 'insightsPortalDomain.placements.groupBys.placementId',
    },
    [Dimensions.PLACEMENT_TYPE]: {
      label: 'insightsPortalDomain.placements.groupBys.placementType',
    },
    [Dimensions.DATE_START]: {
      label: 'insightsPortalDomain.placements.groupBys.dateStart',
      formatter: FormatterType.Date,
    },
    [Dimensions.DATE_END]: {
      label: 'insightsPortalDomain.placements.groupBys.dateEnd',
      formatter: FormatterType.Date,
    },
    [Dimensions.DEVICES]: {
      label: 'insightsPortalDomain.placements.groupBys.devices',
    },
    [Dimensions.PLACEMENT_STATUS]: {
      label: 'insightsPortalDomain.placements.groupBys.placementStatus',
    },
    [Dimensions.WAREHOUSE]: {
      label: 'insightsPortalDomain.creatives.filters.retailer',
    },
    [Dimensions.INSIGHTS_PORTAL_ORDER_SOURCE]: {
      label: 'insightsPortalDomain.collections.filters.orderSource',
    },
    [Dimensions.USER_SEGMENT]: {
      label: 'insightsPortalDomain.placements.filters.userSegment',
    },
    ...buildTimeIntervalDimensionsConfig,
  },

  // TODO: deprecate
  filterConfigs: placementSchemaFilterParamsConfig,
  transformFilterParamsToApiFilterSelections: transformFilterSelectionsToPlacementSchemaFilters,
  groupBys: GroupBys,
  groupBysConfig: {
    [GroupBys.PLACEMENT_NAME]: {
      label: 'insightsPortalDomain.placements.groupBys.placementName',
    },
    [GroupBys.PLACEMENT_ID]: {
      label: 'insightsPortalDomain.placements.groupBys.placementId',
    },
    [GroupBys.PLACEMENT_TYPE]: {
      label: 'insightsPortalDomain.placements.groupBys.placementType',
    },
    [GroupBys.DATE_START]: {
      label: 'insightsPortalDomain.placements.groupBys.dateStart',
      formatter: FormatterType.Date,
    },
    [GroupBys.DATE_END]: {
      label: 'insightsPortalDomain.placements.groupBys.dateEnd',
      formatter: FormatterType.Date,
    },
    [GroupBys.DEVICES]: {
      label: 'insightsPortalDomain.placements.groupBys.devices',
    },
    [GroupBys.PLACEMENT_STATUS]: {
      label: 'insightsPortalDomain.placements.groupBys.placementStatus',
    },
    [GroupBys.INSIGHTS_PORTAL_ORDER_SOURCE]: {
      label: 'insightsPortalDomain.collections.filters.orderSource',
    },
    [GroupBys.USER_SEGMENT]: {
      label: 'insightsPortalDomain.placements.filters.userSegment',
    },
    ...buildTimeIntervalGroupBysConfig,
  },
})
