import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { adminStorefrontSetupViewAccessControl } from '../access-control/onboardingAccess.configuration'

const OnboardingSiteOverviewPage = lazy(
  () => import('../pages/site-overview/OnboardingSiteOverviewPage')
)
const OnboardingSiteSetupFormAdminPage = lazy(
  () => import('../pages/site-setup-form-admin/OnboardingSiteSetupFormAdminPage')
)
const OnboardingSiteSetupAdminPage = lazy(
  () => import('../pages/site-setup-admin/OnboardingSiteSetupAdminPage')
)
// @hygen:inject page-import

export const onboardingAdminRouter = (
  <DomainRoute route="app-admin-onboarding" accessControl={adminStorefrontSetupViewAccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="app-admin-onboarding-site-overview"
        component={OnboardingSiteOverviewPage}
      />
      <DomainRoute
        exact
        route="app-admin-onboarding-site-setup-form-admin"
        component={OnboardingSiteSetupFormAdminPage}
      />
      <DomainRoute
        exact
        route="app-admin-onboarding-site-setup-admin"
        component={OnboardingSiteSetupAdminPage}
      />
      {/* @hygen:inject router-route
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
