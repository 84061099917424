// @ts-nocheck legacy file transformed to TS
import { Component } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import { colors, Icon } from 'ic-snacks'
import styled from '@emotion/styled'
import { Z_INDEX_SECOND } from '../common/theme'

const style = {
  overlay: {
    display: 'flex',
    backgroundColor: '#4a4a4aba',
    flex: '1',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: Z_INDEX_SECOND,
  },
  content: {
    display: 'flex',
    border: '1px solid rgba(91, 91, 91, 0.77)',
    borderRadius: '5px',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    padding: 0,
    flexDirection: 'column',
    alignItems: 'flex-start',
    overflow: 'hidden',
    justifyContent: 'space-between',
    position: 'relative',
    top: 'auto',
    right: 'auto',
    bottom: 'auto',
    left: 'auto',
    overflowY: 'hidden',
    overflowX: 'hidden',
  },
}

const HeaderBody = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  flex-flow: column;
`

const HeaderContainer = styled.div`
  display: flex;
  padding: 25px;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: ${colors.GRAY_20};
  border-bottom: 1px solid ${colors.GRAY_93};
`

const BodyContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
  flex-grow: 1;
`

const FooterContainer = styled.div`
  display: flex;
  padding: 25px;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  min-height: 76px;
  // border: ${props => (props.inline ? `1px solid ${colors.GRAY_74}` : 'none')};
  border-top: 1px solid ${colors.GRAY_93};
  z-index: 1;
  @media print {
    display: none;
  }
`

const closeStyle = {
  position: 'fixed',
  top: '20px',
  right: '20px',
  color: 'white',
  fontSize: '22px',
  cursor: 'pointer',
  '@media print': {
    display: 'none',
  },
}

class Modal extends Component {
  static propTypes = {
    onRequestClose: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    children: PropTypes.func,
  }

  static defaultProps = {
    onRequestClose: () => true,
    height: 800,
    width: 700,
  }

  componentDidMount() {
    global.document.body.style.overflow = 'hidden'
  }

  componentWillUnmount() {
    global.document.body.style.overflow = 'auto'
  }

  onRequestClose = () => {
    this.props.onRequestClose() && this.props.onClose()
  }

  render() {
    const [header, body, footer] = this.props.children(
      HeaderContainer,
      BodyContainer,
      FooterContainer,
      this.props
    )

    const { height, width, minHeight } = this.props

    return (
      <ReactModal
        isOpen
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        onRequestClose={this.onRequestClose}
        style={{
          ...style,
          content: {
            ...style.content,
            maxHeight: height,
            maxWidth: width,
            width: '100%',
            minHeight: minHeight ? minHeight : 'unset',
          },
        }}
      >
        <Icon onClick={this.onRequestClose} name="xBold" style={closeStyle} />
        <HeaderBody>
          {header}
          {body}
        </HeaderBody>
        {footer}
      </ReactModal>
    )
  }
}

export default Modal
