import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'
import { FeatureToggle } from './featureToggles'
import { FeatureEntitlements } from './featureEntitlements'

export const lifecycleCrmAccessControl: DomainAccessControlConfig = [
  {
    featureToggles: [FeatureToggle.lifecycleCrmEnabled],
    permissions: [], // TODO: Figure out what roles/permissions are needed for this feature.
    featureEntitlementIds: [FeatureEntitlements.LifecycleCRM],
  },
]
