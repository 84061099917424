import { ICPlatformVersion } from '@retailer-platform/dashboard/access-control'
import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'
import { FeatureToggle } from './featureToggles'

// Access control for wholesaler segment library development
export const retailerFundedMarketingWholesalerSegmentAccessControl: DomainAccessControlConfig = {
  featureToggles: [FeatureToggle.wholesalerSegmentEnabled],
}

// TODO add partner based access control when ready to roll out
