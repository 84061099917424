import { ICPlatformVersion } from '@retailer-platform/dashboard/access-control'
import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'
import { FeatureToggle } from './featureToggles'
import { FeatureEntitlements } from './featureEntitlements'

export const retailerCollectionsV2AccessControl: DomainAccessControlConfig = {
  // warehouseFeatureToggles: [FeatureToggle.retailerCollectionsV2],
  permissions: [Permission.RetailerCollectionsV2],
}

export const retailerCollectionsV2RetailerAccessControl: DomainAccessControlConfig = {
  warehouseFeatureToggles: [FeatureToggle.retailerCollectionsV2],
  permissions: [Permission.RetailerCollectionsV2],
  maxICPlatformVersionExclusive: ICPlatformVersion.r2025_01_0,
}

export const retailerCollectionsV2AdminAccessControl: DomainAccessControlConfig = {
  warehouseFeatureToggles: [FeatureToggle.retailerCollectionsV2Preview],
  notWarehouseFeatureToggles: [FeatureToggle.retailerCollectionsV2],
  permissions: [Permission.Admin],
}

export const legacyDepartmentsAccessControl: DomainAccessControlConfig = {
  warehouseFeatureToggles: [FeatureToggle.retailerCollectionsDepartmentsAisles],
}

export const dynamiclyFedStaticCollectionsAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.Admin],
  warehouseFeatureToggles: [FeatureToggle.DynamiclyFedStaticCollections],
}

export const pinnedProductsAccessControl: DomainAccessControlConfig = [
  {
    featureEntitlementIds: [FeatureEntitlements.CollectionProductPinning],
  },
]

export const retailerCollectionsManualSortControl: DomainAccessControlConfig = [
  {
    // reuse pinning entitlement
    featureEntitlementIds: [FeatureEntitlements.CollectionProductPinning],
  },
]

export const collectionDuplicationAccessControl: DomainAccessControlConfig = {
  minICPlatformVersion: ICPlatformVersion.r2024_09_0,
}

export const adminAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.Admin],
}
