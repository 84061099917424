import {
  makeUseApolloErrorMessage,
  makeUseGetApolloErrorMessage,
} from '@retailer-platform/dashboard/intl'
import { type DomainMessages } from './intl'

const knownServiceErrorMessages: Record<string, DomainMessages> = {
  // Add known service error messages here
}

export const useDomainGetApolloErrorMessage =
  makeUseGetApolloErrorMessage<DomainMessages>(knownServiceErrorMessages)
export const useDomainApolloErrorMessage =
  makeUseApolloErrorMessage<DomainMessages>(knownServiceErrorMessages)
