import type { PropsWithChildren, FunctionComponent } from 'react'
import { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import {
  borderRadius,
  Button,
  Checkbox,
  colors,
  fontSize,
  fontWeight,
  spacing,
} from '@retailer-platform/shared-components'
import { MultiselectDropdown } from '@retailer-platform/shared-components/src/tds'
// eslint-disable-next-line workspaces/no-relative-imports
import { useDomainMessages } from '../../../../../../../../../domains/products/src/utils/domain/intl'

const FilterDropdownButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  gap: spacing.X8,
  color: colors.GRAYSCALE.X50,
  paddingLeft: spacing.X8,
  paddingRight: spacing.X8,
})

const FilterContainer = styled.div({
  position: 'relative',

  '&.filter-active button': {
    border: `1px solid ${colors.GRAYSCALE.X30}`,
    outline: `2px solid ${colors.GRAYSCALE.X70}`,
    boxShadow: 'none',
    outlineOffset: '1px',
  },

  '.filter-down-icon': {
    transition: 'transform 0.1s ease',
  },

  '&.filter-active .filter-down-icon': {
    transform: 'rotate(180deg)',
  },
})

const FilterContent = styled.div({
  position: 'absolute',
  backgroundColor: '#fff',
  zIndex: 9999,
  boxShadow: `0px 2px 8px rgba(0, 0, 0, 0.16)`,
  borderRadius: borderRadius.X8,
  padding: spacing.X16,
  margin: spacing.X8,
  gap: spacing.X8,
  display: 'flex',
  flexDirection: 'column',
})

const FilterContentRow = styled.div({
  wordBreak: 'keep-all',
  whiteSpace: 'nowrap',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'left',
  gap: spacing.X16,
  color: colors.GRAYSCALE.X50,
  fontWeight: fontWeight.REGULAR,
  fontSize: fontSize.X15,
})
const IS_AVAILABLE = 'isAvailable'
const HAS_SALE_PRICING = 'hasSalePricing'
const HAS_AVAILABILITY_SCORE_OVERRIDE = 'hasAvailabilityScoreOverride'

const filterHelper = (filters, option) => {
  return filters.find(filter => filter.value === option.value)
}

export interface ProductLocationFilters {
  isAvailable: boolean
  hasSalePricing: boolean
  hasAvailabilityScoreOverride: boolean
}

interface Props {
  filters: ProductLocationFilters
  onChange: (filter: ProductLocationFilters) => void
  showAvailabilityScoreOverride: boolean
}

export const ProductLocationsFilters: FunctionComponent<PropsWithChildren<Props>> = ({
  onChange,
  filters,
  showAvailabilityScoreOverride,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const dropdownRef = useRef(null)

  const i18n = useDomainMessages({
    filters: 'productsDomain.view.storeLocations.filters.title',
    isAvailable: 'productsDomain.view.storeLocations.filters.isAvailable',
    hasSalePricing: 'productsDomain.view.storeLocations.filters.hasSalePricing',
    hasAvailabilityScoreOverride:
      'productsDomain.view.storeLocations.filters.hasAvailabilityScoreOverride',
  })

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const handleIsAvailableChange = () => {
    const newState = {
      ...filters,
      isAvailable: !filters.isAvailable,
    }
    if (onChange) onChange(newState)
  }

  const handleHasSalePricingChange = () => {
    const newState = {
      ...filters,
      hasSalePricing: !filters.hasSalePricing,
    }
    if (onChange) onChange(newState)
  }

  const handleHasAvailabilityScoreOverride = () => {
    const newState = {
      ...filters,
      hasAvailabilityScoreOverride: !filters.hasAvailabilityScoreOverride,
    }
    if (onChange) onChange(newState)
  }

  const options = [
    { label: i18n.isAvailable, value: IS_AVAILABLE },
    { label: i18n.hasSalePricing, value: HAS_SALE_PRICING },
  ]

  if (showAvailabilityScoreOverride) {
    options.push({
      label: i18n.hasAvailabilityScoreOverride,
      value: HAS_AVAILABILITY_SCORE_OVERRIDE,
    })
  }

  const optionsHandlers = {
    [IS_AVAILABLE]: handleIsAvailableChange,
    [HAS_SALE_PRICING]: handleHasSalePricingChange,
    [HAS_AVAILABILITY_SCORE_OVERRIDE]: handleHasAvailabilityScoreOverride,
  }

  const [previousDropdownState, setPreviousDropdownState] = useState<
    { label: string; value: string }[]
  >([])

  return (
    <MultiselectDropdown
      options={options}
      onChange={filters => {
        options.forEach(option => {
          if (
            (filterHelper(filters, option) && !filterHelper(previousDropdownState, option)) ||
            (!filterHelper(filters, option) && filterHelper(previousDropdownState, option))
          ) {
            optionsHandlers[option.value]()
          }
          setPreviousDropdownState(filters)
        })
      }}
      controlShouldRenderValue={false}
      placeholder={
        previousDropdownState.length
          ? `${i18n.filters} (${previousDropdownState.length})`
          : i18n.filters
      }
      width={200}
    />
  )
}
