const EN_US = {
  'catalogAdminDomain.title': 'Catalog Admin',
  'catalogAdminDomain.imageManagement.title': 'Fix Image Alert',
  'catalogAdminDomain.imageUploads.title': 'Image uploads',

  // Catalog attribute management Platform
  'catalogAdminDomain.attributeManagement.nav.title': 'Attribute Management',
  'catalogAdminDomain.attributeManagement.createButton': 'Create',
  'catalogAdminDomain.attributeManagement.notifyErrorMsg': 'There was an error fetching the steps',
  'catalogAdminDomain.attributeManagement.create.nav.title': 'Create Step',
  'catalogAdminDomain.attributeManagement.create.label.name': 'Name',
  'catalogAdminDomain.attributeManagement.create.label.owner': 'Owner',
  'catalogAdminDomain.attributeManagement.create.label.description': 'Description',
  'catalogAdminDomain.attributeManagement.create.label.description.optional': 'Optional',
  'catalogAdminDomain.attributeManagement.create.label.prompt': 'Prompt Template',
  'catalogAdminDomain.attributeManagement.create.label.model': 'LLM Model selection',
  'catalogAdminDomain.attributeManagement.create.label.properties': 'Properties',
  'catalogAdminDomain.attributeManagement.create.button.cancel': 'Cancel',
  'catalogAdminDomain.attributeManagement.create.button.create': 'Create',
  'catalogAdminDomain.attributeManagement.create.error.message': 'Error creating extraction model',
  'catalogAdminDomain.attributeManagement.create.success.message':
    'Extraction model created successfully',
  '.attributeManagement.modal.model': 'LLM Model',
  'catalogAdminDomain.attributeManagement.modal.modelPlaceholder': 'Select LLM Model',
  'catalogAdminDomain.attributeCreationPlatform.table.filters.id': 'Filter By Id',
  'catalogAdminDomain.attributeCreationPlatform.table.filters.name': 'Filter By Name',
  'catalogAdminDomain.attributeCreationPlatform.table.filters.owner': 'Filter By Owner',
  'catalogAdminDomain.attributeManagement.create.bottomSectionHeader': 'LLM Model',
  'catalogAdminDomain.attributeManagement.create.label.property.subtitle':
    'These properties will be passed along with your prompt template to the LLM. You can mention these properties in your prompt to signal to the AI how to use them',
  'catalogAdminDomain.attributeManagement.create.label.owner.subtitle':
    'Enter a team name or point of contact here',
  'catalogAdminDomain.attributeManagement.create.topSectionHeader': 'Details',
  'catalogAdminDomain.attributeManagement.table.headers.id': 'ID',
  'catalogAdminDomain.attributeManagement.table.headers.name': 'Name',
  'catalogAdminDomain.attributeManagement.table.headers.type': 'Type',
  'catalogAdminDomain.attributeManagement.table.headers.type.help': 'Displays the type of model.',
  'catalogAdminDomain.attributeManagement.table.headers.owner': 'Owner',
  'catalogAdminDomain.attributeManagement.table.headers.actions': 'Actions',
  'catalogAdminDomain.attributeManagement.table.actions.edit': 'Edit Models',
  'catalogAdminDomain.attributeManagement.modal.table.headers.propertyName': 'Property Name',
  'catalogAdminDomain.attributeManagement.modal.table.headers.required': 'Required',
  'catalogAdminDomain.attributeManagement.modal.table.headers.images': 'Images',
  'catalogAdminDomain.attributeManagement.modal.owner': 'Owner',
  'catalogAdminDomain.attributeManagement.modal.owner.subtitle':
    'Enter a team name or point of contact here',
  'catalogAdminDomain.attributeManagement.modal.prompt': 'Prompt',
  'catalogAdminDomain.attributeManagement.modal.submit': 'Submit',
  'catalogAdminDomain.attributeManagement.modal.cancel': 'Cancel',
  'catalogAdminDomain.attributeManagement.table.property.empty.message': 'No properties added yet',
  'catalogAdminDomain.attributeManagement.property.input.placeholder': 'Enter new property name',
  'catalogAdminDomain.attributeManagement.property.input.button.add': 'Add',
  'catalogAdminDomain.attributeManagement.property.delete': 'Delete',
  'catalogAdminDomain.attributeManagement.changes.modal.title': 'View Changes',
  'catalogAdminDomain.attributeManagement.changes.modal.submit': 'Submit',
  'catalogAdminDomain.attributeManagement.changes.modal.cancel': 'Cancel',
  'catalogAdminDomain.attributeManagement.changes.modal.reason': 'Reason',
  'catalogAdminDomain.attributeManagement.changes.modal.reasonPlaceholder':
    'Enter reason for the change',
  'catalogAdminDomain.attributeManagement.changes.previous': 'Previous',
  'catalogAdminDomain.attributeManagement.changes.next': 'Next',
  'catalogAdminDomain.attributeManagement.changes.none': 'None',
  'catalogAdminDomain.attributeManagement.bottomSectionHeader': 'LLM Prompt Template',
  'catalogAdminDomain.attributeManagement.label.required.help':
    'If required, a prompt will not go to the LLM for a product if that property is not provided. This can be used to save cost when a critical property is missing',
  'catalogAdminDomain.attributeManagement.label.images.help':
    'indicates if this property is an image URL or series of image URLs. LLMs treat images differently so we need to flag them as such.',
  'catalogAdminDomain.attributeManagement.create.property.warning.message':
    'Property name already exists',
  'catalogAdminDomain.attributeManagement.changes.button.makeLive': 'Make Live',
  'catalogAdminDomain.attributeManagement.changes.button.tombstone': 'Tombstone',
  'catalogAdminDomain.attributeManagement.changes.button.viewChanges': 'View Changes',
  'catalogAdminDomain.attributeManagement.modal.createNewVersion': 'Create new version',
  'catalogAdminDomain.attributeManagement.topSectionHeader': 'Details',
  'catalogAdminDomain.attributeManagement.modal.editDetails': 'Edit Details',
  'catalogAdminDomain.attributeManagement.modal.updateDetails': 'Update Details',
  'catalogAdminDomain.attributeManagement.modal.description': 'Description',
  'catalogAdminDomain.attributeManagement.modal.createdAt': 'Created At',
  'catalogAdminDomain.attributeManagement.modal.createdBy': 'Created By',
  'catalogAdminDomain.attributeManagement.details.button.live': 'View Live',
  'catalogAdminDomain.attributeManagement.details.button.latest': 'View Latest',
  'catalogAdminDomain.attributeManagement.details.button.runManualTest': 'Run Manual Test',
  'catalogAdminDomain.attributeManagement.details.version': 'Version',
  'catalogAdminDomain.attributeManagement.details.button.create': 'Create new version',
  'catalogAdminDomain.attributeManagement.details.button.reset': 'Reset',
  'catalogAdminDomain.attributeManagement.runResults.table.headers.id': 'Id',
  'catalogAdminDomain.attributeManagement.runResults.table.headers.requester': 'Requester',
  'catalogAdminDomain.attributeManagement.runResults.table.headers.version': 'Version',
  'catalogAdminDomain.attributeManagement.runResults.table.headers.completed': 'Status',
  'catalogAdminDomain.attributeManagement.runResults.table.headers.productCount': 'Completed Tasks',
  'catalogAdminDomain.attributeManagement.runResults.table.headers.downloadCSV': 'Download CSV',
  'catalogAdminDomain.attributeManagement.runResults.table.headers.startTime': 'Start Time',
  'catalogAdminDomain.attributeManagement.runTests.modal.body.description':
    'You can upload a CSV file with less than 100 rows to run a test.',
  'catalogAdminDomain.attributeManagement.runTests.modal.body.template.description':
    'The file should contain the following columns:',
  'catalogAdminDomain.attributeManagement.runTests.modal.upload.noFileSelected': 'No file selected',
  'catalogAdminDomain.attributeManagement.runTests.modal.body.button.upload': 'Upload file',
  'catalogAdminDomain.attributeManagement.runTests.modal.body.button.download': 'Download Template',
  'catalogAdminDomain.attributeManagement.runTests.modal.body.button.update': 'Update',
  'catalogAdminDomain.attributeManagement.runTests.modal.title': 'Run Test',
  'catalogAdminDomain.attributeManagement.runTests.modal.button.submit': 'Submit',
  'catalogAdminDomain.attributeManagement.runTests.modal.button.cancel': 'Cancel',
  'catalogAdminDomain.attributeManagement.runTests.modal.upload.button': 'Choose file',
  'catalogAdminDomain.attributeManagement.runTests.modal.upload.dragDrop':
    'Drag and Drop file here',
  'catalogAdminDomain.attributeManagement.runTests.modal.upload.or': 'or',
  'catalogAdminDomain.attributeManagement.runTests.modal.upload.error.fileTypeError':
    'File type is not supported. Must be a CSV file',
  'catalogAdminDomain.attributeManagement.runTests.modal.upload.error.fileContentError':
    'File content is not supported',
  'catalogAdminDomain.attributeManagement.runTests.modal.upload.error.uploadError':
    'There was an error uploading the file',
  'catalogAdminDomain.attributeManagement.runTests.modal.upload.error.error': 'Error',
  'catalogAdminDomain.attributeManagement.runTests.modal.upload.error.retry': 'Retry',
  'catalogAdminDomain.attributeManagement.runTests.modal.upload.error.rowError': 'Row error',
  'catalogAdminDomain.attributeManagement.modal.model': 'LLM Model',
  'catalogAdminDomain.attributeManagement.modal.title': 'Changes',
  'catalogAdminDomain.attributeManagement.runManualTest.modal.snowflake.title': 'Run Manual Test',
  'catalogAdminDomain.attributeManagement.runManualTest.modal.snowflake.description':
    'This will start a manual test of this query in Snowflake.',
  'catalogAdminDomain.attributeManagement.stepAttributeForms.snowflakeQueryInputForm.ttl':
    'Time to live (hours)',
  'catalogAdminDomain.attributeManagement.stepAttributeForms.snowflakeQueryInputForm.deduplicationDays':
    'Deduplication days',
  'catalogAdminDomain.attributeManagement.stepAttributeForms.snowflakeQueryInputForm.limit':
    'Tasks created per run',
  'catalogAdminDomain.attributeManagement.stepAttributeForms.snowflakeQueryInputForm.alwaysRunQuery':
    'Always run query',
  'catalogAdminDomain.attributeManagement.stepAttributeForms.snowflakeQueryInputForm.ttl.help':
    'The number of hours before he snowflake query may run again. Records are stored and reused between querying snowflake. Larger values help save costs. Works in tandem with "limit" to process all records over several runs.',
  'catalogAdminDomain.attributeManagement.stepAttributeForms.snowflakeQueryInputForm.deduplicationDays.help':
    'The number of days to check if an entity has already been processed by the pipeline. Allows pipelines to rerun on the same entity after the given number of days.',
  'catalogAdminDomain.attributeManagement.stepAttributeForms.snowflakeQueryInputForm.limit.help':
    'The number of records to process in each run. Larger values help save costs. Works in tandem with "ttl" to process all records over several runs.',
  'catalogAdminDomain.attributeManagement.stepAttributeForms.snowflakeQueryInputForm.query.help':
    'The Snowflake query to be executed. Queries must include an entity_id and entity_type to help link the record back to a catalog entity (ex. product_id and "product"). Queries should be limited to a reasonable number based on how many tasks are expected to be completed during your "Time to live". Regardless, queries are limited to 2 million records.',
  'catalogAdminDomain.attributeManagement.stepAttributeForms.snowflakeQueryInputForm.title':
    'Snowflake Query Input',
  'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.scheduleTitle':
    'Schedule (Coming soon)',
  'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepsTitle': 'Steps',
  'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.enableSchedule':
    'Enable Schedule',
  'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.hoursInterval':
    'Interval (hours)',
  'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.unscheduled':
    'Unscheduled',
  'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepSelectModal.title':
    'Select Step',
  'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepSelectModal.select':
    'Select',
  'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepSelectModal.selectStepTitle':
    'Select Step',
  'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepSelectModal.selectStepVersionTitle':
    'Select Step Version',
  'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepSelectModal.reset':
    'Reset',
  'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepSelectModal.restart':
    'Restart',
  'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepSelectModal.confirm':
    'Confirm',
  'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepSelectModal.errorMessage':
    'Error fetching step versions',
  'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepSelectModal.deleteStep':
    'Delete Step',
  'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepSelectModal.editStep':
    'Edit Step',
  'catalogAdminDomain.attributeManagement.runManualTest.modal.pipeline.title': 'Run Manual Test',
  'catalogAdminDomain.attributeManagement.runManualTest.modal.pipeline.description':
    'This will start a manual test of this pipeline.',
  'catalogAdminDomain.attributeManagement.runManualTest.modal.pipeline.submit': 'Submit',
  'catalogAdminDomain.attributeManagement.runManualTest.modal.pipeline.cancel': 'Cancel',
  'catalogAdminDomain.attributeManagement.table.actions.details': 'Details',
  'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepSelectModal.setOverridesTitle':
    'Set Overrides',
  'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepSelectModal.selectStepDescription':
    'Select a step to use in this step of the pipeline.',
  'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepSelectModal.selectStepVersionDescription':
    'Select a version of the step to use in this step of the pipeline.',
  'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepSelectModal.setOverridesDescription':
    "Set any overrides for the selected step. These overrides will apply exclusively to this pipeline and won't affect other pipelines.",
  // Header Component
  'catalogAdminDomain.imageManagement.header.changeNotification': 'Change Email Notification',
  'catalogAdminDomain.imageManagement.header.settings': 'Settings',

  // List Component
  'catalogAdminDomain.imageManagement.list.title': 'Fix Image Alert',
  'catalogAdminDomain.imageManagement.list.columns.productImage': 'Product Image',
  'catalogAdminDomain.imageManagement.list.columns.productName': 'Product Name',
  'catalogAdminDomain.imageManagement.list.columns.upc': 'UPC Code',
  'catalogAdminDomain.imageManagement.list.columns.rrc': 'RRC Code',
  'catalogAdminDomain.imageManagement.list.columns.imageUrl': 'Image Url',
  'catalogAdminDomain.imageManagement.list.columns.imageProblem': 'Image Problem',
  'catalogAdminDomain.imageManagement.list.columns.removalDeadline': 'Removal Deadline',
  'catalogAdminDomain.imageManagement.list.actions': 'Actions',
  'catalogAdminDomain.imageManagement.list.viewImage': 'View Image',
  'catalogAdminDomain.imageManagement.list.moreActions': 'More Actions',
  'catalogAdminDomain.imageManagement.list.actions.removeImage': 'Remove/Hide Image',
  'catalogAdminDomain.imageManagement.list.actions.delayRemoval': 'Delay Removal By 14 Days',
  'catalogAdminDomain.imageManagement.list.actions.falsePositive': 'Mark As False Positive',
  'catalogAdminDomain.imageManagement.list.actions.editProduct': 'Edit Product',

  // Modal Component
  'catalogAdminDomain.imageManagement.modal.title': 'Settings',
  'catalogAdminDomain.imageManagement.modal.optOut': 'Opt Out',
  'catalogAdminDomain.imageManagement.modal.delayRemoval': 'Delay Removal',

  // Product Edit
  'catalogAdminDomain.productEdit.nav.title': 'Product edit',
  'catalogAdminDomain.productEdit.show.breadcrumb.root': 'Product Edit',
  'catalogAdminDomain.productEdit.show.search.placeholder': 'Product ID',
  'catalogAdminDomain.productEdit.show.search.button': 'Edit',
  'catalogAdminDomain.ProductEdit.show.title': 'Product',
  'catalogAdminDomain.ProductEdit.show.emptyProduct': 'Please, enter a Product ID.',
  'catalogAdminDomain.ProductEdit.show.noProductFound': 'Product not found.',

  // MD Platform
  'catalogAdminDomain.mdPlatform.nav.title': 'Measure & detect platform',
  'catalogAdminDomain.mdPlatform.create.nav.title': 'Create New Detection',
  'catalogAdminDomain.mdPlatform.create.title': 'Create a New Detection',
  'catalogAdminDomain.mdPlatform.create.label.name': 'Detection Name',
  'catalogAdminDomain.mdPlatform.create.label.description': 'Description',
  'catalogAdminDomain.mdPlatform.create.label.limit': 'Limit',
  'catalogAdminDomain.mdPlatform.create.label.defectTag': 'Fix Defect Tag',
  'catalogAdminDomain.mdPlatform.create.label.owner': 'Owner',
  'catalogAdminDomain.mdPlatform.create.label.enable': 'Enable',
  'catalogAdminDomain.mdPlatform.create.label.query': 'Query',
  'catalogAdminDomain.mdPlatform.create.button.cancel': 'Cancel',
  'catalogAdminDomain.mdPlatform.create.button.create': 'Create',

  // Quality Audit Workflows
  'catalogAdminDomain.qualityAuditWorkflows.nav.title': 'Quality Audit Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.title': 'Quality Audit Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.motd':
    'Welcome to the Quality Audit System (QAS)! Here, you can audit and correct any dataset you choose. Click the button below to learn more.',
  'catalogAdminDomain.qualityAuditWorkflows.list.learnMore': 'Learn More',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.launch': 'Launch',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.workflow': 'Workflow',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.taskCount': 'Task Count',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.source': 'Source',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.notifyUpdateSuccessMsg':
    'Update published.',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.notifyUpdateErrorMsg':
    'Update failed, please try again later.',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.dueDate': 'Due Date',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.actions': 'Actions',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.productSources': 'Product Sources',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.productMatches': 'Product Matches',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.value.view': 'View',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.inputData': 'Input Data',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.column.inputData.value': 'View',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.actions.addToMyWorkflows':
    'Add To My Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.actions.comment':
    'Set Internal/External Comments',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.actions.flagReject': 'Reject All Values',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.actions.flagAccept': 'Accept All Values',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.actions.viewInputData': 'View Input Data',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.actions.removeFromMyWorkflows':
    'Remove From My Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.actions.subscribeToNotifications':
    'Subscribe To Notifications',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.actions.unsubscribeToNotifications':
    'Unsubscribe To Notifications',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.outsourceWorkflows.title':
    'Outsource Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.problemTrackerWorkflows.title':
    'Problem Tracker Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.automatedDetectWorkflows.title':
    'Automated Detect Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.humanEvalAutomatedFixIngestionWorkflows.title':
    'Human Eval Automated Fix Ingestion Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.automatedFixWorkflows.title':
    'Automated Fix Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.measureAndDetect.title':
    'Measure And Detect',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.imageAttributesExtractionWorkflows.title':
    'Image Attributes Extraction',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.mlWorkflows.title': 'ML Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.table.myWorkflows.title': 'My Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.list.actions.submitAuditTasks': 'Submit Tasks',
  'catalogAdminDomain.qualityAuditWorkflows.list.actions.modifyWorkflow': 'Modify Workflow',
  'catalogAdminDomain.qualityAuditWorkflows.list.actions.createWorkflow': 'Create Workflow',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.title': 'Submit Tasks',
  'catalogAdminDomain.qualityAuditWorkflows.task.error.unexpectedError':
    'There was an unexpected error processing your submission. Please contact support for assistance or try again later.',
  'catalogAdminDomain.qualityAuditWorkflows.task.error.legacyWorkflow':
    'This is a legacy workflow, please contact support to make changes here.',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.progressbar.text.setup':
    'Setup Task Submission (Step 1 of 2)',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.progressbar.text.loading':
    'Loading Sample Data...',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.progressbar.text.review':
    'Review Sample Rows (Step 1 of 2)',
  'catalogAdminDomain.queryAuditWorkflows.task.submit.form.workflow.placeholder': 'Select Workflow',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.createdWhy.label': 'Reason:',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.createdWhy.explanation':
    'Explanation for creating this batch.',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.maxBatchNum.label':
    'Max Number Of Batches:',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.maxBatchNum.explanation':
    'The max number of batches to create based on SQL provided.',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.batchSize.label': 'Batch Size:',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.batchSize.explanation':
    'The max number of tasks that this batch will contain.',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.description.label': 'Description',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.description.explanation':
    'Explain the details of these tasks.',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.samplingStrategy.label':
    'Sampling Strategy',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.samplingStrategy.explanation':
    '(Optional) Use a sampling function to select key tasks from your query.',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.query.label': 'Batch Query',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.query.alert':
    'To learn how to structure your query, read the details ',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.query.alertHere': 'here.',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.query.explanation':
    'Enter a Snowflake query to create the tasks. Note: Previewing has a 5-minute execution timeout, and submission has a 30-minute timeout.',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.button.continue': 'Continue',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.form.button.cancel': 'Cancel',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.preview.grid.header.entity': 'Entity',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.preview.grid.header.workflowId':
    'Workflow ID',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.preview.button.preview': 'Preview',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.preview.button.submit': 'Submit',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.preview.button.back': 'Back',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.preview.button.cancel': 'Cancel',
  'catalogAdminDomain.qualityAuditWorkflows.task.submit.preview.button.submit.addAnother':
    'Submit and add more',
  'catalogAdminDomain.qualityAuditWorkflows.select.taskState.title': 'Filter Batch State:',
  'catalogAdminDomain.qualityAuditWorkflows.select.taskState.pastDue': 'Past Due',
  'catalogAdminDomain.qualityAuditWorkflows.select.taskState.pending': 'Pending',
  'catalogAdminDomain.qualityAuditWorkflows.select.taskState.completed': 'Completed',
  'catalogAdminDomain.qualityAuditWorkflows.select.taskSecondaryState.title':
    'Filter Batch Status:',
  'catalogAdminDomain.qualityAuditWorkflows.select.assignee.title': 'Filter By Assignee:',
  'catalogAdminDomain.qualityAuditWorkflows.batch.submissionInfo':
    'This is a filtered view showing batches created from the submission {submissionUuid}. If you do not see any batches, please wait a moment for them to be generated. Otherwise, feel free to contact support with this url for further investigation.',
  'catalogAdminDomain.qualityAuditWorkflows.select.reviewer.title': 'Filter By Reviewer:',
  'catalogAdminDomain.qualityAuditWorkflows.select.description.title': 'Filter By Description:',
  'catalogAdminDomain.qualityAuditWorkflows.select.workflow.title': 'Workflow Type',
  'catalogAdminDomain.qualityAuditWorkflows.graph.title': 'Task Count',
  'catalogAdminDomain.qualityAuditWorkflows.graph.seriesName': 'Task Count',
  'catalogAdminDomain.qualityAuditWorkflows.graph.category.approvedTasks': 'Approved Tasks',
  'catalogAdminDomain.qualityAuditWorkflows.graph.category.rejectedTasks': 'Rejected Tasks',
  'catalogAdminDomain.qualityAuditWorkflows.graph.category.auditableTasks': 'Auditable Tasks',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.notifyFetchErrorMsg':
    'Momentary network issue occurred. If this persists, please contact support.',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.collecting':
    'The batch is still being created. Please check back later.',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.notifyFetchSuccessMsg':
    'Network connectivity established.',
  'catalogAdminDomain.qualityAuditWorkflows.range.title': 'Filter Due Date:',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.reassign.title': 'Select Reassignment',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.reassign.select.placeholder':
    'Select Workflow',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.button.reassign': 'Reassign',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.combine.title': 'Confirm Combine',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.combine.body':
    'You are about to combine several batches, please confirm action.',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.byPass.title': 'Confirm Release',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.byPass.body':
    'You are about to release the selected batches.',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.dueDate.title': 'Override Due Date',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.reject.title': 'Confirm Rejection',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.reject.body':
    'You are about to reject the following batch.',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.sample.title': 'Override Sample Size',
  'catalogAdminDomain.qualityAuditWorkflows.batch.title': 'Batch Management',
  'catalogAdminDomain.qualityAuditWorkflows.batch.state.pending': 'Pending',
  'catalogAdminDomain.qualityAuditWorkflows.batch.state.rejected': 'Rejected',
  'catalogAdminDomain.qualityAuditWorkflows.batch.state.approved': 'Approved',
  'catalogAdminDomain.qualityAuditWorkflows.batch.state.bypassed': 'By Passed',
  'catalogAdminDomain.qualityAuditWorkflows.batch.state.force_rejected': 'Force Rejected',
  'catalogAdminDomain.qualityAuditWorkflows.batch.state.collectingTasks': 'Collecting Tasks',
  'catalogAdminDomain.qualityAuditWorkflows.batch.state.errored': 'Errored',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.rework.title': 'Rework Batches for Audit',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.title': 'Workflow Batches for Audit',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.launch': 'Launch',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.batchNumber': 'Batch Number',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.description': 'Description',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.received': 'Received',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.recommendedSample':
    'Recommended Sample',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.sampleSize': 'Sample Size',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.numberAuditable': '# Auditable',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.dueDate': 'Due Date (UTC)',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.createdAt': 'Created At (UTC)',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.actions': 'Actions',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.status': 'Status',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.assignee': 'Assignee',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.column.reviewer': 'Reviewer',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.action.overrideSampleSize':
    'Override Sample Size',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.action.rejectBatch': 'Reject Batch',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.action.overrideDueDate':
    'Override Due Date',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.button.release': 'Release Without Audit',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.button.combine': 'Combine',
  'catalogAdminDomain.qualityAuditWorkflows.batch.table.button.launch': 'Launch Audit',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.legends.title': 'Legend:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.legends.valid': 'Valid Value',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.legends.validWithSuggestedValue':
    'Valid Value With Suggested Value',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.legends.invalid': 'Invalid Value',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.legends.comments': 'Contains Comments',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.batchState': 'Batch State:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.batchSecondaryStatus': 'Batch Workflow Status:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.createdAt': 'Created At:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.vendor': 'Vendor:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.reviewer': 'Reviewer:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.assignee': 'Assignee:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.completedAt': 'Completed At:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.title': 'Tasks for Auditing',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.button.batchComments': 'Batch Comments',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.button.submit': 'Submit Completed Tasks',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.dueDate': 'Due Date',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.setFlag': 'Set Flag:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.imageSize': 'Image Size:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.columnWidth': 'Set Width:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.filterState': 'Filter State:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.filterByInternalComments':
    'Filter By Internal Comments',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.filterByExternalComments':
    'Filter By External Comments',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.pendingState': 'Set Pending:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.acceptTask': 'Accept Task',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.rejectTask': 'Reject Task',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.resetTask': 'Reset Task',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.state': 'State',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.entityId': 'Entity Id',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.productId': 'Product Id',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.retailerProductId':
    'Retailer Product Id',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.upc': 'UPC Code',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.image': 'Image',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.freeze': 'Freeze Column',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.column.unfreeze': 'Unfreeze Column',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.actions.leaveComments':
    'Leave Internal/External Comment',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.flagValue': 'Flag Value',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.viewAll': 'View All',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.flagValueWithSuggestValue':
    'Flag Value With Suggested Value',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.table.comment': 'Comment',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.title.comments': 'Comments',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.title.input': 'Input Data',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.value': 'Value',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.flag': 'Flag',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.submit': 'Submit',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.close': 'Close',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.cancel': 'Cancel',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.valid': 'Valid',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.faq': 'FAQ',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.invalid': 'Invalid',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.existingInternalComment':
    'Existing Internal Comment',
  'catalogAdminDomain.qualityAuditWorkflows.batch.modal.existingExternalComment':
    'Existing External Comment',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.internalComment': 'Internal Comment',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.externalComment': 'External Comment',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.custom': 'Custom',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.optional': 'Optional',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.suggestedValue': 'Suggested Value',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.notFound': 'No input data found',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.errorLoading':
    'Error while loading input data',
  'catalogAdminDomain.qualityAuditWorkflows.modify.title': 'Modify Workflow',
  'catalogAdminDomain.qualityAuditWorkflows.modify.selectWorkflow': 'Select Workflow:',
  'catalogAdminDomain.qualityAuditWorkflows.modify.changeName': 'Change Name',
  'catalogAdminDomain.qualityAuditWorkflows.modify.samplePercentage.title': 'Sample Percentage:',
  'catalogAdminDomain.qualityAuditWorkflows.modify.samplePercentage.details':
    'Percentage sample amount for a batch.',
  'catalogAdminDomain.qualityAuditWorkflows.modify.failurePercentage.title': 'Failure Percentage:',
  'catalogAdminDomain.qualityAuditWorkflows.modify.failurePercentage.details':
    'Maximum failure percentage for a batch.',
  'catalogAdminDomain.qualityAuditWorkflows.modify.slaDays.title': 'Default SLA Days:',
  'catalogAdminDomain.qualityAuditWorkflows.modify.slaDays.details':
    'The expected number of days to finish tasks.',
  'catalogAdminDomain.qualityAuditWorkflows.modify.features.title': 'Features:',
  'catalogAdminDomain.qualityAuditWorkflows.modify.features.details':
    'Apply specific features to your workflow.',
  'catalogAdminDomain.qualityAuditWorkflows.modify.columns.title': 'Columns:',
  'catalogAdminDomain.qualityAuditWorkflows.modify.columns.details':
    'Configure how the columns will be displayed on the workflow.',
  'catalogAdminDomain.qualityAuditWorkflows.batch.errorMessage':
    'Unable to update batch, due to an issue, please try again later.',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.numOfBatches': 'Number Of Batches',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.numOfBatchesInfo':
    'The number of batches to create.',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.targetColumn': 'Target Column',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.targetColumnInfo':
    'Choose a column to calculate GMV or impressions. The chosen column must exist in your query so we can set the sampling weight.',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.sampleOrder': 'Sample Order',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.sampleOrderInfo':
    'Select the order on how we sample tasks.',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.dateRange': 'Date Range',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.dateRangeInfo':
    'Specify a time range to calculate GMV or impressions.',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.portionSize': 'Population Size',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.portionSizeInfo':
    '(Optional) The total number of subjects.',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.portion': 'Population Portion',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.portionInfo':
    'The percentage of entries to select.',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.confidence': 'Confidence Percentage',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.confidenceInfo':
    'The confidence level represents the probability that the true value falls within a specified range, meaning a 95% confidence level indicates a 95% chance the real value is within that range and a 5% chance it is outside.',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.error': 'Error Percentage',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.errorInfo':
    'The margin of error expresses sampling error.',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.title': 'Select Strategy',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.sampleModal.info':
    "The provided query will be modified to include an additional logic that calculates a sampling strategy to select the preferred entities. If you've already applied a sampling strategy, please remove it before proceeding.",
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.flagAll': 'Flag All',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.clearFlags': 'Clear Flags',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.columnsSelector': 'Columns:',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.errorMessage':
    'Unable to save, because a conflict has occurred. Please, review and try again.',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.conflictMessage':
    'There was an update to the existing comments, please review it before saving.',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.input.modal.title.change':
    'Change Input',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.input.modal.title.create': 'New Input',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.input.modal.info':
    'Ensure the provided values exactly match the values in your submissions. ',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.input.modal.type.label': 'Type:',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.input.modal.type.details':
    'Type of component.',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.input.modal.name.label': 'Name:',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.input.modal.name.details':
    'Name of the component.',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.input.modal.groupName.label':
    'Group Name:',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.input.modal.groupName.details':
    'Identifier for a set of components.',
  'catalogAdminDomain.qualityAuditWorkflows.configs.modal.apply': 'Apply',
  'catalogAdminDomain.qualityAuditWorkflows.configs.modal.save': 'Save',
  'catalogAdminDomain.qualityAuditWorkflows.configs.modal.close': 'Close',
  'catalogAdminDomain.qualityAuditWorkflows.configs.modal.features': 'Features',
  'catalogAdminDomain.qualityAuditWorkflows.configs.modal.features.details':
    'Apply specific features on the provided input selection.',
  'catalogAdminDomain.qualityAuditWorkflows.configs.modal.selection': 'Selection',
  'catalogAdminDomain.qualityAuditWorkflows.configs.modal.selection.details':
    'Select an individual component or group of components.',
  'catalogAdminDomain.qualityAuditWorkflows.configs.feature.modal.title': 'Feature',
  'catalogAdminDomain.qualityAuditWorkflows.configs.modal.tombstone': 'Deactivate',
  'catalogAdminDomain.qualityAuditWorkflows.tombstone.modal.title': 'Confirm Workflow Deactivation',
  'catalogAdminDomain.qualityAuditWorkflows.tombstone.modal.message':
    'Warning: Deactivating this workflow will make it inaccessible and remove it from all views. While this action can be reversed, you will need to contact the support team to restore the workflow. Are you sure you want to proceed?',
  'catalogAdminDomain.qualityAuditWorkflows.tombstone.modal.confirm': 'Confirm Deactivation',
  'catalogAdminDomain.qualityAuditWorkflows.tombstone.modal.cancel': 'Cancel',

  'catalogAdminDomain.qualityAuditWorkflows.configs.feature.modal.name.label': 'Name:',
  'catalogAdminDomain.qualityAuditWorkflows.configs.feature.modal.name.details':
    'The provided name should match one from this list.',
  'catalogAdminDomain.qualityAuditWorkflows.configs.feature.modal.value.label': 'Value:',
  'catalogAdminDomain.qualityAuditWorkflows.configs.feature.modal.value.details':
    'Depending on the feature name you entered, you may need to provide a value. (Optional)',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.input.modal.or.label': 'Or',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.modal.title': 'Column Config',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.modal.alias.label': 'Alias:',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.modal.alias.details':
    'Name of the column.',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.modal.render.label': 'Render:',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.modal.render.details':
    'Specifies how to display the column values.',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.modal.inputs.label': 'Inputs:',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.modal.inputs.details':
    'Determines which values to display in the column.',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.modal.inputs.flaggable': 'Flaggable',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.modal.inputs.suggestible':
    'Suggestible',
  'catalogAdminDomain.qualityAuditWorkflows.configs.columns.modal.inputs.subType': 'Subtype',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.tab.currentData': 'New Data',
  'catalogAdminDomain.qualityAuditWorkflows.tasks.modal.tab.conflictingData': 'Conflicting Data',
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.nav.title':
    'Quality Audit Create Workflows',
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.submit.title': 'Create Workflow',
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.submit.error':
    'Unable to create workflow, please try again later.',
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.submit.form.name.explanation': `
        Workflow names are a combination of three parts: Domain, Service, and Type or Workflow. For
        instance, "outsource/scale/alcohol" indicates that the data pertains to auditing
        alcohol-related data from Scale on the outsourcing platform.`,
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.submit.form.name.label': 'Name',
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.submit.form.name.domain': '1. Domain',
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.submit.form.name.domainDetails': `The name of the subject to which the data pertains. For example, enrichment or outsourcing.`,
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.submit.form.name.service': '2. Service',
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.submit.form.name.serviceDetails': `The name of the service or vendor from which the data originates. For example, Scale or ML.`,
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.submit.form.name.workflow':
    '3. Workflow/Type',
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.submit.form.name.workflowDetails': `The name of the workflow. For example, Taxonomy or Images.`,
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.submit.form.name.placeholder':
    'Workflow Name',
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.submit.form.batchSamplePercentage.label':
    'Batch Sample Percentage',
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.submit.form.batchFailurePercentage.label':
    'Batch Failure Percentage',
  'catalogAdminDomain.qualityAuditWorkflows.createWorkflows.submit.form.defaultSLADelayDays.label':
    'SLA Delay in Days',
  'catalogAdminDomain.imageUploads.productNotFound': 'Item not found',
  'catalogAdminDomain.imageUploads.invalidImage': 'Failed to save image',
  'catalogAdminDomain.mdPlatformList.table.filters.id': 'Filter By Id',
  'catalogAdminDomain.mdPlatformList.table.filters.name': 'Filter By Name',
  'catalogAdminDomain.mdPlatformList.table.filters.owner': 'Filter By Owner',
  'catalogAdminDomain.mdPlatformList.motd.button': 'Learn More',
  'catalogAdminDomain.mdPlatformList.motd':
    "Welcome to the Catalog's measure and detect platform! Here, you can create detection queries to find problematic issues in Catalog. You can learn more by clicking on the button below.",
  'catalogAdminDomain.mdPlatformList.createButton': 'Create New Detection',
  'catalogAdminDomain.mdPlatformList.notify.errorMessage':
    'Momentary network issue occurred. If this persists, please contact support.',
  'catalogAdminDomain.mdPlatformList.table.reviewStates.inReview': 'In Review',
  'catalogAdminDomain.mdPlatformList.table.reviewStates.testing': 'Testing',
  'catalogAdminDomain.mdPlatformList.table.reviewStates.rejected': 'Rejected',
  'catalogAdminDomain.mdPlatformList.table.reviewStates.approved': 'Approved',
  'catalogAdminDomain.mdPlatformList.table.details.label': 'Expand for more details',
  'catalogAdminDomain.mdPlatformList.table.query': 'Query:',
  'catalogAdminDomain.mdPlatformList.table.createdBy': 'Created By:',
  'catalogAdminDomain.mdPlatformList.table.createdWhy': 'Created Why:',
  'catalogAdminDomain.mdPlatformList.table.values.none': 'None',
  'catalogAdminDomain.mdPlatformList.table.headers.id': 'Id',
  'catalogAdminDomain.mdPlatformList.table.headers.name': 'Name',
  'catalogAdminDomain.mdPlatformList.table.headers.version': 'Version',
  'catalogAdminDomain.mdPlatformList.table.headers.enabled': 'Enabled',
  'catalogAdminDomain.mdPlatformList.table.headers.enabled.help':
    'Displays if this detection is currently running or not.',
  'catalogAdminDomain.mdPlatformList.table.headers.limit': 'Limit',
  'catalogAdminDomain.mdPlatformList.table.headers.limit.help':
    'The max number of requests we can send',
  'catalogAdminDomain.mdPlatformList.table.headers.owner': 'Owner',
  'catalogAdminDomain.mdPlatformList.table.headers.status': 'Review Status',
  'catalogAdminDomain.mdPlatformList.table.headers.status.help':
    'Displays the state this version is in.',
  'catalogAdminDomain.mdPlatformList.table.headers.actions': 'Actions',
  'catalogAdminDomain.mdPlatformList.title': 'Measure And Detect Platform',
  'catalogAdminDomain.mdPlatformList.table.actions.edit': 'Edit Detection',
  'catalogAdminDomain.mdPlatformList.table.actions.details': 'View Details',
  'catalogAdminDomain.mdPlatformList.modal.cancel': 'Cancel',
  'catalogAdminDomain.mdPlatformList.modal.title': 'Modify',
  'catalogAdminDomain.mdPlatformList.modal.submit': 'Submit',
  'catalogAdminDomain.mdPlatformList.modal.reset': 'Reset',
  'catalogAdminDomain.mdPlatformList.modal.input.reason': 'Reason:',
  'catalogAdminDomain.mdPlatformList.modal.input.reason.placeholder':
    'Enter reason for the change:',
  'catalogAdminDomain.mdPlatformList.modal.input.limit': 'Limit:',
  'catalogAdminDomain.mdPlatformList.modal.input.enabled': 'Enabled:',
  'catalogAdminDomain.mdPlatformList.modal.input.defectTag': 'Defect Tag:',
  'catalogAdminDomain.mdPlatformList.modal.input.cadence': 'Cadence:',
  'catalogAdminDomain.mdPlatformList.modal.input.owner': 'Owner:',
  'catalogAdminDomain.mdPlatformList.modal.input.description': 'Description:',
  'catalogAdminDomain.mdPlatformList.modal.error.create': 'Unable to get detection information',
  'catalogAdminDomain.mdPlatformList.modal.error.get': 'Unable to create new detection version',
  'catalogAdminDomain.mdPlatformList.modal.input.query': 'Query:',
  'catalogAdminDomain.mdPlatformDetails.title': 'Details',
  'catalogAdminDomain.mdPlatformDetails.notify.errorMessage':
    'Momentary network issue occurred. If this persists, please contact support.',
  'catalogAdminDomain.mdPlatformDetails.table.version': 'Version',
  'catalogAdminDomain.mdPlatformDetails.table.status': 'Status',
  'catalogAdminDomain.mdPlatformDetails.table.comments': 'Comments',
  'catalogAdminDomain.mdPlatformDetails.table.createdBy': 'Created By',
  'catalogAdminDomain.mdPlatformDetails.table.createdReason': 'Created Reason',
  'catalogAdminDomain.mdPlatformDetails.table.changes': 'Changes',
  'catalogAdminDomain.mdPlatformDetails.table.actions': 'Actions',
  'catalogAdminDomain.mdPlatformDetails.tab.properties': 'Properties',
  'catalogAdminDomain.mdPlatformDetails.tab.changes': 'Changes',
  'catalogAdminDomain.mdPlatform.versions.state': 'State:',
  'catalogAdminDomain.mdPlatform.versions.enabled': 'Enabled:',
  'catalogAdminDomain.mdPlatformDetails.approve': 'Approve',
  'catalogAdminDomain.mdPlatformDetails.reject': 'Reject',
  'catalogAdminDomain.mdPlatformDetails.tombstoned': 'Tombstoned',
  'catalogAdminDomain.mdPlatformDetails.tombstone': 'Tombstone',
  'catalogAdminDomain.mdPlatformDetails.tombstoneWarning':
    'You will permanently undo the changes made from this version.',
  'catalogAdminDomain.mdPlatformDetails.previous': 'Previous',
  'catalogAdminDomain.mdPlatformDetails.next': 'Next',
  'catalogAdminDomain.mdPlatformDetails.modal.title': 'Confirm',
  'catalogAdminDomain.mdPlatformDetails.modal.cancel': 'Cancel',
  'catalogAdminDomain.mdPlatformDetails.modal.submit': 'Submit',
  'catalogAdminDomain.mdPlatformDetails.modal.reason': 'State Reason',
} as const

export default EN_US
