export const enabledStoreConfigurationIds: Record<number, number[]> = {
  289: [
    1177, // https://freshgrocery.instacart.com
  ],

  295: [
    1249, // https://shop.metropolitan-market.com
  ],

  353: [
    1221, // https://shopnow.stewleonards.com
  ],

  370: [
    1250, // https://beta-shop.pricechopper.com
  ],

  381: [
    1246, // https://shop.newseasonsmarket.com
    1247, // https://shop.newleaf.com
  ],

  382: [
    1216, // https://shop.sprouts.com
  ],

  484: [
    1431, // https://restaurantdepot-whitelabel.instacart.com
  ],

  517: [
    1381, // https://foodtown-whitelabel.instacart.com
  ],

  567: [
    1419, // https://mortonwilliams-whitelabel.instacart.com
    1417, // https://brooklynharvest-whitelabel.instacart.com
  ],

  573: [
    134, // https://shop.hornbachers.com
    1260, // https://shop.coborns.com
    1261, // https://shop.shopmarketplacefoods.com
    1262, // https://shop.cashwise.com
    1331, // https://shop.andysliquor.com
  ],

  596: [
    1217, // https://shop.thefreshmarket.com
  ],

  613: [
    1209, // https://shop.foodbazaar.com
  ],

  885: [
    1245, // https://shop.bristolfarms.com
    1248, // https://shop.lazyacres.com
  ],

  2250: [
    1205, // https://shopwoodmans.com
  ],

  2265: [
    1275, // https://staging-shop.sprouts.com
  ],

  5343: [
    1350, // https://rosauers-whitelabel.instacart.com
    1354, // https://super1foods-whitelabel.instacart.com
    1356, // https://shop.huckleberrysnaturalmarket.com
  ],

  // harris-teeter-cs
  9366: [
    1347, // https://shop.harristeeterbymarianos-dev.com
    1348, // https://shop.qfc-dev.com
    1349, // https://shop.marianos-dev.com
  ],
}
