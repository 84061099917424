import { type FC, useState } from 'react'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { Popover } from '@mantine/core'
import { useIntl } from 'react-intl'
import { OpenInIcon } from '@instacart/ids-core'
import { LinkBase } from '@instacart/ids-customers'
import { useTheme } from '@retailer-platform/shared-components/src/tds'
import { Divider } from '../NavUserMenu/UserSettingsMenu.common'
import { NavItemStyle } from '../NavItem'
import { TextContainer } from '../NavItem'
import { UserFeedbackSurveyController } from '../../../../common/retailer-satisfaction-survey/user-feedback/UserFeedbackSurveyController'
import { useUserFeedbackContext } from '../../../../containers/user-feedback/UserFeedbackContext'
import { useHelpMenuLinks } from './hooks/useHelpMenuLinks'

type Props = {
  isOpen: boolean
  onClose: () => void
}

type MenuLinkProps = {
  text: string
  href?: string
  onClick: () => void
}

const MenuLink = ({ href, text, onClick }: MenuLinkProps) => {
  const theme = useTheme()
  return (
    <div css={{ position: 'relative' }}>
      <LinkBase
        href={href}
        onClick={onClick}
        css={{ display: 'block' }}
        style={{ display: 'contents' }}
        typographyStyle="linkMedium"
        target="_blank"
        rel="noopener noreferrer"
      >
        <NavItemStyle padding="8px 24px">
          <TextContainer>{text}</TextContainer>
        </NavItemStyle>
      </LinkBase>
      {href && (
        <div css={{ position: 'absolute', top: '12px', right: '24px' }}>
          <OpenInIcon css={{ fill: theme.colors.brandPrimaryDark }} size={16} />
        </div>
      )}
    </div>
  )
}

export const HelpMenu: FC<Props> = ({ isOpen, onClose }) => {
  const [showFeedback, setShowFeedback] = useState(false)
  const { hideFeedbackButton, showFeedbackButton } = useUserFeedbackContext()
  const intl = useIntl()
  const menuLinks = useHelpMenuLinks()

  const handleFeedbackClick = () => {
    onClose()
    setShowFeedback(true)
    hideFeedbackButton()
  }

  const handleFeedbackClose = () => {
    setShowFeedback(false)
    showFeedbackButton()
  }

  return (
    <>
      {showFeedback && <UserFeedbackSurveyController onClose={handleFeedbackClose} />}
      <Popover
        opened={isOpen}
        onChange={onClose}
        position="bottom-end"
        offset={0}
        styles={{
          dropdown: {
            padding: '16px 0 16px 0',
            border: 'none',
            boxShadow: '0 0 -10px 12px rgba(0, 0, 0, 0.12)',
            borderRadius: '0 0 0 0',
          },
        }}
        width={240}
      >
        <Popover.Target>
          <div css={{ display: 'flex', alignItems: 'center' }} />
        </Popover.Target>
        <Popover.Dropdown>
          {menuLinks.map(({ href, messageId }) => (
            <MenuLink
              key={messageId}
              onClick={onClose}
              href={href}
              text={intl.formatMessage({ id: messageId })}
            />
          ))}
          <Divider />
          <MenuLink onClick={handleFeedbackClick} text="Give feedback" />
        </Popover.Dropdown>
      </Popover>
    </>
  )
}
