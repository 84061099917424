import { type DashRouteType, URL_PUBLIC } from '@retailer-platform/dashboard/routing'

const URL_PUBLIC_AUTHENTICATION = URL_PUBLIC
const URL_PUBLIC_AUTHENTICATION_LOG_IN = `${URL_PUBLIC_AUTHENTICATION}/log-in`
const URL_PUBLIC_AUTHENTICATION_AUTH_SERVICE_CALLBACK = `${URL_PUBLIC_AUTHENTICATION}/auth/callback`

/**
 * The deprecated routes below are because there is some potential recovery for the UX by redirecting
 * them to the equivalent flow in auth-service. For example, the request-password-reset route in this
 * dashboard should obviously go to the request-password-reset page on auth-service.
 * Or if they are going to /password-reset/:token in this dashboard app, we'll still redirect them to
 * the request-password-reset page on auth-service so at least they can re-attempt to reset their password (correctly).
 */
// DEPRECATED PATH
const URL_PUBLIC_AUTHENTICATION_REQUEST_EMAIL_VERIFICATION = `${URL_PUBLIC_AUTHENTICATION}/request-email-verification`
// DEPRECATED PATH
const URL_PUBLIC_AUTHENTICATION_EMAIL_VERIFICATION = `${URL_PUBLIC_AUTHENTICATION}/email-verification/:token`
// DEPRECATED PATH
const URL_PUBLIC_AUTHENTICATION_PASSWORD_RESET = `${URL_PUBLIC_AUTHENTICATION}/password-reset/:token`
// DEPRECATED PATH
const URL_PUBLIC_AUTHENTICATION_REQUEST_PASSWORD_RESET = `${URL_PUBLIC_AUTHENTICATION}/request-password-reset`
// DEPRECATED PATH
const URL_PUBLIC_AUTHENTICATION_COMPLETE_ACCOUNT_SETUP = `${URL_PUBLIC_AUTHENTICATION}/complete-account-setup/:token`
// DEPRECATED PATH
const URL_PUBLIC_AUTHENTICATION_REQUEST_ACCOUNT_SETUP = `${URL_PUBLIC_AUTHENTICATION}/request-account-setup`
// @hygen:inject public-urls

export type DashRouteSet =
  | DashRouteType<'app-public-authentication'>
  | DashRouteType<'app-public-authentication-log-in'>
  | DashRouteType<'app-public-authentication-request-email-verification'>
  | DashRouteType<'app-public-authentication-email-verification', { token: string }>
  | DashRouteType<'app-public-authentication-password-reset', { token: string }>
  | DashRouteType<'app-public-authentication-request-password-reset'>
  | DashRouteType<'app-public-authentication-complete-account-setup', { token: string }>
  | DashRouteType<'app-public-authentication-request-account-setup'>
  | DashRouteType<'app-public-authentication-auth-service-callback'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'app-public-authentication': URL_PUBLIC_AUTHENTICATION,
  'app-public-authentication-log-in': URL_PUBLIC_AUTHENTICATION_LOG_IN,
  'app-public-authentication-auth-service-callback':
    URL_PUBLIC_AUTHENTICATION_AUTH_SERVICE_CALLBACK,

  // DEPRECATED ROUTE
  'app-public-authentication-request-email-verification':
    URL_PUBLIC_AUTHENTICATION_REQUEST_EMAIL_VERIFICATION,
  // DEPRECATED ROUTE
  'app-public-authentication-email-verification': URL_PUBLIC_AUTHENTICATION_EMAIL_VERIFICATION,
  // DEPRECATED ROUTE
  'app-public-authentication-password-reset': URL_PUBLIC_AUTHENTICATION_PASSWORD_RESET,
  // DEPRECATED ROUTE
  'app-public-authentication-request-password-reset':
    URL_PUBLIC_AUTHENTICATION_REQUEST_PASSWORD_RESET,
  // DEPRECATED ROUTE
  'app-public-authentication-complete-account-setup':
    URL_PUBLIC_AUTHENTICATION_COMPLETE_ACCOUNT_SETUP,
  // DEPRECATED ROUTE
  'app-public-authentication-request-account-setup':
    URL_PUBLIC_AUTHENTICATION_REQUEST_ACCOUNT_SETUP,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
