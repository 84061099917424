import type { FunctionComponent } from 'react'
import { Checkbox } from '@retailer-platform/shared-components/src/tds'
import styled from '@emotion/styled'
import { ConfirmIcon, StoreIcon } from '@instacart/ids-core'
import { type Option, getKey } from './utils'
import { StyledLogoImage, StyledLogoBox } from './Common'

interface Props {
  option: Option
  selectedState: Record<string, Option>
  setSelectedState: (state: Record<string, Option>) => void
  multiSelect: boolean
  onSingleSelect: (option: Option) => void
  internalPadding: number
}

const getDescendantLeafNodes = (option: Option) => {
  const descendants = []

  if (option.children?.length) {
    option.children
      .filter(child => child.type != 'site' || child.siteEnabled)
      .forEach(child => {
        descendants.push(...getDescendantLeafNodes(child))
      })
  } else if (option.leafNode) {
    descendants.push(option)
  }

  return descendants
}

const getAllChildren = (options: Option[]) => {
  const children = []

  options
    .filter(option => option.type != 'site' || option.siteEnabled)
    .forEach(option => {
      children.push(option)

      if (option.children) {
        children.push(...getAllChildren(option.children))
      }
    })

  return children
}

const getCheckboxState = (
  option: Option,
  selectedState: Record<string, Option>,
  isSelected: boolean
) => {
  const [allChildrenSelected, someChildrenSelected] = getDescendantLeafNodes(option).reduce(
    (acc, child) => {
      const [allSelected, someSelected] = acc

      const childKey = getKey(child)

      return [allSelected && selectedState[childKey], someSelected || selectedState[childKey]]
    },
    [true, false]
  )

  let checkboxState: boolean | 'indeterminate'
  if (getAllChildren(option.children || []).length) {
    if (allChildrenSelected) {
      checkboxState = true
    } else if (someChildrenSelected) {
      checkboxState = 'indeterminate'
    } else {
      checkboxState = false
    }
  } else {
    checkboxState = isSelected
  }

  const indeterminate = checkboxState === 'indeterminate'
  const checked = checkboxState === true

  return [checked, indeterminate]
}

const Container = styled.div<{
  selected: boolean
  selectable: boolean
  internalPadding: number
  verticalAlign: 'center' | 'top'
}>(({ selected, selectable, internalPadding, verticalAlign }) => ({
  display: 'flex',
  alignItems: verticalAlign,
  padding: `10px 16px 10px ${16 + internalPadding}px`,
  width: 'auto',
  gap: '8px',
  cursor: selectable ? 'pointer' : 'not-allowed',
  backgroundColor: selected ? 'rgb(245, 251, 249)' : null,
  '&:hover': {
    backgroundColor: selectable ? 'rgb(236, 246, 243)' : null,
  },
}))

const SiteStatusTag = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1px 4px',
  background: theme.colors.systemGrayscale10,
  borderRadius: '0px',
  flexGrow: 0,
  fontSize: '12px',
  lineHeight: '16px',
  color: theme.colors.systemGrayscale80,
  userSelect: 'none',
}))

const LeafText = styled.div<{ disabled?: boolean }>(({ disabled, theme }) => ({
  fontFamily: 'Instacart Sans Text v1.1',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  color: disabled ? theme.colors.systemGrayscale30 : theme.colors.systemGrayscale80,
  textOverflow: 'ellipsis',
}))

const GroupText = styled.div<{ disabled?: boolean }>(({ disabled, theme }) => ({
  fontFamily: 'Instacart Sans Text v1.1',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
  color: disabled ? theme.colors.systemGrayscale30 : theme.colors.systemGrayscale80,
  textOverflow: 'ellipsis',
}))

const DescendantSelectedText = styled.div(({ visible }: { visible: boolean }) => ({
  fontFamily: 'Instacart Sans Text v1.1',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '18px',
  color: '#56595E',
  marginLeft: 'auto',
  marginRight: '0',
  textWrap: 'nowrap',
  visibility: visible ? 'visible' : 'hidden',
}))

const CheckmarkIconWrapper = styled.div({
  marginLeft: 'auto',
  marginRight: '0',
})

const MultiScopeDropdownOption: FunctionComponent<Props> = ({
  option,
  selectedState,
  setSelectedState,
  multiSelect,
  onSingleSelect,
  internalPadding,
}) => {
  const key = getKey(option)
  const isSelected = !!selectedState[key]

  // enabled only false if site and not enabled. Enabled otherwise
  const isSiteAndEnabled = !(option.type === 'site' && !option.siteEnabled)

  const [checked, indeterminate] = getCheckboxState(option, selectedState, isSelected)

  const onClick = () => {
    if (!isSiteAndEnabled) {
      return
    }

    if (multiSelect) {
      const newState = {
        ...selectedState,
      }

      if (isSelected) {
        delete newState[key]
      } else {
        newState[key] = option
      }

      // this might need to be getAllDescendants
      getAllChildren(option.children || []).forEach(child => {
        const childKey = getKey(child)

        if (isSelected) {
          delete newState[childKey]
        } else {
          newState[childKey] = child
        }
      })

      setSelectedState({
        ...newState,
      })
    } else {
      if (option.leafNode) {
        const key = getKey(option)
        const newState = {
          [key]: option,
        }
        setSelectedState(newState)

        onSingleSelect(option)
      }
    }
  }

  const descendantLeafNodes = getDescendantLeafNodes(option)
  const descendantLeafNodesSelectedNumber = descendantLeafNodes.filter(
    node => selectedState[getKey(node)]
  ).length

  return (
    <Container
      onClick={onClick}
      selected={!multiSelect && isSelected}
      selectable={isSiteAndEnabled && (multiSelect || option.leafNode)}
      internalPadding={internalPadding}
      data-testid={`multi-scope-dropdown-option-${option.id}`}
      verticalAlign={option.type === 'site' ? 'top' : 'center'}
    >
      {multiSelect && (
        <Checkbox
          label={''}
          checked={checked}
          indeterminate={indeterminate}
          id={`checkbox-${option.id}`}
          data-testid={`checkbox-${option.id}`}
          disabled={!isSiteAndEnabled}
        />
      )}
      {option.logoUrl && (
        <StyledLogoBox>
          <StyledLogoImage src={option.logoUrl} alt="logo" />
        </StyledLogoBox>
      )}
      {option.type == 'banner' && !option.logoUrl && (
        <StyledLogoBox>
          <StoreIcon size={30} color="systemGrayscale30" />
        </StyledLogoBox>
      )}

      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: '2px',
          width: '100%',
          alignSelf: 'center',
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
          }}
        >
          {option.type === 'site' || !option.leafNode ? (
            <GroupText disabled={!isSiteAndEnabled}>{option.name}</GroupText>
          ) : (
            <LeafText>{option.name}</LeafText>
          )}
          {option.type === 'site' && !option.siteActive && <SiteStatusTag>Private</SiteStatusTag>}
          {option.type === 'site' && !option.siteEnabled && (
            <SiteStatusTag>Not applicable</SiteStatusTag>
          )}
          {multiSelect && !option.leafNode && (
            <DescendantSelectedText visible={descendantLeafNodesSelectedNumber > 0}>
              {descendantLeafNodesSelectedNumber} / {descendantLeafNodes.length} selected
            </DescendantSelectedText>
          )}
          {!multiSelect && isSelected && (
            <CheckmarkIconWrapper>
              <ConfirmIcon size="20px" />
            </CheckmarkIconWrapper>
          )}
        </div>
        {option.type === 'site' && (
          <LeafText disabled={!isSiteAndEnabled}>{option.siteUrl}</LeafText>
        )}
      </div>
    </Container>
  )
}

export default MultiScopeDropdownOption
