import React from 'react'
import { Divider } from '@retailer-platform/shared-components/src/tds'
import { SwitchBase } from '@instacart/ids-customers'
import styled from '@emotion/styled'
import { fontSize, spacing } from '@retailer-platform/shared-components'
import { ChevronRightIcon } from '@instacart/ids-core'
import { AttributeManagementCategoryEnum } from '../../../types/attributeManagement.types'
import {
  Input,
  PropertyContainer,
  PropertyRow,
  PropertyTitle,
  SectionHeader,
} from '../../shared-styled-components/SharedStyledComponents'
import { useDomainMessages } from '../../../utils/domain/intl'
import PipelineFormStepTile from './PipelineFormStepTile'
import { type FormProps } from './StepAttributeForm'

const TileContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: spacing.X16,
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
})

const PipelineForm: React.FC<FormProps> = ({ attributes, mode, onChange }) => {
  const i18n = useDomainMessages({
    scheduleTitle:
      'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.scheduleTitle',
    stepsTitle: 'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepsTitle',
    enableSchedule:
      'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.enableSchedule',
    hoursInterval:
      'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.hoursInterval',
    unscheduled:
      'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.unscheduled',
  })

  return (
    <>
      <SectionHeader>{i18n.scheduleTitle}</SectionHeader>
      <PropertyContainer>
        <PropertyRow>
          <PropertyTitle>
            <div>{i18n.enableSchedule}</div>
          </PropertyTitle>
          <SwitchBase
            checked={attributes.enableSchedule}
            // TODO When this is supported uncomment. disabled={!isEditting}
            disabled={true}
            description={i18n.enableSchedule}
            onChange={event => onChange({ ...attributes, enableSchedule: event.target.checked })}
          />
        </PropertyRow>
        <PropertyRow>
          <PropertyTitle>
            <div>{i18n.hoursInterval}</div>
          </PropertyTitle>
          <Input
            type={attributes.enableSchedule ? 'number' : 'text'}
            value={attributes.enableSchedule ? attributes.hoursInterval : i18n.unscheduled}
            disabled={mode !== 'edit' || !attributes.enableSchedule}
            onChange={event =>
              onChange({ ...attributes, hoursInterval: Math.max(Number(event.target.value), 1) })
            }
          />
        </PropertyRow>
      </PropertyContainer>
      <Divider />
      <SectionHeader>{i18n.stepsTitle}</SectionHeader>
      <PropertyContainer>
        <TileContainer>
          <PipelineFormStepTile
            disabled={mode === 'view'}
            category={AttributeManagementCategoryEnum.Input}
            step={attributes.steps[0]}
            onChange={step => {
              onChange({ ...attributes, steps: [step, attributes.steps[1]] })
            }}
            onDelete={() => {
              onChange({ ...attributes, steps: [null, attributes.steps[1]] })
            }}
          />
          <ChevronRightIcon size={fontSize.X31} />
          <PipelineFormStepTile
            disabled={mode === 'view'}
            category={AttributeManagementCategoryEnum.ExtractionModel}
            step={attributes.steps[1]}
            onChange={step => {
              onChange({ ...attributes, steps: [attributes.steps[0], step] })
            }}
            onDelete={() => {
              onChange({ ...attributes, steps: [attributes.steps[0], null] })
            }}
          />
        </TileContainer>
      </PropertyContainer>
    </>
  )
}

export default PipelineForm
