import React, { type FunctionComponent, useState } from 'react'
import faker from 'faker'
import { Button, NotificationLevel, notify, Text } from '@retailer-platform/shared-components'
import { Divider } from '@retailer-platform/shared-components/src/tds'
import { ConfirmIcon } from '@instacart/ids-core'
import {
  Property,
  PropertyTitle,
} from '../../components/md-platform-list/components/MdPlatformListTableRow.tsx'
import {
  Input,
  TextInput,
} from '../../components/md-platform-list/components/MdPlatformModifyModal.tsx'
import {
  type AttributeManagementStep,
  type AttributeManagementCategoryEnum,
  type AttributeManagementTypeEnum,
  AttributeManagementCategoryEnumToGraphQLEnum,
  DefaultStep,
  TypesForCategory,
} from '../../types/attributeManagement.types.ts'
import {
  ButtonRow,
  Container,
  PropertyContainer,
  PropertySectionLeft,
  PropertySectionRight,
  SectionHeader,
} from '../shared-styled-components/SharedStyledComponents.tsx'
import { useDomainGoToPath } from '../../utils/domain/routing.ts'
import { useDomainMessages } from '../../utils/domain/intl.ts'
import { useCreateNewStepMutation } from '../../api/index.ts'
import StepAttributeForm, {
  validateAttributes,
} from '../attribute-management-common/step-attribute-forms/StepAttributeForm.tsx'

const validateStep = (
  step: AttributeManagementStep,
  category: AttributeManagementCategoryEnum,
  type: AttributeManagementTypeEnum
) => {
  if (!step?.name || !step?.owner || !step?.description) {
    return false
  }
  return validateAttributes(category, type, step.versions[0].attributes)
}

interface Props {
  category: AttributeManagementCategoryEnum
}

export const AttributeManagementCreate: FunctionComponent<Props> = ({ category }) => {
  // In the future, we'll need to switch this type around, but for now there's only one type per category.
  const type = TypesForCategory[category as AttributeManagementCategoryEnum][0]
  const [step, setStep] = useState<AttributeManagementStep>(DefaultStep[type])
  const i18n = useDomainMessages({
    labelName: 'catalogAdminDomain.attributeManagement.create.label.name',
    labelDesc: 'catalogAdminDomain.attributeManagement.create.label.description',
    labelDescSubtitle: 'catalogAdminDomain.attributeManagement.create.label.description.optional',
    labelModel: 'catalogAdminDomain.attributeManagement.create.label.model',
    labelOwner: 'catalogAdminDomain.attributeManagement.create.label.owner',
    labelOwnerSubtitle: 'catalogAdminDomain.attributeManagement.create.label.owner.subtitle',
    labelPrompt: 'catalogAdminDomain.attributeManagement.create.label.prompt',
    labelProperties: 'catalogAdminDomain.attributeManagement.create.label.properties',
    labelPropertiesSubtile: 'catalogAdminDomain.attributeManagement.create.label.property.subtitle',
    cancelButtonText: 'catalogAdminDomain.attributeManagement.create.button.cancel',
    createButtonText: 'catalogAdminDomain.attributeManagement.create.button.create',
    topSectionHeader: 'catalogAdminDomain.attributeManagement.create.topSectionHeader',
    bottomSectionHeader: 'catalogAdminDomain.attributeManagement.create.bottomSectionHeader',
    errorMessage: 'catalogAdminDomain.attributeManagement.create.error.message',
    successMessage: 'catalogAdminDomain.attributeManagement.create.success.message',
  })

  const goToHomePage = useDomainGoToPath('app-admin-catalog-admin-attribute-management')

  const [triggerCreation, { data, loading, called }] = useCreateNewStepMutation({
    variables: {
      clientId: 'catalog_camp_ipp',
      idempotencyKey: faker.random.uuid(),
      step: {
        description: step?.description,
        name: step?.name,
        owner: step?.owner,
        stepCategory: AttributeManagementCategoryEnumToGraphQLEnum[category],
        tombstoned: false,
        type: type,
      },
      attributes: step?.versions[0].attributes,
    },
    onError: () => {
      notify({
        level: NotificationLevel.Error,
        contents: i18n.errorMessage,
      })
    },
  })

  if (data?.instacart_catalog_catflow_v1_CatflowAPI_CreateNewStep?.id) {
    notify({
      icon: <ConfirmIcon />,
      contents: 'Extraction Model created successfully',
    })
    goToHomePage()
  }

  const valid = validateStep(step, category, step?.type)

  return (
    <Container>
      <SectionHeader> {i18n.topSectionHeader} </SectionHeader>
      <PropertyContainer>
        <PropertySectionLeft>
          <Property>
            <PropertyTitle>{i18n.labelName} </PropertyTitle>
            <Input
              error={!step?.name}
              value={step?.name}
              onChange={event => setStep({ ...step, name: event.target.value })}
            />
          </Property>
          <Property>
            <PropertyTitle>{i18n.labelOwner} </PropertyTitle>
            <Text size="small">{i18n.labelOwnerSubtitle}</Text>
            <Input
              error={!step?.owner}
              value={step?.owner}
              onChange={event => setStep({ ...step, owner: event.target.value })}
            />
          </Property>
        </PropertySectionLeft>
        <PropertySectionRight>
          <Property style={{ height: '100%' }}>
            <PropertyTitle>{i18n.labelDesc}</PropertyTitle>
            <Text size="small"> {`(${i18n.labelDescSubtitle})`}</Text>
            <TextInput
              value={step?.description}
              error={!step?.description}
              onChange={event => setStep({ ...step, description: event.target.value })}
            />
          </Property>
        </PropertySectionRight>
      </PropertyContainer>

      <Divider />
      <StepAttributeForm
        type={type}
        attributes={step.versions[0].attributes}
        onChange={attributes => {
          setStep({ ...step, versions: [{ ...step.versions[0], attributes }] })
        }}
        mode={'edit'}
      />
      <ButtonRow>
        <Button
          variant={'secondary'}
          disabled={loading && called}
          onClick={() => goToHomePage()}
          title={i18n.cancelButtonText}
        >
          {i18n.cancelButtonText}
        </Button>
        <Button
          variant={'primary'}
          disabled={!valid || (loading && called)}
          onClick={() => triggerCreation()}
          title={i18n.createButtonText}
        >
          {i18n.createButtonText}
        </Button>
      </ButtonRow>
    </Container>
  )
}
