export enum FeatureToggle {
  retailerCollections = 'rt_retailer_collections',
  // TODO: remove me once we launch instacart collections
  instacartCollectionsEnabled = 'rt_retailer_collections_use_instacart_collections',
  retailerCollectionsV2 = 'rt_retailer_collections_ux_v2',
  // Check if retailer collections v2 is enabled for retailers storefront
  retailerCollectionsV2Preview = 'retailer_collections_v2_enabled',
  retailerCollectionsAdminV2 = 'rt_retailer_collections_admin_v2_enabled',
  storefront = 'rt_storefront',
  retailerCollectionsDepartmentsAisles = 'rt_retailer_collections_departments_aisles_enabled',
  DynamiclyFedStaticCollections = 'rt_dynamicly_fed_static_collections',
  dynamicRulesProductInclusionExclusion = 'rt_dynamic_rules_product_inclusion_exclusion',
  flyersTermsApprovalRequired = 'rt_flyers_terms_approval_required',
  flyersImageAltText = 'rt_flyers_image_alt_text',
}
