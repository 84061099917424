import type { MouseEvent, PropsWithChildren, FunctionComponent } from 'react'
import { Fragment } from 'react'
import { CloseIcon, ConfirmIcon } from '@instacart/ids-core'
import { IconButton } from '@retailer-platform/shared-components/src/tds'
import TableLoader from './TableLoader'

export enum TableActionsConfirmDismissTypes {
  EDIT = 'edit',
  DELETE = 'delete',
}

const baseButtonProps = {
  style: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  snacksStyle: 'flat' as const,
}

interface Props {
  actionType: TableActionsConfirmDismissTypes
  onConfirmClick(event: MouseEvent): void
  onDismissClick(event: MouseEvent): void
  isBusy?: boolean
}

const TableActionsConfirmDismiss: FunctionComponent<PropsWithChildren<Props>> = (props: Props) => {
  const { actionType, onConfirmClick, onDismissClick, isBusy } = props

  const isDeleting = actionType === TableActionsConfirmDismissTypes.DELETE

  if (isBusy) {
    return (
      <div data-testid="table-actions-busy">
        <TableLoader />
      </div>
    )
  }

  return (
    <Fragment>
      <IconButton
        {...baseButtonProps}
        variant="detrimental-secondary"
        data-testid="table-actions-dismiss"
        icon={CloseIcon}
        onClick={onDismissClick}
      />
      <IconButton
        {...baseButtonProps}
        variant="secondary"
        data-testid="table-actions-confirm"
        icon={ConfirmIcon}
        onClick={onConfirmClick}
      />
    </Fragment>
  )
}

export default TableActionsConfirmDismiss
