export enum Metrics {
  AVG_BASKET_SPEND_LIFT = 'AVG_BASKET_SPEND_LIFT',
  AVG_BASKET_SPEND_WITH_PROMO = 'AVG_BASKET_SPEND_WITH_PROMO',
  AVG_COST_PER_ACTIVATION = 'AVG_COST_PER_ACTIVATION',
  AVG_COST_PER_ORDER = 'AVG_COST_PER_ORDER',
  AVG_COST_PER_RESURRECTION = 'AVG_COST_PER_RESURRECTION',
  AVG_COST_PER_USER = 'AVG_COST_PER_USER',
  AVG_COST_PER_USER_ACTIVATED = 'AVG_COST_PER_USER_ACTIVATED',
  AVG_COST_PER_USER_REACTIVATED = 'AVG_COST_PER_USER_REACTIVATED',
  AVG_COST_PER_USER_RETAINED = 'AVG_COST_PER_USER_RETAINED',
  AVG_DAILY_CAMPAIGN_SPEND = 'AVG_DAILY_CAMPAIGN_SPEND',
  AVG_FIRST_ORDER_PER_STORE = 'OVERALL_AVG_FIRST_ORDER_PER_STORE',
  AVG_GMV = 'OVERALL_AVG_GMV',
  OVERALL_AVG_ORDERED_QTY = 'OVERALL_AVG_ORDERED_QTY',
  AVG_GMV_PER_STORE = 'OVERALL_AVG_GMV_PER_STORE',
  AVG_RATING = 'OVERALL_AVG_RATING',
  AVG_UNIT_SPEND_LIFT = 'AVG_UNIT_SPEND_LIFT',
  AVG_UNIT_SPEND_WITH_PROMO = 'AVG_UNIT_SPEND_WITH_PROMO',
  AVG_USER_PER_STORE = 'OVERALL_AVG_USER_PER_STORE',
  COUNT_FIRST_ORDER = 'OVERALL_COUNT_FIRST_ORDER',
  COUNT_ORDER = 'OVERALL_COUNT_ORDER',
  COUNT_USERS = 'COUNT_USERS',
  COUNT_USERS_ACTIVATED = 'COUNT_USERS_ACTIVATED',
  COUNT_USERS_REACTIVATED = 'COUNT_USERS_REACTIVATED',
  COUNT_USERS_RETAINED = 'COUNT_USERS_RETAINED',
  LAST_ETL_UPDATE = 'MAX_UPDATED_AT_DATE_TIME_UTC',
  NUM_ACTIVE_CAMPAIGNS = 'NUM_ACTIVE_CAMPAIGNS',
  NUM_DISTINCT_CAMPAIGNS = 'NUM_DISTINCT_CAMPAIGNS',
  NUM_ENDING_SOON_CAMPAIGNS = 'NUM_ENDING_SOON_CAMPAIGNS',
  NUM_ORDERS_OFFERS = 'NUM_ORDERS_OFFERS',
  RATIO_AVG_UNIT_SPEND_LIFT = 'RATIO_AVG_UNIT_SPEND_LIFT',
  RATIO_BASKET_SPEND_LIFT = 'RATIO_BASKET_SPEND_LIFT',
  REDEMPTION_RATE = 'REDEMPTION_RATE',
  REORDER_RATE_28_DAY = 'REORDER_RATE_28_DAY',
  RETURN_ON_AD_SPEND = 'RETURN_ON_AD_SPEND',
  SUM_ACTIVATIONS = 'SUM_ACTIVATIONS',
  SUM_APPLICATIONS = 'SUM_APPLICATIONS',
  SUM_ATTRIBUTED_SALES = 'SUM_ATTRIBUTED_SALES',
  SUM_CAMPAIGN_SPEND = 'SUM_CAMPAIGN_SPEND',
  SUM_CAMPAIGN_SPEND_OUTSTANDING = 'SUM_CAMPAIGN_SPEND_OUTSTANDING',
  SUM_GMV = 'OVERALL_SUM_GMV',
  SUM_ORDERS_OUTSTANDING = 'SUM_ORDERS_OUTSTANDING',
  SUM_REDEMPTIONS = 'SUM_REDEMPTIONS',
  SUM_RESURRECTIONS = 'SUM_RESURRECTIONS',
  SUM_USERS = 'SUM_USERS',
  SUM_USERS_OFFERS = 'SUM_USERS_OFFERS',
  SUM_ATTRIBUTED_ERV = 'SUM_ATTRIBUTED_ERV',
}

export enum Dimensions {
  DATE_PT = 'DATE_PT',
  WEEK_SAT_PT = 'WEEK_SAT_PT',
  WEEK_SUN_PT = 'WEEK_SUN_PT',
  WEEK_MON_PT = 'WEEK_MON_PT',
  WEEK_TUE_PT = 'WEEK_TUE_PT',
  WEEK_WED_PT = 'WEEK_WED_PT',
  WEEK_THU_PT = 'WEEK_THU_PT',
  WEEK_FRI_PT = 'WEEK_FRI_PT',
  MONTH_PT = 'MONTH_PT',
  CAMPAIGN = 'CAMPAIGN',
  IS_INSTACART_STORE_CONFIGURATION = 'IS_INSTACART_STORE_CONFIGURATION',
  DIM_STORE_CONFIGURATION = 'DIM_STORE_CONFIGURATION',
  CAMPAIGN_ENDING_SOON = 'CAMPAIGN_ENDING_SOON',
  CAMPAIGN_ID = 'CAMPAIGN_ID',
  CAMPAIGN_TYPE = 'CAMPAIGN_TYPE',
  DELIVERY_STATE = 'DELIVERY_STATE',
  DIM_CAMPAIGN_END_DATE = 'DIM_CAMPAIGN_END_DATE',
  DIM_CAMPAIGN_NAME = 'DIM_CAMPAIGN_NAME',
  DIM_CAMPAIGN_OBJECTIVE = 'DIM_CAMPAIGN_OBJECTIVE',
  DIM_CAMPAIGN_START_DATE = 'DIM_CAMPAIGN_START_DATE',
  DIM_CAMPAIGN_STATUS = 'DIM_CAMPAIGN_STATUS',
  DIM_DISCOVERABILITY = 'DIM_DISCOVERABILITY',
  DIM_USER_SEGMENT = 'DIM_USER_SEGMENT',
  DIM_USER_SEGMENT_CAMPAIGN_TYPE = 'DIM_USER_SEGMENT_CAMPAIGN_TYPE',
  INSIGHTS_PORTAL_ORDER_SOURCE = 'INSIGHTS_PORTAL_ORDER_SOURCE',
  TAAS_USER_SEGMENT = 'TAAS_USER_SEGMENT',
  TAAS_USER_SEGMENT_CAMPAIGN_TYPE = 'TAAS_USER_SEGMENT_CAMPAIGN_TYPE',
  WAREHOUSE = 'WAREHOUSE',
  WAREHOUSE_LOCATION = 'WAREHOUSE_LOCATION',
  WAREHOUSE_LOCATION_CODE = 'WAREHOUSE_LOCATION_CODE',
  WAREHOUSE_LOCATION_REGION = 'WAREHOUSE_LOCATION_REGION',
  WAREHOUSE_LOCATION_STATE = 'WAREHOUSE_LOCATION_STATE',
  ZONE_STATE = 'ZONE_STATE',
  WAREHOUSE_LOCATION_GROUP = 'WAREHOUSE_LOCATION_GROUP',
}
