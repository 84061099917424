import { transformFilterParamsToApiFilterSelectionsFactory } from '../utils/insightsPortalSchemas.utils'
import { buildTimeIntervalDimensionsConfig, createSchemaConfig } from '../helpers/schema.helpers'
import { FormatterType } from '../types/formatter.types'
import { GroupBys, Metrics, Dimensions } from './insightsPortalCollections.types'
import { collectionSchemaFilterParamsConfig } from './insightsPortalCollectionsSchema.hooks'

export { Metrics, GroupBys, Dimensions }

export const schemaConfig = createSchemaConfig({
  metrics: Metrics,
  dimensions: Dimensions,
  metricsConfig: {
    [Metrics.ADD_TO_CARTS]: {
      label: 'insightsPortalDomain.collections.metrics.addToCarts',
      formatter: FormatterType.Integer,
    },
    [Metrics.CART_CONVERSIONS]: {
      label: 'insightsPortalDomain.collections.metrics.cartConversions',
      formatter: FormatterType.Percentage,
    },
    [Metrics.GMV]: {
      label: 'insightsPortalDomain.collections.metrics.gmv',
      formatter: FormatterType.Currency,
    },
    [Metrics.IMPRESSIONS]: {
      label: 'insightsPortalDomain.collections.metrics.impressions',
      formatter: FormatterType.Integer,
    },
    [Metrics.LAST_ETL_UPDATE]: {
      private: true,
      formatter: FormatterType.Date,
    },
    [Metrics.UNITS_SOLD]: {
      label: 'insightsPortalDomain.collections.metrics.unitsSold',
      formatter: FormatterType.Integer,
    },
  },
  dimensionsConfig: {
    [Dimensions.COLLECTION]: {
      label: 'insightsPortalDomain.collections.groupBys.collectionName',
    },
    [Dimensions.COLLECTION_ID]: {
      label: 'insightsPortalDomain.collections.groupBys.collectionId',
      private: true,
    },
    [Dimensions.COLLECTION_NAME]: {
      label: 'insightsPortalDomain.collections.groupBys.collectionName',
      private: true,
    },
    [Dimensions.DEVICES]: {
      label: 'insightsPortalDomain.collections.groupBys.devices',
    },
    [Dimensions.LAST_UPDATED]: {
      label: 'insightsPortalDomain.collections.groupBys.lastUpdated',
      formatter: FormatterType.Date,
      date: true,
    },
    [Dimensions.INSIGHTS_PORTAL_ORDER_SOURCE]: {
      label: 'insightsPortalDomain.filters.orderSource.label',
    },
    [Dimensions.RETAILER_COLLECTION_ID]: {
      label: 'insightsPortalDomain.collections.groupBys.retailerCollectionId',
    },
    [Dimensions.START_DATE]: {
      label: 'insightsPortalDomain.collections.groupBys.startDate',
      formatter: FormatterType.Date,
      date: true,
    },
    [Dimensions.WAREHOUSE]: {
      label: 'insightsPortalDomain.filters.retailers.label',
    },
    ...buildTimeIntervalDimensionsConfig,
  },

  // TODO: deprecate
  filterConfigs: collectionSchemaFilterParamsConfig,
  transformFilterParamsToApiFilterSelections: transformFilterParamsToApiFilterSelectionsFactory(
    collectionSchemaFilterParamsConfig
  ),
  groupBys: GroupBys,
  groupBysConfig: {
    [GroupBys.CREATED_BY]: {
      private: true,
    },
    [GroupBys.COLLECTION_NAME]: {
      label: 'insightsPortalDomain.collections.groupBys.collectionName',
    },
    [GroupBys.COLLECTION_ID]: {
      label: 'insightsPortalDomain.collections.groupBys.collectionId',
    },
    [GroupBys.RETAILER_COLLECTION_ID]: {
      label: 'insightsPortalDomain.collections.groupBys.retailerCollectionId',
    },
    [GroupBys.START_DATE]: {
      label: 'insightsPortalDomain.collections.groupBys.startDate',
      formatter: FormatterType.Date,
    },
    [GroupBys.LAST_UPDATED]: {
      label: 'insightsPortalDomain.collections.groupBys.lastUpdated',
      formatter: FormatterType.Date,
    },
    [GroupBys.DATE_PT]: {
      label: 'insightsPortalDomain.pillTabs.days.label',
      formatter: FormatterType.Date,
    },
    [GroupBys.WEEK_SAT_PT]: {
      label: 'insightsPortalDomain.pillTabs.weeks.label',
      formatter: FormatterType.Date,
    },
    [GroupBys.WEEK_SUN_PT]: {
      label: 'insightsPortalDomain.pillTabs.weeks.label',
      formatter: FormatterType.Date,
    },
    [GroupBys.WEEK_MON_PT]: {
      label: 'insightsPortalDomain.pillTabs.weeks.label',
      formatter: FormatterType.Date,
    },
    [GroupBys.MONTH_PT]: {
      label: 'insightsPortalDomain.pillTabs.months.label',
      formatter: FormatterType.Date,
    },
    [GroupBys.DEVICES]: {
      label: 'insightsPortalDomain.collections.groupBys.devices',
    },
    [GroupBys.CATALOG_PRODUCT_ID]: {
      label: 'insightsPortalDomain.collections.groupBys.catalogProductId',
    },
    [GroupBys.RETAILER_PRODUCT]: {
      label: 'insightsPortalDomain.collections.groupBys.retailerProduct',
    },
    [GroupBys.PRODUCT_UPC_CODE]: {
      label: 'insightsPortalDomain.collections.groupBys.productUpcCode',
    },
  },
})
