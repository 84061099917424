import * as React from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { spacing } from '@retailer-platform/shared-components'
import { Link } from '@retailer-platform/shared-components/src/tds'
import { useDashMessages } from '../../../../intl/intl.hooks'

export const Content = styled.div({
  fontSize: '12px',
  lineHeight: '1.5em',
  p: {
    margin: '1em 0',
  },
  b: {
    fontWeight: 'bold',
  },
  ol: {
    counterReset: 'index',
    listStyleType: 'none',
    paddingLeft: '2em',
    li: {
      position: 'relative',
      ':before': {
        counterIncrement: 'index',
        content: 'counters(index, ".", decimal) ". "',
        position: 'absolute',
        left: '-2em',
      },
    },
    ol: {
      paddingLeft: '3em',
      'li:before': {
        left: '-3em',
      },
      ol: {
        paddingLeft: '4em',
        'li:before': {
          left: '-4em',
        },
      },
    },
  },
})

const idpContent = (
  <Content>
    <p>
      <b>Instacart Developer Platform Terms and Conditions</b>
    </p>
    <p>Last Updated: July 1 , 2024</p>
    <p>
      These Instacart Developer Platform Terms and Conditions (the &quot;<b>Agreement</b>&quot;)
      govern your access and use and access of the Instacart API Materials.
    </p>
    <ol>
      <li>
        <b>DEFINITIONS.</b>
        <ol>
          <li>
            &quot;<b>Applicable Law(s)</b>&quot; means all applicable federal, state, and local
            laws, rules, regulations, orders, decrees, directives, or ordinances.
          </li>
          <li>
            &quot;<b>Application</b>&quot; means the software application or website that you use to
            access and use the Instacart API Materials, including access of Instacart API using the
            API Key.
          </li>
          <li>
            &quot;<b>API</b>&quot; means an application programming interface for a service or
            software program.
          </li>
          <li>
            &quot;<b>API Key</b>&quot; means the confidential alphanumeric or cryptographic keys
            that are uniquely associated with your accounts provided by Instacart to you for your
            use of the Instacart API Materials.
          </li>
          <li>
            &quot;<b>API Updates</b>&quot; means bug fixes, updates, upgrades, enhancements,
            modifications and new releases or versions of the Instacart API.
          </li>
          <li>
            &quot;<b>Authorized Users</b>&quot; means you and your employees and independent
            contractors who access and use the Instacart API Materials on your behalf.
          </li>
          <li>
            &quot;<b>Beta Services</b>&quot; means pre-release or beta features that are in
            development by us and not yet available to the public.
          </li>
          <li>
            &quot;<b>Confidential Information</b>&quot; means any data, specifications, software,
            material, and information of a confidential nature made available/disclosed by or on
            behalf of us hereunder which you know or should know that we regard as confidential
            including those related to the (a) Instacart API Materials; (b) Instacart Data, and (c)
            Instacart Platform, including our business plans, strategies, know how, marketing plans,
            suppliers, sources of materials, finances, business relationships, personally
            identifiable end-user information, pricing, technology, employees, trade secrets, data
            related to purchases and/or transactions on Instacart Platforms.
          </li>
          <li>
            &quot;<b>Covered PI</b>&quot; shall have the meaning set forth in the Instacart Privacy
            Addendum.
          </li>
          <li>
            &quot;<b>Customer</b>&quot; means any individual that visits or transacts via the
            Instacart Platform.
          </li>
          <li>
            &quot;<b>Developer Account</b>&quot; means your account to access Instacart API
            materials.
          </li>
          <li>
            &quot;<b>Developer Content</b>&quot; means that content you provide in an Application or
            to the Instacart API Materials, including files, information, data, images, videos, or
            computer programs, except that Developer Content does not include Merchant Content or
            other Instacart Materials.
          </li>
          <li>
            &quot;<b>Developer System</b>&quot; means<b> </b>your networks, operating system and
            software of your web servers, routers, databases, and computer systems.
          </li>
          <li>
            &quot;<b>Feedback</b>&quot; means any suggestions, enhancement requests, and
            recommendations regarding the Instacart Materials or Merchant Content.
          </li>
          <li>
            &quot;<b>Instacart API(s)</b>&quot; means the API(s) provided by us to you under this
            Agreement and available at
            <a href="https://docs.instacart.com/developer_platform_api/">
              https://docs.instacart.com/developer_platform_api/
            </a>
            (as may be updated by us from time to time).
          </li>
          <li>
            &quot;<b>Instacart API Materials</b>&quot; means (a) Instacart API; (b) the requirements
            provided by us to you for interfacing to the Instacart API, which may include Required
            Minimum Functionality; (c) the Instacart Developer Guidelines; (d) any other software
            provided by us under this Agreement; (e) any related materials or documents related to
            the foregoing; and (f) any support or other assistance provided by or on behalf us in
            connection with this Agreement.
          </li>
          <li>
            &quot;<b>Instacart Data</b>&quot; means any and all data or information, including but
            not limited to Covered PI, transmitted by or on behalf of us to you through the
            Instacart API or otherwise and any usage data derived from the Instacart API as
            contemplated under this Agreement.
          </li>
          <li>
            &quot;<b>Instacart Developer Guidelines</b>&quot; means the Instacart Platform Developer
            Guidelines, available at
            <a href="https://docs.instacart.com/developer_platform_api/">
              https://docs.instacart.com/developer_platform_api/
            </a>
            (as may be updated by us from time to time).
          </li>
          <li>
            &quot;<b>Instacart Checkout</b>&quot; means Instacart&apos;s checkout experience that
            allows Customers to enter their shipping information and payment details after adding
            item(s) to their cart and before placing an order. For clarity, Instacart Checkout
            includes checkouts that occur through the Instacart Checkout API.
          </li>
          <li>
            &quot;<b>Instacart Marks</b>&quot; means trade names, trademarks, logos, or other
            distinctive brand features of Instacart.
          </li>
          <li>
            &quot;<b>Instacart Marks Guidelines</b>&quot; means those Instacart Marks guidelines,
            available at
            <a href="https://heyitsinstacart.com/">https://heyitsinstacart.com/</a> (as may be
            updated by us from time to time), and other guidelines and policies we may make
            available to you from time to time.
          </li>
          <li>
            &quot;<b>Instacart Materials</b>&quot; means (a) Instacart API Materials; (b) Instacart
            Data, (c) Instacart Platform, (d) Confidential Information; (e) Instacart Marks; (f)
            Instacart Marks Guidelines; and (g) any other content, data or materials provided or
            used in connection with, or generated by, the Instacart API Materials, together with any
            related intellectual property rights.
          </li>
          <li>
            &quot;<b>Instacart Privacy Policy</b>&quot; means the Instacart Privacy Policy,
            available at https://www.instacart.com/company/privacy (as may be updated by us from
            time to time).
          </li>
          <li>
            &quot;<b>Instacart Related Entity/ies</b>&quot; means any entity that directly or
            indirectly controls, is controlled by, or is under common control with, Instacart; where
            &quot;control&quot; means the possession, directly or indirectly, of the power to direct
            or cause the direction of the management policies of another, whether through the
            ownership of voting securities, by contract, as trustee or executor, or otherwise.
          </li>
          <li>
            &quot;<b>Instacart Terms</b>&quot; means the Instacart Terms and Conditions, available
            at
            <a href="https://www.instacart.com/terms">https://www.instacart.com/terms</a> (as may be
            updated by us from time to time).
          </li>
          <li>
            &quot;<b>Instacart Platform</b>&quot; means (a)
            <a href="https://www.instacart.com">https://www.instacart.com</a> and related
            applications and websites operated by or for any Instacart affiliate; (b) the Instacart
            website or portal which provides access to the Instacart API Materials and related
            information; and (c) any other related website or portal designated by us from time to
            time.
          </li>
          <li>
            &quot;<b>Instacart Data Processing Agreement</b>&quot; means the data processing
            addendum, available at
            <a href="https://instacart.safebase.us/?itemName=legal&amp;source=click&amp;itemUid=c4223a81-5840-4e11-ac9f-2b812794a67e">
              https://instacart.safebase.us/?itemName=legal&amp;source=click&amp;itemUid=c4223a81-5840-4e11-ac9f-2b812794a67e
            </a>
            (as may be updated by us from time to time).
          </li>
          <li>
            &quot;<b>Merchant</b>&quot; means an individual or business that uses the Instacart
            Platform to sell products or services.
          </li>
          <li>
            &quot;<b>Merchant Content</b>&quot; means (a) certain information from a Merchant made
            available to you through the Instacart API Materials, including Customer information,
            product costs, product prices, product names, finances, and Customer data; and (b) any
            files, data sets, materials, documents, names, email addresses, comments, notes, links
            and other content, data and information made available in connection with the foregoing.
          </li>
          <li>
            &quot;<b>Purpose</b>&quot; shall have the meaning set forth in Section 2.3 below.
          </li>
          <li>
            &quot;<b>Required Minimum Functionality</b>&quot; means the scope of integration and
            other technical requirements for the integration of the Instacart API, as it may be
            specified in the Instacart API Materials.
          </li>
        </ol>
      </li>
      <li>
        <b>OVERVIEW.</b>
        <ol>
          <li>
            <u>Relationship</u>. This Agreement is entered into by Maplebear Inc. dba Instacart
            (&quot;
            <b>we</b>&quot;, &quot;<b>us</b>&quot;, &quot;<b>our</b>&quot;, or &quot;
            <b>Instacart</b>&quot;) and the individual or entity accessing and using API Materials
            (&quot;<b>you</b>&quot;, &quot;<b>your</b>&quot;, or &quot;
            <b>Developer</b>&quot;).
          </li>
          <li>
            <u>Representations</u>. You represent and warrant that (a) you have the right, power,
            and authority to enter into this Agreement, including if you are entering into this
            agreement on behalf of an entity, you have the authority to bind that entity to this
            Agreement and by accepting the Agreement, you are doing so on behalf of that entity (and
            all references to &quot;you&quot; herein refer to that entity); (b) you are not a
            citizen of, or located within, a nation that is subject to U.S. trade sanctions or other
            significant trade restrictions or you have not been identified on any U.S. government
            restricted party list; (c) Developer Content and Applications will not violate,
            misappropriate, or infringe upon the rights of any third party and you have the
            requisite rights in such to provide the permissions and licenses hereunder; (d) the
            Application and Developer Content will comply with this Agreement.
          </li>
          <li>
            <u>Purpose</u>. The Instacart APIs are being made available for you to enable you to
            integrate with the Instacart Platform to (a) direct users to the Instacart Platform
            and/or (b) display certain Merchant Content and Instacart Materials in conjunction with
            Developer Content (the &quot;<b>Purpose</b>&quot;).
          </li>
          <li>
            <u>Compliance</u>. You will comply with (a) all Applicable Laws related to (i) your
            access and use of the Instacart Materials and Merchant Content hereunder, and (ii) your
            Application; and (b) the terms and conditions of this Agreement as well as all terms and
            conditions applicable to the Instacart Materials that may be posted by or on behalf of
            us on the Instacart Platform or otherwise communicated by or on behalf of us to you from
            time to time, and as may be amended by Instacart, including the Instacart Privacy
            Policy, Instacart Terms, and Developer Guidelines, (collectively, such posted terms, the
            &quot;<b>Terms</b>&quot;) which are incorporated into the Agreement.
          </li>
          <li>
            <u>Order of Precedence</u>. In the event of a conflict between this Agreement and the
            Terms, this Agreement will control.
          </li>
          <li>
            <u>Modification</u>. We may modify any or all terms of this Agreement from time to time.
            In the event we do so, we will post the most current version of the Agreement to the
            Instacart Developer Platform portal portal and your continued use of the Instacart API
            Materials will constitute your acceptance of any such modification. If you do not agree
            to any changes, your sole recourse is to discontinue your use of the Instacart
            Materials.
          </li>
        </ol>
      </li>
      <li>
        <b>ACCESS AND USE.</b>
        <ol>
          <li>
            <u>Limited Access and Use</u>. You may access and use Instacart Materials and Merchant
            Content only as expressly permitted in this Agreement, including all documents and terms
            incorporated herein by reference, and solely for the Purpose. Further, you agree to
            comply with any attribution requirements that we communicate to you in connection with
            the use of Instacart API Materials, which may include, but is not limited to displaying
            third party copyright notices.
          </li>
          <li>
            <u>Authorized Users</u>. You are responsible for your Authorized User, including their
            acts and omissions hereunder. We reserve the right to restrict, modify, and/or remove
            access and use of any Authorized User hereunder in our sole discretion.
          </li>
          <li>
            <u>Developer Account/Instacart API Key</u>. You are required to create a Developer
            Account. We will provide you with any Instacart API Keys that permit you to access the
            Instacart API Materials. You will not share, or permit any third party to share,
            passwords, usernames/IDs, or the Instacart Key with any third party or access or your
            Developer Account in any manner that would jeopardize the security of such account. In
            the event you become aware of any unauthorized access and/or use to the Developer
            Account you will promptly notify us in writing. You are responsible for the accuracy of
            all information you provide in the Developer Account. Instacart API Keys are our
            exclusive property and we may revoke access in the event you share such with any third
            party (other than as expressly permitted herein) at any time.
          </li>
          <li>
            <u>Required Minimum Functionality</u>. For any Applications that you make available, you
            must expose at least as much functionality as is set forth in the Required Minimum
            Functionality and in this section (&quot;<b>RMF Requirements</b>&quot;). In the event
            the RMF Requirements include a particular function, all aspects of that function and all
            Instacart API calls related to that function must be enabled and exposed. You must
            adhere to any changes made by us to the RMF Requirements and ensure that any additional
            functionality added to the RMF Requirements are enabled and exposed within a time period
            established by us in our sole discretion. In the event you do not, then we reserve the
            right to suspend your access to the Instacart APIs.
          </li>
          <li>
            <u>Restrictions</u>. You will not (a) use the Instacart Materials or Merchant Content
            for any illegal, unauthorized, or otherwise improper purposes, or in any manner that
            would violate this Agreement, or in any manner that would breach any Applicable Laws;
            (b) remove any legal, copyright, trademark, or other proprietary rights or notices
            contained in or on Instacart Materials or Merchant Content; (c) sell, lease, share,
            transfer, sublicense, or fail to protect the confidentiality of any Instacart Materials
            or Merchant Content obtained through this Agreement, directly or indirectly, to any
            third party, including any delivery or ecommerce company, data broker, ad network, ad
            exchange, or other advertising or data monetization-related party; (d) use for your own
            benefit the Instacart Materials or Merchant Content; (e) use any means or form of
            scraping or data extraction to access, query or otherwise collect data and information
            from the Instacart Platform, unless otherwise explicitly permitted herein or authorized
            in writing by Instacart; (f) interfere with or disrupt Instacart Platform or servers or
            networks connected to Instacart services, (g) disobey any requirements, procedures,
            policies or regulations of networks connected to Instacart Platform; or (h) transmit to
            Instacart any viruses, worms, defects, Trojan horses, any items of a destructive nature
            or similar disabling software or code that does or can disable, damage, corrupt,
            interfere with or delete any element of software, data, computer or electronic records
            or files of Instacart, including any such code that allows you or any third party to
            access or to perform any unauthorized operations on Instacart computer systems;(i)
            bypass any Instacart API restrictions for any reason; (j) except as authorized by
            Instacart in writing, substantially replicate products or services offered by Instacart,
            including the Instacart API; (k) use Instacart Materials or Merchant Content on or with
            sites/platforms (i) to promote unsafe conduct, (ii) to provide medical advice; (iii)
            that include inappropriate or discriminatory content, (iv) display priced items from
            multiple retailers on the same screen within any Application, or (l) move, copy,
            compare, or transfer items from one retailer basket to another retailer basket within an
            Application; (m) develop Applications that excessively burden the Instacart system,
            distribute spyware, adware or other commonly objectionable programs; (n) develop an
            Application whose primary purpose is to migrate end users off of Instacart; (o) request
            more than the minimum amount of data from a Instacart API needed by your Application to
            provide the intended functionality; (p) falsify or alter any unique identifier in, or
            assigned to your Application, or otherwise obscure or alter the source of queries coming
            from an Application; (q) include code in any Application which performs any operations
            not related to the services provided by the Application, including embedding or
            incorporating code into any Application which utilizes the resources (including CPU
            resources) of another computer, including for the purposes of cryptocurrency mining; or
            (r) use an alternative to Instacart Platform checkout for web checkout or payment
            processing, or register any transactions through the Instacart API in connection with
            such activity, without our express written authorization. For purposes of clarification
            only, you are expressly prohibited from using any data generated from an end user&apos;s
            interactions with the Instacart API and applicable Application to create user profiles,
            target users, or develop any product or service that competes with our business, as
            reasonably determined by us, including our advertising business.
          </li>
          <li>
            <u>API Limits</u>. We may set and enforce limits on your use of the Instacart API
            (limiting the number of requests that you may make or the number of Merchants
            available), in our sole discretion. You agree to, and will not attempt to circumvent
            such limitations, including those documented provided to you by or on behalf of us in
            writing and/or made available in your Developer Account. If you would like to use any
            Instacart API beyond these limits, you must obtain Instacart&apos;s express written
            consent (and Instacart may decline such request or condition acceptance on your
            agreement to additional terms and/or charges for that use). To seek such approval,
            contact us via the Developer Account by following the Contact Technical Support link.
          </li>
          <li>
            <u>Application Development</u>. You are solely responsible for Applications and
            Developer Content, including any testing, distribution, display, costs, expenses, and
            liabilities. We are not required to provide you any assistance to your development and
            integration of an Application; however, in the event we elect to do so, any assistance
            from us is provided &quot;as is&quot; and without any warranty of any kind.
          </li>
          <li>
            <u>Applications Submission</u>. Instacart may require, in its sole discretion, you
            submit an application to receive access to the data and material hereunder and receive
            the right to approve or disapprove such application in its sole discretion.
          </li>
          <li>
            <u>Monitoring</u>. Without limiting our rights in the inspection section below, you
            agree that we may monitor your access and use of the Instacart API Materials, Instacart
            Data, and/or Merchants Content to ensure quality, improve our products and services, and
            verify your compliance with this Agreement. This monitoring may include our accessing
            and using your Application to identify security issues that could affect us, our
            Customers, or Merchants. You will not interfere with this monitoring and we may use any
            technical means to overcome any such interference. We may immediately suspend access to
            the Instacart API Materials by you or your Application with or without notice if we
            reasonably believe that you are in violation of this Agreement.
          </li>
        </ol>
      </li>
      <li>
        <b>MODIFICATIONS TO INSTACART APIS</b>.
        <ol>
          <li>
            <u>Updates</u>. We reserve the right to require you to install or update any and all
            software to continue using the Instacart API and the Instacart Platform. You acknowledge
            that Instacart may make API Updates from time to time, and at its sole discretion. You
            must implement and use the most current version of the Instacart API and make any
            changes to your Application that are required as a result of such API Update, at your
            sole cost and expense. API Updates may adversely affect the manner in which your
            Application accesses or communicates with the Instacart API. Your continued access or
            use of the Instacart API following an update or modification will constitute binding
            acceptance of the API Update.
          </li>
          <li>
            <u>Access and Use</u>. We may change or discontinue the availability of some or all of
            the Instacart API at any time for any reason with or without notice. Such changes may
            include removal of features, or the requirements of fees for previously free features.
            We may also impose limits on certain features and services or restrict your access to
            some or all of the Instacart API. Your continued use of the Instacart API following a
            subsequent release will be deemed your acceptance of such API Update.
          </li>
          <li>
            <u>Beta Services</u>. From time to time, we may, in its sole discretion, invite you to
            use, on a trial basis, Beta Services. Beta Services may be subject to additional terms
            and conditions, which Instacart will provide to you prior to your use of the Beta
            Services. Such Beta Services and all associated conversations and materials relating
            thereto will be considered Confidential Information and subject to the confidentiality
            provisions in this Agreement. We make no representations or warranties that the Beta
            Services will function. We may discontinue the Beta Services at any time in our sole
            discretion. We will have no liability for any harm or damage arising out of or in
            connection with a Beta Service. The Beta Services may not work in the same way as a
            final version. We may change or not release a final or commercial version of a Beta
            Service in our sole discretion.
          </li>
        </ol>
      </li>
      <li>
        <b>LICENSE.</b>
        <ol>
          <li>
            <u>Instacart</u>. Subject to your compliance with this Agreement, we grant you a
            limited, non-transferrable, non-sublicensable, non-exclusive, revocable license during
            the Term to access and use (a) Instacart API Materials solely to the extent necessary in
            connection with developing, implementing and distributing your Application that
            interoperates or integrates with the Instacart Platform, and solely in the manner
            described in the Agreement and in the technical documentation contained in the Instacart
            API Materials; and (b) the Instacart Marks for the sole purpose of notifying Merchants
            that the Application is compatible with the Instacart Platform; provided, however, (i)
            it will use Instacart&apos;s Marks only as permitted hereunder; and (ii) it will use the
            Instacart Marks in a lawful manner and in strict compliance with all format(s),
            guidelines, standards and other requirements prescribed by Instacart in the Instacart
            Marks Guidelines.
          </li>
          <li>
            <u>Developer</u>. You grant us a limited, non-transferrable, non-sublicensable,
            non-exclusive license during the Term to access and use (a) your Application for
            purposes of answering inquiries related to your Application, and verifying your
            compliance with this Agreement; (b) Developer Content, including reproduce, adapt,
            modify, publicly perform, publicly display and distribute, submitted through the
            Instacart API Materials for the purposes of your use of the Instacart API Materials in
            accordance with this Agreement; and (c) your Developer Content, trademarks and logos to
            publicize or advertise your use of the Instacart API Materials.
          </li>
        </ol>
      </li>
      <li>
        <b>PROPRIETARY RIGHTS. </b>As between you and us, we own and will continue to own Instacart
        Materials, including any and all derivatives thereof, which includes any modifications we
        may permit you to make to the Instacart Materials, and you own the Application and Developer
        Content. For avoidance of doubt, we do not acquire ownership in your Application by use of
        the Instacart APIs, and by using the Instacart API, you do not acquire ownership of any
        rights in the Instacart APIs or the content, data, and information that is accessed through
        the Instacart APIs. All permissions, licenses, or other rights (implied or otherwise) not
        expressly granted under this Agreement are expressly reserved.
      </li>
      <li>
        <b>CONFIDENTIAL INFORMATION.</b>
        <ol>
          <li>
            <u>Use of Confidential Information</u>. You agree (a) to hold the Confidential
            Information in confidence and to take reasonable precautions to protect such
            Confidential Information (including, without limitation, all precautions such party
            employs with respect to its own confidential materials), (b) not to disclose any
            Confidential Information to any third party, except to a service provider authorized in
            accordance with these Terms and you will remain liable to us for such service
            provider&apos;s acts and omissions hereunder , and (c) not to use any Confidential
            Information except for the sole purposes set forth in this Agreement. Any employee given
            access to the Confidential Information must have a legitimate &quot;need to know&quot;
            and shall be similarly bound in writing.
          </li>
          <li>
            <u>Compelled Disclosure of Confidential Information</u>. If you are required by
            Applicable Law to disclose the Confidential Information, you shall, to the extent
            legally permissible, give prompt written notice to us of such requirement before any
            such disclosure and shall assist us in obtaining an order protecting the Confidential
            Information from public disclosure.
          </li>
          <li>
            <u>Exclusion to Confidential Information</u>. Confidential Information shall not include
            information that (a) is already known to you without restriction prior to receipt from
            us; (b) is or becomes publicly known through no wrongful act of you; (c) is received
            from a third party without similar restriction and without breach of this Agreement; or
            (d) is approved in writing for release by us, which we may without in our sole
            discretion.
          </li>
          <li>
            <u>Injunctive Relief</u>. You acknowledge and agree that due to the unique nature of the
            Confidential Information, there may be no adequate remedy at law for any breach of your
            obligations hereunder and therefore, that upon any such breach or any threat thereof, we
            shall be entitled to seek appropriate equitable relief without the need to submit bond
            in addition to whatever remedies it might have at law.
          </li>
          <li>
            <u>Non-Circumvention</u>. You shall not use Confidential Information for the purpose of
            circumventing or attempting to circumvent directly or indirectly, the intent of this
            Agreement, including but not limited to, use of such Confidential Information to
            reverse-engineer proprietary technology and data.
          </li>
          <li>
            <u>Destruction of Confidential Information</u>. Notwithstanding anything to the
            contrary, upon termination or expiration of this Agreement, or sooner upon written
            request from us, you shall promptly delete, destroy, or render unreadable any
            Confidential Information, including the Instacart Data, in its control and/or possession
            and shall provide certification to us within five (5) business days thereof.
          </li>
          <li>
            <u>Survival</u>. Your obligation of confidentiality set forth herein will survive the
            expiration, termination, or cancellation of this Agreement.
          </li>
        </ol>
      </li>
      <li>
        <b>SECURITY.</b>
        <ol>
          <li>
            <u>Application</u>. Your Application will be installed and run on your server or another
            server, and will not be run on our servers. Developer System must be properly configured
            to Internet industry standards so as to securely operate your Application and protect
            against unauthorized access to, disclosure or use of any information you receive from
            Instacart, including Instacart Data and Merchant Data. If you do not completely control
            some aspect of the Developer System, you will use all influence that you have over the
            Developer System to do so. You must diligently correct any security deficiency, and
            disconnect immediately any known or suspected intrusions or intruder.
          </li>
          <li>
            <u>Security Measures</u>. You have in place appropriate technical and organizational
            measures to protect Instacart API Materials, Instacart Data, Merchant Data, and
            Confidential Information from and against accidental or unlawful destruction or
            accidental loss, alteration, unauthorized disclosure or access, and which provide a
            level of security appropriate to the risk represented by the processing and the nature
            of the Instacart API Materials, Instacart Data, Merchant Data, and Confidential
            Information and that complies with all Applicable Laws.
          </li>
          <li>
            <u>Sub-processors</u>. You will have in place procedures so that any third party you
            authorize to have access to the Instacart API Materials, Instacart Data, Merchant Data,
            and Confidential Information, including sub-processors, will respect and maintain the
            confidentiality and security of the Instacart API Materials, Instacart Data, Merchant
            Data, and Confidential Information. You are responsible for the acts and omissions of
            your sub-processors.
          </li>
          <li>
            <u>Security Incident</u>. You will promptly inform us of any unauthorized access,
            destruction, use, modification, or disclosure of any Instacart API Materials, Instacart
            Data, Merchant Data, and Confidential Information, unless such notification is
            prohibited by Applicable Law or requested to be withheld by law enforcement or
            investigators. You will notify us via email with read-receipt to
            security-incidents@instacart.com and a copy to legal@instacart.com and your primary
            business contact at Instacart.
          </li>
          <li>
            <u>Inquiries</u>. You shall promptly notify us of any regulatory inquiry or
            correspondence regarding Instacart API Materials, Instacart Data, Merchant Data, and
            Confidential Information (each an &quot;Inquiry&quot; and collectively
            &quot;Inquiries&quot;), unless such notification is prohibited by Applicable Law.
          </li>
        </ol>
      </li>
      <li>
        <b>PRIVACY. </b>To the extent you have access to, store or otherwise process (as
        &quot;process&quot; is defined in the Instacart Privacy Addendum) any Covered PI, (a) the
        Instacart Privacy Addendum shall apply and form part of the Agreement; and (b) we have the
        right to take reasonable and appropriate steps to ensure that you use the Covered PI
        consistent with &quot;Privacy Laws&quot; (as defined in the Instacart Privacy Addendum). You
        acknowledge that any breach of your covenants or obligations set forth in the Instacart
        Privacy Addendum or non-compliance with Privacy Laws may cause us irreparable harm for which
        monetary damages would not be adequate compensation and you agree that, in the event of such
        breach, threatened breach, or material non-compliance with Privacy Laws, we are entitled to
        seek equitable relief, including a restraining order, injunctive relief, specific
        performance and any other relief that may be available from any court, and without the need
        to submit a bond in addition to any other remedy to which Instacart may be entitled at law
        or in equity. Such remedies shall not be deemed to be exclusive but shall be in addition to
        all other remedies available at law or in equity. Notwithstanding anything to the contrary,
        in the event of a conflict between the terms of this Agreement and the terms of the
        Instacart Privacy Addendum, the Instacart Privacy Addendum shall prevail.
      </li>
      <li>
        <b>INSPECTION. </b>We shall have the right to audit, or to appoint an independent auditor
        under appropriate non-disclosure conditions to audit your Applications, systems and records
        to confirm your compliance with the Agreement, including without limitation your compliance
        with ours requests and requests from Merchants or Customers, as applicable, to delete
        Merchant Content obtained through the Instacart API or otherwise through our performance
        hereunder. If requested, you must provide us with proof compliance with these Agreement.
      </li>
      <li>
        <b>DISCLAIMER OF WARRANTIES. </b>THE INSTACART MATERIALS ARE PROVIDED TO YOU &quot;AS
        IS&quot; AND &quot;AS AVAILABLE&quot; WITHOUT ANY REPRESENTATIONS, WARRANTIES OR COVENANTS
        OF ANY KIND. WE MAKE NO, AND HEREBY DISCLAIM ALL, REPRESENTATIONS OR WARRANTIES OF ANY KIND
        TO ANY PARTY, WHETHER EXPRESS, IMPLIED (EITHER IN FACT OR BY OPERATION OF LAW), OR
        STATUTORY, WITH RESPECT TO THE INSTACART MATERIALS, INCLUDING ALL WARRANTIES OR CONDITIONS
        OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT, AND ALL
        WARRANTIES THAT MAY ARISE FROM COURSE OF DEALING, COURSE OF PERFORMANCE, OR USAGE OF TRADE.
        WITHOUT LIMITING THE ABOVE, WE DO NOT REPRESENT OR WARRANT THAT THE INSTACART API MATERIALS
        ARE OR WILL BE APPROPRIATE OR AVAILABLE FOR USE IN ANY PARTICULAR JURISDICTION, WILL BE
        ERROR FREE, UNINTERRUPTED, OR FREE FROM HARMFUL COMPONENTS. THIS SECTION WILL BE ENFORCEABLE
        TO THE MAXIMUM EXTENT ALLOWED BY APPLICABLE LAW.
      </li>
      <li>
        <b>LIMITATION OF LIABILITY. </b>INSTACART AND INSTACART RELATED ENTITIES SHALL HAVE NO
        LIABILITY WITH RESPECT TO THE AGREEMENT, THE INSTACART MATERIALS OR OTHERWISE FOR ANY
        DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING
        DAMAGES FOR LOSSES OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES RESULTING IN
        ANY WAY FROM THE AGREEMENT OR THE INSTACART MATERIALS, EVEN IF ADVISED OF THE POSSIBILITY OF
        SUCH DAMAGES. IN ANY EVENT, INSTACART AND INSTACART RELATED ENTITIES TOTAL AGGREGATE
        LIABILITY TO YOU UNDER THE AGREEMENT FOR ANY REASON WILL BE LIMITED TO ONE HUNDRED UNITED
        STATES DOLLARS (USD $100). THIS LIMITATION APPLIES TO ALL CAUSES OF ACTION IN THE AGGREGATE,
        INCLUDING BREACH OF CONTRACT, BREACH OF WARRANTY, NEGLIGENCE, STRICT LIABILITY,
        MISREPRESENTATIONS, AND OTHER TORTS. THE FOREGOING LIMITATIONS SHALL APPLY TO THE FULLEST
        EXTENT PERMITTED BY APPLICABLE LAW.
      </li>
      <li>
        <b>INDEMNIFICATION.</b>
        <ol>
          <li>
            You agree to indemnify, defend and hold harmless Instacart and any Instacart Related
            Entities and each such entity&apos;s directors, officers, employees, subcontractors and
            agents (each, an &quot;<b>Indemnified Party</b>&quot;, and collectively, the &quot;
            <b>Indemnified Parties</b>&quot;), with respect to any claim, demand, cause of action,
            debt or liability, including reasonable attorneys&apos; fees (collectively,
            &quot;Indemnification
            <b>Claims</b>&quot;), to the extent that such claim is based upon or arises out of: (a)
            your breach of any representation, warranty, obligation or covenant under the Agreement;
            (b) your gross negligence or willful misconduct; (c) any warranty, condition,
            representation, indemnity or guarantee relating to Instacart and Instacart Related
            Entities granted by you to any third party; (d) your access to or use of the Instacart
            API Materials; (e) any third party claim that your products or services, including any
            Application, infringes the intellectual property or other rights of a third party; (f)
            the performance, non-performance or improper performance of your products or services,
            including any Application; and (h) a Data Breach.
          </li>
          <li>
            In claiming any indemnification hereunder, the Indemnified Party shall promptly provide
            you with written notice of any Indemnification Claim which the Indemnified Party
            believes falls within the scope of the indemnifications provided under the Agreement.
            The Indemnified Party may, at its own expense, assist in the defense if it so chooses,
            provided that you shall control such defense and all negotiations relative to the
            settlement of any such Indemnification Claim and further you shall obtain Indemnified
            Parties&apos; consent, prior to entering into a settlement or compromise or consenting
            to any injunctive relief with respect to any Indemnification Claim.
          </li>
          <li>
            In the event of any breach or threatened breach by you of any provision of Sections 3
            (Access and Use), 6 (Proprietary Rights), 7 (Confidentiality), 8 (Security), or 9
            (Privacy), in addition to all other rights and remedies available to Instacart under the
            Terms and under applicable law, Instacart shall have the right to (a) immediately enjoin
            all such activity, without the necessity of showing damages or posting bond or other
            security; (b) immediately terminate your rights under this Agreement; (c) receive a
            prompt refund of any amounts paid to you hereunder; and (d) be indemnified for any
            losses, damages or liability incurred by Instacart in connection with such violation, in
            accordance with the provisions of this Section 13.3.
          </li>
        </ol>
      </li>
      <li>
        <b>TERMINATION.</b>
        <ol>
          <li>
            <u>Termination By Instacart</u>. Access to the Instacart API Materials may be terminated
            or suspended by us at any time and in our sole discretion. Without limiting the
            foregoing, we may immediately terminate your right to use the Instacart API if you
            breach the Agreement or any documents incorporated by reference in the Agreement.
          </li>
          <li>
            <u>Termination By Developer</u>. You may terminate the Agreement by discontinuing use of
            the Instacart API Materials and notifying us in writing that you have done so.
          </li>
          <li>
            <u>Effect of Termination</u>. Upon termination of the Agreement: (a) each party shall
            return to the other party, or destroy (and provide certification of such destruction),
            all property of the other party in its possession or control (including all Confidential
            Information); (b) you shall immediately cease displaying any Instacart Marks on any
            website or otherwise; and (c) all rights granted to you hereunder will immediately
            cease, including the right of your to access and use the Instacart API Materials.
          </li>
        </ol>
      </li>
      <li>
        Dispute Resolution &amp; Arbitration Agreement
        <ol>
          <li>
            <b></b>
            <u>Overview of Dispute Resolution Process</u>. This Section 15 provides for a two-part
            process for dispute resolution: (a) an informal dispute resolution process directly with
            Instacart (described in Section 15.2, below), and if necessary, (b) a binding
            arbitration administered by the American Arbitration Association (&quot;<b>AAA</b>
            &quot;) or ADR Services Inc. (&quot;<b>ADR Services</b>&quot;), depending on where you
            reside. Notwithstanding these provisions, you and Instacart each retain the right to
            seek resolution of disputes in small claims court as an alternative to arbitration.
          </li>
          <li>
            <u>Mandatory Informal Dispute Resolution</u>. You and Instacart agree that good-faith
            informal efforts to resolve disputes often can result in a prompt, low-cost and mutually
            beneficial outcome. Therefore, before initiating formal proceedings, you and Instacart
            agree to first attempt to work out any such dispute amicably. The initiating party must
            give notice to the other party in writing of its, his, or her dispute, including a
            written description of the dispute. For any dispute you initiate, you agree to provide
            written notice to:
            <p>
              Instacart
              <br />
              ATTN: Legal Department
              <br />
              50 Beale Street, Suite 600
              <br />
              San Francisco, CA 94105
            </p>
            <p>
              Your written notice must include at least the following information: the name and
              title of the signatory; the email address associated with the Developer Account; a
              detailed description of the nature and basis of the dispute, including any transaction
              details; and the specific relief sought and how it was calculated. Your written
              description must be on an individual basis and personally signed by you and not by an
              attorney, agent, or representative. For any dispute that Instacart raises, we will
              send our written description of the dispute (including the information listed above),
              signed by an authorized Instacart representative, to the most recent delivery address
              associated with the Developer Account. The initiating party must allow the other party
              sixty (60) days from the date the notice is sent to respond and attempt to resolve the
              dispute amicably before initiating an arbitration or other proceeding per the terms
              set forth below.
            </p>
            <p>
              You and Instacart agree that this informal dispute resolution process is a requirement
              that must be fulfilled prior to initiating an arbitration or small claims proceeding.
              The applicable statute of limitations period and any filing fee deadlines shall be
              tolled from the time the initiating party sends their written notice through the sixty
              (60) day period set forth above.
            </p>
          </li>
          <li>
            <u>Arbitration Agreement</u>. If we&apos;re unable to work out a solution amicably, both
            you and Instacart agree to resolve through binding arbitration, rather than in court,
            any dispute, controversy, or claim (each, a &quot;<b>Claim</b>&quot;) arising at any
            time, including but not limited to those arising out of or relating to: (i) this
            Agreement, including the formation, existence, breach, termination, enforcement,
            interpretation, validity, scope, or enforceability thereof; (ii) access to or use of the
            Services, including receipt of any advertising or marketing communications and/or any
            information contained on the Instacart Materials; (iii) any transactions through, by, or
            using the Instacart Materials, including any goods or services purchased or sold
            through, by, or using the Instacart Materials and any consumption or use of those goods
            and services; (iv) any other aspect of your relationship or transactions with Instacart
            as a consumer; or (v) Third Party Disputes (as defined in Section 15.4 below) (the
            &quot;
            <b>Arbitration Agreement</b>&quot;). This Arbitration Agreement shall apply, without
            limitation, to all claims that arose or were asserted before the Effective Date of this
            Agreement.
          </li>
          <li>
            <u>Application to Third Parties.</u> This Arbitration Agreement shall be binding upon,
            and shall include any dispute, controversy, or claim brought by or against any third
            parties where the underlying claims arise out of or relate to your use of the Services
            or any use of your Instacart Business Account (&quot;<b>Third Party Dispute</b>s&quot;).
            Third Party Disputes shall include any dispute, controversy, or claim by or against any
            Retailers, Third-Party Providers, Instacart&apos;s vendors and service providers
            including without limitation: payment processors, partners assisting in fraud
            prevention, companies advertising through the Services, partners with whom we work for
            advertising measurement, attribution and/or analytics, and companies that offer
            co-branded services or products, as well as your spouses, heirs, third-party
            beneficiaries and assigns. For avoidance of doubt, a Claim under this Arbitration
            Agreement includes all claims or disputes between you and any Retailer arising out of or
            related to the Services performed under this Agreement and/or services in connection
            with any white label sites that are powered by Instacart.
          </li>
          <li>
            <u>Exceptions to Arbitration Agreement</u>. Notwithstanding this mandatory Arbitration
            Agreement, you and Instacart each retain the right: (a) to assert claims in small claims
            court, so long as the claims qualify and the matter remains in such court and advances
            only on an individual (non-class, non-representative) basis; and, (b) to seek injunctive
            or other equitable relief in a court of competent jurisdiction to prevent the actual or
            threatened infringement of a party&apos;s intellectual property rights. If a Claim
            qualifies for small claims court but a party commences an arbitration proceeding, you
            and Instacart agree that either party may elect instead to have the Claim resolved in
            small claims court, and upon written notice of a party&apos;s election, the arbitration
            proceeding will be administratively closed. Any dispute about whether a Claim qualifies
            for small claims court shall be resolved by that court, not by an arbitrator.
          </li>
          <li>
            <u>Arbitration Procedure and Rules</u>. For United States residents, all Claims
            submitted to binding arbitration under this Section shall be administered by JAMS in San
            Francisco, California. The arbitration shall be conducted in accordance with the JAMS
            Comprehensive Arbitration Rules &amp; Procedures in effect on the Effective Date of this
            Agreement, subject to any modifications contained in this Agreement or this Section. If
            JAMS is not available to arbitrate, the parties will mutually select an alternative
            arbitral forum. The Claim shall be determined by one arbitrator, except that if the
            Claim involves an amount in excess of $1,000,000 (exclusive of interest and costs),
            three arbitrators shall be appointed. Unless the parties agree otherwise, if the Claim
            involves an amount less than or equal to $1,000,000 (exclusive of interest and costs),
            discovery will be limited to an exchange of directly relevant documents and no
            depositions will be taken except as needed in lieu of a live appearance. The Federal
            Arbitration Act, 9 U.S.C. § 1 et seq. (the &quot;FAA&quot;) governs the interpretation
            and enforcement of this Arbitration Agreement and preempts all state laws to the fullest
            extent permitted by law. If the FAA is found to not apply to any issue that arises out
            of or relates to this Arbitration Agreement, then that issue shall be resolved under and
            governed by the law of the State of Delaware. Judgment upon the award rendered may be
            entered in any court having jurisdiction. You acknowledge and agree that, with respect
            to any Claim within the scope of this arbitration agreement, you and Instacart each
            waive the right to bring, join, or participate in, either as a plaintiff or class
            member, any purported class, collective, or representative action or proceeding. By
            entering into the agreement to arbitrate in this Section, the parties knowingly and
            voluntarily waive their rights to have any Claim tried and adjudicated by a judge or a
            jury.
            <p>
              For Canada residents, all Claims shall be resolved by binding arbitration administered
              by ICDR Canada in Toronto, Ontario in accordance with its Canadian Arbitration Rules.
              The language of arbitration shall be English. The number of arbitrators shall be one,
              except that if the Claim involves an amount in excess of $1,000,000 (exclusive of
              interest and costs), three arbitrators shall be appointed. Unless the parties agree
              otherwise, if the Claim involves an amount less than or equal to $1,000,000 (exclusive
              of interest and costs), discovery will be limited to an exchange of directly relevant
              documents and no depositions will be taken except as needed in lieu of a live
              appearance. By entering into this Agreement, you and Instacart each agree that, to the
              extent permitted by applicable law, any and all dispute resolution proceedings will be
              conducted only on an individual basis and not in a collective, consolidated, or on the
              basis of a joinder of proceedings. The agreement to arbitrate in this Section 26 shall
              apply to any Claim that arose prior to, on, or after the Effective Date of this
              Agreement and shall continue in full force and effect despite the expiration,
              rescission or termination of this Agreement. Judgment upon the award rendered may be
              entered in any court having jurisdiction. By entering into this Agreement, you and
              Instacart each agree that you shall only bring and pursue claims against Instacart in
              your individual capacity and further agree to not bring, pursue or act as a plaintiff,
              class representative, or class member in any purported class or collective proceeding.
              By entering into the agreement to arbitrate in this Section 26 the parties knowingly
              and voluntarily waive their rights to have any Claim tried and adjudicated by a judge
              or a jury.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <b>GENERAL.</b>
        <ol>
          <li>
            <u>Relationship of the Parties</u>. The parties to this Agreement are independent
            contractors. Neither party is an agent, representative or related entity of the other
            party. Neither party shall have any right, power or authority to enter into any
            agreement for, or on behalf of, or incur any obligation or liability of, or otherwise
            bind, the other party. This Agreement shall not be interpreted or construed to create an
            association, agency, joint venture or partnership between the parties or to impose any
            liability attributable to such a relationship upon either party. There are no third
            party beneficiaries to the Agreement.
          </li>
          <li>
            <u>Interpretation</u>. Any headings appearing at the beginning of any section of this
            Agreement have been inserted for identification and reference purposes only and must not
            be used to construe or interpret this Agreement. Whenever required by context, a
            singular number will include the plural, the plural number will include the singular,
            and the gender of any pronoun will include all genders. The phrases
            &quot;including&quot; or &quot;includes&quot; as used in this Agreement will not be
            construed as words of limitation and instead will be deemed to be followed by the words
            &quot;without limitation&quot;. Whenever the words &quot;hereunder,&quot;
            &quot;hereof,&quot; &quot;hereto,&quot; and words of similar import are used in this
            Agreement, they will be deemed references to this Agreement as a whole and not to any
            particular section, or other provision hereof. The word &quot;or&quot; is used in the
            inclusive sense of &quot;and/or.&quot; The terms &quot;or,&quot; &quot;any&quot; and
            &quot;either&quot; are not exclusive.
          </li>
          <li>
            <u>Non-Exclusivity</u>. Nothing in this Agreement is intended to create, nor shall it be
            construed as creating, any exclusive arrangement between the parties to the Agreement.
            This Agreement shall not restrict either party from entering into similar arrangements
            with others, provided it does not breach its obligations under the Terms by doing so,
            including any confidentiality obligations.
          </li>
          <li>
            <u>Feedback</u>. In the event you provide Feedback, you hereby grants to us a worldwide,
            perpetual, irrevocable, fully paid-up, royalty-free, non-exclusive license to such
            Feedback, including for use or incorporation into the Instacart Materials, the rights to
            make derivative works therefrom or to otherwise commercially exploit in any way, without
            any restriction and without any payment.
          </li>
          <li>
            <u>Insurance</u>. Without limiting your liabilities or obligations, including
            indemnification, set forth herein, you shall maintain (a) commercial general liability
            insurance and (b) errors and omissions/professional liability and cyber liability
            insurance with reasonably acceptable minimum limits consistent with industry standards
            and the type of data access and services provided hereunder.
          </li>
          <li>
            <u>Export Control</u>. Your access to and use of the Instacart API Materials are subject
            to compliance with the export control laws. You will be solely responsible for Your
            compliance with the export control laws and monitoring any modifications to them.
          </li>
          <li>
            <u>Patent Non-Assertion</u>. You and your affiliates covenant not to assert patent
            infringement claims against Instacart, Instacart Related Entities, or Instacart products
            and services, including the Instacart API.
          </li>
          <li>
            <u>Competitive or Similar Materials</u>. Instacart is not precluded from discussing,
            reviewing, developing for itself, having developed, acquiring, licensing, or developing
            for or by third parties, as well as marketing and distributing materials, products or
            services which are competitive with your products or services, including any
            Application, regardless of their similarity to your products or services, provided that
            Instacart does not use Developer Content in so doing.
          </li>
          <li>
            <u>Publicity</u>. You are not permitted to use Instacart Marks in any publicity release,
            promotional material, advertising, marketing, or other public statement without
            obtaining Instacart&apos;s prior written consent, which may be withheld in
            Instacart&apos;s sole discretion.
          </li>
          <li>
            <u>Surviving Provisions</u>. The following sections shall survive termination or
            expiration of this Agreement: 5, 6, 7, 11, 12, 13, 14, 15, and 16.
          </li>
          <li>
            <u>Notice</u>. Any notice, approval, request, authorization, direction or other
            communication under this Agreement shall be given in writing and shall be deemed to have
            been delivered and given for all purposes (a) on the delivery date if delivered
            personally, or by email to your email address listed in Developer Account, and to
            legal@Instacart.com; (b) two (2) business days after deposit with an internationally
            recognized commercial overnight courier service, with written verification of receipt;
            or (c) five (5) business days after deposit in certified or registered mail, return
            receipt requested, postage and charges prepaid, to the address provided in the Developer
            Account.
          </li>
          <li>
            <u>No Waiver.</u> The failure of either party to insist upon or enforce strict
            performance by the other party of any provision of this Agreement or to exercise any
            right under this Agreement shall not be construed as a waiver or relinquishment to any
            extent of such party&apos;s right to assert or rely upon any such provision or right in
            that or any other instance; rather, the same shall be and remain in full force and
            effect. Each waiver shall be set forth in a written instrument signed by the waiving
            party.
          </li>
          <li>
            <u>Severability</u>. This Agreement will be enforced to the fullest extent permitted
            under Applicable Law. If any provision of the Agreement is held by a court of competent
            jurisdiction to be contrary to law, the provision will be modified by the court and
            interpreted so as best to accomplish the objectives of the original provision to the
            fullest extent permitted by Applicable Law, and the remaining provisions of the
            Agreement will remain in effect.
          </li>
          <li>
            <u>Assignment</u>. All the terms and provisions of this Agreement shall be binding upon
            and inure to the benefit of the parties to the Agreement and to their respective heirs,
            successors, permitted assigns and legal representatives. Instacart shall be permitted to
            assign this Agreement without notice to or consent from you. You shall have no right to
            assign or otherwise transfer the Agreement, or any of its rights or obligations
            hereunder, to any third party without our prior written consent, to be given or withheld
            in our sole discretion.
          </li>
          <li>
            <u>Other Content</u>. Merchant Content and/or the Instacart API Materials may include
            hyperlinks to other websites, content or resources. We have no control over any
            websites, content or resources that are provided by persons or entities other than us.
            We are not responsible for the availability of any such other websites, content or
            resources, and we do not endorse any advertising, products, or other materials on or
            available from or through such websites, content or resources.
          </li>
          <li>
            <u>Controlling Law</u>.
            <ol>
              <li>
                To the extent permitted by applicable law, this Agreement will be governed by the
                laws of the State of Delaware for residents of the United States and by the laws of
                the Province of Ontario for residents of Canada (except Quebec), without respect to
                its conflicts of laws principles, except the Arbitration Agreement, which is
                governed by the laws outlined in Section 17 of these Terms. To the extent permitted
                by applicable law, any claims arising out of or relating to these Terms or use of
                the Services that are not subject to Section 15 (Dispute Resolution &amp;
                Arbitration Agreement) of these Terms shall be brought exclusively in the federal or
                state courts of New Castle County, Delaware, USA, for the residents of the United
                States, and the courts of competent jurisdiction in the City of Toronto for
                residents of Canada (except Quebec), and you and Instacart consent to the personal
                jurisdiction of those courts.
              </li>
              <li>
                For Quebec residents only, to the extent permitted by law, this Agreement will be
                governed by the laws of the Province of Quebec without respect to its conflicts of
                laws principles. To the extent permitted by applicable law, any claims arising out
                of or relating to this Agreement or use of the Instacart API Materials that are not
                subject to Section 15 (Dispute Resolution &amp; Arbitration Agreement) of these
                Terms shall be brought exclusively in the courts of competent jurisdiction in the
                City of Montreal, and you and Instacart consent to the personal jurisdiction of
                those courts.
              </li>
            </ol>
          </li>
          <li>
            <u>Entire Agreement</u>. This Agreement, including the Terms, sets forth the entire
            agreement and supersedes any and all prior agreements, written or oral, of the parties
            with respect to the subject matter hereof (including any prior version of the
            Agreement).
          </li>
        </ol>
      </li>
    </ol>
  </Content>
)

export const IdpTerms: React.FunctionComponent = () => (
  <div
    css={css({
      marginTop: spacing.X16,
      maxHeight: '30vh',
      overflowY: 'scroll',
    })}
  >
    {idpContent}
  </div>
)

export const PartnerDashboardTerms = () => {
  const messages = useDashMessages({
    intro: 'app.halt.terms.partnerDashboard.intro',
    privacyPolicy: 'app.halt.terms.partnerDashboard.privacyPolicy',
    termsOfUse: 'app.halt.terms.partnerDashboard.termsOfUse',
  })
  return (
    <div css={css({ marginTop: spacing.X16, maxHeight: '30vh', overflowY: 'auto' })}>
      <Content css={{ fontSize: '14px' }}>
        <p>{messages.intro}</p>
        <div css={{ paddingLeft: '20px', marginBottom: '0px' }}>
          <ul css={{ listStyleType: 'disc' }}>
            <li>
              <Link
                href="https://www.instacart.com/company/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                {messages.privacyPolicy}
              </Link>
            </li>
            <li>
              <Link
                href="https://docs.instacart.com/instacart/ipp-terms/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {messages.termsOfUse}
              </Link>
            </li>
          </ul>
          <br />
        </div>
      </Content>
    </div>
  )
}
