export enum FeatureToggle {
  ordersOperationalDashboard = 'rt_orders_operational_dashboard',
  businessOrderingTool = 'rt_business_ordering_tool',
  ordersSearchTableTotalChargeEnabled = 'rt_ops_dashboard_orders_search_table_total_charge_enabled',
  ordersSearchTableTotalChargeBackendValueEnabled = 'rt_ops_dashboard_orders_search_table_total_charge_backend_value_enabled',
  ordersOperationalDashboardCancelOrder = 'rt_orders_operational_dashboard_cancel_order',
  ordersOperationalDashboardRescheduleOrder = 'rt_orders_operational_dashboard_reschedule_order',
  ordersOperationalDashboardPizzaTracker2 = 'rt_orders_operational_dashboard_pizza_tracker_2_0',
  ordersOperationalDashboardPizzaTracker2Display = 'rt_orders_operational_dashboard_pizza_tracker_2_0_display',
  ordersOperationalDashboardSearchOrdersFromFrigate = 'rt_orders_operational_dashboard_search_orders_from_frigate',
  ordersOperationalDashboardSearchAndFiltersImprovements = 'rt_orders_operational_dashboard_search_and_filters_improvements',
  ordersOperationalDashboardOrderLineApiUpdate = 'rt_ops_dashboard_orderline_api_update',
  searchOnFilterChange = 'rt_ood_dashboard_search_on_filter_change',
}
