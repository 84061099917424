import { css } from '@emotion/react'
import styled from '@emotion/styled'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { type FloatingPosition, type PopoverWidth, type PopoverProps } from '@mantine/core'
import { Divider as TdsDivider } from '@retailer-platform/shared-components/src/tds'

const dividerContainerStyles = css({
  '& > div': {
    '& > div': {
      margin: '4px 0px !important',
    },
    height: '8px !important',
  },
})

export const Divider = () => (
  <div css={dividerContainerStyles}>
    <TdsDivider />
  </div>
)

export const StyledLogoImage = styled.img(({ grayout }: { grayout?: boolean }) => ({
  width: 26,
  height: 26,
  background: 'white',
  filter: grayout ? 'grayscale(100%)' : '',
}))

export const StyledLogoBox = styled.div({
  boxSizing: 'border-box',
  width: 36,
  height: 28,
  background: '#FFFFFF',
  border: '0.5px solid #C7C8CD',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export type PopoverConfig = {
  maxHeight?: number
  position?: FloatingPosition
  offset?: number
  width?: PopoverWidth
  withinPortal?: PopoverProps['withinPortal']
  triggerWidth?: string | number
}
