import { type FunctionComponent, useState } from 'react'
import { PaginatedGrid, Badge, Text } from '@retailer-platform/shared-components'
import { IconButton } from '@instacart/ids-tooling'
import { SearchIcon } from '@instacart/ids-core'
import { type AttributeManagementStepVersion } from '../../../../types/attributeManagement.types.ts'
import { useDomainMessages } from '../../../../utils/domain/intl.ts'
import { ChangesDeltaModal } from './ChangesDeltaModal.tsx'

interface Props {
  versions: AttributeManagementStepVersion[]
  actions: ((index: number, stepVersionId: string) => React.ReactNode)[]
  loading: boolean
}

const ChangesGrid: FunctionComponent<Props> = ({ versions, actions, loading }) => {
  const i18n = useDomainMessages({
    version: 'catalogAdminDomain.mdPlatformDetails.table.version',
    status: 'catalogAdminDomain.mdPlatformDetails.table.status',
    createdBy: 'catalogAdminDomain.mdPlatformDetails.table.createdBy',
    createdReason: 'catalogAdminDomain.mdPlatformDetails.table.createdReason',
    changes: 'catalogAdminDomain.mdPlatformDetails.table.changes',
    actions: 'catalogAdminDomain.mdPlatformDetails.table.actions',
    makeLive: 'catalogAdminDomain.attributeManagement.changes.button.makeLive',
    tombstone: 'catalogAdminDomain.attributeManagement.changes.button.tombstone',
    viewChanges: 'catalogAdminDomain.attributeManagement.changes.modal.title',
  })
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)

  const changeList = (currVersionIdx: number) => {
    const results = []
    const currVersion = versions[currVersionIdx]
    const prevVersion = currVersionIdx - 1 >= 0 ? versions[currVersionIdx - 1] : null
    for (const attributeName of Object.keys(currVersion.attributes)) {
      const currentValue = JSON.stringify(currVersion.attributes[attributeName] ?? '')
      const prevValue = JSON.stringify(prevVersion?.attributes[attributeName] ?? '')

      if (currentValue != prevValue) {
        results.push(attributeName)
      }
    }
    return results
  }

  return (
    <>
      <PaginatedGrid
        columns={[
          {
            id: 'version',
            Cell: ({ row }) => <Text size="medium">{row.original.version}</Text>,
            Header: i18n.version,
            width: 75,
          },
          {
            id: 'status',
            Cell: ({ row }) => (
              <Badge color={row.original.state === 'LIVE' ? 'green' : 'grey'}>
                <Text size="medium"> {row.original.state}</Text>
              </Badge>
            ),
            Header: i18n.status,
          },
          {
            id: 'createdReason',
            Cell: ({ row }) => <Text size="medium">{row.original.createdWhy}</Text>,
            Header: i18n.createdReason,
          },
          {
            id: 'changes',
            Cell: ({ row }) => <Text size="medium">{changeList(row.index).join(', ')}</Text>,
            Header: i18n.changes,
          },
          {
            id: 'viewChanges',
            width: 100,
            Cell: ({ row }) => <>{actions.map(action => action(row.index, row.original.id))}</>,
            Header: i18n.actions,
          },
        ]}
        data={versions.slice((page - 1) * pageSize, page * pageSize)}
        onPageChange={(page: number) => {
          setPage(page)
        }}
        page={page}
        isLoading={loading}
        showItemsPerPageSelector
        totalPages={Math.ceil(versions.length / pageSize)}
        itemsPerPage={pageSize}
        onItemsPerPageChange={(pageSize: number) => {
          setPageSize(pageSize)
        }}
        width={'100%'}
      ></PaginatedGrid>
    </>
  )
}

export default ChangesGrid
