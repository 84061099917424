import { type RPPDomain } from '@retailer-platform/dashboard/core'
import {
  AdminNavEntryPoint,
  AdminNavEntryPointV2,
  NavEntryPoint,
  NavItem,
} from '@retailer-platform/dashboard/routing'
import { catalogAdminAdminRouter } from './routing/catalogAdminAdminRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { catalogAdminQualityAuditAccessConfiguration } from './access-control/catalogAdminQualityAuditAccess.configuration'
import { catalogAdminScopedRouter } from './routing/catalogAdminScopedRouter'
import { catalogAdminCatalogImageUploadsAccessConfiguration } from './access-control/catalogAdminCatalogImageUploadsAccess.configuration'
import { catalogAdminMDPlatformAccessConfiguration } from './access-control/catalogMDPlatformAccess.configuration'
import { catalogAdminExtractionModelsAccessConfiguration } from './access-control/catalogAdminExtractionModelsAccess.configuration'
import { catalogAdminProductEditAccessConfiguration } from './access-control/catalogAdminProductEditAccess.configuration.ts'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'catalog-admin',
  totem: {
    entity: 'catalog-admin-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    admin: [catalogAdminAdminRouter],
    scoped: [catalogAdminScopedRouter],
  },
  navigationEntries: [
    {
      attachTo: NavItem.Catalog,
      attachToV2: NavEntryPoint.Catalog,
      subNavItems: [
        {
          type: 'item',
          route: 'app-admin-catalog-admin-image-uploads',
          labelId: 'catalogAdminDomain.imageUploads.title',
          accessControl: catalogAdminCatalogImageUploadsAccessConfiguration,
          positionNavV2: 2,
        },
      ],
    },
  ],
  adminNavigationEntries: [
    {
      attachTo: AdminNavEntryPoint.CatalogAdmin,
      attachToV2: AdminNavEntryPointV2.CatalogAdmin,
      subNavItems: [
        {
          route: 'app-admin-catalog-admin-quality-audit-workflow-list',
          labelId: 'catalogAdminDomain.qualityAuditWorkflows.nav.title',
          accessControl: catalogAdminQualityAuditAccessConfiguration,
          positionNavV2: 1,
        },
        {
          route: 'app-admin-catalog-admin-product-edit-show',
          labelId: 'catalogAdminDomain.productEdit.nav.title',
          accessControl: catalogAdminProductEditAccessConfiguration,
          positionNavV2: 0,
        },
        {
          route: 'app-admin-catalog-admin-md-platform',
          labelId: 'catalogAdminDomain.mdPlatform.nav.title',
          accessControl: catalogAdminMDPlatformAccessConfiguration,
          positionNavV2: 2,
        },
        {
          route: 'app-admin-catalog-admin-attribute-management',
          labelId: 'catalogAdminDomain.attributeManagement.nav.title',
          accessControl: catalogAdminExtractionModelsAccessConfiguration,
          positionNavV2: 3,
        },
      ],
    },
  ],
}

export default definition
