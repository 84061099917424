import React, { useMemo } from 'react'
import {
  Button,
  NotificationLevel,
  notify,
  Text,
  TextButton,
} from '@retailer-platform/shared-components'
import { Divider } from '@retailer-platform/shared-components/src/tds'
import faker from 'faker'
import { isEqual } from 'lodash'
import {
  ButtonRow,
  Container,
  Input,
  Property,
  PropertyContainer,
  PropertySectionLeft,
  PropertySectionRight,
  PropertyTitle,
  SectionHeader,
  TextInput,
} from '../shared-styled-components/SharedStyledComponents'
import { PropertyWarning } from '../md-platform-list/components/MdPlatformListTableRow'
import { useDomainMessages } from '../../utils/domain/intl'
import {
  type AttributeManagementStep,
  type AttributeManagementStepVersion,
  type AttributeManagementCategoryEnum,
  AttributeManagementCategoryEnumToGraphQLEnum,
  type AttributeManagementTypeEnum,
} from '../../types/attributeManagement.types'
import { useDomainGoToPath } from '../../utils/domain/routing'
import { useCreateNewStepVersionMutation, useUpdateStepMutation } from '../../api'
import StepAttributeForm, {
  validateAttributes,
} from '../attribute-management-common/step-attribute-forms/StepAttributeForm'

const PropertyRequired = () => <PropertyWarning error={true}>• Value Required</PropertyWarning>

interface Props {
  step: AttributeManagementStep
}

const validateStep = (
  stepVersion: AttributeManagementStepVersion,
  category: AttributeManagementCategoryEnum,
  type: AttributeManagementTypeEnum
) => {
  if (!stepVersion.createdWhy) {
    return false
  }
  return validateAttributes(category, type, stepVersion.attributes)
}

const AttributeManagementEdit: React.FC<Props> = ({ step }) => {
  const [newVersion, setNewVersion] = React.useState<AttributeManagementStepVersion>({
    ...step.versions[0],
    createdWhy: '',
  })
  const [owner, setOwner] = React.useState<string>(step.owner)
  const [description, setDescription] = React.useState<string>(step.description)
  const [isEditingDetails, setIsEditingDetails] = React.useState<boolean>(false)
  const goToHomePage = useDomainGoToPath('app-admin-catalog-admin-attribute-management')

  const anythingChanged = useMemo(
    () => !isEqual(newVersion.attributes, step.versions[0].attributes),
    [newVersion, step.versions]
  )

  const anyDetailsChanged = useMemo(
    () => !isEqual(step.owner, owner) || !isEqual(step.description, description),
    [step.owner, owner, step.description, description]
  )

  const [triggerCreation, { loading }] = useCreateNewStepVersionMutation({
    variables: {
      clientId: 'catalog_camp_ipp',
      idempotencyKey: faker.random.uuid(),
      attributes: newVersion?.attributes,
      createdWhy: newVersion?.createdWhy,
      createdBy: 'test',
      state: 'testing',
      stepCategory: AttributeManagementCategoryEnumToGraphQLEnum[step.category],
      stepId: step.id as string,
      tombstoned: false,
    },
    onError: () => {
      notify({
        level: NotificationLevel.Error,
        contents: 'Error creating extraction model',
      })
    },
    onCompleted: () => {
      goToHomePage()
    },
  })

  const [updateStep] = useUpdateStepMutation({
    variables: {
      clientId: 'catalog_camp_ipp',
      idempotencyKey: faker.random.uuid(),
      stepId: step.id as string,
      owner: owner,
      description: description,
    },
    onError: () => {
      notify({
        level: NotificationLevel.Error,
        contents: 'Error updating step owner and description',
      })
    },
    onCompleted: () => {
      window.location.reload()
    },
  })

  const i18n = useDomainMessages({
    landingTitle: 'catalogAdminDomain.attributeManagement.nav.title',
    cancel: 'catalogAdminDomain.attributeManagement.modal.cancel',
    reset: 'catalogAdminDomain.attributeManagement.details.button.reset',
    createNewVersion: 'catalogAdminDomain.attributeManagement.modal.createNewVersion',
    reason: 'catalogAdminDomain.attributeManagement.changes.modal.reason',
    reasonPlaceholder: 'catalogAdminDomain.attributeManagement.changes.modal.reasonPlaceholder',
    description: 'catalogAdminDomain.attributeManagement.modal.description',
    owner: 'catalogAdminDomain.attributeManagement.modal.owner',
    ownerSubtitle: 'catalogAdminDomain.attributeManagement.modal.owner.subtitle',
    topSectionHeader: 'catalogAdminDomain.attributeManagement.topSectionHeader',
    bottomSectionHeader: 'catalogAdminDomain.attributeManagement.bottomSectionHeader',
    editDetails: 'catalogAdminDomain.attributeManagement.modal.editDetails',
    updateDetails: 'catalogAdminDomain.attributeManagement.modal.updateDetails',
  })
  return (
    <Container>
      <SectionHeader> {i18n.topSectionHeader} </SectionHeader>
      <PropertyContainer>
        <PropertySectionLeft>
          <Property>
            <PropertyTitle>{i18n.reason}</PropertyTitle>
            {newVersion?.createdWhy?.length === 0 && <PropertyRequired />}
            <Input
              error={newVersion?.createdWhy?.length === 0}
              changed={newVersion?.createdWhy?.length === 0}
              onChange={e => {
                setNewVersion({
                  ...newVersion,
                  createdWhy: e.target.value,
                })
              }}
              placeholder={i18n.reasonPlaceholder}
              value={newVersion?.createdWhy}
            />
          </Property>
          <Property>
            <PropertyTitle>{i18n.owner} </PropertyTitle>
            <Text size="small">{i18n.ownerSubtitle}</Text>
            <Input
              value={owner}
              onChange={e => {
                setOwner(e.target.value)
              }}
              disabled={!isEditingDetails}
            />
          </Property>
        </PropertySectionLeft>
        <PropertySectionRight>
          <Property style={{ height: '100%' }}>
            <PropertyTitle>{i18n.description}</PropertyTitle>
            <TextInput
              value={description}
              onChange={e => setDescription(e.target.value)}
              disabled={!isEditingDetails}
            />
          </Property>
        </PropertySectionRight>
      </PropertyContainer>
      <ButtonRow>
        {!isEditingDetails && (
          <Button
            variant={'secondary'}
            onClick={() => setIsEditingDetails(true)}
            disabled={loading}
          >
            {i18n.editDetails}
          </Button>
        )}
      </ButtonRow>
      {isEditingDetails && (
        <ButtonRow>
          <Button
            variant={'secondary'}
            onClick={() => {
              setIsEditingDetails(false)
              setDescription(step.description)
              setOwner(step.owner)
            }}
            disabled={loading}
          >
            {i18n.cancel}
          </Button>
          <Button
            variant={'primary'}
            onClick={() => {
              setIsEditingDetails(false)
              updateStep()
            }}
            disabled={loading || !anyDetailsChanged}
          >
            {i18n.updateDetails}
          </Button>
        </ButtonRow>
      )}
      <Divider />
      <StepAttributeForm
        type={step.type}
        attributes={newVersion.attributes}
        onChange={attributes => setNewVersion({ ...newVersion, attributes: attributes })}
        mode={'edit'}
      />
      <ButtonRow>
        <TextButton
          onClick={() => {
            setNewVersion({
              ...step.versions[0],
              createdWhy: '',
            })
            setOwner(step.owner)
            setDescription(step.description)
          }}
          disabled={loading}
        >
          {i18n.reset}
        </TextButton>
        <Button variant={'secondary'} onClick={() => goToHomePage()} disabled={loading}>
          {i18n.cancel}
        </Button>
        <Button
          onClick={async () => {
            await triggerCreation()
            await updateStep()
          }}
          disabled={
            loading || !anythingChanged || !validateStep(newVersion, step.category, step.type)
          }
        >
          {i18n.createNewVersion}
        </Button>
      </ButtonRow>
    </Container>
  )
}

export default AttributeManagementEdit
