// @ts-nocheck legacy file transformed to TS
/**
 * ASYNC STATES
 * In lieu of proper enums, here are constants for async states.
 * Favor an enum over boolean flags, because with an enum you can
 * never have isLoading = true and isError = true: you will only
 * be in one state at a time.
 * In other words, make impossible states impossible.
 */
export const ASYNC_STATES = {
  BUSY: 'busy',
  DONE: 'done',
  FAIL: 'fail',
  IDLE: 'idle', // deprecated
  INITIAL: 'initial',
}

export const PARTNER_PICKUP_MODEL = 'partner_pick_and_run'

export const YES_NO_OPTIONS = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
]

export const STRING_TO_BOOLEAN_MAP = {
  'true': true,
  'false': false,
}

export const ROUTE_ROOT = process.env.REACT_APP_ROOT_PATH || ''

export const ENV_PRODUCTION = process.env.NODE_ENV === 'production'
export const DEBUG_ANALYTICS = process.env.PUBLIC_CLIENT_DEBUG_ANALYTICS === 'true'
