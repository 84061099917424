export enum Permission {
  MarketingAgentViewAccess = 'marketing_agent.view',
  MarketingAgentModifyAccess = 'marketing_agent.modify',
  RetailerFundedMarketingViewAccess = 'retailer_funded_marketing.view',
  RetailerFundedMarketingModifyAccess = 'retailer_funded_marketing.modify',
  RetailerFundedMarketingAdminAccess = 'retailer_funded_marketing.admin',
  RetailerFundedMarketingBillingView = 'retailer_funded_marketing_billing.view',
  RetailerFundedMarketingBillingModifyAccess = 'retailer_funded_marketing_billing.modify',
  RetailerFundedMarketingInsights = 'retailer_funded_marketing.insights',
  RetailerFundedMarketingCRMCampaignsView = 'retailer_funded_marketing_crm_campaigns.view',
  RetailerFundedMarketingCRMCampaignsModify = 'retailer_funded_marketing_crm_campaigns.modify',
  RetailerFundedMarketingCRMCampaignsManualCRMAccess = 'retailer_funded_marketing_crm_campaigns.manual_crm',
  RouletteApiAccessView = 'roulette_api_access.view',
  RetailerFundedMarketingToolingPermissiveMode = 'retailer_funded_marketing.tooling_permissive_mode',
  OffersToolingAdminAccess = 'offers_tooling.admin',
  OffersToolingModifyAccess = 'offers_tooling.modify',
  OffersToolingViewAccess = 'offers_tooling.view',
}
