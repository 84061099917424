const EN_US = {
  'fulfillmentTestingCenterDomain.title': 'Fulfillment Testing Center',
  'fulfillmentTestingCenterDomain.orderSimulator.title': 'Order Simulator',
  'fulfillmentTestingCenterDomain.orderSimulator.pageHeader': 'Order ID: #{orderId}',
  'fulfillmentTestingCenterDomain.orderSimulator.header': 'Order ID #{orderId} Simulator',
  'fulfillmentTestingCenterDomain.orderSimulator.header.button': 'Simulate Order',
  'fulfillmentTestingCenterDomain.orderSimulator.workflowCard.button': 'Simulate',
  'fulfillmentTestingCenterDomain.orderSimulator.workflowCard.buttonDisabledTooltip':
    'You need to complete the previous step before simulating this one',
  'fulfillmentTestingCenterDomain.orderSimulator.simulateOrder.error':
    'Could not simulate order. Please try again.',
  'fulfillmentTestingCenterDomain.orderSimulator.simulateOrder.error.notAuthorized':
    'Could not simulate order. User not authorized.',
  'fulfillmentTestingCenterDomain.tabs.delivery.title': 'Delivery',
  'fulfillmentTestingCenterDomain.tabs.pickup.title': 'Pickup',
  'fulfillmentTestingCenterDomain.tabs.lmd.title': 'Last Mile Delivery',
  'fulfillmentTestingCenterDomain.columnHeaders.orderId': 'Order ID',
  'fulfillmentTestingCenterDomain.columnHeaders.store': 'Store',
  'fulfillmentTestingCenterDomain.columnHeaders.status': 'Status',
  'fulfillmentTestingCenterDomain.columnHeaders.customerName': 'Customer name',
  'fulfillmentTestingCenterDomain.columnHeaders.deliveryEta': 'Delivery ETA',
  'fulfillmentTestingCenterDomain.columnHeaders.orderTotal': 'Order total',
  'fulfillmentTestingCenterDomain.columnHeaders.created': 'Created',
  'fulfillmentTestingCenterDomain.columnHeaders.date': 'Date',
  'fulfillmentTestingCenterDomain.columnHeaders.pickupEta': 'Pickup ETA',
  'fulfillmentTestingCenterDomain.columnHeaders.lmd.shopperDispatched': 'Shopper Dispatched',
  'fulfillmentTestingCenterDomain.columnHeaders.lmd.shopperEtaToStore': 'Shopper ETA to Store',
  'fulfillmentTestingCenterDomain.columnHeaders.lmd.shopperAtStore': 'Shopper at Store',
  'fulfillmentTestingCenterDomain.columnHeaders.lmd.shopperEtaToCustomer':
    'Shopper ETA to Customer',
  'fulfillmentTestingCenterDomain.columnHeaders.lmd.shopperAtCustomer': 'Shopper at Customer',
  'fulfillmentTestingCenterDomain.columnData.simulateButton.text': 'Simulate',
  'fulfillmentTestingCenterDomain.errorState.retry': 'Retry',
  'fulfillmentTestingCenterDomain.ordersList.searchFilter.orderId': 'Order ID',
  'fulfillmentTestingCenterDomain.ordersList.search': 'Search',
  'fulfillmentTestingCenterDomain.orderSimulator.workflow.orderConfirmed': 'Order Confirmed',
  'fulfillmentTestingCenterDomain.orderSimulator.workflow.deliveryCreated': 'Delivery created',
  'fulfillmentTestingCenterDomain.orderSimulator.workflow.stagedForDelivery': 'Staged for Delivery',
  'fulfillmentTestingCenterDomain.orderSimulator.workflow.deliveryAcknowledged':
    'Delivery acknowledged',
  'fulfillmentTestingCenterDomain.orderSimulator.workflow.shoppingInProgress':
    'Shopping in Progress',
  'fulfillmentTestingCenterDomain.orderSimulator.workflow.picking': 'Picking',
  'fulfillmentTestingCenterDomain.orderSimulator.workflow.shoppingComplete': 'Shopping Complete',
  'fulfillmentTestingCenterDomain.orderSimulator.workflow.checkout': 'Checkout',
  'fulfillmentTestingCenterDomain.orderSimulator.workflow.receiptSanityCheck':
    'Receipt Sanity Check',
  'fulfillmentTestingCenterDomain.orderSimulator.workflow.runnerBatch': 'Runner Batch',
  'fulfillmentTestingCenterDomain.orderSimulator.workflow.outForDelivery': 'Out for Delivery',
  'fulfillmentTestingCenterDomain.orderSimulator.workflow.delivery': 'Delivery',
  'fulfillmentTestingCenterDomain.orderSimulator.workflow.orderComplete': 'Order Complete',
  'fulfillmentTestingCenterDomain.orderSimulator.workflow.delivered': 'Delivered',
  'fulfillmentTestingCenterDomain.orderSimulator.workflow.orderCreated': 'Order created',
  'fulfillmentTestingCenterDomain.orderSimulator.workflow.readyForPickup': 'Ready for Pickup',
  'fulfillmentTestingCenterDomain.orderSimulator.workflow.orderAcknowledged': 'Order acknowledged',
  'fulfillmentTestingCenterDomain.orderSimulator.workflow.bagsCheck': 'Bags Check',
  'fulfillmentTestingCenterDomain.orderSimulator.workflow.staged': 'Staged',
  'fulfillmentTestingCenterDomain.orderSimulator.workflow.brandNew': 'Delivery',
  'fulfillmentTestingCenterDomain.orderSimulator.workflow.atStore': 'At Store',
  'fulfillmentTestingCenterDomain.orderSimulator.workflow.bagsVerified': 'Bags Verified',
  'fulfillmentTestingCenterDomain.orderSimulator.workflow.bagsDelivered': 'Bags Delivered',
  'fulfillmentTestingCenterDomain.orderSimulator.workflow.acknowledgedForRunning':
    'Acknowledged for Running',
  'fulfillmentTestingCenterDomain.orderSimulator.workflow.verifyingReplacements':
    'Verifying Replacements',
  'fulfillmentTestingCenterDomain.orderSimulator.errorHandling.retryLink': 'Retry',
  'fulfillmentTestingCenterDomain.ordersTable.emptyMessage': 'No data to test',
  'fulfillmentTestingCenterDomain.ordersTable.errorMessage': 'Data could not be loaded',
  'fulfillmentTestingCenterDomain.orderSimulator.orderList.title': 'Order Changes',
  'fulfillmentTestingCenterDomain.orderSimulator.refund.title': 'Refunded',
  'fulfillmentTestingCenterDomain.orderSimulator.replace.title': 'Replacements',
  'fulfillmentTestingCenterDomain.orderSimulator.orderList.item.quantity': '{quantity}{unit} x',
  'fulfillmentTestingCenterDomain.orderSimulator.orderList.foundButton': 'Mark Found',
  'fulfillmentTestingCenterDomain.orderSimulator.orderList.itemFound': 'Item Found',
  'fulfillmentTestingCenterDomain.orderSimulator.orderList.replaceButton': 'Replace',
  'fulfillmentTestingCenterDomain.orderSimulator.orderList.refundButton': 'Refund',
  'fulfillmentTestingCenterDomain.orderSimulator.orderList.errorMessage':
    'Error: Please try again.',
  'fulfillmentTestingCenterDomain.orderSimulator.replacementModal.replaceItem': 'Replace Item',
  'fulfillmentTestingCenterDomain.orderSimulator.replacementModal.replaceButton': 'Replace',
  'fulfillmentTestingCenterDomain.orderSimulator.replacementModal.cancelButton': 'Cancel',
  'fulfillmentTestingCenterDomain.orderSimulator.replacementModal.upcLabel': 'UPC Number',
  'fulfillmentTestingCenterDomain.orderSimulator.generateBatch.loading':
    'Generating Batch Order.\n May take up to 10 seconds.',
  'fulfillmentTestingCenterDomain.orderSimulator.advanceBatch.loading':
    'Advancing batch to selected state.',
  'fulfillmentTestingCenterDomain.orderSimulator.generateBatch.error':
    'Error generating batch order. Please try again',
  'fulfillmentTestingCenterDomain.orderSimulator.replacementModal.itemCheckButton': 'Check Item',
  'fulfillmentTestingCenterDomain.orderSimulator.replacementModal.itemNotFound':
    'Item not found: Please enter a new UPC number',
  'fulfillmentTestingCenterDomain.orderSimulator.replacementModal.itemFound':
    'Item found: {itemDisplayName}',
  'fulfillmentTestingCenterDomain.orderSimulator.emptyMessage': 'Unable to find order to test',
  'fulfillmentTestingCenterDomain.orderSimulator.errorMessage': 'Order could not be loaded',
  'fulfillmentTestingCenterDomain.orderSimulator.orderList.disableButton':
    'Simulate Picking to enable changes to the order list',
  'fulfillmentTestingCenterDomain.orderList.orderStatus.complete': 'Complete',
  'fulfillmentTestingCenterDomain.orderList.orderStatus.canceled': 'Canceled',
  'fulfillmentTestingCenterDomain.orderList.orderStatus.inProgress': 'In Progress',
  'fulfillmentTestingCenterDomain.orderList.orderStatus.orderPlaced': 'Order Placed',
  'fulfillmentTestingCenterDomain.orderList.orderStatus.waitingForDelivery': 'Waiting For Delivery',
  'fulfillmentTestingCenterDomain.orderList.orderStatus.orderEnRoute': 'Order En Route',
  'fulfillmentTestingCenterDomain.orderList.orderStatus.orderComplete': 'Order Complete',
  'fulfillmentTestingCenterDomain.orderList.orderStatus.orderCanceled': 'Order Canceled',
} as const

export default EN_US
