import React, { useRef, useState, useMemo } from 'react'
import {
  Modal,
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  NotificationLevel,
  Text,
  notify,
  Button,
  spacing,
} from '@retailer-platform/shared-components'
import { IconButton } from '@instacart/ids-customers'
import { ConfirmIcon } from '@instacart/ids-core'
import faker from 'faker'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  type AttributeManagementCategoryEnum,
  type AttributeManagementStepVersion,
  AttributeManagementTypeEnum,
  graphQLStepVersionToAttributeManagementStepVersion,
  type PipelineStep,
} from '../../../types/attributeManagement.types'
import { useDomainMessages } from '../../../utils/domain/intl'
import AttributeManagementList from '../../attribute-management-list/AttributeManagementList'
import { useGetListStepVersionsQuery } from '../../../api'
import { ButtonRow } from '../../shared-styled-components/SharedStyledComponents'
import { PipelineFormStepVersionSelectTable } from './PipelineFormStepVersionSelectTable'
import StepAttributeForm from './StepAttributeForm'
interface Props {
  category: AttributeManagementCategoryEnum
  onClose: () => void
  onConfirm: (step: PipelineStep) => void
}

interface State {
  selectedStepId: string | null
  selectedStepVersionId: string | null
  baseAttributes: any | null
  overrideAttributes: any | null
}

const DEFAULT_STATE: State = {
  selectedStepId: null,
  selectedStepVersionId: null,
  baseAttributes: null,
  overrideAttributes: null,
}

const ExplanationText = styled.div({
  marginBottom: spacing.X24,
})

const PipelineFormStepSelectModal: React.FC<Props> = ({ category, onClose, onConfirm }) => {
  const i18n = useDomainMessages({
    selectStepTitle:
      'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepSelectModal.selectStepTitle',
    selectStepVersionTitle:
      'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepSelectModal.selectStepVersionTitle',
    reset:
      'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepSelectModal.reset',
    restart:
      'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepSelectModal.restart',
    confirm:
      'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepSelectModal.confirm',
    setOverridesTitle:
      'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepSelectModal.setOverridesTitle',
    selectStepDescription:
      'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepSelectModal.selectStepDescription',
    selectStepVersionDescription:
      'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepSelectModal.selectStepVersionDescription',
    setOverridesDescription:
      'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepSelectModal.setOverridesDescription',
    cancel: 'catalogAdminDomain.attributeManagement.runTests.modal.button.cancel',
  })
  const [state, setState] = useState<State>(DEFAULT_STATE)
  const idempotencyKey = useRef(faker.random.uuid())
  const action = React.useCallback(
    (stepId: string) => (
      <IconButton
        icon={ConfirmIcon}
        accessibleLabel={i18n.selectStepTitle}
        onPress={() => setState({ ...state, selectedStepId: stepId })}
      />
    ),
    [i18n.selectStepTitle, state]
  )
  const { data, loading } = useGetListStepVersionsQuery({
    variables: {
      clientId: 'catalog_camp_ipp',
      idempotencyKey: idempotencyKey.current,
      stepId: state.selectedStepId || '',
    },
    skip: !state.selectedStepId,
    onError: (error: any) => {
      notify({
        contents: `Error fetching steps: ${error.message}`,
        level: NotificationLevel.Error,
      })
    },
  })
  const versions: AttributeManagementStepVersion[] = useMemo(
    () =>
      data?.instacart_catalog_catflow_v1_CatflowAPI_ListStepVersions?.stepVersions?.map(item =>
        graphQLStepVersionToAttributeManagementStepVersion(item)
      ) || [],
    [data]
  )
  const confirm = React.useCallback(() => {
    if (!state.baseAttributes || !state.overrideAttributes || !state.selectedStepVersionId) {
      return
    }

    // Create an object with only the keys that have different values from baseAttributes
    const attributeOverrides = Object.keys(state.overrideAttributes).reduce((acc, key) => {
      // Only include keys where values differ
      if (
        JSON.stringify(state.overrideAttributes[key]) !== JSON.stringify(state.baseAttributes[key])
      ) {
        acc[key] = state.overrideAttributes[key]
      }
      return acc
    }, {} as Record<string, any>)

    onConfirm({
      stepVersionId: state.selectedStepVersionId,
      stepId: versions.find(v => v.id === state.selectedStepVersionId)?.stepId || '',
      version: versions.find(v => v.id === state.selectedStepVersionId)?.version || '',
      attributeOverrides,
    })
  }, [
    onConfirm,
    state.baseAttributes,
    state.overrideAttributes,
    state.selectedStepVersionId,
    versions,
  ])
  const type = data?.instacart_catalog_catflow_v1_CatflowAPI_ListStepVersions?.stepVersions?.[0]
    ?.step?.type
    ? AttributeManagementTypeEnum[
        data?.instacart_catalog_catflow_v1_CatflowAPI_ListStepVersions?.stepVersions?.[0]?.step
          ?.type as keyof typeof AttributeManagementTypeEnum
      ]
    : null
  return (
    <Modal
      isOpen={true}
      maxWidth={'90%'}
      maxHeight={'90%'}
      onRequestClose={onClose}
      styleOverrides={{
        content: {
          height: '90vh',
        },
      }}
    >
      <ModalContainer>
        <ModalHeader>
          {state.selectedStepId
            ? state.selectedStepVersionId
              ? i18n.setOverridesTitle
              : i18n.selectStepVersionTitle
            : i18n.selectStepTitle}
        </ModalHeader>
        <ModalBody>
          <ExplanationText>
            <Text size="small" weight="semibold">
              {state.selectedStepId
                ? state.selectedStepVersionId
                  ? i18n.setOverridesDescription
                  : i18n.selectStepVersionDescription
                : i18n.selectStepDescription}
            </Text>
          </ExplanationText>
          {!state.selectedStepId && (
            <AttributeManagementList category={category} hideCreateButton actions={[action]} />
          )}
          {data?.instacart_catalog_catflow_v1_CatflowAPI_ListStepVersions?.stepVersions?.[0]?.step
            ?.name && (
            <Text size="large" weight="semibold" styles={css({ textAlign: 'center' })}>
              {
                data?.instacart_catalog_catflow_v1_CatflowAPI_ListStepVersions?.stepVersions?.[0]
                  ?.step?.name
              }
              {state.selectedStepVersionId && (
                <Text weight="semibold">
                  Version{' '}
                  {
                    data?.instacart_catalog_catflow_v1_CatflowAPI_ListStepVersions?.stepVersions?.find(
                      v => v.id === state.selectedStepVersionId
                    )?.version
                  }
                </Text>
              )}
            </Text>
          )}
          {state.selectedStepId && !state.selectedStepVersionId && (
            <PipelineFormStepVersionSelectTable
              listData={versions}
              loading={loading}
              onSelect={stepVersionId =>
                setState({
                  ...state,
                  selectedStepVersionId: stepVersionId,
                  baseAttributes: versions.find(v => v.id === stepVersionId)?.attributes,
                  overrideAttributes: versions.find(v => v.id === stepVersionId)?.attributes,
                })
              }
            />
          )}
          {state.selectedStepVersionId && type && (
            <StepAttributeForm
              type={type}
              attributes={state.overrideAttributes}
              mode={'override'}
              onChange={attributes => setState({ ...state, overrideAttributes: attributes })}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <ButtonRow>
            <Button variant={'secondary'} onClick={onClose}>
              {i18n.cancel}
            </Button>
            <Button
              variant={'secondary'}
              disabled={!state.selectedStepId}
              onClick={() => setState(DEFAULT_STATE)}
            >
              {i18n.restart}
            </Button>
            <Button
              variant={'secondary'}
              disabled={
                !state.selectedStepVersionId ||
                JSON.stringify(state.overrideAttributes) === JSON.stringify(state.baseAttributes)
              }
              onClick={() => setState({ ...state, overrideAttributes: state.baseAttributes })}
            >
              {i18n.reset}
            </Button>
            <Button variant={'primary'} disabled={!state.selectedStepVersionId} onClick={confirm}>
              {i18n.confirm}
            </Button>
          </ButtonRow>
        </ModalFooter>
      </ModalContainer>
    </Modal>
  )
}
export default PipelineFormStepSelectModal
