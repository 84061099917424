import { type DataProxy } from 'apollo-cache'
import _ from 'lodash'
import {
  GetNotificationsListDocument,
  type GetNotificationsListQuery,
  type NotificationsNotificationType,
  type SubscribeNotificationMutation,
  type UnsubscribeNotificationMutation,
} from '../api'
import {
  EligibilityOptions,
  SubscriptionOptions,
} from '../components/notifications-list/NotificationsList'

export const filterNotificationList = ({
  data,
  subscriptionOption = SubscriptionOptions.All,
  eligibilityOption = EligibilityOptions.All,
}: {
  data: Pick<
    NotificationsNotificationType,
    'id' | 'name' | 'description' | 'isSubscribed' | 'isEligible'
  >[]
  subscriptionOption?: SubscriptionOptions
  eligibilityOption?: EligibilityOptions
}) =>
  data
    ? data
        .filter(notification => {
          if (!subscriptionOption || subscriptionOption === SubscriptionOptions.All) {
            return notification
          } else {
            return subscriptionOption === SubscriptionOptions.Subscribed
              ? notification.isSubscribed
              : !notification.isSubscribed
          }
        })
        .filter(notification => {
          if (!eligibilityOption || eligibilityOption === EligibilityOptions.All) {
            return notification
          } else {
            return eligibilityOption === EligibilityOptions.Eligible
              ? notification.isEligible
              : !notification.isEligible
          }
        })
    : []

export const updateLocalListCache = ({
  cache,
  data,
  retailerId,
  notificationChannelId,
  updatedValue,
}: {
  cache: Omit<DataProxy, 'writeData'>
  data?: SubscribeNotificationMutation | UnsubscribeNotificationMutation
  retailerId?: string
  notificationChannelId: string
  updatedValue: boolean
}): void => {
  if (
    !(
      (data as SubscribeNotificationMutation).notificationSubscriptionCreate?.success ||
      (data as UnsubscribeNotificationMutation).notificationSubscriptionDelete?.success
    )
  )
    return

  const updatedSubscribeStatus = cache.readQuery({
    query: GetNotificationsListDocument,
    variables: { retailerId },
  }) as GetNotificationsListQuery
  const { notificationTypesForCurrentAccountOnRetailer } = updatedSubscribeStatus
  const notificationTypesForCurrentAccountOnRetailerCopy = _.cloneDeep(
    notificationTypesForCurrentAccountOnRetailer
  )
  const updatedNotification = notificationTypesForCurrentAccountOnRetailerCopy.find(
    notification => notification.id === notificationChannelId
  )

  if (updatedNotification) {
    updatedNotification.isSubscribed = updatedValue
  }

  cache.writeQuery({
    query: GetNotificationsListDocument,
    data: {
      notificationTypesForCurrentAccountOnRetailer:
        notificationTypesForCurrentAccountOnRetailerCopy,
    },
  })
}
