import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { FeatureEntitlements } from './featureEntitlements'
import { FeatureToggle } from './featureToggles'

export const campaignManagementAccessControl: DomainAccessControlConfig = {}

export const pageBuilderPlacementTargetingAccessControl: DomainAccessControlConfig = {
  featureEntitlementIds: [FeatureEntitlements.PageBuilderPlacementTargeting],
}

export const announcementBannerCampaignsEnabledAccessControl: DomainAccessControlConfig = [
  {
    featureToggles: [FeatureToggle.announcementBannerCampaignsEnabled],
  },
]
