import { spacing } from '@retailer-platform/shared-components'
import {
  MultiselectDropdown,
  SinglePanelDateRangePicker,
  WeekStartDay,
} from '@retailer-platform/shared-components/src/tds'
import React, { type FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { type Moment } from 'moment'
import moment from 'moment'
import {
  ReportsNormalizedReportsListDateRangePresetSelect,
  DateRangePreset,
} from '../date-range-preset-select/ReportsNormalizedReportsListDateRangePresetSelect'
import { type ReportTypeEnum } from '../../../../api'
import { ReportsNormalizedReportsListReportTypeSelect } from '../report-type-select/ReportsNormalizedReportsListReportTypeSelect'
import { ReportsNormalizedReportsListMergeButtonAndModal } from '../merge-button-and-modal/ReportsNormalizedReportsListMergeButtonAndModal'
import { useDomainMessage } from '../../../../utils/domain/intl'
import { type ReportTypeConfig } from '../../utils/ReportsNormalizedReportsList.utils'

interface DateRange {
  startDate: Moment
  endDate: Moment
}

interface ReportsNormalizedReportsListFiltersProps {
  reportCount: number
  partnerId: string
  retailerId: string
  dateRangePreset: DateRangePreset
  setDateRangePreset: (r: DateRangePreset) => void
  dateRange: DateRange
  setDateRange: (d: DateRange) => void
  reportType: ReportTypeEnum
  setReportType: (t: ReportTypeEnum) => void
  warehouseOptions?: Array<{ label: string; value: string }>
  setRetailerIds?: (r: string[]) => void
  retailerIds?: string[]
  reportTypesConfig: ReportTypeConfig
  filterUnavailableReportTypes?: boolean
}

const StyledContainer = styled.div({
  display: 'flex',
  padding: spacing.X24,
  alignItems: 'center',
  minHeight: 100,
  justifyContent: 'space-between',

  '& > .filter-container': {
    display: 'flex',

    '& > .filter-item': {
      width: 200,
      marginRight: spacing.X24,

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
})

export const ReportsNormalizedReportsListFilters: FunctionComponent<
  React.PropsWithChildren<ReportsNormalizedReportsListFiltersProps>
> = ({
  reportCount,
  partnerId,
  retailerId,
  dateRangePreset,
  setDateRangePreset,
  dateRange,
  setDateRange,
  reportType,
  setReportType,
  warehouseOptions,
  setRetailerIds,
  retailerIds,
  reportTypesConfig,
  filterUnavailableReportTypes,
}) => {
  const bannerLabel = useDomainMessage('reportsDomain.instacartManagedReports.filters.banner.label')

  const bannerValue =
    retailerIds?.map(id => {
      const option = warehouseOptions?.find(opt => opt.value === id)
      return { value: id, label: option?.label ?? id }
    }) ?? []

  return (
    <StyledContainer>
      <div className="filter-container">
        {warehouseOptions && (
          <div css={{ marginRight: spacing.X24, minWidth: 300 }}>
            <MultiselectDropdown
              options={warehouseOptions}
              onChange={val => setRetailerIds?.(val.map(item => item.value))}
              value={bannerValue}
              placeholder={bannerLabel}
              clearable
            />
          </div>
        )}
        <div className="filter-item">
          <ReportsNormalizedReportsListReportTypeSelect
            reportTypesConfig={reportTypesConfig}
            selectedReportType={reportType}
            onChange={setReportType}
            filterUnavailableReportTypes={filterUnavailableReportTypes}
          />
        </div>

        <div className="filter-item">
          <ReportsNormalizedReportsListDateRangePresetSelect
            selectedDateRange={dateRangePreset}
            onChange={setDateRangePreset}
          />
        </div>

        {dateRangePreset === DateRangePreset.Custom && (
          <div className="filter-item">
            <SinglePanelDateRangePicker
              label=""
              value={{
                startDate: dateRange.startDate?.toDate(),
                endDate: dateRange.endDate?.toDate(),
              }}
              onChange={({ startDate, endDate }) => {
                if (startDate && endDate) {
                  setDateRange({
                    startDate: moment(startDate),
                    endDate: moment(endDate),
                  })
                }
              }}
              weekStartDay={WeekStartDay.Monday}
              hidePresets
              showDayIndicator
            />
          </div>
        )}
      </div>

      <ReportsNormalizedReportsListMergeButtonAndModal
        reportCount={reportCount}
        partnerId={partnerId}
        retailerId={retailerId}
        retailerIds={retailerIds}
        reportType={reportType}
        dateRangePreset={dateRangePreset}
        dateRange={dateRange}
      />
    </StyledContainer>
  )
}
