import {
  Instacart_Catalog_Catflow_V1_StepCategory,
  type Instacart_Catalog_Catflow_V1_StepVersion,
} from '../api'

type ExtractionModelProperty = {
  propertyName: string
  required: boolean
  images: boolean
}

export enum AttributeManagementCategoryEnum {
  ExtractionModel = 'EXTRACTION_MODEL',
  Normalization = 'NORMALIZATION',
  Validation = 'VALIDATION',
  Unknown = 'UNKNOWN',
  Input = 'INPUT',
  Pipeline = 'PIPELINE',
}

export enum AttributeManagementTypeEnum {
  LLMPromptTemplate = 'LLMPromptTemplate',
  SnowflakeQueryInput = 'SnowflakeQueryInput',
  Pipeline = 'Pipeline',
}

export const TypesForCategory: Record<
  AttributeManagementCategoryEnum,
  AttributeManagementTypeEnum[]
> = {
  [AttributeManagementCategoryEnum.ExtractionModel]: [
    AttributeManagementTypeEnum.LLMPromptTemplate,
  ],
  [AttributeManagementCategoryEnum.Normalization]: [],
  [AttributeManagementCategoryEnum.Validation]: [],
  [AttributeManagementCategoryEnum.Unknown]: [],
  [AttributeManagementCategoryEnum.Input]: [AttributeManagementTypeEnum.SnowflakeQueryInput],
  [AttributeManagementCategoryEnum.Pipeline]: [AttributeManagementTypeEnum.Pipeline],
}

const COMMON_DEFAULT_STEP = {
  id: '',
  name: '',
  description: '',
  owner: '',
}

const COMMON_DEFAULT_STEP_VERSION = {
  id: '',
  createdWhy: '',
  createdAt: '',
  version: '',
  isLive: false,
  state: 'testing',
}

export const DefaultStep: Record<AttributeManagementTypeEnum, AttributeManagementStep> = {
  [AttributeManagementTypeEnum.LLMPromptTemplate]: {
    ...COMMON_DEFAULT_STEP,
    category: AttributeManagementCategoryEnum.ExtractionModel,
    type: AttributeManagementTypeEnum.LLMPromptTemplate,
    versions: [
      {
        ...COMMON_DEFAULT_STEP_VERSION,
        attributes: {
          model: '',
          prompt: '',
          properties: [],
        },
        stepId: '',
      },
    ],
  },
  [AttributeManagementTypeEnum.SnowflakeQueryInput]: {
    ...COMMON_DEFAULT_STEP,
    category: AttributeManagementCategoryEnum.Input,
    type: AttributeManagementTypeEnum.SnowflakeQueryInput,
    versions: [
      {
        ...COMMON_DEFAULT_STEP_VERSION,
        attributes: { query: '', ttl: 24, deduplicationDays: null, limit: 50000 },
        stepId: '',
      },
    ],
  },
  [AttributeManagementTypeEnum.Pipeline]: {
    ...COMMON_DEFAULT_STEP,
    category: AttributeManagementCategoryEnum.Pipeline,
    type: AttributeManagementTypeEnum.Pipeline,
    versions: [
      {
        ...COMMON_DEFAULT_STEP_VERSION,
        attributes: {
          enableSchedule: false,
          hoursInterval: 24,
          steps: [null, null],
        },
        stepId: '',
      },
    ],
  },
}

export const AttributeManagementCategoryEnumToGraphQLEnum: Record<
  AttributeManagementCategoryEnum,
  Instacart_Catalog_Catflow_V1_StepCategory
> = {
  [AttributeManagementCategoryEnum.ExtractionModel]:
    Instacart_Catalog_Catflow_V1_StepCategory.ExtractionModel,
  [AttributeManagementCategoryEnum.Normalization]:
    Instacart_Catalog_Catflow_V1_StepCategory.Normalization,
  [AttributeManagementCategoryEnum.Validation]:
    Instacart_Catalog_Catflow_V1_StepCategory.Validation,
  [AttributeManagementCategoryEnum.Unknown]: Instacart_Catalog_Catflow_V1_StepCategory.Unknown,
  [AttributeManagementCategoryEnum.Input]: Instacart_Catalog_Catflow_V1_StepCategory.Input,
  [AttributeManagementCategoryEnum.Pipeline]: Instacart_Catalog_Catflow_V1_StepCategory.Pipeline,
}

export const GraphQLEnumToAttributeManagementCategoryEnum: Record<
  Instacart_Catalog_Catflow_V1_StepCategory,
  AttributeManagementCategoryEnum
> = {
  [Instacart_Catalog_Catflow_V1_StepCategory.ExtractionModel]:
    AttributeManagementCategoryEnum.ExtractionModel,
  [Instacart_Catalog_Catflow_V1_StepCategory.Normalization]:
    AttributeManagementCategoryEnum.Normalization,
  [Instacart_Catalog_Catflow_V1_StepCategory.Validation]:
    AttributeManagementCategoryEnum.Validation,
  [Instacart_Catalog_Catflow_V1_StepCategory.Input]: AttributeManagementCategoryEnum.Input,
  [Instacart_Catalog_Catflow_V1_StepCategory.Unknown]: AttributeManagementCategoryEnum.Unknown,
  [Instacart_Catalog_Catflow_V1_StepCategory.Pipeline]: AttributeManagementCategoryEnum.Pipeline,
}

export const graphQLStepVersionToAttributeManagementStepVersion = (
  stepVersion: Instacart_Catalog_Catflow_V1_StepVersion
): AttributeManagementStepVersion => ({
  attributes: stepVersion.attributes as any,
  createdWhy: stepVersion.createdWhy || '',
  createdAt: stepVersion.createdAt || '',
  version: stepVersion.version || '',
  id: stepVersion.id || '',
  isLive: false,
  state: stepVersion.state || '',
  stepId: stepVersion.stepId || '',
})

export type AttributeManagementStepVersion = {
  createdWhy: string
  createdAt: string
  version: string
  id: string
  stepId: string
  isLive: boolean
  state: string
  attributes: any
}

export type AttributeManagementStep = {
  id: string | undefined
  name: string
  category: AttributeManagementCategoryEnum
  type: AttributeManagementTypeEnum
  description: string
  owner: string
  versions: AttributeManagementStepVersion[]
}

export type LLMPromptTemplateAttributes = {
  model: string
  prompt: string
  properties: ExtractionModelProperty[]
}

export type SnowflakeQueryInputAttributes = {
  query: string
  ttl: number | null
  deduplicationDays: number | null
  limit: number
}

export type PipelineStep = {
  stepVersionId: string
  stepId: string
  version: string
  attributeOverrides: Record<string, any>
}

export type PipelineAttributes = {
  enableSchedule: boolean
  hoursInterval: number
  steps: PipelineStep[]
}
