import { type RPPDomain } from '@retailer-platform/dashboard/core'
import {
  NavItem,
  AdminNavEntryPoint,
  AdminNavEntryPointV2,
  NavEntryPoint,
} from '@retailer-platform/dashboard/routing'
import { retailerFundedMarketingRouter } from './routing/retailerFundedMarketingRouter'
import { retailerFundedMarketingAdminRouter } from './routing/retailerFundedMarketingAdminRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { retailerFundedMarketingHomeAccessControl } from './access-control/retailerFundedMarketingHomeAccess.configuration'
import { retailerFundedMarketingV2Router } from './routing/retailerFundedMarketingV2Router'
import {
  retailerFundedMarketingAdminAccessControl,
  retailerFundedMarketingAdminCustomCampaignAccessControl,
} from './access-control/retailerFundedMarketingAdminAccess.configuration'
import { globalCampaignsAdminRouter } from './routing/GlobalCampaignsAdminRouter'
import { campaignsAdminRouter } from './routing/campaignsAdminRouter'
import { marketingAgentAdminRouter } from './routing/marketingAgentAdminRouter'
import {
  retailerFundedMarketingWholesalerAccessControl,
  retailerFundedMarketingWholesalerEarlyAccessControl,
} from './access-control/retailerFundedMarketingWholesalerAccess.configuration'
import { retailerFundedMarketingPartnerScopedRouter } from './routing/retailerFundedMarketingPartnerScopedRouter'
import { retailerFundedMarketingPartnerScopedSegmentRouter } from './routing/retailerFundedMarketingPartnerScopedSegmentRouter'
import { retailerFundedMarketingWholesalerSegmentAccessControl } from './access-control/retailerFundedMarketingWholesalerSegmentAccess.configuration'
import { lifecycleCrmAccessControl } from './access-control/lifecycleCrmAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'retailer-funded-marketing',
  totem: {
    entity: 'retailer-funded-marketing-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [retailerFundedMarketingV2Router, retailerFundedMarketingRouter],
    partnerScoped: [
      retailerFundedMarketingPartnerScopedRouter,
      retailerFundedMarketingPartnerScopedSegmentRouter,
    ],
    admin: [
      retailerFundedMarketingAdminRouter,
      globalCampaignsAdminRouter,
      campaignsAdminRouter,
      marketingAgentAdminRouter,
    ],
  },
  navigationEntries: [
    {
      attachTo: NavItem.RetailerFundedMarketing,
      attachToV2: NavEntryPoint.Marketing,
      subNavItems: [
        {
          type: 'item',
          labelId: 'retailerFundedMarketingDomain.campaigns.phaseFour.nav',
          route: 'retailer-funded-marketing-site-scoped-list',
          accessControl: retailerFundedMarketingHomeAccessControl,
          positionNavV2: 0,
        },
        {
          type: 'item',
          labelId: 'retailerFundedMarketingDomain.marketplaceCampaigns.nav',
          route: 'campaigns-marketplace-list',
          // TODO: update to use retailerFundedMarketingWholesalerAccessControl
          accessControl: retailerFundedMarketingWholesalerEarlyAccessControl,
          positionNavV2: 1,
        },
        {
          type: 'item',
          labelId: 'retailerFundedMarketingDomain.storefrontProCampaigns.nav',
          route: 'campaigns-storefront-pro-list',
          // TODO: update to use retailerFundedMarketingWholesalerAccessControl
          accessControl: retailerFundedMarketingWholesalerEarlyAccessControl,
          positionNavV2: 2,
        },
        {
          type: 'item',
          labelId: 'retailerFundedMarketingDomain.marketplace.segmentation.nav',
          route: 'segment-marketplace-list',
          accessControl: retailerFundedMarketingWholesalerSegmentAccessControl, //TODO update access control
          positionNavV2: 3,
        },
        {
          type: 'item',
          labelId: 'retailerFundedMarketingDomain.storefrontPro.segmentation.nav',
          route: 'segment-storefront-pro-list',
          accessControl: retailerFundedMarketingWholesalerSegmentAccessControl, //TODO update access control
          positionNavV2: 4,
        },
        {
          type: 'item',
          labelId: 'retailerFundedMarketingDomain.segmentation.nav',
          route: 'retailer-funded-marketing-segmentation-list',
          accessControl: retailerFundedMarketingHomeAccessControl,
          positionNavV2: 5,
        },
        {
          type: 'item',
          labelId: 'retailerFundedMarketingDomain.lifecycleCrm.nav',
          route: 'campaigns-partner-scoped-lifecycle-crm',
          accessControl: lifecycleCrmAccessControl,
          positionNavV2: 3,
        },
      ],
    },
  ],
  adminNavigationEntries: [
    {
      attachTo: AdminNavEntryPoint.CampaignManagement,
      attachToV2: AdminNavEntryPointV2.Marketing,
      subNavItems: [
        {
          route: 'app-admin-global-campaigns-campaign-moments',
          labelId: 'global.campaigns.title',
          accessControl: retailerFundedMarketingAdminAccessControl,
          positionNavV2: 0,
        },
      ],
    },
    {
      attachTo: AdminNavEntryPoint.CampaignManagement,
      attachToV2: AdminNavEntryPointV2.Marketing,
      subNavItems: [
        {
          route: 'app-admin-campaigns-list',
          labelId: 'campaignManagement.customCampaign.title',
          accessControl: retailerFundedMarketingAdminCustomCampaignAccessControl,
          positionNavV2: 4,
        },
      ],
    },
    {
      attachTo: AdminNavEntryPoint.CampaignManagement,
      attachToV2: AdminNavEntryPointV2.Marketing,
      subNavItems: [
        {
          route: 'app-admin-marketing-agent-calendar',
          labelId: 'marketingAgent.title',
          accessControl: retailerFundedMarketingAdminCustomCampaignAccessControl,
          positionNavV2: 5,
        },
      ],
    },
  ],
}

export default definition
