export enum Permission {
  // General Catalog Admin Permissions
  CatalogAdminViewAccess = 'catalog_admin.view',
  CatalogAdminModifyAccess = 'catalog_admin.modify',

  // General Detection Admin Permissions
  CatalogDetectionViewAccess = 'catalog_detection.view',
  CatalogDetectionModifyAccess = 'catalog_detection.modify',

  // Detection Manager Permissions
  CatalogDetectionManagementViewAccess = 'catalog_detection_management.view',
  CatalogDetectionManagementModifyAccess = 'catalog_detection_management.modify',

  // QAS Permissions
  QualityAuditViewAccess = 'catalog_admin_quality_audit.view',
  QualityAuditModifyAccess = 'catalog_admin_quality_audit.modify',

  // QAS Sama Permissions
  QualityAuditSamaViewAccess = 'catalog_admin_quality_audit.view_as_sama',
  QualityAuditSamaModifyAccess = 'catalog_admin_quality_audit.modify_as_sama',

  // QAS Manager Permissions
  QualityAuditManagerViewAccess = 'catalog_admin_quality_audit_management.view',
  QualityAuditManagerModifyAccess = 'catalog_admin_quality_audit_management.modify',

  // Catalog Tools Permissions
  ImageUploadsViewAccess = 'catalog_image_uploads.view',
  ImageUploadsModifyAccess = 'catalog_image_uploads.modify',

  // New API Permissions
  ImageManagementViewAccess = 'catalog_admin_image_management.view',
  ImageManagementModifyAccess = 'catalog_admin_image_management.modify',

  // Legacy API Permissions
  CatalogAdmin = 'catalog-admin',
  ProductViewAccess = 'admin_panel.legacy_permission',
}
