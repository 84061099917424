import type { PropsWithChildren, FunctionComponent } from 'react'
import { useState } from 'react'
import {
  PublicLayout,
  PublicCard,
  notify,
  NotificationLevel,
} from '@retailer-platform/shared-components'
import { Button, Checkbox, Flex } from '@retailer-platform/shared-components/src/tds'
import { routes } from '@retailer-platform/domain-instacart-developer-platform'
import { url } from '../../../../utils/parsing/url'
import { DashRedirect } from '../../../../legacy/dash-components/dash-redirect/DashRedirect'
import {
  useAcceptPartnerAgreementMutation,
  useFetchCurrentEnterpriseAccountQuery,
  AgreementTypeEnum,
} from '../../../../__codegen__/api'
import { useDashMessages } from '../../../../intl/intl.hooks'
import { usePartnerContext } from '../../../../exports/routing'
import { PartnerType } from '../../../../exports/utils'
import { URL_DASHBOARD } from '../../../../utils/routing/routes'
import { IdpTerms, PartnerDashboardTerms } from './Terms'

export const HaltTerms: FunctionComponent<PropsWithChildren<unknown>> = () => {
  const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false)
  const [acceptPartnerAgreement, { loading: isAccepting }] = useAcceptPartnerAgreementMutation()
  const partnerContext = usePartnerContext()
  const { data, refetch, loading } = useFetchCurrentEnterpriseAccountQuery({
    fetchPolicy: 'cache-and-network',
  })

  const messages = useDashMessages({
    idpTitle: 'app.halt.terms.idpTitle',
    sitewideTitle: 'app.halt.terms.sitewideTitle',
    agree: 'app.halt.terms.agree',
    continue: 'app.halt.terms.continue',
    accepting: 'app.halt.terms.accepting',
    error: 'app.halt.terms.error',
  })

  const handleCheck = () => {
    setAgreedToTerms(!agreedToTerms)
  }

  const expectedPartnerAgreementType =
    partnerContext.partnerType === PartnerType.IDP
      ? AgreementTypeEnum.IdpV1
      : AgreementTypeEnum.PartnerDashboardV1

  const handleSubmit = async () => {
    if (agreedToTerms) {
      const { data, errors } = await acceptPartnerAgreement({
        variables: {
          partnerId: partnerContext.partnerId,
          agreementType: expectedPartnerAgreementType,
        },
      })

      if (data?.enterpriseAccountAcceptPartnerAgreement?.acceptedPartnerAgreement?.partnerId) {
        await refetch()
      }

      if (errors) {
        notify({
          level: NotificationLevel.Error,
          body: messages.error,
        })
      }
    }
  }

  const termsAccepted = data?.currentEnterpriseAccount.acceptedPartnerAgreements.some(
    agreement => agreement.agreementType === expectedPartnerAgreementType
  )

  if (termsAccepted && !loading) {
    if (partnerContext.partnerType === PartnerType.IDP) {
      return (
        <DashRedirect
          to={url(routes['instacart-developer-platform-get-started'], {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            partner_id: partnerContext.partnerId,
          })}
        />
      )
    } else {
      return (
        <DashRedirect
          to={url(URL_DASHBOARD, {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            partner_id: partnerContext.partnerId,
          })}
        />
      )
    }
  }

  return (
    <PublicLayout>
      <PublicCard css={{ '> div': { gap: '0px' } }}>
        <div>
          <PublicCard.Title>
            {partnerContext.partnerType === PartnerType.IDP
              ? messages.idpTitle
              : messages.sitewideTitle}
          </PublicCard.Title>
          {partnerContext.partnerType === PartnerType.IDP ? (
            <IdpTerms />
          ) : (
            <PartnerDashboardTerms />
          )}
        </div>
        <Flex.Row justifyContent="space-between" alignItems="center">
          <Checkbox
            label={messages.agree}
            id="app.halt.terms.agree"
            onChange={() => handleCheck()}
            checked={agreedToTerms}
            data-testid="halt-terms-checkbox"
          />
          <Button
            disabled={!agreedToTerms || isAccepting || loading}
            onClick={() => handleSubmit()}
            data-testid="halt-terms-button"
          >
            {isAccepting || loading ? messages.accepting : messages.continue}
          </Button>
        </Flex.Row>
      </PublicCard>
    </PublicLayout>
  )
}
