import React, { type FunctionComponent } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader,
} from '@retailer-platform/shared-components'
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer'
import { startCase } from 'lodash'
import {
  Property,
  PropertyContainer,
  PropertySection,
  PropertyTitle,
} from '../../../md-platform-list/components/MdPlatformListTableRow.tsx'
import { useDomainMessages } from '../../../../utils/domain/intl.ts'
import { type AttributeManagementStepVersion } from '../../../../types/attributeManagement.types.ts'

interface Props {
  prevVersion: AttributeManagementStepVersion | null
  version: AttributeManagementStepVersion
  onClose: () => void
}
export const ChangesDeltaModal: FunctionComponent<React.PropsWithChildren<Props>> = ({
  prevVersion,
  version,
  onClose,
}) => {
  const i18n = useDomainMessages({
    title: 'catalogAdminDomain.attributeManagement.modal.title',
    submit: 'catalogAdminDomain.attributeManagement.modal.submit',
    cancel: 'catalogAdminDomain.attributeManagement.modal.cancel',
    reason: 'catalogAdminDomain.attributeManagement.changes.modal.reason',
    previous: 'catalogAdminDomain.attributeManagement.changes.previous',
    next: 'catalogAdminDomain.attributeManagement.changes.next',
    none: 'catalogAdminDomain.attributeManagement.changes.none',
  })

  return (
    <Modal maxWidth={'90%'} maxHeight={'90%'} isOpen={true} hideCloseButton={true}>
      <ModalContainer>
        <ModalHeader>{i18n.title}</ModalHeader>
        <ModalBody>
          <PropertyContainer style={{ flexDirection: 'column' }}>
            {Object.keys(version.attributes).map(attributeName => {
              const attributeValue = version.attributes[attributeName] || ''
              const prevAttributeValue = prevVersion?.attributes[attributeName] || ''

              if (
                JSON.stringify(attributeValue, null, 2) ===
                JSON.stringify(prevAttributeValue, null, 2)
              ) {
                return null
              }

              return (
                <PropertySection key={attributeName} style={{ width: '100%' }}>
                  <Property key={attributeName}>
                    <PropertyTitle>{startCase(attributeName)}</PropertyTitle>
                    <ReactDiffViewer
                      leftTitle={i18n.previous}
                      rightTitle={i18n.next}
                      oldValue={JSON.stringify(prevAttributeValue, null, 2) || ''}
                      newValue={JSON.stringify(attributeValue, null, 2) || ''}
                      splitView={true}
                      compareMethod={DiffMethod.WORDS}
                    />
                  </Property>
                </PropertySection>
              )
            })}
          </PropertyContainer>
        </ModalBody>
        <ModalFooter>
          <Button variant={'secondary'} onClick={() => onClose()}>
            {i18n.cancel}
          </Button>
        </ModalFooter>
      </ModalContainer>
    </Modal>
  )
}
