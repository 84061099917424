import { AnalyticsBrowser } from '@segment/analytics-next'
import {
  datadogRum,
  type RumEvent,
  type RumResourceEvent,
  type RumFetchResourceEventDomainContext,
  type RumEventDomainContext,
  type RumErrorEvent,
} from '@datadog/browser-rum'
import { matchPath } from 'react-router'
import clientEnv from '../global/clientEnv'
import { isDebugMode } from '../core/factories/events/eventDebugging'
import { type GlobalAnalytics } from '../core/factories/events/segmentAnalytics.types'
import { errors } from '../error-handling/errors'
import type { Instacart_Infra_Totem_V1_System as TotemSystem } from '../../__codegen__/api'
import { IGNORED_ERRORS, IGNORED_REGEX_ERRORS, IGNORED_UNHANDLED_ERRORS } from './ignoredErrors'

export const initAnalytics = () => {
  try {
    Array.from(new Array(localStorage.length))
      .map((_, i) => localStorage.key(i))
      .filter(key => key.startsWith('amplitude_unsent'))
      .forEach(key => localStorage.removeItem(key))
  } catch (err) {
    errors.captureException(err, 'failed to clear amplitude_unsent events')
  }

  if (clientEnv.PUBLIC_CLIENT_SEGMENT_TOKEN) {
    let apiHost = 'analytics.dashboard-api.instacart.com/v1'
    let writeKey = clientEnv.PUBLIC_CLIENT_SEGMENT_TOKEN

    // Check both are set to avoid race conditions on load
    if (clientEnv.PUBLIC_CLIENT_MONGOOSE_WRITE_KEY && clientEnv.PUBLIC_CLIENT_SEGMENT_API_HOST) {
      apiHost = `${clientEnv.PUBLIC_CLIENT_SEGMENT_API_HOST}/v2`
      writeKey = clientEnv.PUBLIC_CLIENT_MONGOOSE_WRITE_KEY
    }

    const analytics: AnalyticsBrowser = AnalyticsBrowser.load(
      {
        writeKey,
        // we proxy the initial load of segment cdn to get around adblockers blocking the segment url
        // this base url is used to load the *initial* javascript package from Segment that will allow
        // events to be sent (aka the 3rd party packaged code)
        cdnURL: 'https://analyticscdn.dashboard-api.instacart.com',
      },
      {
        integrations: {
          // only load specified integrations
          All: false,
          'Segment.io': {
            // This is the host that events will get *sent* to after the package has loaded that allows us
            // to send events. Write key must match the corresponding event ingestor it's being sent to.
            // eg. analytics.dashboard-api.instacart.com/v1 -> Segment.io provided URL
            // eg. ipp-mgs.instacart.team -> IPP's Mongoose ingestor for staging
            apiHost,
            protocol: 'https',
          },
        },
      }
    )

    const userAgentMiddleWare = function ({ payload, next }) {
      // We currently do not support sending userAgentData to segment
      if (payload?.obj?.context?.userAgentData) {
        delete payload.obj.context.userAgentData
      }
      next(payload)
    }

    analytics.addSourceMiddleware(userAgentMiddleWare)

    const global: GlobalAnalytics = globalThis
    global.analytics = analytics

    analytics.debug(isDebugMode())
  }
}

const isResourceEvent = (event: RumEvent): event is RumResourceEvent => event.type === 'resource'

/**
 * Appends the graphql operation name when present on resource events
 */
const enrichResourceWithGraphqlOperationName = (
  event: RumEvent,
  context: RumEventDomainContext
) => {
  if (
    isResourceEvent(event) &&
    event.resource.url.startsWith(clientEnv.PUBLIC_CLIENT_RETAILER_PLATFORM_MESH_URL)
  ) {
    const resourceContext = context as RumFetchResourceEventDomainContext
    if (typeof resourceContext.requestInit?.body === 'string') {
      // extract operationName from body string without parsing JSON
      const operationName = resourceContext.requestInit.body.match(/"operationName":"(.*?)"/)?.[1]

      event.context.graphqlOperationName = operationName
    }
  }
}

export const TOTEM_ENTITY_KEY = 'totem-entity'
export const TOTEM_SYSTEM_KEY = 'totem-system'
export const PARTNER_ID_KEY = 'partner-id'
export const RETAILER_ID_KEY = 'retailer-id'
export const RETAILER_IDS_KEY = 'retailer-ids'
let totemRouteOwnerMapping: Record<
  string,
  { routePattern: string; hasDevTooling?: boolean; totemSystem?: TotemSystem }
> = {}
let lastUrl: string | undefined
let lastTotemEntity: string | undefined

const getMatchedKey = (event?: RumEvent) => {
  const url = event?.view?.url
  const pathname = event ? new URL(url).pathname : window.location.pathname

  const matchedKey = Object.keys(totemRouteOwnerMapping).find(key => {
    if (matchPath(pathname, { path: key, exact: true })?.path) {
      return true
    }
  })

  return matchedKey
}

const enrichWithTotemEntity = (event: RumEvent) => {
  const url = event.view?.url

  if (url === lastUrl) {
    // a bit of memoization to avoid re-processing the same url
    event.context[TOTEM_ENTITY_KEY] = lastTotemEntity
  } else if (url) {
    lastUrl = url
    lastTotemEntity = undefined

    const matchedKey = getMatchedKey(event)

    const totemEntity = totemRouteOwnerMapping[matchedKey]
    if (totemEntity) {
      event.context[TOTEM_ENTITY_KEY] = totemEntity.routePattern
      lastTotemEntity = totemEntity.routePattern
    }
  }
}

export const updateTotemSystem = (totemSystem: TotemSystem) => {
  const matchedKey = getMatchedKey()
  totemRouteOwnerMapping[matchedKey] = {
    ...totemRouteOwnerMapping[matchedKey],
    totemSystem,
  }
}

const enrichWithTotemSystem = (event: RumEvent) => {
  // Only enrich with totem system if it is populated in totemRouteOwnerMapping.
  // This avoids processing the entire totemRouteOwnerMapping on every event,
  // until our ContactHeader populates the totem system for that route. It gurantees
  // that the totem system is present for that event's routes.

  // Totem uses snake_case for all of their keys. We can create a future PR to convert
  // DataDog Mesh + FE totem keys to camelCase if we want to.

  const isTotemSystemPresent = Object.values(totemRouteOwnerMapping).some(
    totem => totem.totemSystem
  )

  if (isTotemSystemPresent) {
    const matchedKey = getMatchedKey(event)

    const totemSystem = totemRouteOwnerMapping[matchedKey]?.totemSystem
    if (totemSystem) {
      event.context[TOTEM_SYSTEM_KEY] = {
        name: totemSystem.name,
        /* eslint-disable @typescript-eslint/naming-convention */
        owner_team: {
          name: totemSystem.owner_team?.name,
        },
        contact_details: {
          oncall_slack_group: {
            handle: totemSystem.contact_details?.oncall_slack_group?.handle,
          },
          opsgenie_responder: {
            name: totemSystem.contact_details?.opsgenie_responder?.name,
          },
          /* eslint-enable @typescript-eslint/naming-convention */
        },
      }
    }
  }
}

export const setTotemRouteOwnerMapping = (
  mapping: Record<
    string,
    { routePattern: string; hasDevTooling?: boolean; totemSystem?: TotemSystem }
  >
) => {
  totemRouteOwnerMapping = mapping
}

export const initDataDogRum = () => {
  if (!clientEnv.PUBLIC_CLIENT_DATADOG_RUM_ENABLED) {
    console.log('Datadog RUM is disabled')
    return
  }

  const sha = 'c896baf6e602fd126dbe57a0220dfb268951d2c2'
  // const sha = 'clientEnv.PUBLIC_CLIENT_ISC_SHA
  const branch = clientEnv.PUBLIC_CLIENT_ISC_BRANCH
  const release = `retailer-platform-web-workspace@${sha}.${clientEnv.PUBLIC_CLIENT_BUILDER}`

  datadogRum.init({
    applicationId: clientEnv.PUBLIC_CLIENT_DATADOG_RUM_APPLICATION_ID as string,
    clientToken: clientEnv.PUBLIC_CLIENT_DATADOG_RUM_CLIENT_TOKEN as string,
    site: 'datadoghq.com',
    service: 'ipp',
    version: release,
    env: clientEnv.PUBLIC_CLIENT_ENVIRONMENT as string,
    sessionSampleRate: parseInt(
      (clientEnv.PUBLIC_CLIENT_DATADOG_RUM_SAMPLE_RATE as string) || '0',
      10
    ),
    sessionReplaySampleRate: parseInt(
      (clientEnv.PUBLIC_CLIENT_DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE as string) || '0',
      0
    ),
    defaultPrivacyLevel: 'allow', // Don't mask any DOM elements by default
    trackResources: true,
    trackUserInteractions: true,
    allowedTracingUrls: [
      clientEnv.PUBLIC_CLIENT_RETAILER_PLATFORM_MESH_URL,
      clientEnv.PUBLIC_CLIENT_RPP_URL,
    ],
    beforeSend: (event, context) => {
      if (event.type === 'error') {
        const error = (event as RumErrorEvent).error

        if (IGNORED_ERRORS.some(ignoredError => error.message?.includes(ignoredError))) {
          return false
        }

        if (IGNORED_REGEX_ERRORS.some(regex => regex.test(error.message))) {
          return false
        }

        if (
          clientEnv.PUBLIC_CLIENT_IGNORE_UNHANDLED_ERRORS &&
          error.handling === 'unhandled' &&
          IGNORED_UNHANDLED_ERRORS.some(
            ignoredError =>
              (error.message || '') === ignoredError.message &&
              (error.stack || '') === ignoredError.stack
          )
        ) {
          return false
        }
      }

      enrichResourceWithGraphqlOperationName(event, context)
      enrichWithTotemEntity(event)
      enrichWithTotemSystem(event)
      event.context.branch = branch
      event.context.sha = sha
      event.context.release = release
      return true
    },
  })

  datadogRum.setGlobalContextProperty('totem-entity', PLATFORM_TOTEM_ENTITY)
}

export const PLATFORM_TOTEM_ENTITY = 'web.retailer-platform-web.retailer-tools'
