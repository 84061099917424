import type { PropsWithChildren, FunctionComponent } from 'react'
import { Fragment, useEffect } from 'react'
import { type StoreGroupsFormikProps } from '../../utils/form.types'
import { useStoreGroupLocations } from './utils/locations.hooks'
import { type OnSelectedLocationsChange } from './utils/locationSelector.types'
import { LocationsListFilters } from './components/StoreGroupsFormLocationSelectorFilters'
import { StoreGroupsFormLocationSelectorTable } from './components/table/StoreGroupsFormLocationSelectorTable'
import { useSelectorActions } from './utils/locationSelector.hooks'
import { StoreGroupsFormLocationSelectorAddAll } from './components/StoreGroupsFormLocationSelectorAddAll'
import LoadingDots from '../../../../../../components/LoadingDots'

interface Props extends StoreGroupsFormikProps {
  showSelectedLocationsOnly?: boolean
  showFilters?: boolean
  showAddAllButton?: boolean
  perPage?: number
  minRows?: number
}

export const StoreGroupsFormLocationSelector: FunctionComponent<PropsWithChildren<Props>> = ({
  values,
  setFieldValue,
  setFieldTouched,
  showSelectedLocationsOnly,
  showFilters,
  showAddAllButton,
}) => {
  const onChange: OnSelectedLocationsChange = selectedIds => {
    setFieldTouched('selectedLocationIds')
    setFieldValue('selectedLocationIds', Array.from(selectedIds))
  }
  const { selectedLocationIds, retailerIds } = values

  const {
    locations,
    locationsFilterValue,
    setLocationsFilterValue,
    clearLocationsFilterValue,
    retailerIdFilter,
    setRetailerIdFilter,
    loading,
  } = useStoreGroupLocations({
    selectedLocationIds,
    showSelectedLocationsOnly,
    currentRetailerIds: retailerIds,
  })

  const {
    isLocationSelected,
    handleToggleLocation,
    handleAddAllLocations,
    addAllDisabled,
    addAllNumStores,
  } = useSelectorActions({
    onChange,
    locations,
    selectedLocationIds,
  })

  useEffect(() => {
    if (!showFilters) clearLocationsFilterValue()
  }, [clearLocationsFilterValue, showFilters])

  return (
    <Fragment>
      {showFilters && (
        <LocationsListFilters
          filterValue={locationsFilterValue}
          setFilterValue={setLocationsFilterValue}
          retailerIdFilter={retailerIdFilter}
          setRetailerIdFilter={setRetailerIdFilter}
        />
      )}
      {loading ? (
        <LoadingDots centered />
      ) : (
        <StoreGroupsFormLocationSelectorTable
          locations={locations}
          isLocationSelected={isLocationSelected}
          handleToggleLocation={handleToggleLocation}
          showActionButtons={!showSelectedLocationsOnly}
        />
      )}
      {showAddAllButton && (
        <StoreGroupsFormLocationSelectorAddAll
          onClick={handleAddAllLocations}
          disabled={addAllDisabled}
          numStores={addAllNumStores}
        />
      )}
    </Fragment>
  )
}
