export enum Metrics {
  COUNT_SEARCH = 'COUNT_SEARCH',
  COUNT_SEARCH_PCT_DIFF = 'COUNT_SEARCH_PCT_DIFF',
  SEARCH_CONVERSION = 'SEARCH_CONVERSION',
  SEARCH_CONVERSION_PCT_DIFF = 'SEARCH_CONVERSION_PCT_DIFF',
  COUNT_NULL_SEARCH = 'COUNT_NULL_SEARCH',
  COUNT_CONVERTED_SEARCH = 'COUNT_CONVERTED_SEARCH',
  COUNT_UNCONVERTED_SEARCH = 'COUNT_UNCONVERTED_SEARCH',
  BENCHMARK_SEARCH_CONVERSION = 'BENCHMARK_SEARCH_CONVERSION',
  DIFF_SEARCH_CONVERSION_TERM_BENCHMARK = 'DIFF_SEARCH_CONVERSION_TERM_BENCHMARK',
  SEARCH_CONVERSION_TERM_BENCHMARK_CONVERSION_GAP = 'SEARCH_CONVERSION_TERM_BENCHMARK_CONVERSION_GAP',
}

export enum Dimensions {
  DATE_PT = 'DATE_PT',
  WEEK_SAT_PT = 'WEEK_SAT_PT',
  WEEK_SUN_PT = 'WEEK_SUN_PT',
  WEEK_MON_PT = 'WEEK_MON_PT',
  WEEK_TUE_PT = 'WEEK_TUE_PT',
  WEEK_WED_PT = 'WEEK_WED_PT',
  WEEK_THU_PT = 'WEEK_THU_PT',
  WEEK_FRI_PT = 'WEEK_FRI_PT',
  MONTH_PT = 'MONTH_PT',
  INSIGHTS_PORTAL_ORDER_SOURCE = 'INSIGHTS_PORTAL_ORDER_SOURCE',
  SEARCH_TERM = 'SEARCH_TERM',
  WAREHOUSE = 'WAREHOUSE',
  ZONE_STATE = 'ZONE_STATE',
  POPULAR_SEARCH_TERM = 'POPULAR_SEARCH_TERM',
  REGION = 'REGION',
  GUEST_USER = 'GUEST_USER',
  PLATFORM = 'PLATFORM',
}

export enum GroupBys {
  DATE_PT = 'DATE_PT',
  WEEK_SAT_PT = 'WEEK_SAT_PT',
  WEEK_SUN_PT = 'WEEK_SUN_PT',
  WEEK_MON_PT = 'WEEK_MON_PT',
  MONTH_PT = 'MONTH_PT',
  INSIGHTS_PORTAL_ORDER_SOURCE = 'INSIGHTS_PORTAL_ORDER_SOURCE',
  SEARCH_TERM = 'SEARCH_TERM',
}
