import {
  Button,
  Modal,
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  NotificationLevel,
  notify,
  spacing,
  Text,
} from '@retailer-platform/shared-components'
import React from 'react'
import faker from 'faker'
import styled from '@emotion/styled'
import { useDomainMessages } from '../../../utils/domain/intl'
import { useRunManualStepTestMutation } from '../../../api'

interface Props {
  onClose: () => void
  stepVersionId: string
}

const StyledModalBody = styled(ModalBody)({
  gap: `${spacing.X8}`,
})

const PipelineRunManualTestModal: React.FC<Props> = ({ onClose, stepVersionId }) => {
  const i18n = useDomainMessages({
    submit: 'catalogAdminDomain.attributeManagement.runTests.modal.button.submit',
    cancel: 'catalogAdminDomain.attributeManagement.runTests.modal.button.cancel',
    title: 'catalogAdminDomain.attributeManagement.runManualTest.modal.pipeline.title',
    description: 'catalogAdminDomain.attributeManagement.runManualTest.modal.pipeline.description',
  })

  const [triggerManualTest, { loading }] = useRunManualStepTestMutation({
    variables: {
      clientId: 'catalog_camp_ipp',
      idempotencyKey: faker.random.uuid(),
      stepVersionId: stepVersionId,
    },
    onError: e => {
      notify({
        level: NotificationLevel.Error,
        contents: `Error running manual test: ${e.message}`,
      })
    },
    onCompleted: () => {
      window.location.reload()
      onClose()
    },
  })
  return (
    <Modal isOpen={true} hideCloseButton={true}>
      <ModalContainer>
        <ModalHeader>{i18n.title}</ModalHeader>
        <StyledModalBody>
          <Text size="medium">{i18n.description}</Text>
        </StyledModalBody>
        <ModalFooter>
          <Button variant={'primary'} disabled={loading} onClick={() => triggerManualTest()}>
            {i18n.submit}
          </Button>
          <Button variant={'secondary'} onClick={() => onClose()}>
            {i18n.cancel}
          </Button>
        </ModalFooter>
      </ModalContainer>
    </Modal>
  )
}

export default PipelineRunManualTestModal
