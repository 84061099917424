export const EN_US = {
  'merchandising.forms.discard': 'Discard',
  'merchandising.forms.save': 'Save',
  'merchandising.common.error': 'Encountered an error.',
  'merchandising.common.confirm': 'Confirm',
  'merchandising.common.cancel': 'Cancel',
  'merchandising.common.is': 'is',
  'merchandising.common.browse': 'Browse',
  'merchandising.button.clear': 'Clear',

  'merchandising.form.input.color.invalidValue':
    'Value is not a valid color. Please input a color using hexidecimal notation, eg. #FFFFFF',
  'merchandising.title': 'Merchandising',
  'merchandising.hero-banners.title': 'Promotional banners',
  'merchandising.hero-banners.new': 'New Hero Banner',
  'merchandising.hero-banners.form.discard.confirm.title': 'Discard Changes',
  'merchandising.hero-banners.form.discard.confirm.body':
    'Are you sure you want to discard your changes?',
  'merchandising.hero-banners.platform.web': 'web',
  'merchandising.hero-banners.platform.mobile': 'mobile web',
  'merchandising.hero-banners.platform.ios': 'iOS',
  'merchandising.hero-banners.platform.android': 'Android',
  'merchandising.hero-banners.type.text': 'text',
  'merchandising.hero-banners.type.image': 'image',
  'merchandising.hero-banners.status.active': 'active',
  'merchandising.hero-banners.status.active.definition':
    'Banner start or end date falls within an active date & time range.',
  'merchandising.hero-banners.status.inactive': 'inactive',
  'merchandising.hero-banners.status.inactive.definition':
    "Banner start and end date doesn't fall within active date & time range.",
  'merchandising.hero-banners.status.live': 'live',
  'merchandising.hero-banners.status.live.definition': 'Currently shown on homepage.',
  'merchandising.hero-banners.field.empty.value': 'n/a',
  'merchandising.hero-banners.filters.search.placeholder': 'Search',
  'merchandising.hero-banners.filters.type.placeholder': 'Type',
  'merchandising.hero-banners.filters.status.placeholder': 'Status',
  'merchandising.hero-banners.filters.platform.placeholder': 'Device',
  'merchandising.hero-banners.columns.date.header': 'Date',
  'merchandising.hero-banners.columns.name.header': 'Banner name',
  'merchandising.hero-banners.columns.platform.header': 'Device',
  'merchandising.hero-banners.columns.status.header': 'Status',
  'merchandising.hero-banners.columns.preview.header': 'Preview',
  'merchandising.hero-banners.details.button.edit': 'Edit',
  'merchandising.hero-banners.details.button.delete': 'delete',
  'merchandising.hero-banners.details.color.label': 'color',
  'merchandising.hero-banners.details.deleteModal.title': 'Delete Placement?',
  'merchandising.hero-banners.details.deleteModal.body':
    'Are you sure you want to permanently delete {name} from your promotional banners?',
  'merchandising.hero-banners.details.deleteModal.checkboxLabel':
    'Yes, I want to delete this placement',
  'merchandising.hero-banners.details.deleteModal.error':
    'Could not delete placement, please try again later.',
  'merchandising.hero-banners.form.validation.default': "This field can't be blank.",
  'merchandising.hero-banners.form.validation.required': "{label} can't be blank.",
  'merchandising.hero-banners.section.tooltip.trigger': "What's this?",
  'merchandising.hero-banners.section.detail.title': 'Banner Detail',
  'merchandising.hero-banners.section.detail.field.placementName.label': 'Placement name',
  'merchandising.hero-banners.section.detail.field.dates.label': 'Active dates',
  'merchandising.hero-banners.section.detail.field.dates.checkbox': 'No end date',
  'merchandising.hero-banners.section.detail.field.status.label': 'Status',
  'merchandising.hero-banners.section.detail.field.timezone.label': 'Timezone',
  'merchandising.hero-banners.section.detail.liveWarning':
    'The banner {name} is currently active with the dates {dates}',
  'merchandising.hero-banners.section.detail.footnote':
    'Only 1 banner will be displayed at a time. If multiple banners are scheduled within the same date range the most recently created banner will be displayed.',
  'merchandising.hero-banners.section.location.title': 'Device',
  'merchandising.hero-banners.section.location.field.location.label': 'Device',
  'merchandising.hero-banners.section.location.tooltip':
    'Refers to where the banner shows up. Selecting Web allows banners to show up on web browsers and tablets. Selecting Mobile makes the banner visible in the app and mobile web.',
  'merchandising.hero-banners.section.link-destination.title': 'Banner Destination',
  'merchandising.hero-banners.section.link-destination.tooltip':
    'Where banners direct customers. Banners can link out to one of four locations including Items (single products), Collection (a group of products), Departments, or an External URL.',
  'merchandising.hero-banners.section.link-destination.input.destinationType.label':
    'Banner Destination',
  'merchandising.hero-banners.section.link-destination.input.destinationType.option.none': 'None',
  'merchandising.hero-banners.section.link-destination.input.destinationType.option.external':
    'URL',
  'merchandising.hero-banners.section.link-destination.input.destinationType.option.department':
    'Department',
  'merchandising.hero-banners.section.link-destination.input.destinationType.option.collection':
    'Collection',
  'merchandising.hero-banners.section.link-destination.input.destinationType.option.product':
    'Product',
  'merchandising.hero-banners.section.link-destination.input.destinationTarget.departments.error':
    'Unable to retrieve possible departments at this time.',
  'merchandising.hero-banners.section.link-destination.input.destinationTarget.collections.error':
    'Unable to retrieve possible collections at this time.',
  'merchandising.hero-banners.section.link-destination.input.destinationTarget.products.error':
    'Unable to retrieve possible products at this time.',
  'merchandising.hero-banners.section.link-destination.input.destinationTarget.department':
    'Select Department',
  'merchandising.hero-banners.section.link-destination.input.destinationTarget.collection':
    'Select Collection',
  'merchandising.hero-banners.section.link-destination.input.destinationTarget.product':
    'Search for Product',
  'merchandising.hero-banners.section.link-destination.input.destinationTarget.product.empty':
    'Begin typing',
  'merchandising.hero-banners.section.link-destination.input.destinationTarget.external':
    'Enter URL',
  'merchandising.hero-banners.section.link-destination.input.destinationTarget.external.description':
    'URL links will open in a new browser tab on desktop and an in-app browser window on mobile apps.',
  'merchandising.hero-banners.section.link-destination.testLink': 'Test Link',
  'merchandising.hero-banners.section.link-destination.field.destinationType.label':
    'Destination Type',
  'merchandising.hero-banners.section.content.title': 'Placement Content',
  'merchandising.hero-banners.section.content.select.primary': 'Primary',
  'merchandising.hero-banners.section.content.heading.label': 'Heading',
  'merchandising.hero-banners.section.content.heading.color.label': 'Heading color',
  'merchandising.hero-banners.section.content.subheading.label': 'Subheading',
  'merchandising.hero-banners.section.content.subheading.color.label': 'Subheading color',
  'merchandising.hero-banners.section.content.description.label': 'Description',
  'merchandising.hero-banners.section.content.description.footnote':
    'Note that Description text is hidden on mobile sized screens.',
  'merchandising.hero-banners.section.content.description.color.label': 'Description color',
  'merchandising.hero-banners.section.content.cta.label': 'CTA',
  'merchandising.hero-banners.section.content.cta.color.label': 'CTA color',
  'merchandising.hero-banners.section.content.text.tooltip':
    'Use this section to upload banner assets. Customize text by adding in a headline, brief description and a call to action. Note that the description text is hidden on mobile sized screens.',
  'merchandising.hero-banners.section.content.text.image': 'Banner Image (2000 x 2000)',
  'merchandising.hero-banners.section.content.image.tooltip':
    'Upload assets for your banner in this section.',
  'merchandising.hero-banners.section.content.image.large': 'Large Banner (4368 x 1222)',
  'merchandising.hero-banners.section.content.image.small': 'Small Banner (2732 x 1036)',
  'merchandising.hero-banners.section.content.image.acceptedFormat': '.png, .jpg images only',
  'merchandising.hero-banners.section.content.image.uploaded': 'Uploaded',
  'merchandising.hero-banners.section.content.image.remove': 'Remove Image',
  'merchandising.hero-banners.section.content.image.altTextLabel': 'Image Alt Text',
  'merchandising.hero-banners.section.content.image.altTextDescription':
    "Alt text appears in place of an image on a webpage if the image fails to load on a customer's screen. This text also helps screen-reading tools describe images to visually impaired customers.",
  'merchandising.hero-banners.section.content.image.preview': 'Preview Placement',
  'merchandising.hero-banners.section.content.background.color.label': 'Background color',
  'merchandising.hero-banners.section.content.field.image.label': 'Storefront banner image',
  'merchandising.hero-banners.section.content.field.altText.label':
    'Storefront Banner Image Alt Text',
  'merchandising.hero-banners.section.content.field.imageLarge.label':
    'Large Banner (Desktop and Tablet)',
  'merchandising.hero-banners.section.content.field.imageSmall.label':
    'Small Banner (Mobile Web and Mobile App)',
  'merchandising.hero-banners.section.content.locale.description':
    'Banner configuration of all supported languages is required.',
  'merchandising.hero-banners.section.content.locale.label': 'Locale',
  'merchandising.hero-banners.section.preview.title': 'Banner Preview',
  'merchandising.hero-banners.page.create.typeSelect.title': 'Select Banner Type',
  'merchandising.hero-banners.page.create.title': 'Create New Placement',
  'merchandising.hero-banners.page.create.selector.image.title': 'Image Banner',
  'merchandising.hero-banners.page.create.selector.image.description':
    'An Image Only banner is a .jpeg or .png that ocupies the entire area of the banner. This banner type requires any creative imagery and text to be created outside of the dashboard using any prefered 3rd party image editor.',
  'merchandising.hero-banners.page.create.selector.text.title': 'Image Banner with Custom Text',
  'merchandising.hero-banners.page.create.selector.text.description':
    'A Text and Image banner has configurable text fields and a seperate image. This banner dynamicaly changes based on the screen size that it is viewed on. Text elements are configured in the dashboard and any creative imagery needs to be created outside of the dashboard using any prefered 3rd party image editor.',
  'merchandising.hero-banners.page.create.continueButton': 'Continue',
  'merchandising.hero-banners.page.details.title': 'Details',
  'merchandising.hero-banners.page.update.title': 'Edit',
} as const

export default EN_US
