import { type FunctionComponent, useState } from 'react'
import { PaginatedGrid, Badge, Text } from '@retailer-platform/shared-components'
import { IconButton } from '@instacart/ids-tooling'
import { SearchIcon } from '@instacart/ids-core'
import { type AttributeManagementStepVersion } from '../../../../types/attributeManagement.types.ts'
import { useDomainMessages } from '../../../../utils/domain/intl.ts'
import { ChangesDeltaModal } from './ChangesDeltaModal.tsx'
import ChangesGrid from './ChangesGrid.tsx'

interface Props {
  versions: AttributeManagementStepVersion[]
}

export const AttributeManagementDetailsChangesTable: FunctionComponent<Props> = ({ versions }) => {
  const i18n = useDomainMessages({
    version: 'catalogAdminDomain.mdPlatformDetails.table.version',
    status: 'catalogAdminDomain.mdPlatformDetails.table.status',
    createdBy: 'catalogAdminDomain.mdPlatformDetails.table.createdBy',
    createdReason: 'catalogAdminDomain.mdPlatformDetails.table.createdReason',
    changes: 'catalogAdminDomain.mdPlatformDetails.table.changes',
    actions: 'catalogAdminDomain.mdPlatformDetails.table.actions',
    makeLive: 'catalogAdminDomain.attributeManagement.changes.button.makeLive',
    tombstone: 'catalogAdminDomain.attributeManagement.changes.button.tombstone',
    viewChanges: 'catalogAdminDomain.attributeManagement.changes.modal.title',
  })
  const [showViewChangesModal, setShowViewChangesModal] = useState<boolean>(false)
  const [prevVersion, setPrevVersion] = useState<AttributeManagementStepVersion | null>(null)
  const [version, setVersion] = useState<AttributeManagementStepVersion>(versions[0])

  const action = (index: number, stepVersionId: string) => (
    <IconButton
      icon={SearchIcon}
      accessibleLabel={i18n.viewChanges}
      onClick={() => {
        setShowViewChangesModal(true)
        setPrevVersion(index > 0 ? versions[index - 1] : null)
        setVersion(versions[index])
      }}
    />
  )

  return (
    <>
      <ChangesGrid versions={versions} actions={[action]} loading={false} />
      {showViewChangesModal && (
        <ChangesDeltaModal
          prevVersion={prevVersion}
          version={version}
          onClose={() => setShowViewChangesModal(false)}
        />
      )}
    </>
  )
}
