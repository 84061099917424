import React from 'react'
import {
  AttributeManagementCategoryEnum,
  type AttributeManagementStepVersion,
  AttributeManagementTypeEnum,
} from '../../../types/attributeManagement.types'
import LLMPromptTemplateRunManualTestModal from './LLMPromptTemplateRunManualTestModal'
import SnowflakeQueryInputRunManualTestModal from './SnowflakeQueryInputRunManualTestModal'
import PipelineRunManualTestModal from './PipelineRunManualTestModal'

interface RunManualTestModalProps {
  type: AttributeManagementTypeEnum
  onClose: () => void
  stepVersion: AttributeManagementStepVersion
}

export const RunManualTestModal: React.FC<RunManualTestModalProps> = ({
  onClose,
  stepVersion,
  type,
}) => {
  if (type === AttributeManagementTypeEnum.LLMPromptTemplate) {
    return (
      <LLMPromptTemplateRunManualTestModal
        onClose={onClose}
        stepVersionId={stepVersion.id}
        expectedFields={stepVersion.attributes.properties.map(
          (property: any) => property.propertyName
        )}
      />
    )
  }
  if (type === AttributeManagementTypeEnum.SnowflakeQueryInput) {
    return (
      <SnowflakeQueryInputRunManualTestModal onClose={onClose} stepVersionId={stepVersion.id} />
    )
  }
  if (type === AttributeManagementTypeEnum.Pipeline) {
    return <PipelineRunManualTestModal onClose={onClose} stepVersionId={stepVersion.id} />
  }

  return <div>FAIL</div>
}

export default RunManualTestModal
