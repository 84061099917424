import { useEffect, useState } from 'react'

export const useUrlSearchParams = (key: string) => {
  const [value, setValue] = useState<string>('')

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const value = urlParams.get(key)

    setValue(value)
  }, [key])

  return {
    value,
  }
}
