import { type FunctionComponent, useMemo, useRef } from 'react'
import { Breadcrumb, Breadcrumbs, PageHeader } from '@retailer-platform/shared-components'
import { DocumentTitle, LoadingPage } from '@retailer-platform/dashboard/gin-and-tonic'
import { useUrlSearchParams } from '@retailer-platform/dashboard/utils'
import { startCase } from 'lodash'
import faker from 'faker'
import { useDomainMessages } from '../../utils/domain/intl.ts'
import { Page } from '../../utils/common.styles.tsx'
import { useDomainScopedPathname } from '../../utils/domain/routing.ts'
import AttributeManagementEdit from '../../components/attribute-management-edit/AttributeManagementEdit.tsx'
import {
  AttributeManagementCategoryEnum,
  type AttributeManagementStep,
  AttributeManagementTypeEnum,
  GraphQLEnumToAttributeManagementCategoryEnum,
} from '../../types/attributeManagement.types.ts'
import {
  Instacart_Catalog_Catflow_V1_StepCategory,
  useGetStepVersionQuery,
} from '../../api/index.ts'
const CatalogAdminAttributeManagementEditPage: FunctionComponent = () => {
  const { value: stepId } = useUrlSearchParams('stepId')
  const platformPath = useDomainScopedPathname('app-admin-catalog-admin-attribute-management')
  const editPath = useDomainScopedPathname('app-admin-catalog-admin-attribute-management-edit', {
    stepId: stepId,
  })

  const fetchIdem = useRef(faker.random.uuid())

  const { data, loading, error, called } = useGetStepVersionQuery({
    variables: {
      clientId: 'catalog_camp_ipp',
      idempotencyKey: fetchIdem.current,
      stepId: stepId,
    },
  })

  const ready = !loading && !error && called
  const step: AttributeManagementStep = useMemo(
    () => ({
      id: data?.instacart_catalog_catflow_v1_CatflowAPI_GetStepVersion?.stepVersion?.stepId,
      description:
        data?.instacart_catalog_catflow_v1_CatflowAPI_GetStepVersion?.stepVersion?.step
          ?.description || '',
      name:
        data?.instacart_catalog_catflow_v1_CatflowAPI_GetStepVersion?.stepVersion?.step?.name || '',
      owner:
        data?.instacart_catalog_catflow_v1_CatflowAPI_GetStepVersion?.stepVersion?.step?.owner ||
        '',
      category:
        GraphQLEnumToAttributeManagementCategoryEnum[
          data?.instacart_catalog_catflow_v1_CatflowAPI_GetStepVersion?.stepVersion?.step
            ?.stepCategory || Instacart_Catalog_Catflow_V1_StepCategory.Unknown
        ],
      type: AttributeManagementTypeEnum[
        data?.instacart_catalog_catflow_v1_CatflowAPI_GetStepVersion?.stepVersion?.step
          ?.type as keyof typeof AttributeManagementTypeEnum
      ],
      versions: [
        {
          id:
            data?.instacart_catalog_catflow_v1_CatflowAPI_GetStepVersion?.stepVersion?.stepId || '',
          createdWhy:
            data?.instacart_catalog_catflow_v1_CatflowAPI_GetStepVersion?.stepVersion?.createdWhy ||
            '',
          createdAt:
            data?.instacart_catalog_catflow_v1_CatflowAPI_GetStepVersion?.stepVersion?.createdAt ||
            '',
          version:
            data?.instacart_catalog_catflow_v1_CatflowAPI_GetStepVersion?.stepVersion?.version ||
            '',
          isLive: false,
          state:
            data?.instacart_catalog_catflow_v1_CatflowAPI_GetStepVersion?.stepVersion?.state || '',
          attributes:
            data?.instacart_catalog_catflow_v1_CatflowAPI_GetStepVersion?.stepVersion?.attributes,
          stepId:
            data?.instacart_catalog_catflow_v1_CatflowAPI_GetStepVersion?.stepVersion?.stepId || '',
        },
      ],
    }),
    [data]
  )

  const i18n = useDomainMessages({
    landingTitle: 'catalogAdminDomain.attributeManagement.nav.title',
    cancel: 'catalogAdminDomain.attributeManagement.modal.cancel',
    reset: 'catalogAdminDomain.attributeManagement.details.button.reset',
    createNewVersion: 'catalogAdminDomain.attributeManagement.modal.createNewVersion',
    reason: 'catalogAdminDomain.attributeManagement.changes.modal.reason',
    reasonPlaceholder: 'catalogAdminDomain.attributeManagement.changes.modal.reasonPlaceholder',
    description: 'catalogAdminDomain.attributeManagement.modal.description',
    owner: 'catalogAdminDomain.attributeManagement.modal.owner',
    ownerSubtitle: 'catalogAdminDomain.attributeManagement.modal.owner.subtitle',
    topSectionHeader: 'catalogAdminDomain.attributeManagement.topSectionHeader',
    bottomSectionHeader: 'catalogAdminDomain.attributeManagement.bottomSectionHeader',
  })

  return (
    <Page>
      <DocumentTitle titleId="catalogAdminDomain.attributeManagement.nav.title" />
      <PageHeader
        addBottomPadding
        title={
          <Breadcrumbs>
            <Breadcrumb path={platformPath}>{i18n.landingTitle}</Breadcrumb>
            <Breadcrumb path={editPath}>{step?.name || ''}</Breadcrumb>
          </Breadcrumbs>
        }
      />

      {ready ? <AttributeManagementEdit step={step} /> : <LoadingPage />}
    </Page>
  )
}

export default CatalogAdminAttributeManagementEditPage
