import type { PropsWithChildren, FunctionComponent } from 'react'
import { lazy, useMemo } from 'react'
import { Redirect } from 'react-router'
import { URL_STORE_GROUPS } from '../../../utils/routing/routes'
import RetailerSwitch from '../../components/RetailerSwitch'
import RetailerRoute from '../../components/RetailerRoute'
import { useCurrentAccountDetails } from '../../../api/current-account/currentAccount.hooks'

const StoreGroupsList = lazy(() => import('./components/list/StoreGroupsList'))
const StoreGroupsAdd = lazy(() => import('./components/add/StoreGroupsAdd'))
const StoreGroupsEdit = lazy(() => import('./components/edit/StoreGroupsEdit'))

export const StoreGroupsSection: FunctionComponent<PropsWithChildren<unknown>> = () => {
  const { account } = useCurrentAccountDetails()

  const multiBannerSelectEnabled = useMemo(
    () =>
      account?.featureToggles?.find(
        featureToggle => featureToggle.name === 'rt_store_groups_multi_banner_select'
      ),
    [account?.featureToggles]
  )

  return (
    <RetailerSwitch>
      <RetailerRoute
        exact
        route="store-groups"
        component={StoreGroupsList}
        scopePicker={{
          titles: ['settings.storeGroups.title'],
          retailerSelectionOptional: multiBannerSelectEnabled,
          hiddenScopePicker: true,
        }}
      />
      <RetailerRoute exact route="store-groups-add" component={StoreGroupsAdd} />
      <RetailerRoute exact route="store-groups-edit" component={StoreGroupsEdit} />
      <Redirect to={URL_STORE_GROUPS} />
    </RetailerSwitch>
  )
}
