import { type ItemGroup } from '../NavMenuItemGroup'
import { type SpotlightAction } from '../NavSearchButton'

export const formCategoriesIntoItemGroups = (
  searchActions: SpotlightAction[],
  onClick: () => void
) => {
  const header = searchActions
    .map(category => category.group)
    .filter((value, index, self) => self.indexOf(value) === index)

  const categoryItemsGroups: ItemGroup[] = [
    ...header.map(categoryL1 => ({
      groupHeaderText: categoryL1,
      items: searchActions
        .filter(categoryL2 => categoryL2.group === categoryL1)
        .map(action => ({
          label: action.label,
          route: action.route || '',
          onClick: action.onClick,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    })),
  ].sort((a, b) => a.groupHeaderText.localeCompare(b.groupHeaderText))

  categoryItemsGroups.forEach(group => {
    group.items.forEach(item => {
      const originalOnClick = item.onClick
      item.onClick = () => {
        onClick()
        originalOnClick?.()
      }
    })
  })

  return categoryItemsGroups
}

export const getTrackEventOpenSearchBarData = () => ({
  id: 'navV2.searchSpotlight.open',
  description: 'User is opening the search bar',
})

export const getTrackEventCloseSearchBarData = (searchValue: string) => ({
  id: 'navV2.searchSpotlight.close',
  description: 'User is closing the search bar',
  data: {
    searchValue: searchValue,
  },
})

export const getNavigateToSearchResultData = (
  searchValue: string,
  label: string,
  route: string,
  navigateBy: string
) => ({
  id: 'navV2.searchSpotlight.navigate',
  description: 'User is navigating to a search result',
  data: {
    searchValue: searchValue,
    label: label,
    route: route,
    navigateBy: navigateBy,
  },
})

export const getTrackEventNothingFoundData = (searchValue: string) => ({
  id: 'navV2.searchSpotlight.nothingFound',
  description: 'User is searching for a non-existent item',
  data: {
    searchValue: searchValue,
  },
})

export const getTrackEventUserSearchedData = (searchValue: string) => ({
  id: 'navV2.searchSpotlight.userSearched',
  description: 'User is searching for something',
  data: {
    searchValue: searchValue,
  },
})
