import styled from '@emotion/styled'
import {
  Button,
  Text,
  TextArea,
  spacing,
  colors,
  Badge,
  borderRadius,
  fontWeight,
} from '@retailer-platform/shared-components'
import { IconButton } from '@instacart/ids-customers'
import { fontSize } from '@retailer-platform/dashboard/gin-and-tonic'
import { PropertySection } from '../md-platform-list/components/MdPlatformListTableRow.tsx'

export const TextInput = styled(TextArea)<{ changed?: boolean; error?: boolean }>(
  ({ error, changed }) => ({
    height: '100%',
    boxShadow: 'none',
    background: changed ? colors.LOYALTY.LIGHT : error ? colors.DETRIMENTAL.LIGHT : 'inherit',
    border: error ? `2px solid ${colors.DETRIMENTAL.REGULAR}` : `1px solid ${colors.GRAYSCALE.X30}`,
    padding: spacing.X12,
    fontSize: fontSize.X15,
    color: colors.GRAYSCALE.X70,
    ':focus': {
      border: `1px solid ${colors.GRAYSCALE.X30}`,
      outline: `2px solid ${colors.GRAYSCALE.X70}`,
      boxShadow: 'none',
      outlineOffset: '1px',
    },
  })
)

export const Input = styled.input<{ changed?: boolean; error?: boolean }>(({ error, changed }) => ({
  boxShadow: 'none',
  border: error ? `2px solid ${colors.DETRIMENTAL.REGULAR}` : `1px solid ${colors.GRAYSCALE.X30}`,
  background: changed
    ? colors.LOYALTY.LIGHT
    : error
    ? colors.DETRIMENTAL.LIGHT
    : colors.GRAYSCALE.X0,
  padding: spacing.X12,
  fontSize: fontSize.X15,
  color: colors.GRAYSCALE.X70,
  borderRadius: borderRadius.X8,
  ':focus': {
    outline: `2px solid ${colors.GRAYSCALE.X70}`,
    outlineOffset: '1px',
  },
  ':disabled': {
    background: colors.GRAYSCALE.X10,
  },
}))

export const ButtonRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
  width: '100%',
  alignItems: 'center',
})

export const TooltipContainer = styled.div({
  margin: spacing.X0,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'flex-start',
  width: '100%',
  gap: spacing.X4,
})

export const TooltipContainerNotFullWidth = styled.div({
  margin: spacing.X0,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'flex-start',
  gap: spacing.X4,
})

export const Row = styled.div({
  margin: spacing.X0,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'flex-end',
  gap: spacing.X4,
})

export const EmptyMessage = styled(Text)({
  width: '250px',
  margin: `${spacing.X8}`,
  textAlign: 'center',
  paddingLeft: '50px',
})

export const PropertyInput = styled(Input)({
  width: '70%',
  height: '40px',
})

export const PropertyInputButton = styled(Button)({
  marginBottom: '0px',
})

export const PropertyContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: spacing.X16,
  width: '100%',
})

export const PropertyTitle = styled.span({
  verticalAlign: 'middle',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: spacing.X4,
})

export const Property = styled.div({
  padding: spacing.X16,
  verticalAlign: 'middle',
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.X12,
  lineHeight: spacing.X20,
  wordBreak: 'break-word',
})

export const PropertyRow = styled.div({
  padding: spacing.X16,
  verticalAlign: 'middle',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: spacing.X12,
  lineHeight: spacing.X20,
  wordBreak: 'break-word',
  justifyContent: 'space-between',
})

export const Container = styled.div({
  display: 'flex',
  alignItems: 'baseline',
  flexDirection: 'column',
  gap: `${spacing.X12}`,
  padding: `${spacing.X20}`,
  paddingTop: `${spacing.X0}`,
  width: '100%',
})

export const Grid = styled.div({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr 1fr 1fr',
  rowGap: spacing.X12,
})

export const GridItem = styled.div({
  borderBottom: `1px solid ${colors.GRAYSCALE.X30}`,
  padding: spacing.X8,
})

export const GridHeader = styled(GridItem)({
  fontWeight: 'bold',
})

export const SectionHeader = styled.div({
  display: 'flex',
  fontSize: fontSize.X18,
  fontWeight: fontWeight.SEMIBOLD,
  color: colors.GRAYSCALE.X60,
  paddingLeft: spacing.X16,
  paddingBottom: spacing.X8,
  paddingTop: spacing.X8,
})

export const StateBadge = styled(Badge)<{ state: string }>(({ state }) => {
  const colorStates = {
    live: {
      backgroundColor: colors.SUCCESS.LIGHT,
      color: colors.SUCCESS.REGULAR,
    },
    testing: {
      backgroundColor: colors.GRAYSCALE.X20,
      color: colors.GRAYSCALE.X60,
    },
    collected: {
      backgroundColor: colors.GRAYSCALE.X20,
      color: colors.GRAYSCALE.X60,
    },
  }
  return {
    ...colorStates[state],
    padding: spacing.X12,
    fontSize: fontSize.X15,
    fontWeight: fontWeight.SEMIBOLD,
    whiteSpace: 'nowrap',
  }
})

export const PropertySectionLeft = styled(PropertySection)({ width: '40%' })
export const PropertySectionRight = styled(PropertySection)({ width: '60%' })

export const IconGridItem = styled(GridItem)({ marginTop: '0px' })
export const IconButtonContainer = styled(IconButton)({ height: '0px' })
