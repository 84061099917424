import {
  type DashRouteType,
  URL_APP_ADMIN,
  URL_RETAILER_SCOPED,
  URL_SITE_SCOPED,
  URL_PARTNER_SCOPED,
} from '@retailer-platform/dashboard/routing'

const URL_RETAILER_FUNDED_MARKETING = `${URL_RETAILER_SCOPED}/retailer-funded-marketing`
const URL_RETAILER_FUNDED_MARKETING_SITE_SCOPED = `${URL_RETAILER_FUNDED_MARKETING}${URL_SITE_SCOPED}`
const URL_RETAILER_FUNDED_MARKETING_SEGMENTATION = `${URL_RETAILER_FUNDED_MARKETING}${URL_SITE_SCOPED}/segmentation`
const URL_RETAILER_FUNDED_MARKETING_SITE_SCOPED_LIST = `${URL_RETAILER_FUNDED_MARKETING_SITE_SCOPED}/list`
export const URL_RETAILER_FUNDED_MARKETING_SITE_SCOPED_EDIT = `${URL_RETAILER_FUNDED_MARKETING_SITE_SCOPED}/:campaignId/edit`
const URL_RETAILER_FUNDED_MARKETING_SITE_SCOPED_CAMPAIGN_TEMPLATES = `${URL_RETAILER_FUNDED_MARKETING_SITE_SCOPED}/campaign-templates`
const URL_RETAILER_FUNDED_MARKETING_SEGMENTATION_LIST = `${URL_RETAILER_FUNDED_MARKETING_SEGMENTATION}/list`
const URL_RETAILER_FUNDED_MARKETING_SEGMENTATION_VIEW = `${URL_RETAILER_FUNDED_MARKETING_SEGMENTATION}/view/:id`
const URL_RETAILER_FUNDED_MARKETING_SEGMENT_DEFINITION_TAB = `${URL_RETAILER_FUNDED_MARKETING_SEGMENTATION}/:id/definition`

const URL_RETAILER_FUNDED_MARKETING_CREATE_PAGE = `${URL_RETAILER_FUNDED_MARKETING}/CreatePage`
const URL_RETAILER_FUNDED_MARKETING_EDIT = `${URL_RETAILER_FUNDED_MARKETING}/edit`
const URL_RETAILER_FUNDED_MARKETING_VIEW = `${URL_RETAILER_FUNDED_MARKETING}/view`

// Admin Global Campaigns
const URL_ADMIN_GLOBAL_CAMPAIGNS = `${URL_APP_ADMIN}/global-campaigns`
const URL_ADMIN_GLOBAL_CAMPAIGNS_CAMPAIGN_MOMENTS = `${URL_ADMIN_GLOBAL_CAMPAIGNS}/campaign-moments`
const URL_ADMIN_GLOBAL_CAMPAIGNS_RETAILER_CAMPAIGNS = `${URL_ADMIN_GLOBAL_CAMPAIGNS}/retailer-campaigns`
const URL_ADMIN_GLOBAL_CAMPAIGNS_CAMPAIGN_MOMENTS_CREATE = `${URL_ADMIN_GLOBAL_CAMPAIGNS_CAMPAIGN_MOMENTS}/create`
const URL_ADMIN_GLOBAL_CAMPAIGNS_CAMPAIGN_MOMENTS_CREATE_SET_UP = `${URL_ADMIN_GLOBAL_CAMPAIGNS_CAMPAIGN_MOMENTS_CREATE}/campaign-moment-set-up`
const URL_ADMIN_GLOBAL_CAMPAIGNS_CAMPAIGN_MOMENTS_VIEW = `${URL_ADMIN_GLOBAL_CAMPAIGNS_CAMPAIGN_MOMENTS}/:momentId`
const URL_ADMIN_GLOBAL_CAMPAIGNS_CAMPAIGN_MOMENTS_VIEW_MOMENT = `${URL_ADMIN_GLOBAL_CAMPAIGNS_CAMPAIGN_MOMENTS_VIEW}/moment`
const URL_ADMIN_GLOBAL_CAMPAIGNS_CAMPAIGN_MOMENTS_VIEW_PARTICIPATING_RETAILERS = `${URL_ADMIN_GLOBAL_CAMPAIGNS_CAMPAIGN_MOMENTS_VIEW}/participating-retailers`
export const URL_ADMIN_GLOBAL_CAMPAIGNS_CAMPAIGN_MOMENTS_EDIT = `${URL_ADMIN_GLOBAL_CAMPAIGNS_CAMPAIGN_MOMENTS_VIEW}/edit`
const URL_ADMIN_GLOBAL_CAMPAIGNS_CAMPAIGN_MOMENTS_EDIT_MOMENT = `${URL_ADMIN_GLOBAL_CAMPAIGNS_CAMPAIGN_MOMENTS_EDIT}/moment`

// Admin Campaigns
const URL_ADMIN_CAMPAIGNS = `${URL_APP_ADMIN}/campaigns`
const URL_ADMIN_CAMPAIGNS_TEMPLATES = `${URL_ADMIN_CAMPAIGNS}/templates`
const URL_ADMIN_CAMPAIGNS_LIST = `${URL_ADMIN_CAMPAIGNS}/list`
const URL_ADMIN_CAMPAIGNS_CREATE = `${URL_ADMIN_CAMPAIGNS}/create`
const URL_ADMIN_CAMPAIGNS_GET = `${URL_ADMIN_CAMPAIGNS}/:campaignId`
const URL_ADMIN_CAMPAIGNS_EDIT = `${URL_ADMIN_CAMPAIGNS}/:campaignId/edit`
const URL_ADMIN_CREATE_SEGMENT = `${URL_ADMIN_CAMPAIGNS}/segments/create`

// Admin Marketing AI Agent Routes
const URL_ADMIN_MARKETING_AGENT = `${URL_APP_ADMIN}/marketing-agent`
const URL_ADMIN_MARKETING_AGENT_CALENDAR = `${URL_ADMIN_MARKETING_AGENT}/calendar`
const URL_ADMIN_MARKETING_AGENT_CAMPAIGNS_VIEW = `${URL_ADMIN_MARKETING_AGENT}/marketing-agent-campaigns/:marketingAgentCampaignId`

// V2 ROUTES
const CAMPAIGNS_V2 = `${URL_RETAILER_SCOPED}/campaigns${URL_SITE_SCOPED}`
const CAMPAIGNS_V2_VIEW = `${CAMPAIGNS_V2}/:campaignId`
const CAMPAIGNS_V2_CREATE = `${CAMPAIGNS_V2}/create/:templateId/:momentId?`
export const CAMPAIGNS_V2_EDIT = `${CAMPAIGNS_V2}/:campaignId/edit`

// Wholesaler campaign routes
const CAMPAIGNS_PARTNER_SCOPED = `${URL_PARTNER_SCOPED}/campaigns`
const CAMPAIGNS_MARKETPLACE_LIST = `${CAMPAIGNS_PARTNER_SCOPED}/marketplace/list`
const CAMPAIGNS_STOREFRONT_PRO_LIST = `${CAMPAIGNS_PARTNER_SCOPED}/storefront-pro/list`
const CAMPAIGNS_CREATE_MP = `${CAMPAIGNS_PARTNER_SCOPED}/marketplace/create/:templateId/:momentId?`
const CAMPAIGNS_CREATE_SFX = `${CAMPAIGNS_PARTNER_SCOPED}/storefront-pro/create/:templateId/:momentId?`
const CAMPAIGNS_VIEW = `${CAMPAIGNS_PARTNER_SCOPED}/:campaignId`
const CAMPAIGNS_EDIT = `${CAMPAIGNS_PARTNER_SCOPED}/:campaignId/edit`
// @hygen:inject scoped-urls

// Wholesaler segment routes
const SEGMENT_PARTNER_SCOPED = `${URL_PARTNER_SCOPED}/segmentation`
const SEGMENT_MARKETPLACE_LIST = `${SEGMENT_PARTNER_SCOPED}/marketplace/list`
const SEGMENT_STOREFRONT_PRO_LIST = `${SEGMENT_PARTNER_SCOPED}/storefront-pro/list`

const URL_ADMIN_RETAILER_FUNDED_MARKETING = `${URL_APP_ADMIN}${URL_RETAILER_SCOPED}/retailer-funded-marketing${URL_SITE_SCOPED}`
const URL_ADMIN_RETAILER_FUNDED_MARKETING_LIST = `${URL_ADMIN_RETAILER_FUNDED_MARKETING}/list`
const URL_ADMIN_RETAILER_FUNDED_MARKETING_CAMPAIGN_TEMPLATES = `${URL_ADMIN_RETAILER_FUNDED_MARKETING}/campaign-templates`
// @hygen:inject admin-urls

// Add the new route
const URL_LIFECYCLE_CRM = `${URL_PARTNER_SCOPED}/lifecycle-crm`

export type DashRouteSet =
  | DashRouteType<'retailer-funded-marketing'>
  | DashRouteType<'app-admin-retailer-funded-marketing'>
  | DashRouteType<'retailer-funded-marketing-site-scoped-list'>
  | DashRouteType<'retailer-funded-marketing-site-scoped-campaign-templates'>
  | DashRouteType<'retailer-funded-marketing-site-scoped-list'>
  | DashRouteType<'retailer-funded-marketing-segmentation-list'>
  | DashRouteType<'retailer-funded-marketing-segmentation-view'>
  | DashRouteType<'retailer-funded-marketing-segment-definition-tab'>
  | DashRouteType<'app-admin-retailer-funded-marketing-list'>
  | DashRouteType<'app-admin-retailer-funded-marketing-campaign-templates'>
  | DashRouteType<'retailer-funded-marketing-CreatePage'>
  | DashRouteType<'retailer-funded-marketing-edit'>
  | DashRouteType<'retailer-funded-marketing-view'>
  | DashRouteType<'app-admin-global-campaigns'>
  | DashRouteType<'app-admin-global-campaigns-campaign-moments'>
  | DashRouteType<'app-admin-global-campaigns-retailer-campaigns'>
  | DashRouteType<'app-admin-global-campaigns-campaign-moments-create'>
  | DashRouteType<'app-admin-global-campaigns-campaign-moments-create-set-up'>
  | DashRouteType<'app-admin-global-campaigns-campaign-moments-view'>
  | DashRouteType<'app-admin-global-campaigns-campaign-moments-view-participating-retailers'>
  | DashRouteType<'app-admin-global-campaigns-campaign-moments-view-moment'>
  | DashRouteType<'app-admin-global-campaigns-campaign-moments-edit'>
  | DashRouteType<'app-admin-global-campaigns-campaign-moments-edit-moment'>
  | DashRouteType<'app-admin-campaigns'>
  | DashRouteType<'app-admin-campaigns-templates'>
  | DashRouteType<'app-admin-campaigns-list'>
  | DashRouteType<'app-admin-campaigns-create'>
  | DashRouteType<'app-admin-campaigns-get'>
  | DashRouteType<'app-admin-campaigns-edit'>
  // Marketing AI Agent Routes
  | DashRouteType<'app-admin-marketing-agent'>
  | DashRouteType<'app-admin-marketing-agent-calendar'>
  | DashRouteType<'app-admin-marketing-agent-campaigns-view'>
  // V2 ROUTES
  | DashRouteType<'campaigns'>
  | DashRouteType<'campaigns-create'>
  | DashRouteType<'campaigns-edit'>
  | DashRouteType<'campaigns-view'>
  | DashRouteType<'app-admin-create-segment'>
  // Wholesaler
  | DashRouteType<'campaigns-partner-scoped'>
  | DashRouteType<'campaigns-marketplace-list'>
  | DashRouteType<'campaigns-storefront-pro-list'>
  | DashRouteType<'partner-campaigns-create-mp'>
  | DashRouteType<'partner-campaigns-create-sfx'>
  | DashRouteType<'partner-campaigns-view'>
  | DashRouteType<'partner-campaigns-edit'>
  | DashRouteType<'segment-partner-scoped'>
  | DashRouteType<'segment-marketplace-list'>
  | DashRouteType<'segment-storefront-pro-list'>
  | DashRouteType<'campaigns-partner-scoped-lifecycle-crm'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'retailer-funded-marketing': URL_RETAILER_FUNDED_MARKETING_SITE_SCOPED,
  'app-admin-retailer-funded-marketing': URL_ADMIN_RETAILER_FUNDED_MARKETING,
  'retailer-funded-marketing-site-scoped-list': URL_RETAILER_FUNDED_MARKETING_SITE_SCOPED_LIST,
  'retailer-funded-marketing-site-scoped-campaign-templates':
    URL_RETAILER_FUNDED_MARKETING_SITE_SCOPED_CAMPAIGN_TEMPLATES,
  'retailer-funded-marketing-segmentation-list': URL_RETAILER_FUNDED_MARKETING_SEGMENTATION_LIST,
  'retailer-funded-marketing-segmentation-view': URL_RETAILER_FUNDED_MARKETING_SEGMENTATION_VIEW,
  'retailer-funded-marketing-segment-definition-tab':
    URL_RETAILER_FUNDED_MARKETING_SEGMENT_DEFINITION_TAB,
  'app-admin-retailer-funded-marketing-list': URL_ADMIN_RETAILER_FUNDED_MARKETING_LIST,
  'app-admin-retailer-funded-marketing-campaign-templates':
    URL_ADMIN_RETAILER_FUNDED_MARKETING_CAMPAIGN_TEMPLATES,
  'retailer-funded-marketing-CreatePage': URL_RETAILER_FUNDED_MARKETING_CREATE_PAGE,
  'retailer-funded-marketing-edit': URL_RETAILER_FUNDED_MARKETING_EDIT,
  'retailer-funded-marketing-view': URL_RETAILER_FUNDED_MARKETING_VIEW,
  'app-admin-global-campaigns': URL_ADMIN_GLOBAL_CAMPAIGNS,
  'app-admin-global-campaigns-campaign-moments': URL_ADMIN_GLOBAL_CAMPAIGNS_CAMPAIGN_MOMENTS,
  'app-admin-global-campaigns-retailer-campaigns': URL_ADMIN_GLOBAL_CAMPAIGNS_RETAILER_CAMPAIGNS,
  'app-admin-global-campaigns-campaign-moments-create':
    URL_ADMIN_GLOBAL_CAMPAIGNS_CAMPAIGN_MOMENTS_CREATE,
  'app-admin-global-campaigns-campaign-moments-create-set-up':
    URL_ADMIN_GLOBAL_CAMPAIGNS_CAMPAIGN_MOMENTS_CREATE_SET_UP,
  'app-admin-global-campaigns-campaign-moments-view':
    URL_ADMIN_GLOBAL_CAMPAIGNS_CAMPAIGN_MOMENTS_VIEW,
  'app-admin-global-campaigns-campaign-moments-view-moment':
    URL_ADMIN_GLOBAL_CAMPAIGNS_CAMPAIGN_MOMENTS_VIEW_MOMENT,
  'app-admin-global-campaigns-campaign-moments-view-participating-retailers':
    URL_ADMIN_GLOBAL_CAMPAIGNS_CAMPAIGN_MOMENTS_VIEW_PARTICIPATING_RETAILERS,
  'app-admin-global-campaigns-campaign-moments-edit':
    URL_ADMIN_GLOBAL_CAMPAIGNS_CAMPAIGN_MOMENTS_EDIT,
  'app-admin-global-campaigns-campaign-moments-edit-moment':
    URL_ADMIN_GLOBAL_CAMPAIGNS_CAMPAIGN_MOMENTS_EDIT_MOMENT,
  'app-admin-campaigns': URL_ADMIN_CAMPAIGNS,
  'app-admin-campaigns-templates': URL_ADMIN_CAMPAIGNS_TEMPLATES,
  'app-admin-campaigns-list': URL_ADMIN_CAMPAIGNS_LIST,
  'app-admin-campaigns-create': URL_ADMIN_CAMPAIGNS_CREATE,
  'app-admin-campaigns-get': URL_ADMIN_CAMPAIGNS_GET,
  'app-admin-campaigns-edit': URL_ADMIN_CAMPAIGNS_EDIT,
  // Marketing AI Agent Routes
  'app-admin-marketing-agent': URL_ADMIN_MARKETING_AGENT,
  'app-admin-marketing-agent-calendar': URL_ADMIN_MARKETING_AGENT_CALENDAR,
  'app-admin-marketing-agent-campaigns-view': URL_ADMIN_MARKETING_AGENT_CAMPAIGNS_VIEW,
  // V2 ROUTES
  campaigns: CAMPAIGNS_V2,
  'campaigns-create': CAMPAIGNS_V2_CREATE,
  'campaigns-edit': CAMPAIGNS_V2_EDIT,
  'campaigns-view': CAMPAIGNS_V2_VIEW,
  // Admin segment
  'app-admin-create-segment': URL_ADMIN_CREATE_SEGMENT,
  // Wholesaler
  'campaigns-partner-scoped': CAMPAIGNS_PARTNER_SCOPED,
  'campaigns-marketplace-list': CAMPAIGNS_MARKETPLACE_LIST,
  'campaigns-storefront-pro-list': CAMPAIGNS_STOREFRONT_PRO_LIST,
  'partner-campaigns-create-mp': CAMPAIGNS_CREATE_MP,
  'partner-campaigns-create-sfx': CAMPAIGNS_CREATE_SFX,
  'partner-campaigns-view': CAMPAIGNS_VIEW,
  'partner-campaigns-edit': CAMPAIGNS_EDIT,
  'segment-partner-scoped': SEGMENT_PARTNER_SCOPED,
  'segment-marketplace-list': SEGMENT_MARKETPLACE_LIST,
  'segment-storefront-pro-list': SEGMENT_STOREFRONT_PRO_LIST,
  'campaigns-partner-scoped-lifecycle-crm': URL_LIFECYCLE_CRM,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes

export type RetailerCampaignViewParams = {
  campaignId: string
}

export type RetailerSegmentViewParams = {
  id: string
}
