import { type ReactElement } from 'react'
import { ConfirmIcon, IssueIcon, spacing, useTheme } from '@instacart/ids-core'
import { Toast, ToastAction, ToastIcon, ToastsContainer } from '@instacart/ids-customers'
import { useDomainMessages } from '../utils/domain/intl'
import { makeState } from '../state'

interface Toast {
  id: number
  type: 'success' | 'failure'
  title: string
  body?: string
}

let id = 0
const [useToasts, updateToasts] = makeState<Toast[]>([])

export function addToast(params: Omit<Toast, 'id'>) {
  const toast = { ...params, id: id++ }
  updateToasts(toasts => [...toasts, toast])
  setTimeout(() => removeToast(toast.id), 5000)
}

function removeToast(id: number) {
  updateToasts(toasts => toasts.filter(toast => toast.id !== id))
}

const icons: Record<Toast['type'], ReactElement> = {
  success: <ConfirmIcon />,
  failure: <IssueIcon />,
}

export default function Toasts() {
  const theme = useTheme()
  const messages = useDomainMessages({
    toastAction: 'connectedStoresAdminDomain.toastAction',
  })
  const toasts = useToasts()
  return (
    <ToastsContainer
      css={{
        bottom: spacing.s16,
        zIndex: 1,
      }}
    >
      {toasts.map(toast => (
        <Toast id={String(toast.id)} key={toast.id} css={{ maxWidth: 500 }}>
          <ToastIcon>{icons[toast.type]}</ToastIcon>
          <div css={{ gridArea: 'text' }}>
            <div css={{ ...theme.typography.bodyMedium1, maxWidth: 300 }}>{toast.title}</div>
            {toast.body && (
              <div css={{ ...theme.typography.bodySmall1, maxWidth: 300 }}>{toast.body}</div>
            )}
          </div>
          <ToastAction onClick={() => removeToast(toast.id)}>{messages.toastAction}</ToastAction>
        </Toast>
      ))}
    </ToastsContainer>
  )
}
