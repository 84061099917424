import {
  useGetTotemEntityQuery,
  type GetTotemEntityQueryVariables,
  useGetTotemSystemQuery,
  useGetTotemSystemLimitedQuery,
  type Instacart_Infra_Totem_V1_System,
} from '../../__codegen__/api'

export const useTotem = (
  params: GetTotemEntityQueryVariables & { hasFullTotemViewAccess?: boolean }
) => {
  const hasFullTotemViewAccess = params.hasFullTotemViewAccess
  const {
    data: entityData,
    loading: entityLoading,
    error: entityError,
  } = useGetTotemEntityQuery({
    variables: params,
    skip: !params.externalId,
  })

  const systemName =
    entityData?.instacart_infra_totem_v1_TotemService_GetEntity?.entity?.system_name

  const {
    data: fullSystemData,
    loading: fullSystemLoading,
    error: fullSystemError,
  } = useGetTotemSystemQuery({
    variables: { systemName },
    skip: !systemName || !hasFullTotemViewAccess,
  })

  const {
    data: limitedSystemData,
    loading: limitedSystemLoading,
    error: limitedSystemError,
  } = useGetTotemSystemLimitedQuery({
    variables: { systemName },
    skip: !systemName || hasFullTotemViewAccess,
  })

  const systemData = hasFullTotemViewAccess ? fullSystemData : limitedSystemData

  return {
    data: systemData?.instacart_infra_totem_v1_TotemService_GetSystem
      ?.system as Instacart_Infra_Totem_V1_System,
    loading: entityLoading || (hasFullTotemViewAccess ? fullSystemLoading : limitedSystemLoading),
    error: entityError || (hasFullTotemViewAccess ? fullSystemError : limitedSystemError),
  }
}
