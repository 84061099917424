import type { PropsWithChildren, FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Button, colors, fontSize, spacing } from '@retailer-platform/shared-components'
import { useDashMessage } from '../../../../intl/intl.hooks'
import { PublicLayout } from '../../../../gin-and-tonic/common/layout/PublicLayout'
import { useCurrentAccountDetails } from '../../../../api/current-account/currentAccount.hooks'
import { useGoToPath } from '../../../../utils/routing/navigation.hooks'
import clientEnv from '../../../../utils/global/clientEnv'
import { Permission } from '../../../../legacy/common/types'

const StyledFormActions = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',

  '& *:last-child': {
    marginRight: 0,
  },
})

const linkStyles = css({
  color: colors.GRAYSCALE.X70,
  textDecoration: 'underline',
  marginBottom: spacing.X32,
  marginLeft: spacing.X8,
  fontSize: fontSize.X15,

  '&:link': {
    color: colors.GRAYSCALE.X70,
  },
})

export const HaltAuthServiceAccountLimitedAccess: FunctionComponent<
  PropsWithChildren<unknown>
> = () => {
  const viewAccessPolicyMessage = useDashMessage(
    'app.halt.authServiceAccountLimitedAccessInternal.viewAccessPolicy'
  )
  const refreshActionMessage = useDashMessage('app.halt.accountLimitedAccess.refreshAction')

  const goToAppHaltRedirect = useGoToPath('app-halt-redirect')
  const redirectToAccessPolicyDoc = () => {
    window.location.href = clientEnv.PUBLIC_CLIENT_RPP_ACCESS_POLICY_URL
  }

  const { loading: loadingAccount, account } = useCurrentAccountDetails({
    fetchPolicy: 'network-only',
  })
  const isInternal = account?.isInternal

  if (loadingAccount) return null

  const docsiteMap = {
    [Permission.StorefrontProPartnerDocs]: {
      url: 'https://partner-docs.instacart.com/storefront_pro/',
      messageId: 'app.halt.authServiceAccountLimitedAccessExternal.storefrontProContent',
    },
    [Permission.CatalogOutsourceDocs]: {
      url: 'https://partner-docs.instacart.com/catalog/',
      messageId: 'app.halt.authServiceAccountLimitedAccessExternal.outsourceContent',
    },
  }

  const docsitePermission = account.permissions.find(p => docsiteMap[p.slug])

  const docsiteLink = docsitePermission && (
    <a href={docsiteMap[docsitePermission.slug].url} css={linkStyles}>
      Documentation
    </a>
  )
  const docsiteMessage = docsitePermission
    ? docsiteMap[docsitePermission.slug].messageId
    : undefined

  if (isInternal) {
    return (
      <PublicLayout
        headerMessageId="app.halt.authServiceAccountLimitedAccessInternal.header"
        contentMessageId="app.halt.authServiceAccountLimitedAccessInternal.content"
        secondaryContentMessageId={docsiteMessage}
        data-testid="app-halt-auth-service-internal-account-limited-access-layout"
      >
        {docsiteLink}

        <StyledFormActions>
          <Button onClick={() => redirectToAccessPolicyDoc()}>{viewAccessPolicyMessage}</Button>
          <Button onClick={goToAppHaltRedirect} variant="secondary">
            {refreshActionMessage}
          </Button>
        </StyledFormActions>
      </PublicLayout>
    )
  }

  return (
    <PublicLayout
      headerMessageId="app.halt.authServiceAccountLimitedAccessExternal.header"
      contentMessageId="app.halt.authServiceAccountLimitedAccessExternal.content"
      secondaryContentMessageId={docsiteMessage}
      data-testid="app-halt-auth-service-external-account-limited-access-layout"
    >
      {docsiteLink}

      <Button onClick={goToAppHaltRedirect}>{refreshActionMessage}</Button>
    </PublicLayout>
  )
}
