import React, { type FunctionComponent } from 'react'
import { Text, NewSelect, Tooltip, Checkbox } from '@retailer-platform/shared-components'
import { HelpIcon, TrashIcon } from '@instacart/ids-core'
import {
  PropertyContainer,
  PropertySectionLeft,
  PropertySectionRight,
  SectionHeader,
  Property,
  PropertyTitle,
  TextInput,
  GridHeader,
  Grid,
  GridItem,
  TooltipContainer,
  IconGridItem,
  IconButtonContainer,
  EmptyMessage,
  Row,
  PropertyInput,
  PropertyInputButton,
} from '../../shared-styled-components/SharedStyledComponents.tsx'
import { useDomainMessages } from '../../../utils/domain/intl.ts'
import { PropertyWarning } from '../../md-platform-list/components/MdPlatformListTableRow.tsx'
import { type FormProps } from './StepAttributeForm'

export const LLMPromptTemplateForm: FunctionComponent<FormProps> = ({
  attributes,
  mode,
  onChange,
}) => {
  const i18n = useDomainMessages({
    model: 'catalogAdminDomain.attributeManagement.modal.model',
    propertyName: 'catalogAdminDomain.attributeManagement.modal.table.headers.propertyName',
    required: 'catalogAdminDomain.attributeManagement.modal.table.headers.required',
    images: 'catalogAdminDomain.attributeManagement.modal.table.headers.images',
    modelPlaceholder: 'catalogAdminDomain.attributeManagement.modal.modelPlaceholder',
    EmptyPropertyTable: 'catalogAdminDomain.attributeManagement.table.property.empty.message',
    PropertyTablePlaceholder: 'catalogAdminDomain.attributeManagement.property.input.placeholder',
    addProperty: 'catalogAdminDomain.attributeManagement.property.input.button.add',
    deleteProperty: 'catalogAdminDomain.attributeManagement.property.delete',
    requiredHelp: 'catalogAdminDomain.attributeManagement.label.required.help',
    imagesHelp: 'catalogAdminDomain.attributeManagement.label.images.help',
    propertyAlreadyExists: 'catalogAdminDomain.attributeManagement.create.property.warning.message',
    prompt: 'catalogAdminDomain.attributeManagement.modal.prompt',
    labelProperties: 'catalogAdminDomain.attributeManagement.create.label.properties',
    labelPropertiesSubtile: 'catalogAdminDomain.attributeManagement.create.label.property.subtitle',
  })

  const [propertyName, setPropertyName] = React.useState<string>('')
  const validPropertyName = !attributes?.properties.find(
    (p: any) => p.propertyName === propertyName
  )

  const PropertyNameWarning = () => (
    <PropertyWarning error={true}>• {i18n.propertyAlreadyExists}</PropertyWarning>
  )

  return (
    <>
      <SectionHeader> LLM Prompt Template </SectionHeader>
      <PropertyContainer>
        <PropertySectionLeft>
          <Property>
            <PropertyTitle>{i18n.model}</PropertyTitle>
            <NewSelect
              isDisabled={mode !== 'edit'}
              placeholder={i18n.modelPlaceholder}
              onChange={value => {
                onChange({
                  ...attributes,
                  model: value || '',
                })
              }}
              options={[
                { label: 'OpenAI: gpt-4o', value: 'gpt-4o' },
                { label: 'OpenAI: gpt-4o-mini', value: 'gpt-4o-mini' },
                { label: 'Claude: 3 opus', value: 'claude-3-opus' },
                { label: 'Claude: 3.5 haiku', value: 'claude-3.5-haiku' },
                { label: 'Claude: 3.5 sonnet', value: 'claude-3.5-sonnet' },
                { label: 'LLama: 3.2.1b instruct', value: 'llama3-2-1b-instruct' },
                { label: 'LLama: 3.2.3b instruct', value: 'llama3-2-3b-instruct' },
                { label: 'LLama: 3.2.11b instruct', value: 'llama3-2-11b-instruct' },
                { label: 'LLama: 3.2.90b instruct', value: 'llama3-2-90b-instruct' },
                { label: 'LLama: 3.3.70b instruct', value: 'llama3-3-70b-instruct' },
              ]}
              value={attributes.model || ''}
            />
          </Property>
          <Property>
            <PropertyTitle>{i18n.labelProperties} </PropertyTitle>
            <Text size="small">{i18n.labelPropertiesSubtile}</Text>
            <Grid>
              <GridHeader>{i18n.propertyName}</GridHeader>
              <GridHeader>
                <TooltipContainer>
                  {i18n.required}
                  <Tooltip placement="bottom" target={<HelpIcon />}>
                    {i18n.requiredHelp}
                  </Tooltip>
                </TooltipContainer>
              </GridHeader>
              <GridHeader>
                <TooltipContainer>
                  {i18n.images}
                  <Tooltip placement="bottom" target={<HelpIcon />}>
                    {i18n.imagesHelp}
                  </Tooltip>
                </TooltipContainer>
              </GridHeader>
              <GridHeader> {i18n.deleteProperty}</GridHeader>
              {attributes.properties?.map((property: any) => (
                <>
                  <GridItem> {property.propertyName} </GridItem>
                  <GridItem>
                    <Checkbox
                      disabled={mode !== 'edit'}
                      checked={property.required}
                      onChange={() => {
                        onChange({
                          ...attributes,
                          properties: attributes.properties.map((p: any) =>
                            p.propertyName === property.propertyName
                              ? { ...p, required: !p.required }
                              : p
                          ),
                        })
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <Checkbox
                      disabled={mode !== 'edit'}
                      checked={property.images}
                      onChange={() => {
                        onChange({
                          ...attributes,
                          properties: attributes.properties.map((p: any) =>
                            p.propertyName === property.propertyName
                              ? { ...p, images: !p.images }
                              : p
                          ),
                        })
                      }}
                    />
                  </GridItem>
                  <IconGridItem>
                    <IconButtonContainer
                      disabled={mode !== 'edit'}
                      icon={TrashIcon}
                      onClick={() => {
                        onChange({
                          ...attributes,
                          properties: attributes.properties.filter(
                            (p: any) => p.propertyName !== property.propertyName
                          ),
                        })
                      }}
                      accessibleLabel={'Delete'}
                    ></IconButtonContainer>
                  </IconGridItem>
                </>
              ))}
            </Grid>
            {!validPropertyName && <PropertyNameWarning />}
            {attributes?.properties.length === 0 && (
              <EmptyMessage> {i18n.EmptyPropertyTable} </EmptyMessage>
            )}
            {mode === 'edit' && (
              <Row>
                <PropertyInput
                  placeholder={i18n.PropertyTablePlaceholder}
                  value={propertyName}
                  onChange={event => setPropertyName(event.target.value)}
                  error={!validPropertyName || attributes.properties.length === 0}
                ></PropertyInput>
                <PropertyInputButton
                  variant={'secondary'}
                  onClick={() => {
                    propertyName !== '' &&
                      onChange({
                        ...attributes,
                        properties: [
                          ...attributes.properties,
                          {
                            propertyName,
                            required: false,
                            images: false,
                          },
                        ],
                      })
                    setPropertyName('')
                  }}
                  disabled={propertyName === '' || !validPropertyName}
                >
                  {i18n.addProperty}
                </PropertyInputButton>
              </Row>
            )}
          </Property>
        </PropertySectionLeft>
        <PropertySectionRight>
          <Property style={{ height: '100%' }}>
            <PropertyTitle>{i18n.prompt}</PropertyTitle>
            <TextInput
              disabled={mode !== 'edit'}
              onChange={e => {
                onChange({
                  ...attributes,
                  prompt: e.target.value,
                })
              }}
              value={attributes.prompt}
              error={attributes.prompt.length === 0 && mode === 'edit'}
            />
          </Property>
        </PropertySectionRight>
      </PropertyContainer>
    </>
  )
}
