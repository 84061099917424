import { transformFilterParamsToApiFilterSelectionsFactory } from '../utils/insightsPortalSchemas.utils'
import {
  createSchemaConfig,
  buildTimeIntervalGroupBysConfig,
  buildTimeIntervalDimensionsConfig,
  PrivateSchemaPropertyType,
  buildTimeIntervalDimensionsGrouping,
} from '../helpers/schema.helpers'
import { InsightsFormatterType, InsightsGroupByTypes } from '../types/schema.types'
import { searchTermSchemaFilterParamsConfig } from './insightsSearchTermSchema.hooks'
import { GroupBys, Metrics, Dimensions } from './insightsSearchTerm.types'

export { GroupBys, Metrics, Dimensions }

export const schemaConfig = createSchemaConfig({
  metrics: Metrics,
  dimensions: Dimensions,
  metricsConfig: {
    [Metrics.COUNT_SEARCH]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.searchDashboard.metrics.countSearch',
    },
    [Metrics.SEARCH_CONVERSION]: {
      formatter: InsightsFormatterType.Percentage,
      label: 'insightsPortalDomain.searchDashboard.metrics.searchConversion',
    },
    [Metrics.COUNT_UNCONVERTED_SEARCH]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.searchDashboard.termPerformance.header.unconvertedSearches',
    },
    [Metrics.COUNT_CONVERTED_SEARCH]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.searchDashboard.termPerformance.convertedSearches',
    },
    [Metrics.COUNT_NULL_SEARCH]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.searchDashboard.metrics.countNullSearch',
    },
  },
  dimensionsConfig: {
    [Dimensions.WAREHOUSE]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.filters.retailers.label',
    },
    [Dimensions.ZONE_STATE]: {
      type: InsightsGroupByTypes.Info,
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.WAREHOUSE_LOCATION_STATE',
    },
    [Dimensions.INSIGHTS_PORTAL_ORDER_SOURCE]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.collections.filters.orderSource',
    },
    [Dimensions.POPULAR_SEARCH_TERM]: {
      type: InsightsGroupByTypes.Program,
      private: PrivateSchemaPropertyType.DataExplorerTable,
      label: 'insightsPortalDomain.filters.popularSearchTerm.label',
    },
    [Dimensions.SEARCH_TERM]: {
      type: InsightsGroupByTypes.Program,
      private: PrivateSchemaPropertyType.DataExplorerFilters,
      label: 'insightsPortalDomain.filters.popularSearchTerm.label',
    },
    [Dimensions.REGION]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.controls.groupBy.region',
    },
    [Dimensions.GUEST_USER]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      label: 'insightsPortalDomain.filters.guestUser.label',
    },
    ...buildTimeIntervalDimensionsConfig,
  },
  dimensionGroupings: [
    buildTimeIntervalDimensionsGrouping,
    {
      label: 'insightsPortalDomain.filters.stores',
      dimensions: [Dimensions.WAREHOUSE, Dimensions.ZONE_STATE, Dimensions.REGION],
    },
    {
      label: 'insightsPortalDomain.filters.orders',
      dimensions: [Dimensions.INSIGHTS_PORTAL_ORDER_SOURCE],
    },
    {
      label: 'insightsPortalDomain.storePerformance.search.title',
      dimensions: [Dimensions.SEARCH_TERM, Dimensions.GUEST_USER, Dimensions.POPULAR_SEARCH_TERM],
    },
  ],
  // TODO: deprecate
  groupBys: GroupBys,
  filterConfigs: searchTermSchemaFilterParamsConfig,
  transformFilterParamsToApiFilterSelections: transformFilterParamsToApiFilterSelectionsFactory(
    searchTermSchemaFilterParamsConfig
  ),
  groupBysConfig: {
    ...buildTimeIntervalGroupBysConfig,
    [GroupBys.INSIGHTS_PORTAL_ORDER_SOURCE]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.collections.filters.orderSource',
    },
    [GroupBys.POPULAR_SEARCH_TERM]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.filters.popularSearchTerm.label',
    },
    [GroupBys.WAREHOUSE]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.filters.retailers.label',
    },
    [GroupBys.REGION]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.controls.groupBy.region',
    },
    [GroupBys.ZONE_STATE]: {
      type: InsightsGroupByTypes.Info,
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.WAREHOUSE_LOCATION_STATE',
    },
    [GroupBys.SEARCH_TERM]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.filters.popularSearchTerm.label',
    },
    [GroupBys.GUEST_USER]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      label: 'insightsPortalDomain.filters.guestUser.label',
    },
  },
})
