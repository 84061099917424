import { Polly, type Request, type Response } from '@pollyjs/core'
import FetchAdapter from '@pollyjs/adapter-fetch'
import XHRAdapter from '@pollyjs/adapter-xhr'

// only register if were not in production
if (process.env.PUBLIC_CLIENT_ENVIRONMENT !== 'production') {
  Polly.register(FetchAdapter)
  Polly.register(XHRAdapter)
}

export type GraphQLRequest = {
  operationName: string
  variables: Record<string, any>
  query: string
}

export type GraphQLResponse = {
  statusCode: number
  responseBody: any
  status(code: number): GraphQLResponse
  json(body: any): GraphQLResponse
  sendStatus(code: number): GraphQLResponse
}

export interface GraphQLError {
  message: string
  path?: string[]
  extensions?: {
    code: string
    stacktrace?: string[]
    [key: string]: unknown
  }
}

/**
 * Handler registry to store operation-specific handlers
 *
 * @param req - The GraphQL request
 * @param res - The GraphQL response
 */
export type GraphQLHandler = (req: GraphQLRequest, res: GraphQLResponse) => void

/**
 * A manager for a PollyJS server
 *
 * https://netflix.github.io/pollyjs/
 *
 * We use Polly to intercept GraphQL requests (or any network request in the future)
 * and return mock responses. This is useful for development purposes, allowing us
 * to test the dashboard with mock data.
 *
 * This class allows us to register handlers for specific GraphQL operations,
 * and to register a fallback handler for operations that
 * are not explicitly handled by a registered handler.
 */
export class PollyServerManager {
  // Add a unique ID to each instance
  private readonly instanceId: string

  private polly: Polly | null = null
  private handlers: Map<string, GraphQLHandler> = new Map()
  private fallbackHandler: GraphQLHandler | null = null

  constructor() {
    // Generate a unique ID for this instance or use provided ID
    this.instanceId =
      Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
  }

  // Method to get the instance ID
  getInstanceId(): string {
    return this.instanceId
  }

  enable() {
    if (this.polly) {
      console.debug('Polly server is already enabled')
      return this
    }

    this.polly = new Polly('ipp-core', {
      mode: 'passthrough',
      adapters: ['fetch', 'xhr'],
      logLevel: 'debug',
    })

    // Set up the GraphQL endpoint interception
    this.setupInterceptors()
    console.debug(`Polly server has been enabled. PollyManager instance ID: ${this.instanceId}`)
    return this
  }

  private setupInterceptors() {
    const url =
      process.env.PUBLIC_CLIENT_RETAILER_PLATFORM_MESH_URL ||
      Cypress.env('PUBLIC_CLIENT_RETAILER_PLATFORM_MESH_URL')

    if (!url) {
      throw new Error('PUBLIC_CLIENT_RETAILER_PLATFORM_MESH_URL is not set')
    }

    this.polly?.server
      .post(url + '/graphql')
      .intercept((req: Request, res: Response) => this.handleGraphQLRequest(req, res))

    console.debug('Intercepting GraphQL requests on URL:', url)
  }

  private ensureEnabled() {
    if (!this.polly) {
      throw new Error('Polly server is not enabled. Call enable() before using this method.')
    }
  }

  private handleGraphQLRequest(req: Request, res: Response) {
    try {
      // Parse the request body
      const body = typeof req.body === 'string' ? JSON.parse(req.body) : req.body

      // Handle both single operations and batched operations
      if (Array.isArray(body)) {
        // Handle batched operations
        const responses = body.map(operation => this.processOperation(operation))
        res.status(200).json(responses)
      } else {
        // Handle single operation
        const result = this.processOperation(body)
        res.status(200).json(result)
      }
    } catch (error) {
      console.error('Error handling GraphQL request:', error)
      res.status(500).json({ errors: [{ message: 'Internal mock server error' }] })
    }
  }

  private processOperation(operation: GraphQLRequest) {
    const operationName = operation.operationName

    // Create mock response object to capture the response
    const mockRes = {
      statusCode: 200,
      responseBody: null,
      status(code: number) {
        this.statusCode = code
        return this
      },
      json(body: any) {
        this.responseBody = body
        return this
      },
      sendStatus(code: number) {
        this.statusCode = code
        this.responseBody = null
        return this
      },
    }

    // Find a handler for this operation using Map lookup
    if (this.handlers.has(operationName)) {
      console.debug(`Using registered handler for operation: ${operationName}`)
      // Call the handler with the operation and mock response
      this.handlers.get(operationName)!(operation, mockRes)
    } else if (this.fallbackHandler) {
      console.debug(`Using fallback handler for operation: ${operationName}`)
      this.fallbackHandler(operation, mockRes)
    } else {
      console.debug(`No handler found for operation: ${operationName}`)
      // Default response for unhandled operations
      return { errors: [{ message: `No mock handler registered for operation: ${operationName}` }] }
    }

    return mockRes.responseBody
  }

  // Public API for registering handlers
  registerGraphQLHandler(operationName: string, handler: GraphQLHandler) {
    if (!operationName) {
      throw new Error('Operation name is required')
    }

    this.handlers.set(operationName, handler)
    console.debug(
      `Registered GraphQL handler for operation: ${operationName}. PollyManager instance ID: ${this.instanceId}`
    )
    return this
  }

  // Register a fallback handler for unmatched operations
  registerFallbackGraphQLHandler(handler: GraphQLHandler) {
    this.fallbackHandler = handler
    console.debug(
      `Registered fallback GraphQL handler. PollyManager instance ID: ${this.instanceId}`
    )
    return this
  }

  // Get the Polly instance
  getPolly() {
    this.ensureEnabled()
    return this.polly
  }
}

// We need to declare the pollyServerManager property on the window object.
// This is because we need to access it in the Cypress tests. Since the
// app runs in an iframe, we need to access the window object of the parent
// frame.
declare global {
  interface Window {
    pollyServerManager?: PollyServerManager
  }
}

let pollyServerManager = window.pollyServerManager

if (!window.pollyServerManager) {
  pollyServerManager = new PollyServerManager()
  window.pollyServerManager = pollyServerManager
}

export { pollyServerManager }
