import React, { type FunctionComponent } from 'react'
import { DocumentTitle } from '@retailer-platform/dashboard/gin-and-tonic'
import { Breadcrumb, Breadcrumbs, PageHeader, spacing } from '@retailer-platform/shared-components'
import { useCurrentAccountContext, useUrlSearchParams } from '@retailer-platform/dashboard/utils'
import { type DashRouteComponentProps } from '@retailer-platform/dashboard/routing'
import styled from '@emotion/styled'
import { useHasPermission } from '../../utils/hasPermission.hooks.ts'
import { useDomainMessages } from '../../utils/domain/intl.ts'
import { Permission } from '../../access-control/permissions.ts'
import { useDomainScopedPathname } from '../../utils/domain/routing.ts'
import { AttributeManagementCreate } from '../../components/attribute-management-create/AttributeManagementCreate.tsx'
import { type AttributeManagementCategoryEnum } from '../../types/attributeManagement.types.ts'

type Props = DashRouteComponentProps<{}> & {}

export const Page = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const Container = styled.div({
  flex: 1,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
  margin: `0 ${spacing.X16}`,
})

const CatalogAdminAttributeManagementCreatePage: FunctionComponent<
  React.PropsWithChildren<Props>
> = () => {
  const { value: category } = useUrlSearchParams('category')
  const i18n = useDomainMessages({
    listTitle: 'catalogAdminDomain.attributeManagement.nav.title',
    title: 'catalogAdminDomain.attributeManagement.create.nav.title',
  })
  const platformPath = useDomainScopedPathname('app-admin-catalog-admin-attribute-management')
  const currentAccount = useCurrentAccountContext()
  const hasViewAccess = useHasPermission(currentAccount, Permission.CatalogDetectionViewAccess)

  if (!hasViewAccess) return null
  return (
    <Page>
      <DocumentTitle titleId="catalogAdminDomain.attributeManagement.create.nav.title" />
      <PageHeader
        addBottomPadding
        title={
          <Breadcrumbs>
            <Breadcrumb path={platformPath}>{i18n.listTitle}</Breadcrumb>
            <Breadcrumb>{i18n.title}</Breadcrumb>
          </Breadcrumbs>
        }
      />

      <Container>
        {category && (
          <AttributeManagementCreate category={category as AttributeManagementCategoryEnum} />
        )}
      </Container>
    </Page>
  )
}

export default CatalogAdminAttributeManagementCreatePage
