import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { catalogAdminAdminAccessControl } from '../access-control/catalogAdminAdminAccess.configuration'
import { catalogAdminImageManagementAccessConfiguration } from '../access-control/catalogAdminImageManagementAccess.configuration'
import { catalogAdminQualityAuditAccessConfiguration } from '../access-control/catalogAdminQualityAuditAccess.configuration'
import {
  catalogAdminMDPlatformAccessConfiguration,
  catalogAdminMDPlatformEditAccessConfiguration,
} from '../access-control/catalogMDPlatformAccess.configuration'
import {
  catalogAdminExtractionModelsAccessConfiguration,
  catalogAdminExtractionModelsEditAccessConfiguration,
} from '../access-control/catalogAdminExtractionModelsAccess.configuration'
import CatalogAdminAttributeManagementCreatePage from '../pages/attribute-management/CatalogAdminAttributeManagementCreatePage'
import CatalogAdminAttributeManagementDetailsPage from '../pages/attribute-management/CatalogAdminAttributeManagementDetailsPage'
import CatalogAdminAttributeManagementEditPage from '../pages/attribute-management/CatalogAdminAttributeManagementEditPage'

// --------------------
// QAS Page Components
// --------------------

const CatalogAdminQualityAuditWorkflowListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CatalogAdminQualityAuditWorkflowListPage" */
      '../pages/quality-audit-workflow-list/CatalogAdminQualityAuditWorkflowListPage'
    )
)

const CatalogAdminQualityAuditWorkflowBatchListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CatalogAdminQualityAuditWorkflowBatchListPage" */
      '../pages/quality-audit-workflow-batch-list/CatalogAdminQualityAuditWorkflowBatchListPage'
    )
)

const CatalogAdminQualityAuditWorkflowTaskListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CatalogAdminQualityAuditWorkflowTaskListPage" */
      '../pages/quality-audit-workflow-task-list/CatalogAdminQualityAuditWorkflowTaskListPage'
    )
)

const CatalogAdminQualityAuditWorkflowTaskListInputDataPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CatalogAdminQualityAuditWorkflowTaskListInputDataPage" */
      '../pages/quality-audit-workflow-task-list-input-data/CatalogAdminQualityAuditWorkflowTaskListInputDataPage'
    )
)

// --------------------
// Image Management Page Components
// --------------------

const CatalogAdminImageManagementPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CatalogAdminImageManagementPage" */
      '../pages/image-management/CatalogAdminImageManagementPage'
    )
)

// --------------------
// Product Edit Page Components
// --------------------

const CatalogAdminProductEditShowPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CatalogAdminProductEditShowPage" */
      '../pages/product-edit-show/CatalogAdminProductEditShowPage'
    )
)
const CatalogAdminMdPlatformPage = lazy(
  () => import('../pages/md-platform/CatalogAdminMdPlatformPage')
)
const CatalogAdminMdPlatformDetailsPage = lazy(
  () => import('../pages/md-platform-details/CatalogAdminMdPlatformDetailsPage')
)
const CatalogAdminMdPlatformCreatePage = lazy(
  () => import('../pages/md-platform-create/CatalogAdminMdPlatformCreatePage')
)
const CatalogAdminQualityAuditSubmitTasksPage = lazy(
  () => import('../pages/quality-audit-submit-tasks/CatalogAdminQualityAuditSubmitTasksPage')
)
const CatalogAdminAttributeManagementPage = lazy(
  () => import('../pages/attribute-management/CatalogAdminAttributeManagementPage')
)
const CatalogAdminQualityAuditWorkflowModifyPage = lazy(
  () => import('../pages/quality-audit-workflow-modify/CatalogAdminQualityAuditWorkflowModifyPage')
)
const CatalogAdminQualityAuditCreateWorkflowsPage = lazy(
  () =>
    import('../pages/quality-audit-create-workflows/CatalogAdminQualityAuditCreateWorkflowsPage')
)
// @hygen:inject page-import

// @ts-ignore
export const catalogAdminAdminRouter = (
  <DomainRoute route="app-admin-catalog-admin" accessControl={catalogAdminAdminAccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="app-admin-catalog-admin-product-edit-show"
        component={CatalogAdminProductEditShowPage}
      />
      <DomainRoute
        exact
        route="app-admin-catalog-admin-image-management"
        component={CatalogAdminImageManagementPage}
        accessControl={catalogAdminImageManagementAccessConfiguration}
      />
      {/* Quality Audit Routes */}
      <DomainRoute
        exact
        route="app-admin-catalog-admin-quality-audit-workflow-list"
        component={CatalogAdminQualityAuditWorkflowListPage}
        accessControl={catalogAdminQualityAuditAccessConfiguration}
      />
      <DomainRoute
        exact
        route="app-admin-catalog-admin-quality-audit-workflow-batch-list"
        // @ts-ignore
        component={CatalogAdminQualityAuditWorkflowBatchListPage}
        accessControl={catalogAdminQualityAuditAccessConfiguration}
      />
      <DomainRoute
        exact
        route="app-admin-catalog-admin-quality-audit-workflow-task-list"
        component={CatalogAdminQualityAuditWorkflowTaskListPage}
        accessControl={catalogAdminQualityAuditAccessConfiguration}
      />
      <DomainRoute
        exact
        route="app-admin-catalog-admin-quality-audit-workflow-task-list-input-data"
        // @ts-ignore
        component={CatalogAdminQualityAuditWorkflowTaskListInputDataPage}
        accessControl={catalogAdminQualityAuditAccessConfiguration}
      />
      <DomainRoute
        exact
        route="app-admin-catalog-admin-md-platform"
        component={CatalogAdminMdPlatformPage}
        accessControl={catalogAdminMDPlatformAccessConfiguration}
      />
      <DomainRoute
        exact
        route="app-admin-catalog-admin-md-platform-details"
        component={CatalogAdminMdPlatformDetailsPage}
        accessControl={catalogAdminMDPlatformAccessConfiguration}
      />
      <DomainRoute
        exact
        route="app-admin-catalog-admin-md-platform-create"
        component={CatalogAdminMdPlatformCreatePage}
        accessControl={catalogAdminMDPlatformEditAccessConfiguration}
      />
      <DomainRoute
        exact
        route="app-admin-catalog-admin-quality-audit-submit-tasks"
        component={CatalogAdminQualityAuditSubmitTasksPage}
        accessControl={catalogAdminQualityAuditAccessConfiguration}
      />
      <DomainRoute
        exact
        route="app-admin-catalog-admin-quality-audit-workflow-modify"
        component={CatalogAdminQualityAuditWorkflowModifyPage}
        accessControl={catalogAdminQualityAuditAccessConfiguration}
      />
      <DomainRoute
        exact
        route="app-admin-catalog-admin-attribute-management"
        component={CatalogAdminAttributeManagementPage}
        accessControl={catalogAdminExtractionModelsAccessConfiguration}
      />
      <DomainRoute
        exact
        route="app-admin-catalog-admin-quality-audit-create-workflows"
        component={CatalogAdminQualityAuditCreateWorkflowsPage}
        accessControl={catalogAdminQualityAuditAccessConfiguration}
      />
      <DomainRoute
        exact
        route="app-admin-catalog-admin-attribute-management-create"
        component={CatalogAdminAttributeManagementCreatePage}
        accessControl={catalogAdminExtractionModelsEditAccessConfiguration}
      />
      <DomainRoute
        exact
        route="app-admin-catalog-admin-attribute-management-details"
        component={CatalogAdminAttributeManagementDetailsPage}
        accessControl={catalogAdminExtractionModelsAccessConfiguration}
      />
      <DomainRoute
        exact
        route="app-admin-catalog-admin-attribute-management-edit"
        component={CatalogAdminAttributeManagementEditPage}
        accessControl={catalogAdminExtractionModelsEditAccessConfiguration}
      />
    </DashSwitch>
  </DomainRoute>
)
