// @ts-nocheck legacy file transformed to TS
import styled from '@emotion/styled'
import { Component } from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import { injectIntl } from 'react-intl'
import { components } from 'react-select-5'
import deprecatedAnalytics from '../common/deprecatedAnalytics'
import withRetailerRouter from '../common/withRouter'
import { ASYNC_STATES } from '../common/constants'
import { RegionLocationContext } from '../contexts/regionLocation/RegionLocationContext'
import FlexRow from './FlexRow'
import Dropdown from './Dropdown'
import { NewSelect, spacing, colors, fontSize } from '@retailer-platform/shared-components'
import { SelectField } from '@retailer-platform/shared-components/src/tds'
import { SearchIcon } from '@instacart/ids-core'
import { css } from '@emotion/react'

const AvailabilityIcon = styled.span<{ available?: boolean }>(({ available }) => ({
  marginRight: spacing.X8,
  color: available ? colors.SUCCESS.REGULAR : colors.GRAYSCALE.X50,
  '&::after': {
    content: '"•"',
    fontSize: fontSize.X23,
  },
}))

const Container = styled(FlexRow)`
  margin: 0;
  padding: 0;
`

const InventoryAreaShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
})

const RegionShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  inventory_areas: PropTypes.arrayOf(InventoryAreaShape),
})

@injectIntl
@withRetailerRouter
class RegionLocationDropdown extends Component<any> {
  static propTypes = {
    history: PropTypes.object.isRequired,
    query: PropTypes.object.isRequired,
    regions: PropTypes.arrayOf(RegionShape),
    inventoryAreas: PropTypes.arrayOf(InventoryAreaShape),
    analyticsEvent: PropTypes.string,
    useInventoryAreas: PropTypes.bool,
    isDisabled: PropTypes.bool,
    showRegions: PropTypes.bool,
    hideTestNamesInInventoryAreas: PropTypes.bool,
    availabilityByInventoryAreaId: PropTypes.object,
  }

  static defaultProps = {
    regions: [],
    inventoryAreas: [],
  }

  onRegionsChange = region_ids => {
    const { analyticsEvent } = this.props
    if (analyticsEvent) {
      deprecatedAnalytics.track(analyticsEvent, {
        source_type: 'region',
        source_value: region_ids,
      })
    }
    const query = { ...this.props.query }
    if (!region_ids.length) {
      delete query.region_ids
    } else {
      query.region_ids = region_ids
    }
    this.props.history.push({
      query: omit(query, ['inventory_area_ids']),
    })
  }

  onInventoryAreasChange = inventory_area_ids => {
    const { analyticsEvent } = this.props
    if (analyticsEvent) {
      deprecatedAnalytics.track(analyticsEvent, {
        source_type: 'warehouse_location',
        source_value: inventory_area_ids,
      })
    }
    const query = { ...this.props.query }
    if (!inventory_area_ids || !inventory_area_ids.length) {
      delete query.inventory_area_ids
    } else {
      query.inventory_area_ids = inventory_area_ids
    }
    this.props.history.push({
      query: {
        ...query,
        inventory_area_ids,
      },
    })
  }

  RegionLocationDropdownOption = props => {
    const showAvailability = !!this.props.availabilityByInventoryAreaId
    const id = props?.data?.value ?? ''
    const isAvailability = this.props.availabilityByInventoryAreaId?.[id]
    return (
      <components.Option {...props}>
        {showAvailability ? <AvailabilityIcon available={isAvailability} /> : null}
        {props.data.label}
      </components.Option>
    )
  }

  optionify(values) {
    return values
      .sort((a, b) => {
        const aKey = a?.warehouse_location_code ?? (a?.name || a?.street)
        const bKey = b?.warehouse_location_code ?? (b?.name || b?.street)
        return aKey.localeCompare(bKey, undefined, { numeric: true })
      })
      .map(value => ({
        label: value?.warehouse_name ?? (value?.name || value?.street),
        value: value?.id.toString(),
      }))
  }

  render() {
    const { intl } = this.props

    return (
      <RegionLocationContext.Consumer>
        {({
          regions,
          locations,
          inventoryAreas,
          regionStatus,
          locationStatus,
          inventoryAreaStatus,
        }) => {
          let inventoryAreaOptions = inventoryAreas
          if (this?.props?.hideTestNamesInInventoryAreas) {
            inventoryAreaOptions = inventoryAreas.filter(
              area => !/test/i.test(area?.warehouse_name ?? '')
            )
          }
          return (
            <Container>
              {this.props.showRegions && (
                <Dropdown
                  width="200px"
                  defaultable
                  filterable
                  isBusy={regionStatus === ASYNC_STATES.BUSY || regionStatus === ASYNC_STATES.IDLE}
                  defaultText={intl.formatMessage({ id: 'filters.region.all' })}
                  options={this.optionify(regions)}
                  value={this.props.query.region_ids}
                  onChange={this.onRegionsChange}
                />
              )}
              <SelectField
                disabled={this.props.isDisabled}
                isLoading={
                  this.props.useInventoryAreas
                    ? inventoryAreaStatus === ASYNC_STATES.BUSY ||
                      inventoryAreaStatus === ASYNC_STATES.IDLE
                    : locationStatus === ASYNC_STATES.BUSY || locationStatus === ASYNC_STATES.IDLE
                }
                placeholder={intl.formatMessage({ id: 'filters.location.all' })}
                options={this.optionify(
                  this.props.useInventoryAreas ? inventoryAreaOptions : locations
                )}
                value={this.props.query.inventory_area_ids}
                onChange={this.onInventoryAreasChange}
              />
            </Container>
          )
        }}
      </RegionLocationContext.Consumer>
    )
  }
}

export default RegionLocationDropdown
