import { startCase } from 'lodash'
import React, { type FunctionComponent } from 'react'
import { Link, Text, Marker } from '@retailer-platform/shared-components/src/tds'
import { DetectionStatusState, type GetDetectionResponseDetectionVersion } from '../../../api'
import { useDomainMessages } from '../../domain/intl.ts'
import {
  Property,
  PropertyContainer,
  PropertySection,
} from '../../../components/md-platform-list/components/MdPlatformListTableRow.tsx'
import { PropertyValue } from '../common.styles.tsx'
import { ACCOUNT_BLAZER_LINK } from '../helpers.tsx'
import { MdPlatformVersionState } from './MdPlatformVersionState.tsx'

interface Props {
  version: GetDetectionResponseDetectionVersion
  showEnabled: boolean
  showState: boolean
  ignoreProperties?: string[]
}

const SKIP_DETAIL_ATTRIBUTES = ['description', 'enabled', 'query']

export const MdPlatformVersionProperties: FunctionComponent<React.PropsWithChildren<Props>> = ({
  showEnabled,
  showState,
  version,
  ignoreProperties,
}) => {
  const i18n = useDomainMessages({
    none: 'catalogAdminDomain.mdPlatformList.table.values.none',
    query: 'catalogAdminDomain.mdPlatformList.table.query',
    description: 'catalogAdminDomain.mdPlatformList.modal.input.description',
    state: 'catalogAdminDomain.mdPlatform.versions.state',
    enabled: 'catalogAdminDomain.mdPlatform.versions.enabled',
  })

  return (
    <>
      <PropertyContainer style={{ maxWidth: `calc(100vw - 120px)`, overflow: `auto` }}>
        <PropertySection style={{ width: '30%' }}>
          {(showEnabled || showState) && (
            <PropertyContainer style={{ flexWrap: 'wrap' }}>
              {showEnabled && (
                <Property>
                  <Text typography="bodyEmphasized">{i18n.state}</Text>
                  <MdPlatformVersionState
                    state={
                      version.states.length > 0
                        ? version.states[0].state
                        : DetectionStatusState.DetectionStatusStateUnknown
                    }
                  />
                </Property>
              )}
              {showState && (
                <Property>
                  <Text typography="bodyEmphasized">{i18n.enabled}</Text>
                  {version.attributes.enabled ? (
                    <Marker variant={'success'} text={'enabled'} />
                  ) : (
                    <Marker variant={'detrimental'} text={'disabled'} />
                  )}
                </Property>
              )}
            </PropertyContainer>
          )}
          {Object.keys(version?.attributes ?? {}).map(attributeName => {
            if (attributeName === '__typename') return null
            if ((ignoreProperties ?? SKIP_DETAIL_ATTRIBUTES).includes(attributeName)) return null

            let value = (version?.attributes ?? {})[attributeName] ?? i18n.none
            if (typeof value === 'boolean') value = startCase(JSON.stringify(value))

            return (
              <Property key={attributeName}>
                <Text typography="bodyEmphasized">{startCase(attributeName)}:</Text>
                <PropertyValue>
                  {attributeName === 'createdBy' ? (
                    <Link href={`${ACCOUNT_BLAZER_LINK}${value}`}>{value}</Link>
                  ) : (
                    <Text typography="bodyRegular">{value}</Text>
                  )}
                </PropertyValue>
              </Property>
            )
          })}
        </PropertySection>
        <PropertySection style={{ width: '70%', flex: 1 }}>
          <Property style={{ height: '100%' }}>
            <Text typography="bodyEmphasized">{i18n.description}</Text>
            <PropertyValue style={{ overflowY: 'auto', height: '200px' }}>
              <Text typography="bodyRegular">{version?.attributes?.description}</Text>
            </PropertyValue>
          </Property>
          <Property style={{ height: '100%' }}>
            <Text typography="bodyEmphasized">{i18n.query}</Text>
            <PropertyValue style={{ overflow: 'auto', maxHeight: '880px' }}>
              <Text typography="bodyRegular">
                <pre>{version?.attributes?.query}</pre>
              </Text>
            </PropertyValue>
          </Property>
        </PropertySection>
      </PropertyContainer>
    </>
  )
}
