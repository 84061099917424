import { useState, type FunctionComponent } from 'react'
import {
  PaginatedGrid,
  Badge,
  notify,
  NotificationLevel,
  Text,
} from '@retailer-platform/shared-components'
import { ApprovedIcon, TimeIcon } from '@instacart/ids-core'
import styled from '@emotion/styled'
import { LoadingPage } from '@retailer-platform/dashboard/gin-and-tonic'
import { useDomainMessages } from '../../../../utils/domain/intl'
import { useListStepRunsQuery } from '../../../../api'
import { ResultDownloadButton } from './ResultDownloadButton'

type Props = {
  stepId: string
}

const NoResultsText = styled(Text)({
  textAlign: 'center',
  marginTop: '20px',
})

export const ResultsTab: FunctionComponent<Props> = ({ stepId }) => {
  const i18n = useDomainMessages({
    id: 'catalogAdminDomain.attributeManagement.runResults.table.headers.id',
    requester: 'catalogAdminDomain.attributeManagement.runResults.table.headers.requester',
    version: 'catalogAdminDomain.attributeManagement.runResults.table.headers.version',
    completed: 'catalogAdminDomain.attributeManagement.runResults.table.headers.completed',
    productCount: 'catalogAdminDomain.attributeManagement.runResults.table.headers.productCount',
    downloadCSV: 'catalogAdminDomain.attributeManagement.runResults.table.headers.downloadCSV',
    startTime: 'catalogAdminDomain.attributeManagement.runResults.table.headers.startTime',
  })
  const [page, setPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)

  const { data, loading } = useListStepRunsQuery({
    variables: {
      clientId: 'catalog_camp_ipp',
      idempotencyKey: page.toString() + '_' + itemsPerPage.toString(),
      stepId: stepId,
      page: page.toString(),
      pageSize: itemsPerPage.toString(),
    },
    onError: e => {
      notify({
        contents: `Error fetching step runs: ${e.message}`,
        level: NotificationLevel.Error,
      })
    },
  })
  return (
    <>
      {loading ? (
        <LoadingPage css={{ position: 'unset' }} />
      ) : !data?.instacart_catalog_catflow_v1_CatflowAPI_ListStepRuns?.stepRuns?.length ? (
        <NoResultsText size="large" weight="semibold">
          No results to show.
        </NoResultsText>
      ) : (
        <PaginatedGrid
          columns={[
            {
              id: 'id',
              Cell: ({ row }) => <>{row.original.id}</>,
              Header: i18n.id,
              width: 75,
            },
            {
              id: 'requester',
              Cell: ({ row }) => <>{row.original.requester}</>,
              Header: i18n.requester,
              width: 320,
            },
            {
              id: 'version',
              Cell: ({ row }) => <>{row.original.version}</>,
              Header: i18n.version,
              width: 75,
            },
            {
              id: 'startTime',
              Cell: ({ row }) => <>{row.original.createdAt}</>,
              Header: i18n.startTime,
              width: 250,
            },
            {
              id: 'status',
              Cell: ({ row }) => (
                <>
                  {row.original.state === 'completed' && (
                    <Badge color={'green'}>
                      <ApprovedIcon size={28} color="systemSuccessRegular" />
                    </Badge>
                  )}
                  {row.original.state === 'in_progress' && (
                    <Badge color={'blue'}>
                      <TimeIcon size={28} />
                    </Badge>
                  )}
                  {row.original.state !== 'in_progress' && row.original.state !== 'completed' && (
                    <Badge color={'red'}>
                      <TimeIcon size={28} color="systemDetrimentalRegular" />
                    </Badge>
                  )}
                </>
              ),
              Header: i18n.completed,
            },
            {
              id: 'productCount',
              Cell: ({ row }) => <>{row.original.productCount}</>,
              Header: i18n.productCount,
            },
            {
              id: 'download',
              Cell: ({ row }) => <>{<ResultDownloadButton stepRunId={row.original.id || ''} />}</>,
              Header: i18n.downloadCSV,
            },
          ]}
          data={data?.instacart_catalog_catflow_v1_CatflowAPI_ListStepRuns?.stepRuns || []}
          onPageChange={setPage}
          page={page}
          showItemsPerPageSelector
          totalPages={parseInt(
            data?.instacart_catalog_catflow_v1_CatflowAPI_ListStepRuns?.pageCount || '1'
          )}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={setItemsPerPage}
          width={'100%'}
        />
      )}
    </>
  )
}
