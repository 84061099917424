import { type CurrentAccountContextValue } from '@retailer-platform/dashboard/utils'

export const useHasPermission = (
  currentAccountContext: CurrentAccountContextValue,
  accessSlug: string
) => {
  const permissions = currentAccountContext?.account?.permissions ?? []

  for (const permission of permissions) {
    if (permission?.slug === accessSlug) {
      return true
    }
  }

  return false
}
