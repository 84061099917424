import { useMeshQuery } from '../../../apollo/clientHooks'
import {
  RetailerProductAggregateDataDocument,
  type RetailerProductAggregateDataQuery,
  type RetailerProductAggregateDataQueryVariables,
} from '../../../../__codegen__/api'

export const useRetailerProductAggregateDataMesh = (
  props: RetailerProductAggregateDataQueryVariables
) => {
  const variables = {
    ...props,
  }

  const { data, error, loading } = useMeshQuery<
    RetailerProductAggregateDataQuery,
    RetailerProductAggregateDataQueryVariables
  >(RetailerProductAggregateDataDocument, {
    fetchPolicy: 'cache-and-network',
    variables,
  })

  return {
    apiResult: data?.catalogToolsApiServiceGetRetailerProductData,
    apiSuccess: Boolean(data),
    apiLoading: loading,
    apiError: error,
  }
}
