import type { PropsWithChildren, FunctionComponent } from 'react'
import { useMemo } from 'react'
import { usePartnerContext } from '../../../utils/contexts/partner/PartnerContext.hooks'
import {
  type InstacartStoreConfiguration,
  StoreConfigurationRelationshipEnum,
} from '../../../__codegen__/api'
import { useStoreConfigurations } from '../../../api/store-configurations/useStoreConfigurations.hooks'
import {
  type SiteOperationIntent,
  type FilterSitesFn,
} from '../retailer-and-site-picker/RetailerAndSitePicker.types'
import { MARKETPLACE_SITE_ID } from '../../common/selected-site-page/CommonSiteFilters'
import { ViewType } from '../retailer-scope-wrapper/RetailerScopeWrapper'
import {
  createSiteOptions,
  getLeafNodes,
  groupBannersBySites,
  groupBannersByWarehouseGroup,
  normalizeStoreConfigurations,
} from './utils'
import MultiScopeDropdownComponent from './MultiScopeDropdownComponent'
import { type Option, type StoreConfig } from './utils'
import { type PopoverConfig } from './Common'

export interface Props {
  selectedOptions: { siteId?: string; retailerId?: string }[]
  onSelected: (selectedOptions: { siteId?: string; retailerId?: string }[]) => void
  selectionType?: ViewType
  multiSelect?: boolean
  scopeSelectionOptional?: boolean
  openByDefault?: boolean
  popoverConfig?: PopoverConfig
  storeConfigFilter?: FilterSitesFn
  bannerFilter?: (options: Option[]) => Option[]
  storeConfigOperationIntent?: SiteOperationIntent
  defaultToAll?: boolean
  rerenderIfInputChanges?: boolean
}

// there is built-in intersection for sets, but only as of 2024
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set/intersection
// lets use a custom intersection function for now
const intersection = (a: Set<string>, b: Set<string>): string[] => [...a].filter(e => b.has(e))

export const MultiScopePicker: FunctionComponent<PropsWithChildren<Props>> = ({
  selectedOptions,
  selectionType = ViewType.RETAILER_AND_STORE_CONFIG,
  multiSelect = false,
  scopeSelectionOptional,
  onSelected,
  popoverConfig,
  storeConfigFilter,
  bannerFilter,
  storeConfigOperationIntent,
  openByDefault,
  defaultToAll = true,
  rerenderIfInputChanges = false,
}) => {
  // We use `renderKey`` to force a re-render of <MultiScopeDropdownComponent> when the
  // "selectedOptions" input props change. We need to force a re-initialization of
  // the entire component because the component has a useState hook, and the
  // default value can only be set once per initialization.  This seems to be
  // a recommended practice by React. See
  // https://react.dev/reference/react/useState#resetting-state-with-a-key
  // https://legacy.reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
  // The other solution I see on the web is a janky use of useEffect to
  // change the value of the state via its setter after rendering is complete
  const { originalSelectedOptions, renderKey } = useMemo(
    () => ({
      originalSelectedOptions: new Set(
        selectedOptions.map(e => {
          if (selectionType === ViewType.RETAILER_AND_STORE_CONFIG) {
            return e.siteId + ':' + e.retailerId
          } else if (selectionType === ViewType.RETAILER) {
            return e.retailerId
          } else {
            return e.siteId
          }
        })
      ),
      renderKey: rerenderIfInputChanges ? JSON.stringify(selectedOptions) : undefined,
    }),
    [selectedOptions, selectionType, rerenderIfInputChanges]
  )

  const { warehouses: availableWarehouses } = usePartnerContext()

  const { data, loading } = useStoreConfigurations({
    retailerIds: availableWarehouses?.map(e => e.id.toString()),
    storeConfigRelationship: StoreConfigurationRelationshipEnum.Associated,
  })

  const storeConfigurations: StoreConfig[] = useMemo(() => {
    // we convert here from InstacartStoreConfiguration to StoreConfigurationForSelection
    // so that we can use the existing logic for filtering - but once we have the filtered
    // options, we can convert them back to InstacartStoreConfiguration

    const storeConfigs: InstacartStoreConfiguration[] = data?.storeConfigurationsByRetailerIds
    if (!storeConfigs) {
      return []
    }

    const warehouseIds = new Set(availableWarehouses?.map(e => e.id.toString()))

    const filteredStoreConfigs = storeConfigs.filter(sc => {
      const whitelistedWarehouseIds = new Set(sc.whitelistedWarehouseIds)
      return (
        intersection(whitelistedWarehouseIds, warehouseIds).length > 0 ||
        sc.id === MARKETPLACE_SITE_ID
      )
    })

    let options = normalizeStoreConfigurations(filteredStoreConfigs, storeConfigOperationIntent)

    if (storeConfigFilter) {
      options = storeConfigFilter(options)
    }

    return options
  }, [
    availableWarehouses,
    data?.storeConfigurationsByRetailerIds,
    storeConfigFilter,
    storeConfigOperationIntent,
  ])

  if (loading) {
    return null
  }

  let options: Option[]
  if (selectionType === ViewType.RETAILER) {
    options = groupBannersByWarehouseGroup(availableWarehouses, originalSelectedOptions)
  } else if (selectionType === ViewType.STORE_CONFIG) {
    options = createSiteOptions(storeConfigurations, originalSelectedOptions)
  } else if (selectionType === ViewType.RETAILER_AND_STORE_CONFIG) {
    options = groupBannersBySites(storeConfigurations, availableWarehouses, originalSelectedOptions)

    // remove sites with no banners
    options = options.filter(e => e.children?.length)
  } else {
    throw new Error('Unexpected selection type')
  }

  if (bannerFilter) {
    options = bannerFilter(options)
  }

  const leafNodes = getLeafNodes(options)
  const allLeafNodesSelected = leafNodes.every(e => e.selected)
  if (allLeafNodesSelected && defaultToAll && multiSelect) {
    leafNodes.forEach((e: Option) => {
      e.selected = false
    })
  }

  const onSingleSelect = (option: Option) => {
    if (selectionType === ViewType.RETAILER_AND_STORE_CONFIG) {
      onSelected([
        {
          siteId: option.breadcrumbs[0].id,
          retailerId: option.id,
        },
      ])
    } else if (selectionType === ViewType.RETAILER) {
      onSelected([{ retailerId: option.id }])
    } else {
      onSelected([{ siteId: option.id }])
    }
  }

  const onMultiSelect = (options: Option[]) => {
    if (selectionType === ViewType.RETAILER_AND_STORE_CONFIG) {
      onSelected(
        options.map(option => ({
          siteId: option.breadcrumbs[0].id,
          retailerId: option.id,
        }))
      )
    } else if (selectionType === ViewType.RETAILER) {
      onSelected(options.map(option => ({ retailerId: option.id })))
    } else {
      onSelected(options.map(option => ({ siteId: option.id })))
    }
  }

  if (multiSelect) {
    options = [
      {
        id: 'all',
        name: 'All',
        children: options,
        selected: false,
        type: 'all',
        breadcrumbs: [],
        leafNode: false,
      },
    ]
  }

  return (
    <MultiScopeDropdownComponent
      key={renderKey}
      options={options}
      multiSelect={multiSelect}
      onSingleSelect={onSingleSelect}
      onMultiSelect={onMultiSelect}
      scopeSelectionOptional={scopeSelectionOptional}
      openByDefault={openByDefault}
      selectionType={selectionType}
      popoverConfig={popoverConfig}
      defaultToAll={defaultToAll}
    />
  )
}

export default MultiScopePicker
