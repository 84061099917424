import { ICPlatformVersion } from '@retailer-platform/dashboard/access-control'
import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'
import { FeatureToggle } from './featureToggles'
import { FeatureEntitlements } from './featureEntitlements'

export const brandingV2AccessControl: DomainAccessControlConfig = {
  warehouseFeatureToggles: FeatureToggle.brandingV2,
}

export const storefrontHomepageDashboardV2: DomainAccessControlConfig = {
  permissions: [Permission.StoreConfigsView, Permission.PageBuilderView],
  featureEntitlementIds: [FeatureEntitlements.PageBuilder],
  minICPlatformVersion: ICPlatformVersion.r2025_03_0,
}

export const pageBuilderViewAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.StoreConfigsView, Permission.PageBuilderView],
  featureEntitlementIds: [FeatureEntitlements.PageBuilder],
}

export const storefrontHomepageDashboardV1: DomainAccessControlConfig = {
  permissions: [Permission.StoreConfigsView, Permission.PageBuilderView],
  featureEntitlementIds: [FeatureEntitlements.PageBuilder],
  maxICPlatformVersionExclusive: ICPlatformVersion.r2025_03_0,
}

export const pageBuilderModifyAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.StoreConfigsModify, Permission.PageBuilderModify],
  featureEntitlementIds: [FeatureEntitlements.PageBuilder],
}

export const marketplaceOnlyPageBuilderViewAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.StoreConfigsView, Permission.PageBuilderView],
  featureToggles: [FeatureToggle.pageBuilderSingleRetailer], // Feature toggle for now since internal only
}

export const marketplaceOnlyPageBuilderModifyAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.StoreConfigsModify, Permission.PageBuilderModify],
  featureToggles: [FeatureToggle.pageBuilderSingleRetailer], // Feature toggle for now since internal only
}

export const readOnlyAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.ReadOnly],
}

export const storeConfigurationDraftViewAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.StoreConfigsView, Permission.StoreConfigurationDraftView],
}

export const storeConfigurationDraftModifyAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.StoreConfigsModify, Permission.StoreConfigurationDraftModify],
}

export const storefrontBrandingCustomFontsControl: DomainAccessControlConfig = {
  featureEntitlementIds: [FeatureEntitlements.StorefrontBrandingCustomFonts],
}

export const storefrontBannerLogoAccessControl: DomainAccessControlConfig = [
  {
    warehouseFeatureToggles: FeatureToggle.logoManagement,
    permissions: [
      Permission.StoreConfigsView,
      Permission.StoreConfigsModify,
      Permission.StoreConfigurationDraftView,
      Permission.StoreConfigurationDraftModify,
    ],
  },
  {
    permissions: [
      Permission.StoreConfigsView,
      Permission.StoreConfigsModify,
      Permission.StoreConfigurationDraftView,
      Permission.StoreConfigurationDraftModify,
    ],
  },
  {
    featureToggles: FeatureToggle.logoManagement,
  },
]

// Depends on the logo management feature toggle above
export const storefrontBannerLogoSquareAccessControl: DomainAccessControlConfig = {
  minICPlatformVersion: ICPlatformVersion.r2025_01_0,
}

export const storefrontBannerLogoWarningAccessControl: DomainAccessControlConfig = [
  {
    minICPlatformVersion: ICPlatformVersion.r2025_01_0,
  },
]

export const storefrontCustomImagesAccessControl: DomainAccessControlConfig = {
  // note: internal only feature for now
  permissions: [
    Permission.StoreConfigsView,
    Permission.StoreConfigsModify,
    Permission.StoreConfigurationDraftView,
    Permission.StoreConfigurationDraftModify,
  ],
}

export const slotManagerViewAccessControl: DomainAccessControlConfig = [
  {
    featureEntitlementIds: [FeatureEntitlements.PageBuilder],
    permissions: [
      Permission.PageBuilderView,
      Permission.StoreConfigsView,
      Permission.RetailerFundedMarketingView,
    ],
  },
  {
    permissions: [Permission.AdminPanel],
  },
]

export const slotManagerModifyAccessControl: DomainAccessControlConfig = [
  {
    featureEntitlementIds: [FeatureEntitlements.PageBuilder],
    permissions: [
      Permission.PageBuilderModify,
      Permission.StoreConfigsModify,
      Permission.RetailerFundedMarketingModify,
    ],
  },
  {
    permissions: [Permission.AdminPanel],
  },
]
