import _groupBy from 'lodash/groupBy'
import { type WarehouseWithFeatures } from '../../../utils/contexts/warehouse/queries/warehouse'
import { type InstacartStoreConfiguration } from '../../../__codegen__/api'
import {
  type SiteOperationIntent,
  SiteSelectOption,
} from '../retailer-and-site-picker/RetailerAndSitePicker.types'
import { MARKETPLACE_SITE_ID } from '../../common/selected-site-page/CommonSiteFilters'

export interface Option {
  id: string
  name: string
  shortName?: string
  selected: boolean
  type: 'banner' | 'site' | 'warehouseGroup' | 'all'
  breadcrumbs: Option[]
  leafNode: boolean
  siteUrl?: string
  siteActive?: boolean // boolean flag in Store Configuration db table - can still select inactive sites
  siteEnabled?: boolean // custom login in IPP to enable/disable certain sites - cannot select disabled sites
  logoUrl?: string
  children?: Option[]
}

export interface StoreConfig {
  storeConfig: InstacartStoreConfiguration
  selectOption: SiteSelectOption
}

const getDomainName = (url: string) => new URL(url).hostname

export const storeConfigToOption = (
  storeConfigExtra: StoreConfig,
  children: Option[],
  originalSelectedOptions: Set<string>
): Option => {
  const { storeConfig, selectOption } = storeConfigExtra

  return {
    id: storeConfig.id,
    name: `${storeConfig.brandedName}`,
    shortName: storeConfig.brandedName,
    selected: originalSelectedOptions.has(storeConfig.id),
    type: 'site',
    siteUrl: getDomainName(storeConfig.domainName),
    logoUrl: '',
    children: children || [],
    leafNode: !children?.length,
    siteActive: storeConfig.active,
    siteEnabled: selectOption === SiteSelectOption.ALLOW,
    breadcrumbs: [],
  }
}

export const bannerToOption = (
  banner: WarehouseWithFeatures,
  originalSelectedOptions: Set<string>
): Option => ({
  id: banner.id.toString(),
  name: banner.name,
  selected: originalSelectedOptions.has(banner.id.toString()),
  type: 'banner',
  logoUrl: banner.logo?.url,
  breadcrumbs: [],
  leafNode: true,
})

export const createSiteOptions = (
  storeConfigs: StoreConfig[],
  originalSelectedOptions: Set<string>
) => {
  const options: Option[] = []
  storeConfigs?.forEach(storeConfig => {
    const siteOption = storeConfigToOption(storeConfig, [], originalSelectedOptions)
    options.push(siteOption)
  })
  return options
}

export const groupBannersByWarehouseGroup = (
  warehouses: WarehouseWithFeatures[],
  originalSelectedOptions: Set<string>
) => {
  const warehouseGroups = _groupBy(
    warehouses,
    warehouse => warehouse.warehouseGroup?.franchisorId || 'undefined'
  )

  const options: Option[] = []

  warehouseGroups.undefined?.forEach(warehouse => {
    const bannerOption = bannerToOption(warehouse, originalSelectedOptions)
    options.push(bannerOption)
  })
  delete warehouseGroups.undefined

  for (const [franchisorId, franchisorWarehouses] of Object.entries(warehouseGroups)) {
    const children = franchisorWarehouses.map(warehouse =>
      bannerToOption(warehouse, originalSelectedOptions)
    )
    const warehouseGroupOption: Option = {
      id: franchisorId,
      name: franchisorWarehouses[0].warehouseGroup?.franchisorName,
      selected: false,
      type: 'warehouseGroup',
      children: children,
      leafNode: !children?.length,
      breadcrumbs: [],
    }

    options.push(warehouseGroupOption)
  }

  options.sort((a, b) => a.name.localeCompare(b.name))

  return options
  // return [...options.filter(e => e.selected), ...options.filter(e => !e.selected)]
}

export const groupBannersBySites = (
  storeConfigs: StoreConfig[],
  availableWarehouses: WarehouseWithFeatures[],
  originalSelectedOptions: Set<string>
) => {
  const options: Option[] = []
  storeConfigs?.forEach(storeConfig => {
    const filteredWarehouses = availableWarehouses?.filter(
      warehouse =>
        storeConfig.storeConfig.whitelistedWarehouseIds.includes(warehouse.id.toString()) ||
        storeConfig.storeConfig.id === MARKETPLACE_SITE_ID
    )

    const bannersByWarehouseGroup = groupBannersByWarehouseGroup(
      filteredWarehouses,
      originalSelectedOptions
    )
    const siteOption = storeConfigToOption(
      storeConfig,
      bannersByWarehouseGroup,
      originalSelectedOptions
    )

    bannersByWarehouseGroup.forEach(banner => {
      banner.breadcrumbs = [siteOption]
      const breadcrumbsIds = [...banner.breadcrumbs, banner].map(e => e.id).join(':')
      banner.selected = originalSelectedOptions.has(breadcrumbsIds)
    })

    siteOption.children = [
      ...bannersByWarehouseGroup.filter(e => e.selected),
      ...bannersByWarehouseGroup.filter(e => !e.selected),
    ]

    options.push(siteOption)
  })

  // sort by name
  options.sort((a, b) => a.name.localeCompare(b.name))
  return options
}

export const getLeafNodes = (options: Option[]) => {
  const leafNodes: Option[] = []
  options
    .filter(option => option.type != 'site' || option.siteEnabled)
    .forEach(option => {
      if (option.leafNode) {
        leafNodes.push(option)
      } else {
        leafNodes.push(...getLeafNodes(option.children ?? []))
      }
    })
  return leafNodes
}

export const getKey = (option: Option) =>
  [...option.breadcrumbs, option].map(e => e.type + '-' + e.id).join(':')

export const isSelected = (option: Option, selectedOptions: Set<string>) =>
  selectedOptions.has(getKey(option))

export const normalizeStoreConfigurations = (
  storeConfigurations: InstacartStoreConfiguration[],
  storeConfigOperationIntent: SiteOperationIntent
): StoreConfig[] =>
  storeConfigurations?.map(s => {
    const disabled = storeConfigOperationIntent === 'modify' && !s.userAccessToModify

    return {
      storeConfig: s,
      selectOption: disabled ? SiteSelectOption.SELECT_NOT_APPLICABLE : SiteSelectOption.ALLOW,
    }
  }) || []
