import React, { type FunctionComponent, useState } from 'react'
import { ChevronDownIcon, ChevronRightIcon, EditIcon, SearchIcon } from '@instacart/ids-core'
import { IconButton, Marker } from '@retailer-platform/shared-components/src/tds'
import { Badge, colors, fontWeight, spacing } from '@retailer-platform/shared-components'
import styled from '@emotion/styled'
import { fontSize } from '@retailer-platform/dashboard/gin-and-tonic'
import {
  DetectionStatusState,
  type GetDetectionResponseDetectionVersion,
  type ListDetectionsResponseDetection,
} from '../../../api'
import { useDomainMessages } from '../../../utils/domain/intl.ts'
import { useDomainGoToPath } from '../../../utils/domain/routing.ts'
import { Divider, DividerText } from '../../../utils/md-platform/common.styles.tsx'
import { MdPlatformVersionProperties } from '../../../utils/md-platform/components/MdPlatformVersionProperties.tsx'
import { MdPlatformVersionState } from '../../../utils/md-platform/components/MdPlatformVersionState.tsx'

export const TableRow = styled.tr<{ ignoreBorderBottom?: boolean }>(({ ignoreBorderBottom }) => ({
  verticalAlign: 'middle',
  borderBottom: ignoreBorderBottom ? 'none' : `1px solid ${colors.GRAYSCALE.X20}`,
}))

export const TableRowCol = styled.td({
  padding: spacing.X16,
  verticalAlign: 'middle',
})

export const PropertyContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: spacing.X16,
})

export const PropertySection = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

export const Property = styled.div({
  padding: spacing.X16,
  verticalAlign: 'middle',
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.X12,
  lineHeight: spacing.X20,
  wordBreak: 'break-word',
  maxWidth: '628px',
})

export const PropertyTitle = styled.span({
  verticalAlign: 'middle',
  fontWeight: 'bold',
})

export const PropertyWarning = styled.span<{ error?: boolean }>(({ error }) => ({
  verticalAlign: 'middle',
  fontWeight: 'bold',
  color: error ? colors.DETRIMENTAL.REGULAR : colors.LOYALTY.REGULAR,
  fontSize: fontSize.X12,
}))

export const EmailBadge = styled(Badge)(() => ({
  padding: spacing.X12,
  fontSize: fontSize.X15,
  fontWeight: fontWeight.REGULAR,
  wordBreak: 'normal',
  whiteSpace: 'nowrap',
}))

interface Props {
  detection: ListDetectionsResponseDetection
  onEditDetection: (id: string) => void
}

export const MdPlatformListTableRow: FunctionComponent<React.PropsWithChildren<Props>> = ({
  onEditDetection,
  detection,
}) => {
  const [toggled, setToggled] = useState<boolean>(false)

  const i18n = useDomainMessages({
    inReview: 'catalogAdminDomain.mdPlatformList.table.reviewStates.inReview',
    testing: 'catalogAdminDomain.mdPlatformList.table.reviewStates.testing',
    rejected: 'catalogAdminDomain.mdPlatformList.table.reviewStates.rejected',
    approved: 'catalogAdminDomain.mdPlatformList.table.reviewStates.approved',
    expandDetails: 'catalogAdminDomain.mdPlatformList.table.details.label',
    createdBy: 'catalogAdminDomain.mdPlatformList.table.createdBy',
    createdWhy: 'catalogAdminDomain.mdPlatformList.table.createdWhy',
    none: 'catalogAdminDomain.mdPlatformList.table.values.none',
    edit: 'catalogAdminDomain.mdPlatformList.table.actions.edit',
    details: 'catalogAdminDomain.mdPlatformList.table.actions.edit',
    query: 'catalogAdminDomain.mdPlatformList.table.query',
  })

  const goToDetails = useDomainGoToPath(
    'app-admin-catalog-admin-md-platform-details',
    undefined,
    undefined,
    {
      detectionId: detection?.id,
    }
  )

  const limit = detection?.currentAttributes?.limit ?? i18n.none

  return (
    <>
      <TableRow key={detection.id} ignoreBorderBottom={toggled}>
        <TableRowCol>
          <IconButton
            icon={toggled ? ChevronDownIcon : ChevronRightIcon}
            aria-label={i18n.expandDetails}
            onClick={() => setToggled(!toggled)}
            variant="tertiary"
          />
        </TableRowCol>
        <TableRowCol>{`${detection.id}`}</TableRowCol>
        <TableRowCol>{`${detection.name}`}</TableRowCol>
        <TableRowCol>{detection.version}</TableRowCol>
        <TableRowCol style={{ textAlign: 'center' }}>
          {detection.currentAttributes.enabled ? (
            <Marker variant={'success'} text={'Enabled'} />
          ) : (
            <Marker variant={'detrimental'} text={'Disabled'} />
          )}
        </TableRowCol>
        <TableRowCol>{limit}</TableRowCol>
        <TableRowCol style={{ textAlign: 'center' }}>
          {detection?.currentAttributes?.owner && (
            <Marker variant={'neutral'} text={detection.currentAttributes.owner} />
          )}
        </TableRowCol>
        <TableRowCol style={{ textAlign: 'center' }}>
          <MdPlatformVersionState state={detection.state} />
        </TableRowCol>
        <TableRowCol>
          <div style={{ display: 'flex', gap: `${spacing.X8}` }}>
            <IconButton
              icon={EditIcon}
              aria-label={i18n.edit}
              variant="secondary"
              disabled={
                detection.state &&
                (detection.state ==
                  DetectionStatusState.DetectionStatusStateRunningAutomatedTests ||
                  detection.state == DetectionStatusState.DetectionStatusStateAdminAudit)
              }
              onClick={() =>
                onEditDetection(
                  !detection.state ||
                    detection.state !=
                      DetectionStatusState.DetectionStatusStateRunningAutomatedTests
                    ? detection.id
                    : null
                )
              }
              compact
            />
            <IconButton
              icon={SearchIcon}
              aria-label={i18n.details}
              variant="secondary"
              onClick={() => goToDetails()}
              compact
            />
          </div>
        </TableRowCol>
      </TableRow>
      {toggled ? (
        <TableRow>
          <TableRowCol colSpan={9} style={{ paddingTop: '0' }}>
            <Divider>
              <DividerText>Details</DividerText>
            </Divider>
            <MdPlatformVersionProperties
              showEnabled={false}
              showState={false}
              version={
                {
                  version: detection.version,
                  attributes: {
                    ...detection?.currentAttributes,
                  },
                  stats: [],
                  states: [],
                } as GetDetectionResponseDetectionVersion
              }
            />
          </TableRowCol>
        </TableRow>
      ) : null}
    </>
  )
}
