import type { ChangeEvent, PropsWithChildren, ComponentType } from 'react'
import { Component } from 'react'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import {
  SelectField,
  Button,
  TextField,
  MultiselectDropdown,
  Alert,
  Flex,
  Text,
} from '@retailer-platform/shared-components/src/tds'
import Modal from '../../../../../components/Modal'
import { Box } from '@instacart/ids-core'
import instacart from '../../../../../common/instacart'
import { errors } from '../../../../../../utils/error-handling/errors'
import Slash from '../../../../../components/Slash'
import {
  withNotificationsContext,
  type NotificationsContextValue,
} from '../../../../../contexts/notifications/NotificationsContext'
import { type RegionLocationContextValue } from '../../../../../contexts/regionLocation/RegionLocationContext'
import deprecatedAnalytics from '../../../../../common/deprecatedAnalytics'
import { AsyncStatus } from '../../../../../common/types'
import { YES_NO_OPTIONS, STRING_TO_BOOLEAN_MAP } from '../../../../../common/constants'
import { withTrackEventOnMount } from '../../../../../../utils/events/hocs'
import { COST_UNIT_OPTIONS, COST_UNIT } from '../../../../../common/utils/catalog/units'

const buttonStyle = {
  marginLeft: '10px',
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 380px;
`

interface Props
  extends NotificationsContextValue,
    Pick<RegionLocationContextValue, 'inventoryAreas' | 'inventoryAreaStatus'> {
  onClose: () => void
  partnerId: string
  warehouseId: string
  productId: string
}

interface State {
  isBusy: boolean
  isError: boolean
  inventoryAreaIds: string[]
  available: boolean
  taxable: boolean
  costPricePerUnit: string
  cost_unit: string
}

class AddProductToLocationsModal extends Component<Props, State> {
  readonly state: State = {
    isBusy: false,
    isError: false,
    inventoryAreaIds: [],
    available: false,
    taxable: false,
    costPricePerUnit: '',
    cost_unit: COST_UNIT.EACH,
  }

  handleClose = () => {
    this.props.onClose()
  }

  onSubmit = async () => {
    const { costPricePerUnit, inventoryAreaIds, taxable, available, cost_unit } = this.state
    if (!costPricePerUnit || !inventoryAreaIds) return

    this.setState({
      isBusy: true,
      isError: false,
    })

    const { partnerId, warehouseId, productId } = this.props

    try {
      await instacart.go.post(
        `/v1/partners/${partnerId}/warehouses/${warehouseId}/products/${productId}/items/bulk_create`,
        {
          data: {
            inventory_area_ids: inventoryAreaIds,
            available,
            taxable,
            cost_price_per_unit: parseFloat(costPricePerUnit),
            cost_unit,
          },
        }
      )
      deprecatedAnalytics.track('product.add_warehouse_location', {
        productId,
        source_value: 'new',
        inventory_area_ids: inventoryAreaIds,
      })
      this.props.notify(<FormattedMessage id="catalog.products.locations.requestSubmitted" />)
      this.props.onClose()
    } catch (e) {
      errors.captureException(e, `AddProductToLocationsModal with error + ${e}`)

      this.setState({
        isError: true,
        isBusy: false,
      })
    }
  }

  onInventoryAreasChange = (inventoryAreaIds: string[]) => {
    this.setState({
      inventoryAreaIds,
    })
  }

  onAvailableChange = (available: boolean) => {
    this.setState({
      available,
    })
  }

  onTaxableChange = (taxable: boolean) => {
    this.setState({
      taxable,
    })
  }

  onPriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      costPricePerUnit: e.target.value,
    })
  }

  onCostUnitChange = (cost_unit: string) => {
    this.setState({
      cost_unit,
    })
  }

  // eslint-disable-next-line
  optionify(values: any[]) {
    return (values || []).map(({ id, name }) => ({
      label: name,
      value: id.toString(),
    }))
  }

  render() {
    const { inventoryAreaStatus, inventoryAreas } = this.props

    const currentValue = this.optionify(inventoryAreas).filter(v =>
      this.state.inventoryAreaIds.includes(v.value)
    )

    return (
      <Modal height={660} onClose={this.handleClose}>
        {(
          Header: ComponentType<PropsWithChildren<unknown>>,
          Body: ComponentType<PropsWithChildren<unknown>>,
          Footer: ComponentType<PropsWithChildren<unknown>>
        ) => [
          <Header>Add Product to Store Locations</Header>,
          <Body>
            <Container>
              <InnerContainer>
                <Flex.Column gap="24px" width="100%">
                  {this.state.isError && <Alert variant="error">Please try again later</Alert>}

                  <Text typography="bodyEmphasized">Store Locations</Text>
                </Flex.Column>
                <Flex.Column gap="24px" width="100%">
                  <MultiselectDropdown
                    compact
                    isLoading={inventoryAreaStatus === AsyncStatus.Busy}
                    options={this.optionify(inventoryAreas)}
                    value={currentValue}
                    onChange={options => {
                      this.onInventoryAreasChange(options.map(o => o.value))
                    }}
                    controlShouldRenderValue={false}
                    labelProps={{
                      label: 'Store Locations',
                    }}
                    width="100%"
                    placeholder={
                      currentValue.length
                        ? `All Locations (${currentValue.length})`
                        : 'All Locations'
                    }
                  />

                  <SelectField
                    compact
                    value={this.state.available.toString()}
                    onChange={v => this.onAvailableChange(STRING_TO_BOOLEAN_MAP[v])}
                    options={YES_NO_OPTIONS}
                    labelProps={{
                      label: 'Available',
                    }}
                  />

                  <SelectField
                    compact
                    value={this.state.taxable.toString()}
                    onChange={v => this.onTaxableChange(STRING_TO_BOOLEAN_MAP[v])}
                    options={YES_NO_OPTIONS}
                    labelProps={{
                      label: 'Taxable',
                    }}
                  />

                  <Flex.Row alignItems="end">
                    <TextField
                      compact
                      name="costPricePerUnit"
                      prefix="$"
                      value={this.state.costPricePerUnit}
                      onChange={this.onPriceChange}
                      labelProps={{
                        label: 'Price',
                      }}
                    />
                    <Box css={{ marginBottom: '8px' }}>
                      <Slash />
                    </Box>
                    <SelectField
                      compact
                      placeholder="Select Unit"
                      value={this.state.cost_unit}
                      onChange={this.onCostUnitChange}
                      options={COST_UNIT_OPTIONS}
                    />
                  </Flex.Row>
                </Flex.Column>
              </InnerContainer>
            </Container>
          </Body>,
          <Footer>
            <Box css={{ marginLeft: 'auto' }}>
              <Button.Group>
                <Button
                  style={buttonStyle}
                  onClick={this.handleClose}
                  disabled={this.state.isBusy}
                  variant="secondary"
                >
                  Cancel
                </Button>
                <Button
                  style={buttonStyle}
                  onClick={this.onSubmit}
                  disabled={this.state.isBusy}
                  variant="primary"
                >
                  Save
                </Button>
              </Button.Group>
            </Box>
          </Footer>,
        ]}
      </Modal>
    )
  }
}

export default withTrackEventOnMount({
  id: 'catalog.products.store_locations.add_location.viewed',
  description: 'Viewed the add locations section of the product store locations page',
})(withNotificationsContext(AddProductToLocationsModal))
