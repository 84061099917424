import type { PropsWithChildren, ComponentType } from 'react'
import { useState, useMemo, useEffect, useCallback, Fragment } from 'react'
import Modal from '../../../../../components/Modal'
import styled from '@emotion/styled'
import {
  Alert,
  Button,
  Checkbox,
  Text,
  TextField,
} from '@retailer-platform/shared-components/src/tds'
import { colors } from 'ic-snacks'
import { useCreateTempOverride } from '../../../../../sections/catalog/graphql/createTempOverride.hooks'
import { type NotificationsContextValue } from '../../../../../contexts/notifications/NotificationsContext'
import { FormattedMessage } from 'react-intl'
// eslint-disable-next-line workspaces/no-relative-imports
import { useDomainMessages } from '../../../../../../../../../domains/products/src/utils/domain/intl'

interface Props extends NotificationsContextValue {
  onClose(boolean?): void
  items: Options[]
  initialItemIds: number[]
  productId: string
  retailerId: string
  localeId: number
  surfaceId: number
}

interface Options {
  itemId: number
  displayName: string
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem;
  height: 590px;
  overflow: hidden;
  padding: 1px;
`

const ScrollContainer = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
  padding: 0 1rem;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  row-gap: 1rem;
`

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: stretch;
  white-space: nowrap;
`

const FilterBoxSize = styled.div`
  min-height: 4rem;
  max-height: 4rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  border-bottom: 1px solid ${colors.GRAY_93};
  padding: 0 0 1rem 0;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`

const PrimaryButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

const AddTempOverridePatchModal = ({
  productId,
  retailerId,
  surfaceId,
  localeId,
  onClose,
  items,
  initialItemIds,
  notifyError,
}: Props) => {
  const [selectedItemIds, setSelectedItemIds] = useState<Set<number>>(new Set(initialItemIds))
  const [searchText, setSearchText] = useState('')
  const [limitedToActive, setLimitedToActive] = useState(false)

  const allSelected = selectedItemIds.size === items.length

  const { createTempOverrideCall, loading, error, called, success } = useCreateTempOverride()

  const surfaceLabel = useMemo(() => {
    if (surfaceId === 2) {
      return <FormattedMessage id="productsDomain.view.details.surfaces.storefrontPro" />
    }
    return <FormattedMessage id="productsDomain.view.details.surfaces.marketplace" />
  }, [surfaceId])

  useEffect(() => {
    if (success && called) {
      onClose(true)
      window.location.reload()
    }
  }, [success, called, onClose])

  const handleSubmit = useCallback(() => {
    const itemsArr = Array.from(selectedItemIds)
    const tombstones = initialItemIds.filter(i => !selectedItemIds.has(i))
    createTempOverrideCall(productId, retailerId, localeId, surfaceId, itemsArr, tombstones)
  }, [
    createTempOverrideCall,
    initialItemIds,
    localeId,
    productId,
    retailerId,
    selectedItemIds,
    surfaceId,
  ])

  const handleAllCheckboxClick = useCallback(() => {
    if (allSelected) {
      setSelectedItemIds(new Set())
    } else {
      setSelectedItemIds(
        items.reduce((set, ia) => {
          set.add(ia.itemId)
          return set
        }, new Set<number>())
      )
    }
  }, [allSelected, items])

  const handleIACheckboxClick = useCallback(
    (iaId: number) => {
      if (selectedItemIds.has(iaId)) {
        selectedItemIds.delete(iaId)
        setSelectedItemIds(new Set(selectedItemIds))
      } else {
        selectedItemIds.add(iaId)
        setSelectedItemIds(new Set(selectedItemIds))
      }
    },
    [selectedItemIds]
  )

  const filteredIAs = useMemo(
    () =>
      items
        ?.filter(ia => ia.displayName?.toLowerCase()?.includes(searchText.toLowerCase()))
        ?.filter(ia => (limitedToActive ? selectedItemIds.has(ia?.itemId) : true)),
    [items, limitedToActive, searchText, selectedItemIds]
  )

  useEffect(() => {
    if (error && !loading) {
      notifyError(
        <FormattedMessage id="productsDomain.view.storeLocations.override.modal.error.text" />
      )
    }
  }, [notifyError, error, loading])

  const i18n = useDomainMessages({
    title: 'productsDomain.view.storeLocations.override.modal.title',
    subtitle: 'productsDomain.view.storeLocations.override.modal.subtitle',
    onlySelectedFilter: 'productsDomain.view.storeLocations.override.modal.only.selected.filter',
  })

  return (
    <Modal onClose={onClose} height={800}>
      {(
        Header: ComponentType<PropsWithChildren<unknown>>,
        Body: ComponentType<PropsWithChildren<unknown>>,
        Footer: ComponentType<PropsWithChildren<unknown>>
      ) => [
        <Header>
          <div>
            <Text typography="subtitle">{i18n.title}</Text>
            <Text typography="bodyRegular">
              {i18n.subtitle} {surfaceLabel}
            </Text>
          </div>
        </Header>,
        <Body>
          <Container>
            <Alert variant={'warning'}>
              <FormattedMessage id="productsDomain.view.storeLocations.override.modal.warning.text" />
            </Alert>
            <FilterBoxSize>
              <TextField
                id="emergency-patch-search"
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
                placeholder="Search Store Location"
              />
              <Center>
                <Checkbox
                  label={i18n.onlySelectedFilter}
                  checked={limitedToActive}
                  onChange={() => setLimitedToActive(!limitedToActive)}
                />
              </Center>
            </FilterBoxSize>
            {filteredIAs.length ? (
              <ScrollContainer>
                <Grid>
                  <Text typography="bodyEmphasized">Store Location</Text>
                  <FlexColumn>
                    <Text typography="bodyEmphasized">Made unavailable</Text>
                    <FlexRow>
                      <Checkbox
                        label="Select All"
                        checked={allSelected}
                        onChange={handleAllCheckboxClick}
                      />
                    </FlexRow>
                  </FlexColumn>
                  {filteredIAs.map(ia => (
                    <Fragment>
                      <div>{ia.displayName}</div>
                      <Center>
                        <Checkbox
                          label=""
                          checked={selectedItemIds.has(ia.itemId)}
                          onChange={() => handleIACheckboxClick(ia.itemId)}
                        />
                      </Center>
                    </Fragment>
                  ))}
                </Grid>
              </ScrollContainer>
            ) : (
              <Center>
                <Text typography="bodyEmphasized">
                  <FormattedMessage id="productsDomain.view.storeLocations.override.modal.no.store" />
                </Text>
              </Center>
            )}
          </Container>
        </Body>,
        <Footer>
          <ButtonRow>
            <Button.Group>
              <Text typography="bodyEmphasized">{selectedItemIds.size} selected</Text>
              <Button variant="secondary" onClick={onClose}>
                <FormattedMessage id="productsDomain.view.storeLocations.override.modal.cancel.button" />
              </Button>
              <PrimaryButtons>
                <Button
                  variant="primary"
                  loading={called && loading}
                  disabled={called && loading}
                  onClick={handleSubmit}
                >
                  <FormattedMessage id="productsDomain.view.storeLocations.override.modal.submit.button" />
                </Button>
              </PrimaryButtons>
            </Button.Group>
          </ButtonRow>
        </Footer>,
      ]}
    </Modal>
  )
}
export default AddTempOverridePatchModal
