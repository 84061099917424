import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { FeatureToggle } from './featureToggles'
import { Permission } from './permissions'

export const catalogAdminQualityAuditAccessConfiguration: DomainAccessControlConfig = [
  {
    permissions: Permission.QualityAuditViewAccess,
  },
  {
    permissions: Permission.QualityAuditSamaViewAccess,
  },
]

export const showQasWorkflowActions: DomainAccessControlConfig = {
  featureToggles: [FeatureToggle.ShowQasWorkflowActions],
}
