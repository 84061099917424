/* eslint-disable react/jsx-key */
import React, { lazy } from 'react'
import { DomainRoute } from '../utils/domain/routingComponents'

const AuthenticationLogInV2Page = lazy(() => import('../pages/log-in-v2/AuthenticationLogInV2Page'))
const RedirectToAuthServiceRequestPasswordReset = lazy(
  () => import('../components/auth-service/RedirectToAuthServiceRequestPasswordReset')
)
const AuthenticationAuthServiceCallbackPage = lazy(
  () => import('../pages/auth-service-callback/AuthenticationAuthServiceCallbackPage')
)
// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const authenticationPublicRouter = [
  <DomainRoute
    exact
    route="app-public-authentication-log-in"
    component={AuthenticationLogInV2Page}
  />,

  <DomainRoute
    exact
    route="app-public-authentication-request-email-verification"
    component={RedirectToAuthServiceRequestPasswordReset}
  />,
  <DomainRoute
    exact
    route="app-public-authentication-email-verification"
    component={RedirectToAuthServiceRequestPasswordReset}
  />,
  <DomainRoute
    exact
    route="app-public-authentication-password-reset"
    component={RedirectToAuthServiceRequestPasswordReset}
  />,
  <DomainRoute
    exact
    route="app-public-authentication-request-password-reset"
    component={RedirectToAuthServiceRequestPasswordReset}
  />,
  <DomainRoute
    exact
    route="app-public-authentication-complete-account-setup"
    component={RedirectToAuthServiceRequestPasswordReset}
  />,
  <DomainRoute
    exact
    route="app-public-authentication-request-account-setup"
    component={RedirectToAuthServiceRequestPasswordReset}
  />,

  <DomainRoute
    exact
    route="app-public-authentication-auth-service-callback"
    component={AuthenticationAuthServiceCallbackPage}
  />,
  // @hygen:inject router-route
  // injection point for routes, please do not delete
]
