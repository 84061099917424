import React, { type FunctionComponent } from 'react'
import { Tab, TabList, TabPanel, Tabs } from '@retailer-platform/shared-components'
import styled from '@emotion/styled'
import { type AttributeManagementStep } from '../../types/attributeManagement.types'
import { PropertiesTab } from './components/properties-tab/PropertiesTab'
import { AttributeManagementDetailsChangesTable } from './components/changes-tab/ChangesTab'
import { ResultsTab } from './components/results-tab/ResultsTab'

interface Props {
  step: AttributeManagementStep
}

const tabs = {
  properties: 'properties',
  changes: 'changes',
  results: 'results',
}

const TabStretcher = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  alignItems: 'stretch',
})

export const CatalogAttributeManagementPlatformDetails: FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ step }) => (
  <TabStretcher>
    <Tabs>
      <TabList>
        <Tab id={tabs.properties}>Properties</Tab>
        <Tab id={tabs.changes}>Changes</Tab>
        <Tab id={tabs.results}>Results</Tab>
      </TabList>
      <TabPanel tabId={tabs.properties}>
        <PropertiesTab step={step} />
      </TabPanel>
      <TabPanel tabId={tabs.changes}>
        <AttributeManagementDetailsChangesTable versions={step.versions} />
      </TabPanel>
      <TabPanel tabId={tabs.results}>
        <ResultsTab stepId={step.id || ''} />
      </TabPanel>
    </Tabs>
  </TabStretcher>
)
