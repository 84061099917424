import { type FunctionComponent, useState } from 'react'
import { PaginatedGrid, Tooltip, Text } from '@retailer-platform/shared-components'
import { HelpIcon } from '@instacart/ids-core'
import styled from '@emotion/styled'
import { TooltipContainer } from '../../utils/common.styles.tsx'
import { useDomainMessages } from '../../utils/domain/intl.ts'
import { AttributeManagementListTableNameCell } from './AttributeManagementListTableNameCell.tsx'
import { type Action } from './AttributeManagementList.tsx'
type ListData = {
  id: string | undefined
  name: string
  type: string
  owner: string
}

interface Props {
  listData: ListData[]
  loading: boolean
  actions?: Action[]
}

const ActionsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
`

export const AttributeManagementListTable: FunctionComponent<Props> = ({
  listData,
  loading,
  actions,
}) => {
  const i18n = useDomainMessages({
    id: 'catalogAdminDomain.attributeManagement.table.headers.id',
    name: 'catalogAdminDomain.attributeManagement.table.headers.name',
    type: 'catalogAdminDomain.attributeManagement.table.headers.type',
    typeHelp: 'catalogAdminDomain.attributeManagement.table.headers.type.help',
    owner: 'catalogAdminDomain.attributeManagement.table.headers.owner',
    actions: 'catalogAdminDomain.attributeManagement.table.headers.actions',
    select:
      'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepSelectModal.select',
  })

  const [page, setPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)

  return (
    <PaginatedGrid
      isLoading={loading}
      columns={[
        {
          id: 'id',
          Cell: ({ row }) => <>{row.original.id}</>,
          Header: i18n.id,
          width: 40,
        },
        {
          id: 'name',
          Cell: ({ row }) => <>{row.original.name}</>,
          Header: i18n.name,
          width: 250,
        },
        {
          id: 'type',
          Cell: ({ row }) => <>{row.original.type}</>,
          Header: (
            <TooltipContainer>
              {i18n.type}{' '}
              <Tooltip placement="bottom" target={<HelpIcon />}>
                {i18n.typeHelp}{' '}
              </Tooltip>{' '}
            </TooltipContainer>
          ),
          width: 150,
        },
        {
          id: 'owner',
          Cell: ({ row }) => <Text size="medium">{row.original.owner}</Text>,
          Header: i18n.owner,
          minWidth: 200,
        },
        {
          id: 'actions',
          Cell: ({ row }) => (
            <ActionsRow>
              {actions && actions.map(action => action(row.original.id || '0'))}
            </ActionsRow>
          ),
          Header: i18n.actions,
        },
      ]}
      data={listData}
      onPageChange={setPage}
      page={page}
      showItemsPerPageSelector
      totalPages={Math.ceil(listData.length / itemsPerPage)}
      itemsPerPage={itemsPerPage}
      onItemsPerPageChange={setItemsPerPage}
      width={999}
    ></PaginatedGrid>
  )
}
