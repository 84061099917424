import { usePartnerContext } from '../../../../../../utils/contexts/partner/PartnerContext.hooks'
import { PartnerType } from '../../../../../../exports/utils'
import clientEnv from '../../../../../../utils/global/clientEnv'

export type HelpMenuLink = {
  href?: string
  messageId: string
  onClick?: () => void
}

export const useHelpMenuLinks = () => {
  const partnerContext = usePartnerContext()

  const docsUrl =
    partnerContext.partnerType === PartnerType.IDP
      ? 'https://docs.instacart.com/developer_platform_api/'
      : clientEnv.PUBLIC_CLIENT_PARTNER_DOCS_URL

  const termsUrl =
    partnerContext.partnerType === PartnerType.IDP
      ? 'https://docs.instacart.com/developer_platform_api/guide/terms_and_policies/developer_terms/'
      : 'https://docs.instacart.com/instacart/ipp-terms/'

  return [
    { href: docsUrl, messageId: 'navV2.userSettings.documentation' },
    { href: 'https://instacart.com/press', messageId: 'press' },
    { href: 'https://instacart.com/privacy', messageId: 'privacy' },
    { href: 'https://instacart.safebase.us/', messageId: 'security_and_trust' },
    { href: termsUrl, messageId: 'terms' },
    {
      href: 'https://instacart.atlassian.net/servicedesk/customer/portal/1',
      messageId: 'service_desk',
    },
  ]
}
