export enum FeatureToggle {
  campaignManagement = 'campaignManagement',
  campaignManagementPrimarySplitBanner = 'rt_campaign_management_primary_split_banner',
  campaignManagementRedemptionUrls = 'rt_redemption_urls',
  campaignManagementMultiRetailerPages = 'rt_multi_retailer_pages',
  navigateToContentPage = 'rt_navigate_to_content_page',
  navigateToAuthentication = 'rt_navigate_to_authentication',
  navigateToFlyers = 'rt_navigate_to_flyers',
  flyoutsOnCreativeManager = 'rt_flyout_on_creative_manager',
  adminCalendarPage = 'rt_campaigns_admin_calendar_enabled',
  adminCreativesPage = 'rt_campaigns_admin_creatives_enabled',
  universalValueCarousel = 'rt_universal_value_carousel',
  collectionSlots = 'rt_collection_slots',
  retailerForwardList = 'rt_retailer_forward_list',
  retailerForwardListImageRow = 'rt_retailer_forward_list_image_row',
  universalValueCarouselSlot = 'rt_uvc_slot',
  multiRetailerPageSlots = 'rt_multi_retailer_page_slots',
  multiRetailerPageMakeUniqueSlots = 'rt_multi_retailer_page_make_unique_slots',
  multiRetailerRichText = 'rt_multi_retailer_rich_text',
  multiRetailerFaqs = 'rt_multi_retailer_faqs',
  announcementBannerCampaignsEnabled = 'rt_announcement_banner_campaigns_enabled',
  multiRetailerPageItemListImageRow = 'rt_multi_retailer_page_item_list_image_row',
  multiRetailerHeaderBannerSettings = 'rt_multi_retailer_header_banner_settings',
  multiRetailerLocalizationRowEnabled = 'rt_multi_retailer_localization_row_enabled',
  multiRetailerItemListPinnedProducts = 'rt_multi_retailer_item_list_pinned_products',
  multiRetailerItemListItemCountThreshold = 'rt_multi_retailer_item_list_item_count_threshold',
  uploadAssetToInstacartS3 = 'rt_upload_asset_to_instacart_s3',
  mrpFullWidthBannerFullBleed = 'rt_mrp_full_width_banner_full_bleed',
  mrpFullWidthBannerDisclaimer = 'rt_mrp_full_width_banner_disclaimer',
  userLoyaltyVisibilityCondition = 'rt_user_loyalty_visibility_condition',
  gifFileUpload = 'rt_gif_file_upload',
  landingPageSlots = 'rt_landing_page_slots',
  icPlusBenefitsSlot = 'rt_ic_plus_benefits_slot',
  landingValuePropRichText = 'rt_landing_value_prop_rich_text',
  mediaFormatSelectorEnabled = 'rt_media_format_selector_enabled',
  dynamicSlotEditAccess = 'rt_dynamic_slot_edit_access',
}
