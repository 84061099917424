import React, { useMemo } from 'react'
import { Text } from '@instacart/ids-customers'
import { useMessage } from '../../utils/intl/intl.hooks'
import { Grid } from '../../common/grid/Grid'
import { NewSelect } from '../../common/new-select'
import { Pagination } from '../../common/pagination/Pagination'
import { Pagination as TDSPagination } from '../../tds'
import { type GridDatum } from '../../common/grid/utils/grid.types'
import { useTDSContext } from '../../tds/index'
import { type PaginatedGridProps } from './PaginatedGrid.types'
import {
  ExpandableGrid,
  PaginatedGridFooter,
  ItemsPerPageSelectContainer,
  Spacer,
  Container,
} from './PaginatedGrid.styles'
export const defaultItemsPerPageValues = [10, 20, 50, 100]

/**
 * A component for displaying tabular data, just like `Grid`, but with pagination controls.
 * Usually, this will be the grid component that you'll most likely end up using, as it's pagination is our standard as opposed to infinite scrolling.
 *
 * As with `Grid`, always remember to memoize `columns` and `data` as much as possible.
 *
 * Note that this component will not split a dataset for you, and it's up to you to come up with all related props and to ensure the data is the correst page size.
 *
 * @param param0: `PaginatedGridProps`
 * @returns
 */
export const PaginatedGrid = <T extends GridDatum>({
  page,
  totalPages,
  onPageChange,
  disablePagination,
  itemsPerPageValues = defaultItemsPerPageValues,
  itemsPerPage,
  onItemsPerPageChange,
  showItemsPerPageSelector,
  isLoading,
  hidePagination,
  height,
  ...props
}: PaginatedGridProps<T>): React.ReactElement | null => {
  const itemsPerPageLabel = useMessage('sharedComponents.paginatedGrid.itemsPerPageLabel')
  const paginationProps = { page, total: totalPages, onChange: onPageChange }
  const { useTDS } = useTDSContext()

  const itemsPerPageOptions = useMemo(() => {
    if (!itemsPerPageValues) return []
    return itemsPerPageValues.map(v => ({
      label: v.toString(),
      value: v,
    }))
  }, [itemsPerPageValues])

  const renderTDSPagination = () => (
    <PaginatedGridFooter style={{ paddingLeft: 0 }}>
      <TDSPagination
        itemsPerPage={
          showItemsPerPageSelector
            ? {
                current: itemsPerPage,
                label: itemsPerPageLabel,
                options: itemsPerPageValues,
                onChange: onItemsPerPageChange,
              }
            : undefined
        }
        compact
        currentPage={page}
        onCurrentPageChange={onPageChange as (page: number | '') => void}
        totalPages={totalPages || 1}
      />
    </PaginatedGridFooter>
  )

  const renderIDSPagination = () => (
    <PaginatedGridFooter>
      {showItemsPerPageSelector && (
        <ItemsPerPageSelectContainer>
          <label htmlFor="itemsPerPageSelect">
            <Text typography="bodySmall1">{itemsPerPageLabel}</Text>
          </label>
          <NewSelect
            compact
            menuPlacement="top"
            inputId="itemsPerPageSelect"
            options={itemsPerPageOptions}
            value={itemsPerPage}
            onChange={v => v && onItemsPerPageChange(v)}
            isDisabled={isLoading || disablePagination}
          />
        </ItemsPerPageSelectContainer>
      )}
      <Spacer />
      <Pagination {...paginationProps} disabled={isLoading || disablePagination} />
    </PaginatedGridFooter>
  )

  return (
    <Container>
      {height ? (
        <Grid {...props} isLoading={isLoading} useLoadingRows height={height} />
      ) : (
        // @ts-expect-error, something's borked here, but the types match up.
        <ExpandableGrid {...props} isLoading={isLoading} useLoadingRows />
      )}

      {!hidePagination && (useTDS ? renderTDSPagination() : renderIDSPagination())}
    </Container>
  )
}
