import { type FunctionComponent, useMemo, useState } from 'react'
import {
  Button,
  TextButton,
  colors,
  NewSelect,
  spacing,
  Text,
  NotificationLevel,
  notify,
} from '@retailer-platform/shared-components'
import styled from '@emotion/styled'
import { IconButton } from '@instacart/ids-tooling'
import { fontSize } from '@retailer-platform/dashboard/gin-and-tonic'
import { ChevronLeftIcon, ChevronRightIcon } from '@instacart/ids-core'
import { Divider } from '@retailer-platform/shared-components/src/tds'
import faker from 'faker'
import { isEqual } from 'lodash'
import type {
  AttributeManagementStep,
  AttributeManagementStepVersion,
  AttributeManagementTypeEnum,
  AttributeManagementCategoryEnum,
} from '../../../../types/attributeManagement.types.ts'
import { useDomainMessages } from '../../../../utils/domain/intl.ts'
import {
  ButtonRow,
  Input,
  Property,
  PropertyTitle,
  PropertyContainer,
  PropertySectionLeft,
  PropertySectionRight,
  SectionHeader,
  TextInput,
} from '../../../shared-styled-components/SharedStyledComponents'
import { useCreateNewStepVersionMutation, useUpdateStepMutation } from '../../../../api/index.ts'
import { RunManualTestModal } from '../../../attribute-management-common/run-manual-test-modals/RunManualTestModal.tsx'
import StepAttributeForm, {
  validateAttributes,
} from '../../../attribute-management-common/step-attribute-forms/StepAttributeForm.tsx'

const Header = styled.div({
  display: 'flex',
  gap: `${spacing.X16}`,
  padding: `${spacing.X16}`,
  paddingTop: `${spacing.X8}`,
  marginBottom: `${spacing.X20}`,
  borderBottom: `4px solid ${colors.GRAYSCALE.X20}`,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
})

const Row = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  gap: `${spacing.X12}`,
  justifyContent: 'space-between',
})

const VersionContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 0,
  gap: spacing.X12,
  fontSize: fontSize.X41,
  '> span': {
    fontSize: fontSize.X12,
  },
})

const StyledButton = styled(Button)({
  width: '190px',
})

type PropertyTabProps = {
  step: AttributeManagementStep
}

const validateVersion = (
  category: AttributeManagementCategoryEnum,
  type: AttributeManagementTypeEnum,
  version: AttributeManagementStepVersion
): boolean => {
  if (!version.createdWhy) {
    return false
  }
  return validateAttributes(category, type, version.attributes)
}

type State = {
  selectedVersion: number
  isEditing: boolean
  newVersion: AttributeManagementStepVersion
  showManualTestModal: boolean
  isEditingDetails: boolean
}

const DEFAULT_STATE = {
  isEditing: false,
  showManualTestModal: false,
  isEditingDetails: false,
}

export const PropertiesTab: FunctionComponent<PropertyTabProps> = ({ step }) => {
  const i18n = useDomainMessages({
    description: 'catalogAdminDomain.attributeManagement.modal.description',
    owner: 'catalogAdminDomain.attributeManagement.modal.owner',
    live: 'catalogAdminDomain.attributeManagement.details.button.live',
    latest: 'catalogAdminDomain.attributeManagement.details.button.latest',
    runManualTest: 'catalogAdminDomain.attributeManagement.details.button.runManualTest',
    create: 'catalogAdminDomain.attributeManagement.details.button.create',
    version: 'catalogAdminDomain.attributeManagement.details.version',
    reset: 'catalogAdminDomain.attributeManagement.details.button.reset',
    cancel: 'catalogAdminDomain.attributeManagement.modal.cancel',
    createNewVersion: 'catalogAdminDomain.attributeManagement.modal.createNewVersion',
    topSectionHeader: 'catalogAdminDomain.attributeManagement.topSectionHeader',
    editDetails: 'catalogAdminDomain.attributeManagement.modal.editDetails',
    updateDetails: 'catalogAdminDomain.attributeManagement.modal.updateDetails',
  })

  const [state, setState] = useState<State>({
    ...DEFAULT_STATE,
    newVersion: step.versions[step.versions.length - 1],
    selectedVersion: step.versions.length - 1,
  })
  const [owner, setOwner] = useState<string>(step.owner)
  const [description, setDescription] = useState<string>(step.description)

  const [triggerCreation, { loading }] = useCreateNewStepVersionMutation({
    variables: {
      clientId: 'catalog_camp_ipp',
      idempotencyKey: faker.random.uuid(),
      attributes: state.newVersion?.attributes,
      createdWhy: state.newVersion?.createdWhy,
      createdBy: 'test',
      state: 'testing',
      stepId: step.id as string,
      tombstoned: false,
    },
    onError: () => {
      notify({
        level: NotificationLevel.Error,
        contents: 'Error creating new version',
      })
    },
    onCompleted: () => {
      window.location.reload()
    },
  })

  const [updateStep] = useUpdateStepMutation({
    variables: {
      clientId: 'catalog_camp_ipp',
      idempotencyKey: faker.random.uuid(),
      stepId: step.id as string,
      owner: owner,
      description: description,
    },
    onError: () => {
      notify({
        level: NotificationLevel.Error,
        contents: 'Error updating step owner and description',
      })
    },
    onCompleted: () => {
      window.location.reload()
    },
  })

  const onNextVersion = () => {
    if (!state.isEditing) {
      setState({
        ...state,
        newVersion: step.versions[Math.min(step.versions.length - 1, state.selectedVersion + 1)],
        selectedVersion: Math.min(step.versions.length - 1, state.selectedVersion + 1),
      })
    }
  }
  const onPrevVersion = () => {
    if (!state.isEditing) {
      setState({
        ...state,
        newVersion: step.versions[Math.max(0, state.selectedVersion - 1)],
        selectedVersion: Math.max(0, state.selectedVersion - 1),
      })
    }
  }
  const onLatestVersion = () => {
    if (!state.isEditing) {
      setState({
        ...state,
        newVersion: step.versions[step.versions.length - 1],
        selectedVersion: step.versions.length - 1,
      })
    }
  }

  const anythingChanged = useMemo(
    () =>
      !isEqual(state?.newVersion?.attributes, step?.versions?.[state.selectedVersion]?.attributes),
    [state.newVersion, state.selectedVersion, step.versions]
  )

  const anyDetailsChanged = useMemo(
    () => !isEqual(step.owner, owner) || !isEqual(step.description, description),
    [step.owner, owner, step.description, description]
  )

  const options = step.versions.map((version, index) => ({ label: version.version, value: index }))

  return (
    <>
      {state.showManualTestModal && (
        <RunManualTestModal
          type={step.type}
          stepVersion={state.newVersion}
          onClose={() => setState({ ...state, showManualTestModal: false })}
        />
      )}
      <Header>
        <Row>
          <IconButton
            icon={ChevronLeftIcon}
            accessibleLabel={''}
            onClick={() => onPrevVersion()}
            disabled={state.selectedVersion === 0 || state.isEditing}
          />
          <VersionContainer>
            <Text weight="semibold">{i18n.version}</Text>
            <NewSelect
              options={options}
              value={state.selectedVersion}
              onChange={value => {
                setState({
                  ...state,
                  newVersion: step.versions[value || 0],
                  selectedVersion: value || 0,
                })
              }}
              isDisabled={state.isEditing}
            />
          </VersionContainer>
          <IconButton
            icon={ChevronRightIcon}
            accessibleLabel={''}
            onClick={() => onNextVersion()}
            disabled={state.selectedVersion === step.versions.length - 1 || state.isEditing}
          />
        </Row>
        <Row>
          <StyledButton
            variant="secondary"
            onClick={() => onLatestVersion()}
            disabled={state.isEditing || state.selectedVersion === step.versions.length - 1}
          >
            {i18n.latest}
          </StyledButton>
        </Row>
      </Header>

      <Row>
        <StyledButton
          variant="secondary"
          onClick={() => {
            setState({ ...state, showManualTestModal: true })
          }}
          disabled={state.isEditing}
        >
          {i18n.runManualTest}
        </StyledButton>
        <ButtonRow>
          <Button
            variant="primary"
            disabled={state.isEditing || state.isEditingDetails}
            onClick={() => {
              setState({
                ...state,
                newVersion: { ...state.newVersion, createdWhy: '' },
                isEditing: true,
              })
            }}
          >
            {i18n.create}
          </Button>
          <Button
            variant={'secondary'}
            onClick={() => {
              setState({ ...state, isEditingDetails: true })
            }}
            disabled={state.isEditingDetails || state.isEditing}
          >
            {i18n.editDetails}
          </Button>
        </ButtonRow>
      </Row>
      <SectionHeader> {i18n.topSectionHeader} </SectionHeader>
      <PropertyContainer>
        <PropertySectionLeft>
          <Property>
            <PropertyTitle>{i18n.owner} </PropertyTitle>
            <Input
              value={owner}
              onChange={e => setOwner(e.target.value)}
              disabled={!state.isEditingDetails}
            />
          </Property>
          <Property>
            <PropertyTitle>Reason</PropertyTitle>
            <Input
              value={state.newVersion?.createdWhy || ''}
              disabled={!state.isEditing}
              error={state.newVersion?.createdWhy.length === 0 && state.isEditing}
              onChange={event => {
                setState({
                  ...state,
                  newVersion: { ...state.newVersion, createdWhy: event.target.value },
                })
              }}
            />
          </Property>
        </PropertySectionLeft>
        <PropertySectionRight>
          <Property style={{ height: '100%' }}>
            <PropertyTitle>{i18n.description}</PropertyTitle>
            <TextInput
              value={description}
              onChange={e => setDescription(e.target.value)}
              disabled={!state.isEditingDetails}
            />
          </Property>
        </PropertySectionRight>
      </PropertyContainer>
      {state.isEditingDetails && (
        <ButtonRow>
          <Button
            variant={'secondary'}
            onClick={() => setState({ ...state, isEditingDetails: false })}
            disabled={loading}
          >
            {i18n.cancel}
          </Button>
          <Button
            variant={'primary'}
            onClick={() => {
              setState({ ...state, isEditingDetails: false })
              updateStep()
            }}
            disabled={loading || !anyDetailsChanged}
          >
            {i18n.updateDetails}
          </Button>
        </ButtonRow>
      )}
      <Divider />
      <StepAttributeForm
        type={step.type}
        attributes={state.newVersion.attributes}
        mode={state.isEditing ? 'edit' : 'view'}
        onChange={value => {
          setState({ ...state, newVersion: { ...state.newVersion, attributes: value } })
        }}
      />
      {state.isEditing && (
        <ButtonRow>
          <TextButton
            onClick={() => {
              setState({
                ...state,
                newVersion: { ...step.versions[state.selectedVersion], createdWhy: '' },
              })
              setOwner(step.owner)
              setDescription(step.description)
            }}
            disabled={loading}
          >
            {i18n.reset}
          </TextButton>
          <Button
            variant={'secondary'}
            onClick={() => {
              setState({
                ...state,
                isEditing: false,
                newVersion: step.versions[state.selectedVersion],
              })
              setOwner(step.owner)
              setDescription(step.description)
            }}
            disabled={loading}
          >
            {i18n.cancel}
          </Button>
          <Button
            disabled={
              !validateVersion(step.category, step.type, state.newVersion) ||
              loading ||
              !anythingChanged
            }
            onClick={() => triggerCreation()}
          >
            {i18n.createNewVersion}
          </Button>
        </ButtonRow>
      )}
    </>
  )
}
