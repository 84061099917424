import type { ReactNode, PropsWithChildren, FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { colors, Text, Button } from 'ic-snacks'
import { FormattedMessage } from 'react-intl'
import LoadingDots from '../LoadingDots'
import { Z_INDEX_THIRD } from '../../common/theme'
import { Button as ButtonTds } from '@retailer-platform/shared-components/src/tds'

const Bar = styled.div<{ error?: boolean }>`
  position: fixed;
  z-index: ${Z_INDEX_THIRD};
  bottom: 0;
  left: 0px;
  height: 65px;
  background-color: #ffffff;
  width: 100%;
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${colors.GRAY_93};
`

interface Props {
  error?: boolean
  errorMessage?: ReactNode
  isSubmitting?: boolean
  submitButtonText?: ReactNode
}

/**
 * Note on usage: This component needs to be placed **inside**
 * the <form> tag. It uses standard form semantics and does not
 * accept callbacks for onSubmit/onReset. Please attach those to
 * your <form> (or automatic if using <Form> from Formik) instead.
 */
const FormConfirmationBar: FunctionComponent<PropsWithChildren<Props>> = props => {
  const { isSubmitting, error, errorMessage, submitButtonText } = props

  return (
    <Bar data-testid="form-confirmation-bar" error={error}>
      <Text variant="T.14" style={{ color: colors.WHITE }}>
        {error ? (
          errorMessage || <FormattedMessage id="forms.fieldError" />
        ) : (
          <FormattedMessage id="forms.savePrompt" />
        )}
      </Text>
      <div style={{ display: 'flex', gap: '6px' }}>
        <ButtonTds
          variant="secondary"
          data-testid="form-confirmation-bar-reset-button"
          type="reset"
        >
          <FormattedMessage id="forms.discard" />
        </ButtonTds>
        <ButtonTds
          variant="primary"
          data-testid="form-confirmation-bar-submit-button"
          type="submit"
          loading={isSubmitting}
        >
          {
            submitButtonText || <FormattedMessage id="forms.save" />
          }
        </ButtonTds>
      </div>
    </Bar>
  )
}

export default FormConfirmationBar
