import React, { useMemo, useRef, useState } from 'react'
import {
  Button,
  colors,
  fontSize,
  NotificationLevel,
  notify,
  spacing,
  Text,
} from '@retailer-platform/shared-components'
import styled from '@emotion/styled'
import faker from 'faker'
import { IconButton } from '@instacart/ids-customers'
import { ArrowRightIcon, EditIcon, TrashIcon } from '@instacart/ids-core'
import { useGetStepVersionQuery } from '../../../api'
import { useDomainMessages } from '../../../utils/domain/intl'
import {
  type AttributeManagementCategoryEnum,
  AttributeManagementStepVersion,
  type PipelineStep,
} from '../../../types/attributeManagement.types'
import PipelineFormStepSelectModal from './PipelineFormStepSelectModal'

interface Props {
  category: AttributeManagementCategoryEnum
  step: PipelineStep | null
  onChange: (step: PipelineStep) => void
  onDelete: () => void
  disabled: boolean
}

const Tile = styled.div({
  verticalAlign: 'middle',
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${colors.GRAYSCALE.X80}`,
  backgroundColor: colors.GRAYSCALE.X10,
})

const TileHeader = styled.div({
  display: 'flex',
  flexDirection: 'column',
  borderBottom: `1px solid ${colors.GRAYSCALE.X80}`,
  padding: spacing.X8,
  alignItems: 'center',
})

const TileBody = styled.div({
  padding: spacing.X16,
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.X8,
  alignItems: 'center',
})

const OverrideGrid = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr auto auto auto',
  gap: spacing.X8,
})

const TextRight = styled.div({
  textAlign: 'right',
})

const PipelineFormStepTile: React.FC<Props> = ({
  category,
  step,
  onChange,
  onDelete,
  disabled,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const i18n = useDomainMessages({
    errorMessage:
      'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepSelectModal.errorMessage',
    deleteStep:
      'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepSelectModal.deleteStep',
    editStep:
      'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepSelectModal.editStep',
  })
  const idempotencyKey = useRef(faker.random.uuid())
  const idemKey = useMemo(
    () => idempotencyKey.current + step?.stepId + step?.version,
    [idempotencyKey, step?.stepId, step?.version]
  )
  const { data, loading } = useGetStepVersionQuery({
    variables: {
      stepId: step?.stepId || '',
      version: step?.version || '',
      clientId: 'catalog_camp_ipp',
      idempotencyKey: idemKey,
    },
    skip: !step?.stepId || !step?.version,
    onError: () => {
      notify({
        level: NotificationLevel.Error,
        contents: i18n.errorMessage,
      })
    },
  })
  const stepVersionDetails = React.useMemo(
    () => data?.instacart_catalog_catflow_v1_CatflowAPI_GetStepVersion?.stepVersion,
    [data]
  )
  return (
    <>
      <Tile>
        <TileHeader>
          <Text weight="bold">{category}</Text>
          {stepVersionDetails?.step?.name && (
            <Text weight="semibold">{stepVersionDetails?.step?.name}</Text>
          )}
          {stepVersionDetails?.version && (
            <Text size="small" weight="semibold">
              Version {stepVersionDetails?.version}
            </Text>
          )}
        </TileHeader>
        {stepVersionDetails ? (
          <TileBody>
            <Text size="small" weight="bold">
              Overrides
            </Text>
            {Object.keys(step?.attributeOverrides || {}).length === 0 ? (
              <Text size="small" weight="semibold">
                No overrides
              </Text>
            ) : (
              <OverrideGrid>
                {Object.keys(step?.attributeOverrides || {}).map(key => (
                  <React.Fragment key={key}>
                    <TextRight>
                      <Text size="small" weight="semibold">
                        {key}:
                      </Text>
                    </TextRight>
                    <TextRight>{stepVersionDetails?.attributes[key] || 'null'}</TextRight>
                    <ArrowRightIcon size={fontSize.X14} />
                    <div>{step?.attributeOverrides[key] || 'null'}</div>
                  </React.Fragment>
                ))}
              </OverrideGrid>
            )}
            <div>
              <IconButton
                disabled={disabled}
                icon={TrashIcon}
                accessibleLabel={i18n.deleteStep}
                onPress={() => {
                  onDelete()
                }}
              />
              <IconButton
                disabled={disabled}
                icon={EditIcon}
                accessibleLabel={i18n.editStep}
                onPress={() => {
                  setIsModalOpen(true)
                }}
              />
            </div>
          </TileBody>
        ) : (
          <TileBody>
            {disabled ? (
              <Text weight="semibold">None</Text>
            ) : (
              <Button
                variant="primary"
                onClick={() => {
                  setIsModalOpen(true)
                }}
              >
                Add {category} Step
              </Button>
            )}
          </TileBody>
        )}
      </Tile>
      {isModalOpen && (
        <PipelineFormStepSelectModal
          category={category}
          onClose={() => {
            setIsModalOpen(false)
          }}
          onConfirm={step => {
            onChange(step)
            setIsModalOpen(false)
          }}
        />
      )}
    </>
  )
}

export default PipelineFormStepTile
