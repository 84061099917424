import { MenuItem } from '@retailer-platform/shared-components/src/tds'
import { type FunctionComponent } from 'react'
import { useTrackEvent } from '../../../../utils/events/useTrackEvent.hook'
import { type Item } from './NavMenuItemGroup'
import { BaseNavLink } from './NavLink'
import { getNavigateToSearchResultData } from './NavSearchMenu/NavSearchMenuUtilities'

export type Props = {
  searchValue: string
  displayItem: Item
  activeItem: Item | null
  onMouseEnter: () => void
}

export const NavMenuItem: FunctionComponent<Props> = ({
  searchValue,
  displayItem,
  activeItem,
  onMouseEnter,
}) => {
  const trackEvent = useTrackEvent()

  return (
    <BaseNavLink
      key={displayItem.label + displayItem.route}
      onClick={() => {
        trackEvent(
          getNavigateToSearchResultData(searchValue, displayItem.label, displayItem.route, 'click')
        )
        displayItem.onClick()
      }}
      onMouseEnter={onMouseEnter}
    >
      <MenuItem
        text={displayItem.label}
        isHover={activeItem?.label === displayItem.label && activeItem?.route === displayItem.route}
      />
    </BaseNavLink>
  )
}
