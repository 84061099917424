import {
  type DashRouteType,
  URL_APP_ADMIN,
  URL_PUBLIC,
  URL_RETAILER_SCOPED,
  URL_SITE_SCOPED,
} from '@retailer-platform/dashboard/routing'

const URL_ONBOARDING = `${URL_RETAILER_SCOPED}/onboarding`
const URL_ONBOARDING_LAUNCH_CHECKLIST = `${URL_ONBOARDING}/launch-checklist`
const URL_ONBOARDING_RETAILER_SETTINGS = `${URL_ONBOARDING}/retailer-settings`
const URL_ONBOARDING_STORE_MANAGEMENT = `${URL_ONBOARDING}/store-management/:mode?`
export const URL_ONBOARDING_INITIAL_SETUP = `${URL_ONBOARDING}/initial-setup`
export const URL_ONBOARDING_STORE_DETAIL = `${URL_ONBOARDING}/store-detail/:warehouseLocationId`

const URL_ONBOARDING_SITE_SETUP_FORM = `${URL_ONBOARDING}/site-setup-form/:whitelabelSiteId`
const URL_ONBOARDING_SITE_SETUP_REVIEW = `${URL_ONBOARDING}/site-setup-review/:whitelabelSiteId`

// store config scoped urls
const URL_ONBOARDING_SITE_SCOPED = `${URL_ONBOARDING}${URL_SITE_SCOPED}`
const URL_ONBOARDING_SITE_SETUP = `${URL_ONBOARDING_SITE_SCOPED}/site-setup`
// @hygen:inject scoped-urls

const URL_PUBLIC_ONBOARDING = `${URL_PUBLIC}/onboarding`
const URL_PUBLIC_ONBOARDING_COMPLETE_ACCOUNT_SETUP = `${URL_PUBLIC_ONBOARDING}/complete-account-setup/:token`
export const URL_PUBLIC_ONBOARDING_REQUEST_ACCOUNT_SETUP = `${URL_PUBLIC_ONBOARDING}/request-account-setup`
// @hygen:inject public-urls

// admin urls
const URL_ADMIN_ONBOARDING = `${URL_APP_ADMIN}/onboarding`
const URL_ADMIN_ONBOARDING_SITE_OVERVIEW = `${URL_ADMIN_ONBOARDING}/site-overview`
const URL_ADMIN_ONBOARDING_SITE_SETUP_FORM_ADMIN = `${URL_ADMIN_ONBOARDING}/site-setup-form-admin/:whitelabelSiteId`
const URL_ADMIN_ONBOARDING_SITE_SETUP_ADMIN = `${URL_ADMIN_ONBOARDING}/site-setup-admin/:storeConfigId`
// @hygen:inject admin-urls

export type DashRouteSet =
  | DashRouteType<'onboarding'>
  | DashRouteType<'app-public-onboarding'>
  | DashRouteType<'onboarding-launch-checklist'>
  | DashRouteType<'onboarding-retailer-settings'>
  | DashRouteType<'onboarding-store-management', { mode: string }>
  | DashRouteType<'onboarding-initial-setup'>
  | DashRouteType<'app-public-onboarding-complete-account-setup', { token: string }>
  | DashRouteType<'onboarding-store-detail', { warehouseLocationId: string }>
  | DashRouteType<'app-public-onboarding-request-account-setup'>
  | DashRouteType<'onboarding-site-setup-form', { whitelabelSiteId: string }>
  // store config scoped urls
  | DashRouteType<'onboarding-site-scoped'>
  | DashRouteType<'onboarding-site-setup'>
  | DashRouteType<'onboarding-site-setup-review'>
  // admin urls
  | DashRouteType<'app-admin-onboarding'>
  | DashRouteType<'app-admin-onboarding-site-overview'>
  | DashRouteType<'app-admin-onboarding-site-setup-form-admin', { whitelabelSiteId: string }>
  | DashRouteType<'app-admin-onboarding-site-setup-admin'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  onboarding: URL_ONBOARDING,
  'app-public-onboarding': URL_PUBLIC_ONBOARDING,
  'onboarding-launch-checklist': URL_ONBOARDING_LAUNCH_CHECKLIST,
  'onboarding-retailer-settings': URL_ONBOARDING_RETAILER_SETTINGS,
  'onboarding-store-management': URL_ONBOARDING_STORE_MANAGEMENT,
  'onboarding-initial-setup': URL_ONBOARDING_INITIAL_SETUP,
  'app-public-onboarding-complete-account-setup': URL_PUBLIC_ONBOARDING_COMPLETE_ACCOUNT_SETUP,
  'onboarding-store-detail': URL_ONBOARDING_STORE_DETAIL,
  'app-public-onboarding-request-account-setup': URL_PUBLIC_ONBOARDING_REQUEST_ACCOUNT_SETUP,
  'onboarding-site-setup-form': URL_ONBOARDING_SITE_SETUP_FORM,
  // store config scoped urls
  'onboarding-site-scoped': URL_ONBOARDING_SITE_SCOPED,
  'onboarding-site-setup': URL_ONBOARDING_SITE_SETUP,
  'onboarding-site-setup-review': URL_ONBOARDING_SITE_SETUP_REVIEW,
  // admin urls
  'app-admin-onboarding': URL_ADMIN_ONBOARDING,
  'app-admin-onboarding-site-overview': URL_ADMIN_ONBOARDING_SITE_OVERVIEW,
  'app-admin-onboarding-site-setup-form-admin': URL_ADMIN_ONBOARDING_SITE_SETUP_FORM_ADMIN,
  'app-admin-onboarding-site-setup-admin': URL_ADMIN_ONBOARDING_SITE_SETUP_ADMIN,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
