import * as Sentry from '@sentry/react'
import { type SeverityLevel } from '@sentry/react'
import { type ScopeContext } from '@sentry/types'
import { datadogRum } from '@datadog/browser-rum'
import clientEnv from '../global/clientEnv'
import ApiResponseError from '../../legacy/common/errors/ApiResponseError'
import HttpError from '../../legacy/common/errors/HttpError'

const API_ERROR_IGNORE_4XX_CODES = [401, 403, 404]

export type ErrorContext = Partial<Pick<ScopeContext, 'extra' | 'level'>>

export function initSentry() {
  const environment = clientEnv.PUBLIC_CLIENT_ENVIRONMENT
  const dsn = clientEnv.PUBLIC_CLIENT_SENTRY_DSN
  const sha = clientEnv.PUBLIC_CLIENT_ISC_SHA
  const branch = clientEnv.PUBLIC_CLIENT_ISC_BRANCH
  const release = `retailer-platform-web-workspace@${sha}.${clientEnv.PUBLIC_CLIENT_BUILDER}`

  // Don't send Sentry events in dev
  if (environment === 'development') return

  Sentry.init({
    dsn,
    environment,
    release,
    maxBreadcrumbs: 10,

    replaysOnErrorSampleRate: clientEnv.PUBLIC_CLIENT_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
    replaysSessionSampleRate: 0,
    integrations: [
      Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false }),
      Sentry.extraErrorDataIntegration({ depth: 3, captureErrorCause: true }),
    ],
  })

  Sentry.getCurrentScope().setTag('instacart-owner', 'retailer-tools')
  Sentry.getCurrentScope().setTag('branch', branch)
  Sentry.getCurrentScope().setTag('builder', clientEnv.PUBLIC_CLIENT_BUILDER)
}

const captureDatadogException = (exception: Error, message?: string, context?: ErrorContext) => {
  datadogRum.addError(exception, { message, context })
}

const captureSentryException = (exception: Error, message?: string, context?: ErrorContext) => {
  if (context?.level === 'warning') {
    console.warn('captureSentryException called', { exception, message, context })
  } else {
    console.error('captureSentryException called', { exception, message, context })
  }

  const sentryLevel: SeverityLevel = context?.level || 'error'

  Sentry.withScope(scope => {
    // Note: This is for legacy support (arbitrary params). Docs recommend structured context instead.
    if (context?.extra) {
      scope.setExtras(context.extra)
    }

    Sentry.captureException(exception, {
      level: sentryLevel,
      contexts: {
        message: { message },
        location: {
          url: window.location.href,
        },
      },
    })
  })
}

const captureException = (
  exception: Error | ApiResponseError | HttpError,
  message?: string,
  context?: ErrorContext
) => {
  // already reported, but often gets rethrown
  if (exception instanceof ApiResponseError || exception instanceof HttpError) {
    console.info('captureException ignored')
    return
  }

  captureSentryException(exception, message, { level: 'error', ...context })
  captureDatadogException(exception, message, { level: 'error', ...context })
}

const captureWarning = (exception: Error, message?: string, context?: ErrorContext) =>
  captureSentryException(exception, message, { level: 'warning', ...context })

const captureMessage = (message: string, context?: ErrorContext) => {
  console.warn('captureMessage called', { message, context })

  const level = context?.level || 'error'

  if (level === 'error') {
    captureDatadogException(new Error(message), message, context)
  }

  Sentry.withScope(scope => {
    if (context?.extra) {
      scope.setExtras(context.extra)
    }

    Sentry.captureMessage(message, {
      level,
      contexts: {
        message: { message },
      },
    })
  })
}

// Legacy API Error Handling
const captureApiError = (error: ApiResponseError, context?: ErrorContext) => {
  if (error.status >= 500 || API_ERROR_IGNORE_4XX_CODES.includes(error.status)) {
    console.info('captureApiError ignored')
    return
  }

  captureSentryException(error, undefined, { level: 'error', ...context })
  captureDatadogException(error, undefined, { level: 'error', ...context })
}

const setUser = (user: Sentry.User) => Sentry.setUser(user)

/**
 * Adds to the shared context that will be set with every event.
 */
const setMonitoringContextProperty = (key: string, value: unknown) => {
  datadogRum.setGlobalContextProperty(key, value)
}

export const errors = {
  captureException,
  captureWarning,
  captureMessage,
  captureApiError,
  setUser,
  setMonitoringContextProperty,
}
