import { RetailerLocationGroupQuery, useListStoreGroupsQuery } from '../../../../__codegen__/api'
import { edgesToCollection } from '../../../apollo/utils/edgesToCollection'
import { usePartnerId, useRetailerId } from '../../../../utils/routing/params.hooks'
import { type WatchQueryFetchPolicy } from '@apollo/client'

export const storeGroupListDefaultParams = {
  numGroups: 200,
}

// Right now we are using the old table implementation, and API pagination hasn't been implemented
// Once we have updated it and implemented API pagination, we can remove the cap for max_pagination_nodes
// and replace it with proper pagination
export const useListStoreGroups = (
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first',
  retailerIds?: string[]
) => {
  const partnerId = usePartnerId()
  const retailerId = useRetailerId()

  let retailerIdsQuery = []
  if (retailerIds?.length) {
    retailerIdsQuery = retailerIds
  } else if (retailerId && Number(retailerId) > 0) {
    retailerIdsQuery = [retailerId]
  }

  const queryParams: {
    partnerId: string
    retailerIds?: string[]
    numGroups?: number
    matchType?: RetailerLocationGroupQuery
  } = {
    ...storeGroupListDefaultParams,
    partnerId,
  }

  if (retailerIdsQuery.length > 0) {
    queryParams.retailerIds = retailerIdsQuery
    queryParams.matchType = RetailerLocationGroupQuery.Intersect
  }

  const { data, error, loading, refetch } = useListStoreGroupsQuery({
    variables: queryParams,
    fetchPolicy,
  })

  const edges = (data && data.retailerLocationGroupsByRetailer.edges) || []
  const storeGroups = edgesToCollection(edges)

  return {
    storeGroups,
    loading,
    error,
    refetch,
  }
}
