import React, { type FunctionComponent } from 'react'
import { borderRadius, DecoratedInput, spacing, colors } from '@retailer-platform/shared-components'
import { FilterIcon, SearchIcon } from '@instacart/ids-core'
import { Spacer } from '@retailer-platform/shared-components/src/common/paginated-grid/PaginatedGrid.styles.tsx'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { TextButton } from '@retailer-platform/dashboard/legacy'
import styled from '@emotion/styled'
import { isEqual } from 'lodash'
import { type MdPlatformListFilters } from '../../types/mdPlatform.types.ts'
import { useDomainMessages } from '../../utils/domain/intl.ts'

export const DEFAULT_FILTER = {
  name: '',
  id: '',
  owner: '',
  hideRejectedVersions: false,
}

const TableFilterTextButton = styled.div({
  textTransform: 'uppercase',
  padding: spacing.X12,
  height: 32,
  margin: 0,
  display: 'inline-flex',
  alignItems: 'center',
})

const Row = styled.div({
  margin: spacing.X0,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  gap: `${spacing.X16}`,
  alignItems: 'center',
  background: colors.GRAYSCALE.X10,
  padding: `${spacing.X16}`,
  borderRadius: `${borderRadius.X12}`,
})

const StyledFilterIcon = styled(FilterIcon)({
  margin: 'auto',
})

interface Props {
  filters: MdPlatformListFilters
  onChange: (filters: MdPlatformListFilters) => void
}

export const ListFilterContainer: FunctionComponent<React.PropsWithChildren<Props>> = ({
  onChange,
  filters,
}) => {
  const i18n = useDomainMessages({
    id: 'catalogAdminDomain.attributeCreationPlatform.table.filters.id',
    name: 'catalogAdminDomain.attributeCreationPlatform.table.filters.name',
    owner: 'catalogAdminDomain.attributeCreationPlatform.table.filters.owner',
  })
  return (
    <Row>
      <StyledFilterIcon size={24} style={{ margin: 'auto' }} />
      <DecoratedInput
        decorationComponent={SearchIcon}
        placeholder={i18n.id}
        id="acp-filters-id"
        value={filters.id}
        size={10}
        onChange={evt => {
          onChange({
            ...filters,
            id: evt.target.value,
          })
        }}
      />
      <DecoratedInput
        decorationComponent={SearchIcon}
        placeholder={i18n.name}
        id="acp-filters-name"
        value={filters.name}
        onChange={evt => {
          onChange({
            ...filters,
            name: evt.target.value,
          })
        }}
      />
      <DecoratedInput
        decorationComponent={SearchIcon}
        placeholder={i18n.owner}
        id="acp-filters-name"
        value={filters.owner}
        onChange={evt => {
          onChange({
            ...filters,
            owner: evt.target.value,
          })
        }}
      />
      {!isEqual(filters, DEFAULT_FILTER) && (
        <TableFilterTextButton>
          <TextButton
            disabled={isEqual(filters, DEFAULT_FILTER)}
            onClick={() => {
              onChange({
                name: '',
                id: '',
                owner: '',
                hideRejectedVersions: false,
              })
            }}
          >
            Clear
          </TextButton>
        </TableFilterTextButton>
      )}
      <Spacer />
    </Row>
  )
}
