import { type FunctionComponent } from 'react'
import { IconButton } from '@instacart/ids-customers'
import { ConfirmIcon } from '@instacart/ids-core'
import ChangesGrid from '../../attribute-management-details/components/changes-tab/ChangesGrid.tsx'
import { type AttributeManagementStepVersion } from '../../../types/attributeManagement.types.ts'
import { useDomainMessages } from '../../../utils/domain/intl.ts'

interface Props {
  listData: AttributeManagementStepVersion[]
  loading: boolean
  onSelect: (stepVersionId: string) => void
}

export const PipelineFormStepVersionSelectTable: FunctionComponent<Props> = ({
  listData,
  loading,
  onSelect,
}) => {
  const i18n = useDomainMessages({
    id: 'catalogAdminDomain.attributeManagement.table.headers.id',
    name: 'catalogAdminDomain.attributeManagement.table.headers.name',
    type: 'catalogAdminDomain.attributeManagement.table.headers.type',
    typeHelp: 'catalogAdminDomain.attributeManagement.table.headers.type.help',
    owner: 'catalogAdminDomain.attributeManagement.table.headers.owner',
    actions: 'catalogAdminDomain.attributeManagement.table.headers.actions',
    select:
      'catalogAdminDomain.attributeManagement.stepAttributeForms.pipelineForm.stepSelectModal.select',
  })

  const action = (index: number, stepVersionId: string) => (
    <IconButton
      icon={ConfirmIcon}
      accessibleLabel={i18n.select}
      onPress={() => {
        onSelect(stepVersionId)
      }}
    />
  )

  return <ChangesGrid versions={listData} actions={[action]} loading={loading} />
}
