/* eslint-disable @typescript-eslint/no-explicit-any */
import type { PropsWithChildren, FunctionComponent } from 'react'

import { useMemo, useState } from 'react'
import { Button, colors, spacing, Tooltip, Text } from '@retailer-platform/shared-components'
import { Checkbox, IconButton, Link } from '@retailer-platform/shared-components/src/tds'
import styled from '@emotion/styled'
import { type Country, type InventoryArea, Permission } from '../../../../../common/types.ts'
import TableActions from '../../../../../components/Table/TableActions.tsx'
import {
  ChevronDownIcon,
  ChevronRightIcon,
  CloseIcon,
  ConfirmIcon,
  EditIcon,
  InformationIcon,
} from '@instacart/ids-core'
import { LoadingLockupTextBase } from '@instacart/ids-customers'
import { FormattedMessage } from 'react-intl'
import ProductLocationsCell from './ProductLocationsCell.tsx'
import { YES_NO_OPTIONS } from '../../../../../common/constants.tsx'
import { COST_UNIT_OPTIONS } from '../../../../../common/utils/catalog/units.ts'
import { countryIdToDomain } from './ProductLocations.tsx'
// eslint-disable-next-line workspaces/no-relative-imports
import {
  Divider,
  DividerText,
} from '../../../../../../../../../domains/catalog-admin/src/utils/md-platform/common.styles.tsx'
import { startCase } from 'lodash'
// eslint-disable-next-line workspaces/no-relative-imports
import { useDomainMessages } from '../../../../../../../../../domains/products/src/utils/domain/intl'
import ProductLocationsAvailabilityScoreOverrideCell from './ProductLocationsAvailabilityScoreOverrideCell.tsx'
import useAccessControl from '../../../../../components/AccessControl/useAccessControl.ts'
import { AccessGated } from '../../../../../../exports/access-control.ts'

const BooleanText = styled.span({
  padding: '0 10px',
})

export const ProductLocationsTableTd = styled.td({
  padding: spacing.X16,
  verticalAlign: 'middle',
})
export const FlexRow = styled.td({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

export const ProductLocationTableTr = styled.tr<{ ignoreBorderBottom?: boolean }>(
  ({ ignoreBorderBottom }) => ({
    verticalAlign: 'middle',
    borderBottom: ignoreBorderBottom ? 'none' : `1px solid ${colors.GRAYSCALE.X20}`,
  })
)

const Properties = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, minmax(250px, 1fr))',
  padding: spacing.X28,
  gap: spacing.X16,
})

const Property = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.X8,
  margin: spacing.X8,
})

const PropertyLabel = styled.div({
  fontWeight: 600,
  whiteSpace: 'nowrap',
  wordBreak: 'keep-all',
})

const PropertyValue = styled.div({})

const allowedKeysWithPriority = {
  loyalty_cost_price_start_at: 1,
  loyalty_cost_price_end_at: 2,
  loyalty_price_active: 3,
  sale_start_at: 3,
  sale_end_at: 4,
  sale_price: 5,
  sale_price_active: 6,
}

interface Props {
  item: any
  availabilityScoreOverride: boolean | null
  availabilityScoreOverrideLoading: boolean
  index: number
  country?: Country
  handleEditRowClick: (index: number) => void
  handleConfirmEditClick: () => void
  selected: number[]
  currentRowEditIndex: number
  onSelectItem: (item_id: number) => void
  handleEdit: (attr: string, value: string) => void
  inventoryAreaMap: { [id: string]: InventoryArea }
  showAvailabilityScoreOverride: boolean
  setAvailabilityScoreOverride: (AvailabilityScoreOverrideState) => void
}

export const ProductLocationsTableRow: FunctionComponent<PropsWithChildren<Props>> = ({
  item,
  availabilityScoreOverride,
  availabilityScoreOverrideLoading,
  country,
  index,
  handleEditRowClick,
  handleConfirmEditClick,
  onSelectItem,
  handleEdit,
  currentRowEditIndex,
  selected,
  inventoryAreaMap,
  showAvailabilityScoreOverride,
  setAvailabilityScoreOverride,
}) => {
  const [toggled, setToggled] = useState<boolean>(false)
  const hasAccess = useAccessControl()
  const sproutsEnabled = hasAccess([
    {
      // @ts-expect-error, throwaway feature toggle
      warehouseFeatureToggles: ['rt_catalog_sprouts_imporved_product_search'],
    },
  ])

  const i18n = useDomainMessages({
    yes: 'productsDomain.search.yes',
    no: 'productsDomain.search.no',
    none: 'productsDomain.view.storeLocations.table.none',
    details: 'productsDomain.view.storeLocations.table.details',
  })

  const actionElement = useMemo(() => {
    let curElement = (
      <TableActions>
        <AccessGated
          accessControlConfig={{ permissions: Permission.CatalogProductsModify }}
          accessBlockedResult={'disable'}
        >
          <IconButton
            onClick={() => handleEditRowClick(index)}
            icon={EditIcon}
            variant="secondary"
          />
        </AccessGated>
      </TableActions>
    )

    if (selected.length) {
      curElement = null
    } else if (currentRowEditIndex === index) {
      curElement = (
        <TableActions>
          <IconButton
            onClick={() => handleEditRowClick(index)}
            icon={CloseIcon}
            variant="detrimental-secondary"
          />
          <IconButton onClick={handleConfirmEditClick} icon={ConfirmIcon} variant="secondary" />
        </TableActions>
      )
    }
    return curElement
  }, [currentRowEditIndex, handleConfirmEditClick, handleEditRowClick, index, selected.length])

  const storeName = useMemo(() => {
    let { name } = item.inventory_area
    const inventoryArea = inventoryAreaMap?.[item.inventory_area_id]

    // If we have inventoryArea then we use the value from that instead.
    if (inventoryArea) {
      name = inventoryArea?.warehouse_name ?? (inventoryArea?.name || inventoryArea?.street)
    }
    return name
  }, [inventoryAreaMap, item.inventory_area, item.inventory_area_id])

  const detailedAttributes = useMemo(() => {
    return Object.keys(item)
      .filter(attributeName => !!allowedKeysWithPriority?.[attributeName])
      .sort((attributeNameA, attributeNameB) => {
        const priorityA = allowedKeysWithPriority?.[attributeNameA] ?? 0
        const priorityB = allowedKeysWithPriority?.[attributeNameB] ?? 0
        if (priorityA === priorityB)
          return attributeNameA.localeCompare(attributeNameB, undefined, { numeric: true })
        return priorityA - priorityB
      })
  }, [item])

  return (
    <>
      <ProductLocationTableTr ignoreBorderBottom={toggled}>
        <ProductLocationsTableTd css={{ height: '145px' }}>
          <Checkbox
            id={`checkbox-${item.id}`}
            checked={selected.includes(item.id)}
            onChange={() => onSelectItem(item.id)}
            label=""
          />
        </ProductLocationsTableTd>
        <ProductLocationsTableTd>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: spacing.X16,
              wordBreak: 'keep-all',
              whiteSpace: 'nowrap',
            }}
          >
            <IconButton
              icon={toggled ? ChevronDownIcon : ChevronRightIcon}
              aria-label={i18n.details}
              onClick={() => setToggled(!toggled)}
              variant="tertiary"
            />
            <Link href={country && `${countryIdToDomain[country.id]}/store/items/${item.id}`}>
              {storeName}
            </Link>
          </div>
        </ProductLocationsTableTd>
        <ProductLocationsTableTd>
          <FlexRow>
            {index === currentRowEditIndex ? (
              <ProductLocationsCell
                attribute="available"
                type="dropdown"
                options={YES_NO_OPTIONS}
                item={item}
                onChange={handleEdit}
              />
            ) : (
              <BooleanText>{item.available ? i18n.yes : i18n.no}</BooleanText>
            )}
            {item.emergency_take_down && (
              <Tooltip target={<InformationIcon size={18} color={'brandLoyaltyRegular'} />}>
                <FormattedMessage id="productsDomain.view.storeLocations.override.alert.in.row" />{' '}
                {item.emergency_take_down_end}
              </Tooltip>
            )}
          </FlexRow>
        </ProductLocationsTableTd>
        {sproutsEnabled && (
          <ProductLocationsTableTd>
            <FlexRow>
              <BooleanText>{item.in_assortment ? i18n.yes : i18n.no}</BooleanText>
              {item.emergency_take_down && (
                <Tooltip target={<InformationIcon size={18} color={'brandLoyaltyRegular'} />}>
                  <FormattedMessage id="productsDomain.view.storeLocations.override.alert.in.row" />{' '}
                  {item.emergency_take_down_end}
                </Tooltip>
              )}
            </FlexRow>
          </ProductLocationsTableTd>
        )}
        {showAvailabilityScoreOverride && (
          <ProductLocationsTableTd>
            {availabilityScoreOverrideLoading ? (
              <LoadingLockupTextBase />
            ) : availabilityScoreOverride == null ? (
              <Text>-</Text>
            ) : index === currentRowEditIndex ? (
              <ProductLocationsAvailabilityScoreOverrideCell
                options={YES_NO_OPTIONS}
                onChange={setAvailabilityScoreOverride}
                disable={item.available == false}
              />
            ) : (
              <BooleanText>{availabilityScoreOverride ? i18n.yes : i18n.no}</BooleanText>
            )}
          </ProductLocationsTableTd>
        )}
        <ProductLocationsTableTd>
          {index === currentRowEditIndex ? (
            <ProductLocationsCell
              attribute="taxable"
              type="dropdown"
              options={YES_NO_OPTIONS}
              item={item}
              onChange={handleEdit}
            />
          ) : (
            <BooleanText>{item.taxable ? i18n.yes : i18n.no}</BooleanText>
          )}
        </ProductLocationsTableTd>
        <ProductLocationsTableTd>
          <span>{item.product_type === 'variable' ? i18n.yes : item.id ? i18n.no : '-'}</span>
        </ProductLocationsTableTd>
        <ProductLocationsTableTd>
          {index === currentRowEditIndex ? (
            <ProductLocationsCell
              attribute="cost_price_per_unit"
              type="currency"
              item={item}
              onChange={handleEdit}
            />
          ) : (
            <span>${item.cost_price_per_unit}</span>
          )}
        </ProductLocationsTableTd>
        <ProductLocationsTableTd>
          <span>${item.price}</span>
        </ProductLocationsTableTd>
        <ProductLocationsTableTd>
          {index === currentRowEditIndex ? (
            <ProductLocationsCell
              attribute="cost_unit"
              type="dropdown"
              options={COST_UNIT_OPTIONS}
              item={item}
              onChange={handleEdit}
            />
          ) : (
            <span>{item.cost_unit}</span>
          )}
        </ProductLocationsTableTd>
        <ProductLocationsTableTd>{actionElement}</ProductLocationsTableTd>
      </ProductLocationTableTr>

      {toggled ? (
        <ProductLocationTableTr>
          <ProductLocationsTableTd colSpan={10} style={{ paddingTop: '0' }}>
            <Divider>
              <DividerText>{i18n.details}</DividerText>
            </Divider>
            <Properties>
              {detailedAttributes.map(key => {
                return (
                  <Property key={key}>
                    <PropertyLabel>{startCase(key)}:</PropertyLabel>
                    <PropertyValue>{JSON.stringify(item?.[key] ?? 'None')}</PropertyValue>
                  </Property>
                )
              })}
            </Properties>
          </ProductLocationsTableTd>
        </ProductLocationTableTr>
      ) : null}
    </>
  )
}
