import React, { type FunctionComponent, useState } from 'react'
import {
  Alert,
  Button,
  colors,
  FileUploader,
  Modal,
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  NotificationLevel,
  notify,
  spacing,
  Text,
} from '@retailer-platform/shared-components'
import styled from '@emotion/styled'
import {
  type FileUploaderDropAreaComponentProps,
  type FileUploaderDroppedFileComponentProps,
  type FileUploaderErrorComponentProps,
} from '@retailer-platform/shared-components/src/common/file-uploader/utils/fileUploader.types'
import { parse, type ParseError } from 'papaparse'
import { Divider } from '@retailer-platform/shared-components/src/tds'
import faker from 'faker'
import { useDomainMessages } from '../../../utils/domain/intl'
import { useRunManualStepTestMutation } from '../../../api'
interface Props {
  onClose: () => void
  stepVersionId: string
  expectedFields: string[]
}

const Row = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

const StyledButton = styled(Button)({
  margin: `${spacing.X0}`,
})
const StyledModalBody = styled(ModalBody)({
  gap: `${spacing.X8}`,
})

const LLMPromptTemplateRunManualTestModal: FunctionComponent<React.PropsWithChildren<Props>> = ({
  onClose,
  stepVersionId,
  expectedFields,
}) => {
  const i18n = useDomainMessages({
    title: 'catalogAdminDomain.attributeManagement.runTests.modal.title',
    submit: 'catalogAdminDomain.attributeManagement.runTests.modal.button.submit',
    cancel: 'catalogAdminDomain.attributeManagement.runTests.modal.button.cancel',
    upload: 'catalogAdminDomain.attributeManagement.runTests.modal.upload.button',
    download: 'catalogAdminDomain.attributeManagement.runTests.modal.body.button.download',
    fileDescription: 'catalogAdminDomain.attributeManagement.runTests.modal.body.description',
    templateDescription:
      'catalogAdminDomain.attributeManagement.runTests.modal.body.template.description',
    noFileSelected: 'catalogAdminDomain.attributeManagement.runTests.modal.upload.noFileSelected',
    update: 'catalogAdminDomain.attributeManagement.runTests.modal.body.button.update',
  })
  const [file, setFile] = useState(null)
  const [fileProcessing, setFileProcessing] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [triggerManualTest, { loading }] = useRunManualStepTestMutation({
    variables: {
      clientId: 'catalog_camp_ipp',
      idempotencyKey: faker.random.uuid(),
      stepVersionId: stepVersionId,
      testInputs: file,
    },
    onError: e => {
      notify({
        level: NotificationLevel.Error,
        contents: `Error running manual test: ${e.message}`,
      })
    },
    onCompleted: () => {
      window.location.reload()
      onClose()
    },
  })
  const DropAreaComponent = ({ messages }: FileUploaderDropAreaComponentProps) => (
    <Row>
      <Text> {i18n.noFileSelected}</Text>
      <Button variant="secondary">{messages.uploadButton}</Button>
    </Row>
  )
  const DroppedFileComponent = ({ fileName }: FileUploaderDroppedFileComponentProps) => (
    <Row>
      <Text>{fileName}</Text>
      <Button variant="tertiary">{i18n.update}</Button>
    </Row>
  )
  const ErrorComponent = (props: FileUploaderErrorComponentProps) => (
    <Row>
      <Text>{props.fileName}</Text>
      <Text css={{ color: colors.DETRIMENTAL.REGULAR }}>{props.messages.error}</Text>
      <Button>{props.messages.retry}</Button>
    </Row>
  )

  const droppedAreaComponentMessages = useDomainMessages({
    uploadButton: 'catalogAdminDomain.attributeManagement.runTests.modal.upload.button',
    uploadText: 'catalogAdminDomain.attributeManagement.runTests.modal.upload.dragDrop',
    or: 'catalogAdminDomain.attributeManagement.runTests.modal.upload.or',
  })

  const errorComponentMessages = useDomainMessages({
    fileTypeError:
      'catalogAdminDomain.attributeManagement.runTests.modal.upload.error.fileTypeError',
    fileContentError:
      'catalogAdminDomain.attributeManagement.runTests.modal.upload.error.fileContentError',
    uploadError: 'catalogAdminDomain.attributeManagement.runTests.modal.upload.error.uploadError',
    error: 'catalogAdminDomain.attributeManagement.runTests.modal.upload.error.error',
    retry: 'catalogAdminDomain.attributeManagement.runTests.modal.upload.error.retry',
    rowError: 'catalogAdminDomain.attributeManagement.runTests.modal.upload.error.rowError',
  })

  const isUploadButtonDisabled = fileProcessing || errorMessage !== '' || !file

  const onDownloadTemplate = () => {
    const headers = ['product_id', 'locale_id', ...expectedFields]
    const csvContent = headers.join(',') + '\n'
    const blob = new Blob([csvContent], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'template.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const parseFile = (file: File) => {
    setFileProcessing(true)
    parse(file, {
      worker: true,
      header: true,
      skipEmptyLines: true,
      complete: result => {
        setFileProcessing(false)
        const rowCountIsValid = result.data.length > 0 && result.data.length <= 100
        if (rowCountIsValid) {
          const formattedData = result.data.map(row => {
            const formattedRow = Object.fromEntries(
              Object.entries(row).map(([key, value]) => [key, value])
            )
            return formattedRow
          })
          setFile(formattedData)
          setErrorMessage('')
        } else {
          setErrorMessage(errorComponentMessages.rowError)
        }
      },
      error: (error: ParseError) => {
        setFileProcessing(false)
        setErrorMessage(error.message)
      },
    })
  }

  return (
    <>
      <Modal isOpen={true} hideCloseButton={true}>
        <ModalContainer>
          <ModalHeader>{i18n.title}</ModalHeader>
          <StyledModalBody>
            <Text size="medium">{i18n.fileDescription}</Text>
            <Text size="medium">{i18n.templateDescription} </Text>

            <StyledButton variant="secondary" compact={true} onClick={onDownloadTemplate}>
              {i18n.download}
            </StyledButton>
            <Divider></Divider>
            <Text>{i18n.upload} </Text>

            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

            <FileUploader
              accept=".csv"
              DropAreaComponent={DropAreaComponent}
              onUpload={parseFile}
              DroppedFileComponent={DroppedFileComponent}
              ErrorComponent={ErrorComponent}
              droppedAreaComponentMessages={droppedAreaComponentMessages}
              errorComponentMessages={errorComponentMessages}
            />
          </StyledModalBody>

          <ModalFooter>
            <Button
              variant={'primary'}
              disabled={isUploadButtonDisabled || loading}
              onClick={() => triggerManualTest()}
            >
              {i18n.submit}
            </Button>
            <Button variant={'secondary'} onClick={() => onClose()}>
              {i18n.cancel}
            </Button>
          </ModalFooter>
        </ModalContainer>
      </Modal>
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
    </>
  )
}

export default LLMPromptTemplateRunManualTestModal
