import styled from '@emotion/styled'
import React, { type ButtonHTMLAttributes } from 'react'
import { ChevronDownIcon } from '@instacart/ids-core'
import { colors, fontSize } from '@retailer-platform/shared-components/src/foundation'

export type PopoverTriggerProps = ButtonHTMLAttributes<HTMLDivElement> & {
  /**
   * Whether the trigger should be compact.
   */
  compact?: boolean
  /**
   * Additional class name to apply. This is also used for enabling usage of the `css` prop to customize the component.
   */
  className?: string
  /**
   * Whether the popover is open, will change the chevron icon.
   */
  isOpen?: boolean
  onClick?: () => void

  focused?: boolean
}

const StyledRootElement = styled.div<PopoverTriggerProps>(
  ({ compact, disabled, focused, theme }) => {
    const color = disabled ? colors.GRAYSCALE.X30 : colors.GRAYSCALE.X50
    const backgroundColor = disabled ? colors.GRAYSCALE.X10 : colors.GRAYSCALE.X0

    return {
      color,
      backgroundColor,
      height: compact ? 28 : 40,
      padding: '10px 8px 10px 16px',
      borderRadius: compact ? '6px' : '8px',
      border: focused
        ? `1px solid ${theme.tdsColors.brandPrimaryRegular}`
        : `1px solid ${theme.tdsColors.systemGrayscale30}`,
      fontWeight: compact ? 'bold' : 'normal',
      fontSize: compact ? fontSize.X12 : fontSize.X15,
      verticalAlign: 'middle',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      transition: 'all 0.1s ease',
      gap: '8px',
      cursor: 'pointer',
    }
  }
)

/**
 * A component that is commonly used for triggering popovers while maintaining a common look and feel.
 * This accepts any children passed to it, and will automatically color any SVGs to match the color of the text (including disabled states).
 *
 * Combine with [Popover](https://mantine.dev/core/popover/) to have a fully working popover.
 * Look into the stories for an integrated example using both components.
 */
export const PopoverTriggerDiv: React.FunctionComponent<
  React.PropsWithChildren<PopoverTriggerProps>
> = React.forwardRef<HTMLDivElement, PopoverTriggerProps>(
  ({ children, disabled, focused, ...props }, ref) => (
    <StyledRootElement {...props} ref={ref} disabled={disabled} focused={props.isOpen}>
      <div
        css={{
          overflow: 'hidden',
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          gap: 6,
        }}
      >
        {children}
      </div>

      <ChevronDownIcon
        css={{
          transform: props.isOpen ? 'rotate(180deg)' : '',
        }}
        size={16}
      />
    </StyledRootElement>
  )
)
