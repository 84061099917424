import { useState, useCallback, useEffect } from 'react'
import * as filestack from 'filestack-js'
import { type FSProgressEvent } from 'filestack-js/build/main/lib/api/upload'

interface FilestackProps {
  value: string | null
  filestackApiKey: string
  onFileUpload?: (uploadUrl: string, file: File, key?: string) => void
}

// Define interface for storeURL response based on API documentation
interface StoreUrlResponse {
  url: string
  key?: string
  handle?: string
  filename?: string
  size?: number
  mimetype?: string
}

export const useFilestack = ({ value, filestackApiKey, onFileUpload }: FilestackProps) => {
  const [client, setClient] = useState<filestack.Client>()
  const [url, setUrl] = useState<string | null>(value)
  const [fileName, setFileName] = useState<string | null>(value)
  const [uploading, setUploading] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [uploadError, setUploadError] = useState<boolean>(false)

  useEffect(() => {
    setClient(filestack.init(filestackApiKey))
  }, [filestackApiKey])

  const onUploadProgress = useCallback(
    (e: FSProgressEvent) => setUploadProgress(e.totalPercent),
    [setUploadProgress]
  )

  const uploadFile = useCallback(
    async (file: File, container?: string, region?: string, path?: string) => {
      setUploading(true)
      setUploadError(false)

      try {
        if (client?.upload) {
          const res = await client.upload(
            file,
            {
              onProgress: onUploadProgress,
            },
            { location: 's3', filename: file.name, container, region, path }
          )
          onFileUpload?.(res.url, file, res.key)
          setUrl(res.url)
          setFileName(file.name)
        }
      } catch (_) {
        setUploadError(true)
        setUrl(null)
        setFileName(null)
      }

      setUploading(false)
    },
    [onFileUpload, client, onUploadProgress]
  )

  const uploadFileAndTransform = useCallback(
    async (
      file: File,
      transformOptions: filestack.TransformOptions,
      container?: string,
      region?: string,
      path?: string
    ) => {
      const storageOptions = {
        location: 's3',
        container,
        region,
        path,
      }

      setUploading(true)
      setUploadError(false)

      try {
        if (client?.upload) {
          // First upload the file to get a handle
          const uploadRes = await client.upload(
            file,
            {
              onProgress: onUploadProgress,
            },
            storageOptions
          )

          // Apply the transformation
          const transformedUrl = client.transform(uploadRes.handle, transformOptions)

          // Store the transformed URL to get a permanent URL without transformation parameters
          const storeResult = (await client.storeURL(
            transformedUrl,
            storageOptions
          )) as StoreUrlResponse

          // Use the stored URL and new key from storeResult
          onFileUpload?.(storeResult.url, file, storeResult.key || uploadRes.key)
          setUrl(storeResult.url)

          // Update filename if output format is specified
          if (transformOptions.output?.format) {
            const newExtension = transformOptions.output.format
            const newFileName = file.name.replace(/\.[^.]+$/, `.${newExtension}`)
            setFileName(newFileName)
          } else {
            setFileName(file.name)
          }
        }
      } catch (_) {
        setUploadError(true)
        setUrl(null)
        setFileName(null)
      }

      setUploading(false)
    },
    [onFileUpload, client, onUploadProgress]
  )

  const clearFile = useCallback(() => {
    setUrl(null)
    setFileName(null)
    setUploading(false)
    setUploadError(false)
    setUploadProgress(0)
  }, [])

  return {
    client,
    url,
    fileName,
    uploadFile,
    uploadFileAndTransform,
    clearFile,
    uploading,
    uploadProgress,
    uploadError,
  }
}
