import React, { type FunctionComponent } from 'react'
import { borderRadius, spacing, colors } from '@retailer-platform/shared-components'
import { SearchInput, Checkbox, ButtonText } from '@retailer-platform/shared-components/src/tds'
import { FilterIcon } from '@instacart/ids-core'
import { css } from '@emotion/react'
import { Spacer } from '@retailer-platform/shared-components/src/common/paginated-grid/PaginatedGrid.styles.tsx'
import { Row } from '../../../utils/common.styles.tsx'
import { type MdPlatformListFilters } from '../../../types/mdPlatform.types.ts'
import { useDomainMessages } from '../../../utils/domain/intl.ts'

export const cssTableFilterTextButtonStyle = css({
  textTransform: 'uppercase',
  padding: spacing.X12,
  height: 32,
  margin: 0,
  display: 'inline-flex',
  alignItems: 'center',
})

interface Props {
  filters: MdPlatformListFilters
  onChange: (filters: MdPlatformListFilters) => void
}

export const MdPlatformListFilterContainer: FunctionComponent<React.PropsWithChildren<Props>> = ({
  onChange,
  filters,
}) => {
  const i18n = useDomainMessages({
    id: 'catalogAdminDomain.mdPlatformList.table.filters.id',
    name: 'catalogAdminDomain.mdPlatformList.table.filters.name',
    owner: 'catalogAdminDomain.mdPlatformList.table.filters.owner',
  })
  return (
    <Row
      style={{
        gap: `${spacing.X16}`,
        alignItems: 'center',
        background: colors.GRAYSCALE.X0,
        padding: `${spacing.X16}`,
        borderRadius: `${borderRadius.X12}`,
      }}
    >
      <FilterIcon size={24} style={{ margin: 'auto' }} />
      <SearchInput
        placeholder={i18n.id}
        id="md-filters-id"
        value={filters.id}
        onChange={evt => {
          onChange({
            ...filters,
            id: evt.target.value,
          })
        }}
        width={'200px'}
      />
      <SearchInput
        placeholder={i18n.name}
        id="md-filters-name"
        value={filters.name}
        onChange={evt => {
          onChange({
            ...filters,
            name: evt.target.value,
          })
        }}
        width={'200px'}
      />
      <SearchInput
        placeholder={i18n.owner}
        id="md-filters-name"
        value={filters.owner}
        onChange={evt => {
          onChange({
            ...filters,
            owner: evt.target.value,
          })
        }}
        width={'200px'}
      />
      <div
        style={{
          display: 'flex',
          gap: `${spacing.X8}`,
          alignItems: 'center',
          padding: `${spacing.X4}`,
        }}
      >
        <Checkbox
          id={'filterHideRejections'}
          checked={filters.hideRejectedVersions}
          onChange={() => {
            onChange({
              ...filters,
              hideRejectedVersions: !filters.hideRejectedVersions,
            })
          }}
          label={'Hide Rejections'}
        />
      </div>
      <ButtonText
        onClick={() => {
          onChange({
            name: '',
            id: '',
            owner: '',
            hideRejectedVersions: false,
          })
        }}
        css={cssTableFilterTextButtonStyle}
      >
        Clear
      </ButtonText>
      <Spacer />
    </Row>
  )
}
