import type { FunctionComponent } from 'react'
import { Divider } from './Common'
import { getKey, type Option } from './utils'
import MultiScopeDropdownHiddenOptionContainer from './MultiScopeDropdownHiddenOptionContainer'
import MultiScopeDropdownOption from './MultiScopeDropdownOption'

interface Props {
  option: Option
  selectedState: Record<string, Option>
  setSelectedState: (state: Record<string, Option>) => void
  multiSelect: boolean
  onSingleSelect: (option: Option) => void
  internalPadding?: number
  searchFilter: string
}

const renderChildren = (
  option: Option,
  options: Option[],
  selectedState: Record<string, Option>,
  setSelectedState: (state: Record<string, Option>) => void,
  multiSelect: boolean,
  onSingleSelect: (option: Option) => void,
  internalPadding: number,
  searchFilter: string
) => {
  let visibleOptions: Option[]
  let hiddenOptions: Option[]

  if (option.type === 'all' || searchFilter?.length > 0) {
    visibleOptions = options
    hiddenOptions = []
  } else {
    visibleOptions = options.slice(0, 3)
    hiddenOptions = options.slice(3)

    if (!multiSelect) {
      // options that are selected but in the hidden section should always be visible. Move
      // these options to the top of the visible options list.
      const hiddenSelectedOption = hiddenOptions.find(option => selectedState[getKey(option)])
      hiddenOptions = hiddenOptions.filter(option => !selectedState[getKey(option)])

      if (hiddenSelectedOption) {
        visibleOptions.unshift(hiddenSelectedOption)
        hiddenOptions.unshift(visibleOptions.pop())
      }
    }
  }

  const jsxArr = visibleOptions.map(option => (
    <div key={getKey(option)}>
      <MultiScopeDropdownOptionContainer
        option={option}
        selectedState={selectedState}
        setSelectedState={setSelectedState}
        multiSelect={multiSelect}
        onSingleSelect={onSingleSelect}
        internalPadding={internalPadding}
        searchFilter={searchFilter}
      />
    </div>
  ))

  if (hiddenOptions.length > 0) {
    jsxArr.push(
      <MultiScopeDropdownHiddenOptionContainer
        key={getKey(option)}
        option={option}
        options={hiddenOptions}
        selectedState={selectedState}
        setSelectedState={setSelectedState}
        multiSelect={multiSelect}
        onSingleSelect={onSingleSelect}
        internalPadding={internalPadding}
        searchFilter={searchFilter}
      />
    )
  }

  return jsxArr
}

const MultiScopeDropdownOptionContainer: FunctionComponent<Props> = ({
  option,
  selectedState,
  setSelectedState,
  multiSelect,
  onSingleSelect,
  internalPadding = 0,
  searchFilter,
}) => {
  const isSiteAndDisabled = option.type === 'site' && !option.siteEnabled

  return (
    <div>
      <MultiScopeDropdownOption
        option={option}
        selectedState={selectedState}
        setSelectedState={setSelectedState}
        multiSelect={multiSelect}
        onSingleSelect={onSingleSelect}
        internalPadding={internalPadding}
      />
      {option.type === 'all' && <Divider />}
      {!option.leafNode && !isSiteAndDisabled && (
        <div>
          {renderChildren(
            option,
            option.children,
            selectedState,
            setSelectedState,
            multiSelect,
            onSingleSelect,
            option.type === 'all' ? internalPadding : internalPadding + 24,
            searchFilter
          )}
        </div>
      )}
    </div>
  )
}

export default MultiScopeDropdownOptionContainer
