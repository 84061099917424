import React from 'react'
import { fontSize, Text, TextArea, Tooltip } from '@retailer-platform/shared-components'
import { HelpIcon } from '@instacart/ids-core'
import { SwitchBase } from '@instacart/ids-tooling'
import {
  Input,
  Property,
  PropertyContainer,
  PropertyRow,
  PropertySectionLeft,
  PropertySectionRight,
  PropertyTitle,
  SectionHeader,
  TooltipContainerNotFullWidth,
} from '../../shared-styled-components/SharedStyledComponents'
import { useDomainMessages } from '../../../utils/domain/intl'
import { type FormProps } from './StepAttributeForm'

const SnowflakeQueryInputForm = ({ attributes, mode, onChange }: FormProps) => {
  const i18n = useDomainMessages({
    ttl: 'catalogAdminDomain.attributeManagement.stepAttributeForms.snowflakeQueryInputForm.ttl',
    deduplicationDays:
      'catalogAdminDomain.attributeManagement.stepAttributeForms.snowflakeQueryInputForm.deduplicationDays',
    limit:
      'catalogAdminDomain.attributeManagement.stepAttributeForms.snowflakeQueryInputForm.limit',
    ttlTooltip:
      'catalogAdminDomain.attributeManagement.stepAttributeForms.snowflakeQueryInputForm.ttl.help',
    deduplicationDaysTooltip:
      'catalogAdminDomain.attributeManagement.stepAttributeForms.snowflakeQueryInputForm.deduplicationDays.help',
    limitTooltip:
      'catalogAdminDomain.attributeManagement.stepAttributeForms.snowflakeQueryInputForm.limit.help',
    queryTooltip:
      'catalogAdminDomain.attributeManagement.stepAttributeForms.snowflakeQueryInputForm.query.help',
    title:
      'catalogAdminDomain.attributeManagement.stepAttributeForms.snowflakeQueryInputForm.title',
  })
  return (
    <>
      <SectionHeader> {i18n.title} </SectionHeader>
      <PropertyContainer>
        <PropertySectionLeft>
          <PropertyRow>
            <PropertyTitle>
              <div>{i18n.ttl}</div>
              <TooltipContainerNotFullWidth>
                <Tooltip placement="bottom" target={<HelpIcon />}>
                  {i18n.ttlTooltip}
                </Tooltip>
              </TooltipContainerNotFullWidth>
            </PropertyTitle>
            <SwitchBase
              checked={attributes.ttl !== null}
              disabled={mode === 'view'}
              description="Enable time to live"
              onChange={event => onChange({ ...attributes, ttl: event.target.checked ? 24 : null })}
            />
            <Input
              type={attributes.ttl === null ? 'text' : 'number'}
              value={attributes.ttl ?? i18n.ttl}
              disabled={attributes.ttl === null || mode === 'view'}
              onChange={event =>
                onChange({ ...attributes, ttl: Math.max(Number(event.target.value), 1) })
              }
            />
          </PropertyRow>
          <PropertyRow>
            <PropertyTitle>
              {i18n.deduplicationDays}
              <TooltipContainerNotFullWidth>
                <Tooltip placement="bottom" target={<HelpIcon />}>
                  {i18n.deduplicationDaysTooltip}
                </Tooltip>
              </TooltipContainerNotFullWidth>
            </PropertyTitle>
            <SwitchBase
              checked={attributes.deduplicationDays !== null}
              disabled={mode === 'view'}
              description="Enable deduplication"
              onChange={event =>
                onChange({
                  ...attributes,
                  deduplicationDays: event.target.checked ? 7 : null,
                })
              }
            />
            <Input
              type={attributes.deduplicationDays === null ? 'text' : 'number'}
              value={attributes.deduplicationDays ?? 'Always deduplicate'}
              disabled={attributes.deduplicationDays === null || mode === 'view'}
              onChange={event =>
                onChange({
                  ...attributes,
                  deduplicationDays: Math.max(Number(event.target.value), 1),
                })
              }
            />
          </PropertyRow>
          <PropertyRow>
            <PropertyTitle>
              {i18n.limit}
              <TooltipContainerNotFullWidth>
                <Tooltip placement="bottom" target={<HelpIcon />}>
                  {i18n.limitTooltip}
                </Tooltip>
              </TooltipContainerNotFullWidth>
            </PropertyTitle>
            <Input
              type="number"
              value={attributes.limit}
              disabled={mode === 'view'}
              onChange={event =>
                onChange({
                  ...attributes,
                  limit: Math.min(Math.max(Number(event.target.value), 1), 100000),
                })
              }
            />
          </PropertyRow>
        </PropertySectionLeft>
        <PropertySectionRight>
          <Property style={{ height: '100%' }}>
            <PropertyTitle>Query</PropertyTitle>
            <Text size="small">{i18n.queryTooltip}</Text>
            <TextArea
              css={{ fontFamily: 'Courier', fontSize: fontSize.X15, resize: 'vertical' }}
              disabled={mode !== 'edit'}
              onChange={e => {
                onChange({
                  ...attributes,
                  query: e.target.value,
                })
              }}
              value={attributes.query}
            />
          </Property>
        </PropertySectionRight>
      </PropertyContainer>
    </>
  )
}

export default SnowflakeQueryInputForm
