/* eslint-disable @typescript-eslint/naming-convention */
// eslint-disable-next-line workspaces/no-relative-imports
import { REPORTS_EN_US_TRANSLATIONS } from '../../../../../domains/reports/src/translations/en-US'

/* eslint-disable max-lines */
// prettier-ignore
export const EN_US_TRANSLATIONS = {
  "admin.edit.new.title": "New User",
  "admin.title": "Admin",
  "admin.activity.title": "Activity Log",
  "admin.activity.action.create": "Created",
  "admin.activity.action.delete": "Deleted",
  "admin.activity.action.update": "Updated",
  "admin.activity.action.reactivate": "Reactivated",
  "admin.activity.section.department": "Department",
  "admin.activity.section.aisle": "Aisle",
  "admin.activity.section.product": "Product",
  "admin.activity.section.user": "User account",
  "admin.activity.section.shopper": "Shopper",
  "admin.activity.details.dept.created": "{name} created {department}",
  "admin.activity.details.dept.deleted": "{name} deleted {department}",
  "admin.activity.details.dept.rename": "{name} renamed {oldName} to {newName}",
  "admin.activity.details.dept.schedule": "{name} scheduled {department} from {startDate} to {endDate}",
  "admin.activity.details.aisle.created": "Within the {department} department, {name} created {aisle}",
  "admin.activity.details.aisle.deleted": "Within the {department} department, {name} deleted {aisle}",
  "admin.activity.details.aisle.renamed": "Within the {department} department, {name} renamed {oldName} to {newName}",
  "admin.activity.details.aisle.visible": "Within the {department} department, {name} made {aisle} visible",
  "admin.activity.details.aisle.invisible": "Within the {department} department, {name} made {aisle} invisible",
  "admin.activity.details.aisle.addProduct": "Within the {department} department, {name} added {product} to {aisle}",
  "admin.activity.details.aisle.addProductBulk": "Within the {department} department, {name} added {productCount} to {aisle}",
  "admin.activity.details.aisle.removeProduct": "Within the {department} department, {name} removed {product} from {aisle}",
  "admin.activity.details.productCount": "{productCount, number} {productCount, plural, one {product} other {products}}",
  "admin.activity.details.product.request": "{name} made a request to add {product} to your catalog.",
  "admin.activity.details.product.addProduct": "{name} added {product} to your catalog.",
  "admin.activity.details.user.create": "{name} created account for {firstName} {lastName}:",
  "admin.activity.details.user.update": "{name} updated account for {firstName} {lastName}:",
  "admin.activity.details.user.destroy": "{name} deleted user account for {firstName} {lastName}",
  "admin.activity.details.user.reactivate": "{name} reactivated user account for {firstName} {lastName}",
  "admin.activity.details.item.update": "{name} made the following updates to {product} at 1 location",
  "admin.activity.details.item.createBulk": "{name} assigned {product} to {count, number} {count, plural, one {location} other {locations}} with following attributes",
  "admin.activity.details.item.updateBulk": "{name} made the following updates to {product} at {count, number} {count, plural, one {location} other {locations}}",
  "admin.activity.details.product.update": "{name} made the following updates to {product}",
  "admin.activity.details.product.changeAisle": "{name} moved {product} from {from} to {to}",
  "admin.activity.details.product.addAisle": "{name} added {product} to {to}",
  "admin.activity.details.product.removeAisle": "{name} removed {product} from {from}",
  "admin.activity.details.product.assignMainAisleBulk": "{name} assigned {productCount} to {aisle} within {department} department",
  "admin.activity.details.product.taxable": "Taxable: {taxable}",
  "admin.activity.details.product.available": "Available: {available}",
  "admin.activity.details.product.costPerUnit": "Standard price: {cost}",
  "admin.activity.details.product.costUnit": "Priced by: {unit}",
  "admin.activity.details.product.name": "Name: {name}",
  "admin.activity.details.product.brand": "Brand: {brand}",
  "admin.activity.details.product.size": "Size: {size}",
  "admin.activity.details.product.unit": "Unit: {unit}",
  "admin.activity.details.product.details": "Details: {details}",
  "admin.activity.details.product.image": "Image:",
  "admin.activity.details.shopper.create": "{name} created a shopper account for {firstName} {lastName}",
  "admin.activity.details.shopper.destroy": "{name} deactivated a shopper account with id {id}",
  "admin.activity.details.user.email": "Email: {email}",
  "admin.activity.details.user.roles": "Roles: {roles}",
  "admin.activity.status.in_progress": "In Progress",
  "admin.activity.status.success": "Success",
  "admin.activity.status.failure": "Failure",
  "admin.users.edit.title": "Edit User",
  "admin.users.title": "Manage Users",
  "admin.users.new.title": "Create New User",
  "admin.users.roles.hacker": "Hacker",
  "admin.users.roles.partner": "Manager",
  "admin.users.roles.partner_staff": "Staff",
  "admin.users.roles.admin": "Instacart Admin",
  "admin.users.roles.partner_admin": "Admin",
  "admin.users.roles.pharmacist": "Pharmacist",
  "admin.users.roles.pharmacist_admin": "Pharmacist Admin",
  "admin.users.roles.hacker.description": "Full access, including dev tools",
  "admin.users.roles.partner.description": "Managers can access the homepage, catalog management, and reporting, but cannot manage users.",
  "admin.users.roles.partner_staff.description": "Staff can access the homepage and catalog management, but cannot access reporting or manage users.",
  "admin.users.roles.admin.description": "Instacart Admins have admin level access to the entire platform, with the addition of internal only tools.",
  "admin.users.roles.partner_admin.description": "Admins have access to the entire platform, including account management and Stripe tools.",
  "admin.users.roles.pharmacist.description": "Pharmacists can access the homepage and catalog management and rx reports, but cannot access reporting or manage users.",
  "admin.users.roles.pharmacist_admin.description": "Pharmacist Admins can access the homepage, catalog management and rx reports; as well as manage and add pharmacist users.",
  "admin.users.roles.shift_manager": "Shift Manager",
  "admin.users.roles.shift_manager.description": "Shift Managers can access shopper and shift management",
  "admin.users.apps.rpp": "Instacart Platform Portal",
  "admin.users.apps.read_only": "Read-only",
  "admin.users.filters.find_user": "Search by name...",
  "admin.users.filters.roles.all": "All roles",
  "admin.users.add_user": "Add User",
  "admin.users.locations.defaultText": "Select location(s) to limit access *",
  "admin.users.locations.message": "* Deselect all locations to provide full access.",
  "adminPanel.title": "Admin Panel",
  "aisles.addProducts": "Add Products",
  "aisles.create": "Add Aisle",
  "aisles.all": "All Aisles",
  "aisle.empty.message": "Create an aisle to begin adding products.",
  "aisle.delete.title": "Delete Aisle?",
  "aisle.delete.confirmation": "Are you sure you want to delete this aisle? It has {productCount} products in it. This action cannot be undone.",
  "aisle.delete.error": "There was a problem deleting the aisle.",
  "aisle.notification.share": "A link to the {name} aisle in your storefront has been copied to your clipboard. Please note that for newly created aisles, links may take up to 6 hours to become active.",
  "aisle.onlyPrimaryProducts": "Only primary products",
  "aisles.onlyPrimaryProducts.error": "Unable to load data for aisle visibility. Please try again.",
  "aisles.tooltip.edit": "Edit Aisle",
  "aisles.tooltip.share": "Share Aisle",
  "aisles.tooltip.delete": "Delete Aisle",
  "aisles.tooltip.show": "Show Aisle",
  "aisles.tooltip.hide": "Hide Aisle",
  "aisles.modal.label": "Aisle Name",
  "aisles.modal.labelFr": "Aisle Name (French)",
  "aisles.modal.edit": "Edit Aisle",
  "aisles.visibility.warningModal.title": "Cannot Hide {aisleName} Aisle",
  "aisles.visibility.warningModal.message": "Please remove any products that have the current department and aisle set as their primary department and aisle before attempting to hide the aisle.",
  "aisles.visibility.warningModal.filterMessage": "To identify impacted products, enable the \"Only Primary Products\" toggle.",
  "aisles.visibility.modal.show.title": "Show {aisleName} Aisle?",
  "aisles.visibility.modal.show.message": "This aisle will be visible in all locations and sales channels IF products are present. Are you sure you want to continue?",
  "aisles.visibility.modal.show.confirm": "Show",
  "aisles.visibility.modal.hide.title": "Hide {aisleName} Aisle?",
  "aisles.visibility.modal.hide.message": "This aisle will be hidden in all locations and sales channels. Are you sure you want to continue?",
  "aisles.visibility.modal.hide.confirm": "Hide",
  "aisles.visibility.modal.editClause": "This setting can be changed later on.",
  "aisles.visibility.async.show.success": "{aisleName} Aisle has been set to visible.",
  "aisles.visibility.async.show.error": "There was an error setting {aisleName} Aisle to visible. Please try again.",
  "aisles.visibility.async.hide.success": "{aisleName} Aisle has been set to hidden.",
  "aisles.visibility.async.hide.error": "There was an error setting {aisleName} Aisle to hidden. Please try again.",
  "aisles.visibility.async.timingClause": "\u2000Please note that updates may take up to 6 hours to be reflected on the storefront.",
  "campaigns.title": 'Campaigns',
  "catalog.aisle.addProductRequested": "Your request is being processed. Please note that updates may take up to 6 hours to take effect.",
  "catalog.aisle.countSummary": "{product_count} products",
  "catalog.aisle.new.title": "Create Aisle",
  "catalog.aisles.title": "Aisles",
  "catalog.breadcrumb.department": "Departments",
  "catalog.department.edit.title": "Edit Department",
  "catalog.department.new.title": "Create Department",
  "catalog.department.new": "New Department",
  "catalog.departments.title": "Departments",
  "catalog.error.trademark": "Trademarked names should not be used. Please contact adcopy@instacart.com for assistance.",
  "catalog.filter.findDepartment": "Find a department...",
  "catalog.manageProducts": "manage products",
  "catalog.products.addToStores.title": "Add to store",
  "catalog.products.bulkEdit.title": "Bulk Edit",
  "catalog.products.details.title": "Details",
  "catalog.products.locations.add-locations": "Add Locations",
  "catalog.products.locations.sprouts.add-item-availability-patch": "Temporary Availability Overrides",
  "catalog.products.locations.bulk-edit": "Edit All ({count})",
  "catalog.products.locations.items.selected": "{count, number} {count, plural, one {item} other {items}} selected",
  "catalog.products.locations.title": "Locations",
  "catalog.products.locations.availabilityScoreOverride.disabled": "Function not available due to product not being available in this store location.",
  "catalog.products.locations.requestSubmitted.availabilityScoreOverride.missingDateError": "Missing start or end dates for Disable Availability Prediction.",
  "catalog.products.locations.requestSubmitted.availabilityScoreOverride.dateLimitError": "Availability prediction cannot be disabled for more than 90 days.",
  "catalog.products.locations.requestSubmitted.availabilityScoreOverride.countTooHighError": "Too many products with disable availability prediction active.",
  "catalog.products.locations.requestSubmitted.availabilityScoreOverride.multipleEntityTypeError": "Only one type of edit is supported at a time.",
  "catalog.products.locations.requestSubmitted": "Your request is being processed. Please note that updates may take up to 6 hours to be reflected here.",
  "catalog.products.newRequest": "New Product Request",
  "catalog.products.newRequest.title": "New Product Request",
  "catalog.products.product.title": "Product",
  "catalog.products.title": "Products",
  "catalog.product.editProduct.errors.name": "You must include a Product Name",
  "catalog.product.editProduct.errors.size": "You must include a size (e.g. \"2.5 lbs\")",
  "catalog.product.editProduct.errors.unitCount": "You must include a unit count",
  "catalog.product.editProduct.errors.images": "You must re-upload a new image.",
  "catalog.product.editProduct.warning.title": "Warning",
  "catalog.product.editProduct.warning.catalogFile": "catalog file",
  "catalog.product.editProduct.warning.productDetailsPage": "product details page",
  "catalog.product.editProduct.warning.0": "Images uploaded via the {productDetailsPage} cannot be changed through bulk image uploads",
  "catalog.product.editProduct.warning.1": "If images are uploaded from either the {catalogFile} or via the {productDetailsPage}, the latest one to be uploaded will be shown",
  "catalog.product.editProduct.warning.2": "You will be required to re-upload all existing Product Images when updating or adding new Product Images to ensure image consistency.",
  "catalog.search.hints.name": "bananas, granola...",
  "catalog.search.hints.rrc": "00091993, 150124, 120450...",
  "catalog.search.hints.upc": "0039978021540, 00039978009524...",
  "catalog.search.title": "Catalog Search",
  "catalog.searchResults.title": "Search Results",
  "catalog.search": "Search Catalog",
  "catalog.search.hasImageFilter.all": "All Image Coverage",
  "catalog.search.hasImageFilter.with": "Products with images",
  "catalog.search.hasImageFilter.without": "Products without images",
  "catalog.nav.manageCatalog": "Manage Catalog",
  "catalog.requestNewProduct": "Request new product",
  "catalog.addProducts.searchFilter.allProducts": "All Products",
  "catalog.addProducts.searchFilter.InCatalog": "In Catalog",
  "catalog.addProducts.searchFilter.NotInCatalog": "Not in Catalog",
  "catalog.addProducts.addToCatalog.add": "Add",
  "catalog.addProducts.addToCatalog.inCatalog": "In Catalog",
  "catalog.addProducts.searchResults.footerPrefix": "Can't find what you're looking for?",
  "catalog.addProducts.searchResults.footerAction": "Add a new item",
  "catalog.addProducts.searchResults.footerSuffix": "to your catalog.",
  "catalog.addProducts.title": "Add Products",
  "catalog.imageUploads": "Image Uploads",
  "catalog.imageUploads.errorLoading": "There was an error loading image uploads.",
  "catalog.imageUploads.productNotFound": "Item not found",
  "catalog.imageUploads.emptyState.title": "Upload Product Images Here",
  "catalog.imageUploads.emptyState.description": "Bulk add product images to your catalog by uploading a zip file or multiple images at once.",
  "catalog.imageUploads.uploadProductImages": "Upload Product Images Here",
  "catalog.imageUploads.uploadImages": "Upload Images",
  "catalog.imageUploads.uploadSuccess": "Your request is being processed. The images should be available here for your review within 6 hours.",
  "catalog.imageUploads.uploadFailure": "Your images failed to upload. Please try again.",
  "catalog.imageUploads.fileErrors.heading": "Some of the files had problems:",
  "catalog.imageUploads.fileErrors.wrongFileFormat": "{name} is not PNG or JPG",
  "catalog.imageUploads.fileErrors.fileTooBig": "{name} is too big",
  "catalog.imageUploads.fileErrors.wrongDimensions": "{name} is {width}x{height}px; it must be between {minDimension}x{minDimension}px and {maxDimension}x{maxDimension}px",
  "catalog.imageUploads.fileErrors.badImage": "{name} appears to be corrupted",
  "catalog.imageUploads.uploadErrors.heading": "A problem occurred:",
  "catalog.imageUploads.uploadZone.text": "Drag and drop product images or a zip file here, or {link}",
  "catalog.imageUploads.uploadZone.linkText": "browse your files",
  "catalog.imageUploads.warning.title": "Warning",
  "catalog.imageUploads.warning.catalogFile": "catalog file",
  "catalog.imageUploads.warning.productDetailsPage": "product details page",
  "catalog.imageUploads.warning.0": "Images uploaded via the bulk image upload will not appear if images were previously provided via another image source (such as updates via {catalogFile} or via the {productDetailsPage})",
  "catalog.imageUploads.warning.1": "Updates to a product image from a {catalogFile} or via the {productDetailsPage} will override images from bulk image upload",
  "catalog.imageUploads.requirements.title": "Requirements",
  "catalog.imageUploads.requirements.0": "Main image must contain the product code, then a period, and the file extension, such as {file}",
  "catalog.imageUploads.requirements.1": "Each image {maxSize} or a resolution of 4000 x 4000 px. Our recommended resolution is {recommendedRes}",
  "catalog.imageUploads.requirements.1.maxSize": "cannot exceed 3mb",
  "catalog.imageUploads.requirements.1.recommendedRes": "1000 x 1000 px @ 300 DPI",
  "catalog.imageUploads.requirements.2": "Images must be in {format}",
  "catalog.imageUploads.requirements.2.format": "either PNG or JPG format",
  "catalog.imageUploads.trouble": "Having trouble? See full {link}.",
  "catalog.imageUploads.trouble.uploadGuide": "upload guide",
  "catalog.imageUploads.uploadComplete": "Upload complete. Please stand by...",
  "catalog.imageUploads.uploadError": "Upload Error",
  "catalog.imageUploads.processing": "Processing...",
  "catalog.title": "Catalog",
  "catalog.department.legacy": "Departments (legacy)",
  "catalog.department.summary": "{aisle_count} aisles, {product_count} products",
  "catalog.product.image": "Primary Image",
  "catalog.product.secondaryImages": "Secondary Images",
  "catalog.product.image.edit": "Edit Image",
  "catalog.product.image.add": "Add Image",
  "catalog.product.angle.angle_left": "Left",
  "catalog.product.angle.angle_right": "Right",
  "catalog.product.angle.angle_back": "Back",
  "catalog.product.angle.angle_top": "Top",
  "catalog.product.angle.ingredients": "Ingredients",
  "catalog.product.angle.nutrition": "Nutrition",
  "catalog.product.angle.supplements": "Supplement Facts",
  "catalog.product.angle.drugs": "Drug Facts",
  "catalog.product.angle.lifestyle": "Lifestyle",
  "catalog.product.info": "Product Info",
  "catalog.product.pricing": "Pricing Info",
  "catalog.product.description": "Product Description",
  "catalog.product.details": "Product Details",
  "catalog.product.aisles": "Departments and Aisles",
  "catalog.product.aisle": "Department and Aisle",
  "catalog.product.locations": "Store Locations",
  "catalog.product.codes": "Product Codes",
  "catalog.product.codes.note":"Please enter the product's UPC or RRC, or both.",
  "catalog.product.upcCodes.label": "UPC",
  "catalog.product.rrcCodes.label": "RRC",
  "catalog.product.ingredients": "Ingredients",
  "catalog.product.attributes": "Attributes",
  "catalog.product.aisles.add": "Add Aisle",
  "catalog.product.form.errorMessage": "One or more fields have errors. Please fix before proceeding.",
  "catalog.product.edit.title": "Edit",
  "catalog.product.add.title": "Add Product",
  "catalog.product.requestNew.title": "Request New Product",
  "catalog.product.requestNew.notification": "Your product request will be reviewed shortly.",
  "catalog.product.edit.secondary_image_delete_fail": "Secondary image deletion failed!",
  "catalog.product.edit.image_upload_fail": "Unexpected error while saving images. Images may be of unsupported type.",
  "catalog.product.edit.saving_fail": "Unexpected error while saving product edits.",
  "catalog.product.edit.notification": "You successfully updated this product’s details. Please note that updates may take up to 6 hours to be reflected here.",
  "catalog.product.add.notification": "You have successfully added this product to your catalog.",
  "catalog.product.requestNew.submitButtonText": "Submit for review",
  "catalog.product.add.submitButtonText": "Add to catalog",
  "catalog.product.fetchFailed.title": "We couldn't load this product.",
  "catalog.product.fetchFailed.message": "Something went wrong on our end. Please try reloading the page.",
  "catalog.product.head.department": "Department",
  "catalog.product.head.aisle": "Aisle",
  "catalog.product.head.availability": "Availability",
  "catalog.product.aisle.updatePrimary.success": "You've edited the primary department and aisle for this product. Catalog updates will take effect within 1 hour.",
  "catalog.product.tooltip.edit": "Edit department or aisle",
  "catalog.product.tooltip.delete": "Delete department and aisle",
  "catalog.product.tooltip.primary": "This is the primary department and aisle",
  "catalog.product.tooltip.setPrimary": "Make this the primary department and aisle",
  "catalog.product.tooltip.deletePrimary": "Set a new primary aisle and department in order to delete this one",
  "catalog.product.aisles.deleteFailure": "We weren't able to remove {aisleName} from this product. Please try again later.",
  "catalog.product.aisles.deleteSuccess": "You have removed {aisleName} from this product.",
  "catalog.product.aisles.updateFailure": "We weren't able to update this product's aisle. Please try again later.",
  "catalog.product.aisles.updateSuccess": "You have updated the aisle for this product.",
  "catalog.merchandising.title": "Merchandising",
  "storeLocations.count": "{count} {count, plural, one {Store} other {Stores}}",
  "common.yes": "Yes",
  "common.no": "No",
  "common.none": "None",
  "common.loading": "Loading",
  "common.loadingDots": "...",
  "common.error": "Encountered an error.",
  "common.error.title": "Error",
  "common.add": "Add",
  "common.remove": "Remove",
  "common.date": "Date",
  "common.reloadPage": "Reload Page",
  "common.tryAgain": "Try again",
  "common.lists.selectAll": "Select All",
  "common.dates.startDate": "Start",
  "common.dates.endDate": "End",
  "common.dates.noEndDate": "No end date",
  "common.confirm": "Confirm",
  "common.cancel": "Cancel",
  "common.back": "Back",
  "common.close": "Close",
  "common.here": "Here",
  "common.noResults": "No Results",
  "common.browse": "Browse",
  "common.is": "is",
  "common.uploaded": "Uploaded",
  "common.edit": "Edit",
  "common.upc": "UPC",
  "common.rrc": "RRC",
  "common.active": "Active",
  "common.inactive": "Inactive",
  'components.ippCaptchaCheckbox.error': 'Please complete the Captcha',
  "components.table.totalPages": "of {pageCount}",
  "components.table.itemCounts": "Displaying {from} - {to} of {count} items",
  "components.table.itemsPerPage": "Items Per Page",
  "components.progressBar.completed": "COMPLETED",
  "components.progressBar.failed": "FAILED",
  "components.retailerLocationSelect.allLocationsOption": "All Locations",
  "components.retailerLocationSelect.noGroups": 'No Store Groups Available',
  "components.retailerLocationSelect.createGroup": 'Create a Store Group',
  "components.retailerLocationSelect.toggleGroups": 'See Store Groups Only',
  "components.departmentsSelect.defaultLabel": 'Select Department',
  "components.departmentsSelect.noDepartment": 'No Department Selected',
  "components.aislesSelect.defaultLabel": 'Select Aisle',
  'components.loadingPage.header': "Loading",
  'components.errorPage.header': "Uh Oh",
  'components.errorPage.content': "Something happened while attempting to get your information. Please try again, and if the problem persists, contact support.",
  'components.errorPage.defaultAction': "Retry",
  'components.unavailablePage.header': 'Page Unavailable',
  'components.unavailablePage.content': "Apologies, but this page is temporarily unavailable. Please try again later, and if you need additional assistance, contact support.",
  'components.unavailablePage.action': "Go back",
  'components.imageUploader.selectImage': "Select Image",
  'components.imageUploader.confirmCrop': "Confirm Crop",
  'components.imageUploader.imageEditor': "Image Editor",
  'components.imageUploader.remove': "remove image",
  'components.imageUploader.add': "add image",
  'components.imageUploader.cropError': "Failed to generate cropped image, please try again.",
  'components.imageUploader.fileError.invalidFile': "Invalid Image File",
  'components.imageUploader.fileError.invalidDimensions': "Image does not match required dimensions",
  'components.imageUploader.uploadError': "Error occurred when uploading file, try again",
  'components.imageUploader.scaleToFit': "Height and width will scale to fit the required dimensions based on locked aspect ratio.",
  'components.compactImageUploader.fileError.invalidFile': "Image does not match required dimensions",
  'components.compactImageUploader.fileError.invalidDimensions': "Invalid Image File",
  'components.compactImageUploader.uploadError': "Error occurred when uploading file, try again",
  'components.codeLookupMatcher.validationError.invalidCharacters': "Codes should only contain numerical characters",
  'components.codeLookupMatcher.validationError.invalidLength': "UPCs should be 13 characters or less",
  'components.codeLookupMatcher.validationError.invalidTotalCount': "Only 500 Codes can be uploaded at one time",
  'components.localeSelect.noLocale': "No locale selected.",
  'components.localeSelect.locales.en': "English",
  'components.localeSelect.locales.fr': "French",
  'components.localeSelect.locales.es': "Spanish",
  "components.dayWeekToggle.label": "Day Week Toggle",
  "components.dayWeekToggle.day": "Day",
  "components.dayWeekToggle.week": "Week",
  'components.dateRangePicker.yesterday': 'Yesterday',
  'components.dateRangePicker.last_7_days': 'Last 7 days',
  'components.dateRangePicker.quarter_to_date': 'Quarter to date',
  'components.dateRangePicker.last_month': 'Last Month',
  'components.dateRangePicker.last_week': 'Last Week',
  'components.dateRangePicker.customWeek': 'Week: {dateStart} - {dateEnd}',
  'components.dateRangePicker.last_30_days': 'Last 30 days',
  'components.dateRangePicker.last_90_days': 'Last 90 days',
  'components.dateRangePicker.last_120_days': 'Last 120 days',
  'components.dateRangePicker.last_1_year': 'Last 1 year',
  'components.dateRangePicker.year_to_date': 'Year to date',
  'components.dateRangePicker.custom_dates': 'Custom dates...',
  "components.accountSelect.placeholder": 'Search account by email...',
  "components.accountSelect.noOptions": 'No options available, try a different term',
  "components.multiStepSelect.clear": 'Clear',
  "components.multiStepSelect.apply": 'Apply',
  "components.noAccessPage.title": "You don't have the right access",
  "components.noAccessPage.description": "This feature isn’t available to you. Please contact your company admin to access this feature.",
  "search.label.selectAisle": "Select aisle",
  "search.action.newSearch": "New Search",
  "search.empty.title": "No products found",
  "search.empty.body": "We didn’t find any products matching your search. Did you mean to {link}?",
  "search.empty.searchLink": "search by {type}",
  "search.empty.addLink": "add it to the catalog",
  "search.empty.footer": "If you still can’t find what you’re looking for, you can {link}.",
  "search.notFound.message": "{button} in your search did not match any products",
  "search.notfound.button": "{codeCount, plural, one {# code} other {# codes}} ",
  "search.missingCodes": "Unmatched {type} Codes",
  "search.setMainAisle.title": "Set primary department and aisle for {count, plural, one {# product} other {# products}}",
  "search.setMainAisle.existingAislesNote": "Note that existing aisles will be preserved as secondary aisles.",
  "search.setMainAisle.success": "Your edits for the primary department and aisle for {count, plural, one {# product} other {# products}} have been submitted. You will receive an email notification when these updates have been processed.",
  "search.label.selectDepartment": "Select department",
  "dashboard.title.welcome": "Welcome back, {name}!",
  "dashboard.basketSize": "Average basket size",
  "dashboard.charts.labelDay": "{value} on {date}",
  "dashboard.charts.labelHour": "{value} at {hour}",
  "dashboard.charts.lastPeriod": "Last period",
  "dashboard.charts.thisPeriod": "This period",
  "dashboard.loadError": "Oops, failed to load dashboard. Please try again.",
  "dashboard.metric.vs": " vs {value}",
  "dashboard.orders": "Orders",
  "dashboard.title": "Dashboard",
  "dashboard.gmv": "GMV",
  "dashboard.insightsPortalSummary.tooltip": 'The data is updated daily at 7AM PT',
  "gmv": "GMV",
  "catalog_health": "Catalog Health",
  "found_item_rate": "Item Found",
  "image_coverage_rate": "overall image coverage",
  "search_conversion": "Search Conversion",
  "refund_rate": "Refunds",
  "view_catalog_health": "View all",
  "view_performance_metrics": "View all",
  "weekly_breakdown": "Weekly Breakdowns",
  "hourly_breakdown": "Hourly Breakdowns",
  "date.end": "End",
  "date.start": "Start",
  "default.title": "Instacart for Retailers",
  "delivery.title": "Delivery orders",
  "departments.modal.label": "Department Name",
  "departments.modal.labelFr": "Department Name (French)",
  "department.confirmDelete": "Delete?",
  "department.edit.cancel": "no",
  "department.edit.confirm": "yes",
  "department.edit.editSchedule": "edit schedule",
  "department.edit.end": "end",
  "department.edit.schedule": "schedule",
  "department.edit.schedulePrompt": "Update schedule?",
  "department.error.delete": "Sorry, we weren't able to delete your department.",
  "department.error.update": "Sorry, we weren't able to update your department.",
  "department.notification.share": "A link to the {name} department in your storefront has been copied to your clipboard. Please note that for newly created departments, links may take up to 6 hours to become active.",
  "department.status.active": "Active",
  "department.status.ended": "Ended",
  "department.status.inactive": "Inactive",
  "department.status.upcoming": "Upcoming",
  "department.success.update": "Your department has been updated.",
  "departments.aisles.sort_aisle_success": "Aisle ordering has been updated successfully. Please note it may take up to 6 hours for this change to appear on the storefront.",
  "departments.aisles.sort_aisle_failure": "Sorry, we weren't able to update the order. Please try again later or contact support if the problem persists.",
  "files.title": "Files",
  "filters.location.all": "All Locations",
  "filters.region.all": "All Regions",
  "filters.orderSource.all": "All Order Sources",
  "filters.orderSource.marketplace": "Marketplace Orders",
  "filters.orderSource.pbi": "PBI Orders",
  "filters.orderSource.unata": "SFP Orders",
  "filters.orderSource.api": "API Orders",
  "login.createAccount.title": "Create Account",
  "login.resetPassword.title": "Login > Reset Password",
  "login.setPassword.title": "Login > Set Password",
  "login.title": "Login",
  "login.verify.title": "Login",
  "login.verifyEmail.title": "Verify Email",
  "login.welcome.title": "Welcome",
  "login.label.createAccount": "Create an account",
  "login.welcome.message.firstTime": "First time here?",
  "login.welcome.message.welcome": "Welcome to Instacart for Retail Partners",
  "login.welcome.message.existingAccount": "Already have a Retail Partner account?",
  "login.message.login": "Log in",
  "performance_metrics": "Performance Metrics",
  "performanceMetrics.title": "Performance Metrics",
  "performanceMetrics.unfinalizedOrdersMessage": "Currently, {percentage}% ({finalized} of {completed}) of orders have been finalized for the specified time range.",
  "catalogHealth.title": "Catalog Health",
  "catalogHealth.imageCoverage.title": "Image Coverage (Searched Products)",
  "catalogHealth.imageCoverage.infoTooltip": "Search Product Image Coverage rate only takes into account products that have received search impressions.",
  "catalogHealth.imageCoverage.emptyState.title": "No image coverage data available",
  "catalogHealth.imageCoverage.emptyState.message": "No image coverage data available for {name} between {startAt} and {endAt}.",
  "catalogHealth.imageCoverage.improve.cta": "How can we improve this?",
  "catalogHealth.imageCoverage.improve.modal.title": "Improve Image Coverage",
  "catalogHealth.imageCoverage.improve.modal.report.title": "Missing Images Report",
  "catalogHealth.imageCoverage.improve.modal.report.description": "Download this report to identify products that require images, and see their performance over the past 90 days.",
  "catalogHealth.imageCoverage.improve.modal.report.loading": "The Missing Images Report is currently being generated. It may take a few moments.",
  "catalogHealth.imageCoverage.improve.modal.report.success": "The Missing Images Report has been successfully generated. If your download has not started, you may need to enable downloads for this site.",
  "catalogHealth.imageCoverage.improve.modal.report.failure": "There was an error downloading this report. Please try again.",
  "catalogHealth.imageCoverage.improve.modal.upload.title": "Upload Product Images",
  "catalogHealth.imageCoverage.improve.modal.upload.description": "Bulk add product images to your catalog by uploading a zip file or multiple images at once.",
  "top_10_orders": "Top 10 (% of Orders)",
  "top_10_departments": "Departments",
  "top_10_aisles": "Aisles",
  "first_time_orders": "first time orders",
  "average_basket_size": "average basket size",
  "avg_basket_size": "Average Basket Size",
  "avg_rating": "average rating",
  "items_per_order": "items per order",
  "num_orders": "Orders",
  "total_revenue": "gmv",
  "charts.tooltip.noData": "No data",
  "charts.series.labels.this_period": "This period ({start} - {end})",
  "charts.series.labels.last_period": "Last period ({start} - {end})",
  "charts.series.labels.less_than_5_minutes": "Less than 5 min",
  "charts.series.labels.between_5_and_10_minutes": "Between 5 and 10 min",
  "charts.series.labels.greater_than_10_minutes": "Greater than 10 min",
  "charts.download": "Download this Dataset",
  "marketing.title": "Marketing",
  "notFound.button": "Back to home page",
  "notFound.message": "Nothing to see here.",
  "notFound.title": "Page Not Found",
  "orderDetails.title": "Order Details",
  "ordersManagement.title": "Orders Management",
  "orders.title": "Orders",
  "pickup.title": "Pickup orders",
  "orders.alcoholTooltip": "This order contains alcoholic items. You can reject alcoholic items by calling support at 1-888-246-7822",
  "orders.all": "All Orders",
  "orders.in_progress": "In Progress",
  "orders.completed": "Completed",
  "orders.canceled": "Canceled",
  "orders.count": " ({count, number})",
  "orders.details.total": "Order Total",
  "orders.details.refund.yes": "Yes",
  "orders.details.refund.no": "None",
  "orders.nav.approx_gmv": "~{amount} GMV",
  "orders.search_order_number.placeholder": "Search by order number...",
  "orders.nav.approx_gmv.info_delayed": "Reported GMV will be delayed",
  "orders.nav.approx_gmv.last_updated": "Last updated {lastUpdatedAt}",
  "orders.table.empty_state": "No orders for the selected time period",
  "reports.files.title": "Inventory files",
  "reports.subscriptions.title": "Subscriptions",
  "reports.title": "Reports",
  "analytics.title": "Analytics",
  "reports.tlogs.title": "Transaction logs",
  "reports.tlogs.reportType.dropdown.placeholder": "Choose Report Type",
  "reports.tlogs.emptyState.text": "No files for the selected filters",
  "reports.transactions.title": "Transactions",
  "scope.title": "Pick Retailer and Banner",
  "settings.stripe.title": "Stripe associations",
  "settings.stripe.name": "Warehouse Name",
  "settings.stripe.disconnect": "Disconnect Stripe",
  "settings.stripe.connect": "Connect Stripe",
  "settings.stripe.connect_pharmacy": "Connect Pharmacy Stripe",
  "settings.stripe.disconnect_pharmacy": "Disconnect Pharmacy Stripe",
  "settings.stripe.disconnect_banner": "Disconnect Banner",
  "settings.stripe.disconnect_pharmacy_banner": "Disconnect Pharmacy Banner",
  "settings.stripe.disconnect_retailer_direct_pay_banner": "Disconnect Retailer Direct Pay Banner",
  "settings.stripe.connect_banner": "Connect Banner",
  "settings.stripe.connect_pharmacy_banner": "Connect Pharmacy Banner",
  "settings.stripe.connect_retailer_direct_pay_banner": "Connect Retailer Direct Pay Banner",
  "settings.stripe.banner_connected": "Banner stripe account connected",
  "settings.stripe.pharmacy_banner_connected": "Pharmacy Banner stripe account connected",
  "settings.stripe.retailer_direct_pay_banner_connected": "Retailer Direct Pay Banner stripe account connected",
  "settings.stripe.account.connected": "Stripe account associated",
  "settings.stripe.account.connected.error": "Stripe association failed. Please try again.",
  "settings.stripe.account.disconnected": "Stripe account dissociated",
  "settings.stripe.account.disconnected.error": "Stripe dissociation failed. Please try again.",
  "settings.stripe.id": "Stripe ID",
  "settings.stripe.pharmacy_id": "Pharmacy Stripe ID",
  "settings.stripe.retailer_direct_pay_id": "Retailer Direct Pay Stripe ID",
  "settings.stripe.active": "Active Warehouse",
  "settings.stripe.active.yes": "Yes",
  "settings.stripe.banner.id": "Banner stripe ID: ",
  "settings.stripe.pharmacy_banner.id": "Pharmacy banner stripe ID: ",
  "settings.stripe.retailer_direct_pay_banner.id": "Retailer Direct Pay banner stripe ID: ",
  "settings.stripe.active.no": "No",
  "settings.title": "Settings",
  "settings.storeGroups.title": "Store groups",
  "settings.storeGroups.error.title": "Store Groups - Load Error",
  "settings.storeGroups.error.body": "There was an error loading the data.  Please try again later or contact support if the problem persists.",
  "settings.storeGroups.search.placeholder": "Search by name or ID",
  "settings.storeGroups.add.title": "Add New Group",
  "settings.storeGroups.edit.title": "Edit Group",
  "settings.storeGroups.button.addNew": "Add New",
  "settings.storeGroups.headerNote": "Note: New Store Groups will take 24 hours to reflect in the Insights Portal",
  "settings.storeGroups.list.emptyState.title": "Add Store Group",
  "settings.storeGroups.list.emptyState.body": "Create a grouping of store locations that can be used for analytics and more.",
  "settings.storeGroups.list.emptyState.addGroup": "Add Group",
  "settings.storeGroups.addStoreLocations": "Add Store Locations",
  "settings.storeGroups.form.groupDetail": "Group Detail",
  "settings.storeGroups.form.storeLocationsTitle": "Store Locations",
  "settings.storeGroups.form.locationsEmptyState.body": "Add active store locations to this group.",
  "settings.storeGroups.form.addLocations": "Add Locations",
  "settings.storeGroups.form.editLocations": "Edit Locations",
  "settings.storeGroups.form.viewLocations": "View Locations",
  "settings.storeGroups.form.labels.name": "Group Name",

  "settings.storeGroups.form.table.columns.code": "Code",
  "settings.storeGroups.form.table.columns.storeName": "Store Name",
  "settings.storeGroups.form.table.columns.banner": "Banner",
  "settings.storeGroups.form.table.columns.address": "Address",
  "settings.storeGroups.form.table.columns.city": "City",
  "settings.storeGroups.form.table.columns.state": "State",
  "settings.storeGroups.form.table.columns.zipCode": "Zip Code",

  "settings.storeGroups.table.columns.id": "ID",
  "settings.storeGroups.table.columns.name": "Name",
  "settings.storeGroups.table.columns.stores": "Store(s)",
  "settings.storeGroups.table.columns.banners": "Banner(s)",
  "settings.storeGroups.table.columns.lastEdited": "Last Edited",
  "settings.storeGroups.table.columns.banners.all": "All",

  "settings.storeGroups.form.errors.name.required": "Group Name is required",
  "settings.storeGroups.form.errors.selectedLocationIds.required": "Must choose at least one store location.",
  "settings.storeGroups.deleteModal.title": "Delete {storeGroupName}?",
  "settings.storeGroups.deleteModal.body": "Are you sure you want to delete this store group? It has {retailerLocationCount, plural, one {# store} other {# stores}} in it. This action cannot be undone.",
  "settings.storeGroups.filters.name.placeholder": "Search by name",
  "settings.storeGroups.addModal.filters.name.placeholder": "Search stores",
  "settings.storeGroups.addModal.addAll": "Add All ({numStores, number})",
  "settings.storeGroups.notifications.createSuccess": "Store group \"{name}\" has been created.",
  "settings.storeGroups.notifications.createError": "There was a problem creating your store group.",
  "settings.storeGroups.notifications.updateSuccess": "Store group \"{name}\" has been updated.",
  "settings.storeGroups.notifications.updateError": "There was a problem updating your store group.",
  "settings.storeGroups.notifications.deleteSuccess": "Store group \"{storeGroupName}\" has been deleted.",
  "settings.storeGroups.notifications.deleteError": "There was a problem deleting your store group.",
  "storeOperations.title": "Store Operations",
  "shoppers.title": "Staffing",
  "shoppers.profiles.title": "Devices",
  "shoppers.profiles.edit.title": "Edit Shopper",
  "shoppers.profiles.new.title": "Add Shopper",
  "shoppers.profiles.new.button.label": "Add Shopper",
  "shoppers.profiles.empty.title": "Manage your shoppers here",
  "shoppers.profiles.table.noShopper": "No Users",
  "shoppers.profiles.table.shopperState.active": 'Active',
  "shoppers.profiles.table.shopperState.inactive": 'Inactive',
  "shoppers.profiles.table.shopperStateAction.setToActive": 'Set To Active',
  "shoppers.profiles.table.shopperStateAction.setToInactive": 'Set To Inactive',
  "shoppers.profiles.table.shopperStateFilterOption.allShoppers": 'All Shoppers',
  "shoppers.profiles.table.shopperStateFilterOption.activeShoppers": 'Active Shoppers',
  "shoppers.profiles.table.shopperStateFilterOption.inactiveShoppers": 'Inactive Shoppers',
  "shoppers.profiles.empty.body": "Create a shopper profile to manage their access to the Instacart Shopper App and schedule shifts.",
  "shiftsDomain.title": "Shifts",
  "shiftsDomain.picking": "Picking",
  "shiftsDomain.running": "Running",
  "shiftsDomain.recurring": "Recurring shift",
  "shiftsDomain.fullService": "Full Service",
  "shiftsDomain.time": "Time ({zone})",
  "shiftsDomain.add_shopper": "Add Device",
  "shiftsDomain.add_shopper_empty": "No more Devices",
  "shiftsDomain.add_shopper_error": "Error loading Devices",
  "shiftsDomain.instructions": "Click and drag to create shifts",
  "shiftsDomain.createFailed": "We weren't able to create this shift. Please try again, and if the problem persists, contact support",
  "shiftsDomain.updateFailed": "We weren't able to update this shift. Please try again, and if the problem persists, contact support",
  "shiftsDomain.destroyFailed": "We weren't able to delete this shift. Please try again, and if the problem persists, contact support",
  "shiftsDomain.createFailed.past": "Shifts cannot be created in the past. Please try again, and if the problem persists, contact support",
  "shiftsDomain.createFailed.invalidHours": "Shifts cannot be created outside of store hours. Please try again, and if the problem persists, contact support",
  "shiftsDomain.invalidShift": "Invalid shift length - our support team has been notified",
  "shiftsDomain.prompt.setRecurring": "Set recurring shift for every {weekday} from {start} - {end}?",
  "shiftsDomain.prompt.setRecurring.set": "Set",
  "shiftsDomain.prompt.stopRecurring": "Stop recurring shifts starting {rel} {date}?",
  "shiftsDomain.prompt.stopRecurring.stop": "Stop",
  "shiftsDomain.prompt.editRecurring": "This is a recurring shift. Edit this shift or all shifts?",
  "shiftsDomain.prompt.editRecurring.thisShift": "This Shift",
  "shiftsDomain.prompt.editRecurring.allShifts": "All Shifts",
  "shiftsDomain.prompt.deleteRecurring": "This is a recurring shift. Deleting this shift or all shifts may cause orders to be canceled or rescheduled. Delete this shift or all shifts?",
  "shiftsDomain.prompt.deleteRecurring.thisShift": "This Shift",
  "shiftsDomain.prompt.deleteRecurring.allShifts": "All Shifts",
  "shiftsDomain.prompt.deleteShift": "Deleting this shift may cause orders to be canceled or rescheduled. Delete this shift?",
  "shiftsDomain.prompt.deleteShift.delete": "Delete",
  "shiftsDomain.prompt.error": "{message}",
  "shiftsDomain.prompt.errorDismissLabel": "Ok",
  "shiftsDomain.prompt.shiftGenerationError": "Some future shifts could not be generated: {errors}",
  "shiftsDomain.prompt.newShift": "New shift",
  "shiftsDomain.prompt.editShift": "Shift details",
  "shiftsDomain.prompt.from": "From",
  "shiftsDomain.prompt.to": "To",
  "shiftsDomain.prompt.repeatShift": "Repeat Shift?",
  "shiftsDomain.prompt.doesNotRepeat": "Does not repeat",
  "shiftsDomain.prompt.repeatsOn": "Repeats on",
  "shiftsDomain.prompt.weeklyOnDay": "Weekly on {dayOfWeek}",
  "shiftsDomain.prompt.everyWeekday": "Every weekday (Mon - Fri)",
  "shiftsDomain.prompt.daily": "Daily",
  "shiftsDomain.prompt.custom": "Custom",
  "shiftsDomain.prompt.noEndDate": "No end date",
  "shiftsDomain.prompt.endsOn": "Ends on",
  "shiftsDomain.prompt.cancel": "Cancel",
  "shiftsDomain.prompt.save": "Save",
  "shiftsDomain.prompt.delete": "Delete",
  "shiftsDomain.prompt.monday": "Monday",
  "shiftsDomain.prompt.tuesday": "Tuesday",
  "shiftsDomain.prompt.wednesday": "Wednesday",
  "shiftsDomain.prompt.thursday": "Thursday",
  "shiftsDomain.prompt.friday": "Friday",
  "shiftsDomain.prompt.saturday": "Saturday",
  "shiftsDomain.prompt.sunday": "Sunday",
  "shiftsDomain.prompt.error.shift.delete": "There was an error deleting this shift. Please try again.",
  "shiftsDomain.prompt.error.shift.saving": "There was an error saving this shift. Please try again.",
  "shiftsDomain.prompt.error.shift.saving.changes": "There was an error saving changes to this shift. Please try again.",
  "shiftsDomain.confirmation.modal.checking": "Checking for schedule conflicts",
  "shiftsDomain.confirmation.modal.startsFromLabel": "From",
  "shiftsDomain.confirmation.modal.endsAtLabel": "to",
  "shiftsDomain.confirmation.modal.noEndDate": "No end date",
  "shiftsDomain.confirmation.modal.hasStartDate": "Starts on",
  "shiftsDomain.confirmation.modal.hasEndDate": "Ends on",
  "shiftsDomain.confirmation.modal.conflicts.overwrite": "existing shift(s) conflict with your new shift and will be deleted",
  "shiftsDomain.confirmation.modal.cancel": "Cancel",
  "shiftsDomain.confirmation.modal.confirm": "Confirm",
  "shiftsDomain.confirmation.modal.save.changes": "Save changes",
  "shiftsDomain.confirmation.modal.save.title": "Save changes to recurring shift?",
  "shiftsDomain.confirmation.modal.save.only.this": "Only this shift",
  "shiftsDomain.confirmation.modal.save.this.and.following": "This and following shifts",
  "shiftsDomain.confirmation.modal.delete": "Delete",
  "shiftsDomain.confirmation.modal.delete.title": "Delete this shift?",
  "shiftsDomain.confirmation.modal.delete.only.this": "Delete only this shift",
  "shiftsDomain.confirmation.modal.delete.this.and.following": "Delete this and following shifts",
  "shiftsDomain.cellOptions.stopRecurring": "Stop Recurring",
  "shiftsDomain.cellOptions.setRecurring": "Set Recurring",
  "shiftsDomain.cellOptions.delete": "Delete",
  "shiftsDomain.notifications.single.created": "Shift scheduled",
  "shiftsDomain.notifications.recurring.created": "Repeating shift scheduled",
  "shiftsDomain.notifications.single.deleted": "Shift deleted",
  "shiftsDomain.notifications.recurring.deleted": "Repeating shift deleted",
  "shiftsDomain.notifications.changes.saved": "Changes saved",
  "shiftsDomain.emptyView.title": "Unable to display shift management",
  "shiftsDomain.emptyView.message": "We're currently unable to display shift management for this location.",
  "shiftsDomain.staffing_level": "{scheduled} of {required}",
  "shiftsDomain.allocationType.label": "Shift type",
  "shiftsDomain.allocationType.dedicated.label": "Dedicated",
  "shiftsDomain.allocationType.dedicated.tooltip": "Employees are expected to be busy fulfilling orders during this time.",
  "shiftsDomain.allocationType.oncall.label": "On-call",
  "shiftsDomain.allocationType.oncall.tooltip": "On-call shifts are meant for capturing unexpected demand. Employees should have other work to complete during expected downtime.",
  "shiftsDomain.allocation": "allocation",
  "shiftsDomain.allocation.dedicated.label": "Dedicated Allocation",
  "shiftsDomain.allocation.dedicated.tooltip": "Dedicated hours staffed compared to dedicated hours recommended.",
  "shiftsDomain.allocation.oncall.label": "On-Call Allocation",
  "shiftsDomain.allocation.oncall.tooltip": "On-call hours staffed compared to hours recommended. Employees on-call should be ready to shop, but focused on other work during expected downtime.",
  "shiftsDomain.pickupWarning": "Pickup is not yet active. You may begin scheduling shifts, but orders will only be sent to shoppers after an account rep enables this location for Pickup",
  "shiftsDomain.inactive": "This location is not yet active. You may begin scheduling shifts, but only shifts dated on or after the activation date will take effect.",
  "shiftsDomain.day": "Day",
  "shiftsDomain.week": "Week",
  "shiftsDomain.legend.understaffed": "Hours understaffed",
  "shiftsDomain.legend.understaffed.tooltip": "Hours when staffing is less than the recommendation.",
  "shiftsDomain.legend.overstaffed": "Hours overstaffed",
  "shiftsDomain.legend.overstaffed.tooltip": "Hours when staffing is more than the recommendation.",
  "shiftsDomain.legend.optimal": "Optimal",
  "shiftsDomain.legend.optimal.tooltip": "Hours when staffing matches the recommendation.",
  "shiftsDomain.legend.none": "No recommendations yet",
  "shiftsDomain.legend.none.tooltip": "Staffing recommendations are available two weeks in advance.",
  "shiftsDomain.picking.performance": "Picking performance",
  "shiftsDomain.running.performance": "Running performance",
  "shiftsDomain.fullService.performance": "Full Service performance",
  'shiftsDomain.driving.performance': 'Driving performance',
  "shiftsDomain.performance.cta.toPickupAnalytics": "See pickup data",
  "shiftsDomain.performance.understaffedHours": "Understaffed hours",
  "shiftsDomain.performance.overstaffedHours": "Overstaffed hours",
  "shiftsDomain.performance.totalHours": "Total hours",
  "shiftsDomain.performance.totalHours.tooltip": "Hours scheduled compared to hours recommended.",
  "shiftsDomain.performance.scheduleGaps": "Schedule gaps",
  "shiftsDomain.performance.scheduleGaps.tooltip": "Number of times zero devices are scheduled when the staffing recommendation is greater than zero.",
  "shiftsDomain.performance.scheduledDevices": "Scheduled devices",
  "shiftsDomain.performance.scheduledDevices.tooltip": "Devices that are on shift.",
  "tlogs.title": "Transaction Logs",
  "users.title": "Users",
  "alcoholTlogs.title": "Alcohol delivery logs",
  "alcoholTlogs.tableHeader.purchaser_name": "purchaser Name",
  "alcoholTlogs.tableHeader.purchaser_address": "purchaser Address",
  "alcoholTlogs.tableHeader.delivery_recipient_name": "Recipient Name",
  "alcoholTlogs.tableHeader.delivery_recipient_address": "Recipient Address",
  "alcoholTlogs.tableHeader.delivery_recipient_dob": "Recipient Date of Birth",
  "alcoholTlogs.tableHeader.delivery_recipient_id_type": "Recipient ID Type *",
  "alcoholTlogs.tableHeader.delivery_recipient_id_string": "Recipient ID *",
  "alcoholTlogs.tableHeader.delivery_recipient_id_state": "Recipient ID State *",
  "alcoholTlogs.tableHeader.delivery_recipient_id_country": "Recipient ID Country *",
  "alcoholTlogs.tableHeader.delivery_recipient_id_expiration": "Recipient ID Expiration *",
  "alcoholTlogs.tableHeader.signature_url": "Recipient Signature",
  "alcoholTlogs.tableHeader.shopper_name": "Shopper Name",
  "alcoholTlogs.tableHeader.shopper_signature_url": "Shopper Signature *",
  "alcoholTlogs.tableHeader.retailer_name": "Retailer Name",
  "alcoholTlogs.tableHeader.retailer_address": "Retailer Address",
  "alcoholTlogs.tableHeader.brand": "Brand",
  "alcoholTlogs.tableHeader.alcohol_by_volume": "ABV",
  "alcoholTlogs.tableHeader.quantity": "Quantity",
  "alcoholTlogs.tableHeader.price": "Price",
  "alcoholTlogs.tableHeader.volume": "Volume",
  "alcoholTlogs.tableHeader.purchased_at": "Purchased At",
  "alcoholTlogs.tableHeader.delivered_at": "Delivered At",
  "alcoholTlogs.tableHeader.delivery_status": "Delivery Status",
  "alcoholTlogs.td.signature_url": "Recipient signature",
  "alcoholTlogs.td.shopper_signature_url": "Shopper signature",
  "alcoholTlogs.footNote": "* Some fields are only populated in select states for legal requirements.",
  "standardReports.title": "Standard reports",
  "generatedReports.title": "Generated reports",
  "generatedReports.empty.title": "Generate Your First Report",
  "generatedReports.empty.body": "Create a custom analytic reports based on metrics from your performance, catalog and more.",
  "generatedReports.newReport.title": "New Report",
  "generatedReports.status.NEW": "New",
  "generatedReports.status.IN_PROGRESS": "In Progress",
  "generatedReports.status.COMPLETE": "Download",
  "generatedReports.status.FAILED": "Failed",
  "generatedReports.list.header.name": "Report Name",
  "generatedReports.list.header.requestedAt": "Requested At",
  "generatedReports.list.header.requestedBy": "Requested By",
  "generatedReports.list.header.dataset": "Report Dataset",
  "generatedReports.list.header.status": "Status",
  "generatedReports.list.dataset.topAisles": "Top Aisles",
  "generatedReports.list.dataset.topDepartments": "Top Departments",
  "generatedReports.list.dataset.storeLevelPerformance": "Store-Level Performance",
  "generatedReports.list.dataset.unknown": "Unknown Report",
  "generatedReports.list.error.title": "Error loading Generated Reports",
  "generatedReports.newReport.formFields.reportName.label": "Report Name",
  "generatedReports.newReport.formFields.reportName.placeholder": "Name your report",
  "generatedReports.newReport.formFields.reportName.error.required": "Report name is required",
  "generatedReports.newReport.formFields.reportType.label": "Report Dataset",
  "generatedReports.newReport.formFields.reportType.placeholder": "Select a report type to generate",
  "generatedReports.newReport.formFields.reportType.error.required": "Report dataset is required",
  "generatedReports.newReport.formFields.dateRange.label": "Report Date Range",
  "generatedReports.newReport.formFields.dateRange.placeholder": "Select a report date range",
  "generatedReports.newReport.formFields.dateRange.error.required": "Report date range is required",
  "generatedReports.newReport.formFields.retailerLocation.label": "Report Location",
  "generatedReports.newReport.formFields.retailerLocation.placeholder": "Select a report location",
  "generatedReports.newReport.formFields.retailerLocation.error.required": "Report location is required",
  "generatedReports.newReport.generateReport": "Generate Report",
  "generatedReports.newReport.noOptionsMessage": "No Reports Available",
  "generatedReports.newReport.errors.reportName": "Report name is required",
  "generatedReports.newReport.errors.reportType": "Report dataset is required",
  "generatedReports.newReport.errors.loadingError": "There was an error loading the data. Please try again or reach out to support if the problem persists.",
  "generatedReports.newReport.submit.success": "The report was successfully created and is currently being processed.",
  "generatedReports.newReport.submit.fail": "We were not able to create the report. Please try again or reach out to support if the problem persists.",
  "weighted_average_time_per_item": "Avg Time Per Item",
  "avg_time_per_item_note": "We are moving to Minutes Per Item as our standard metric for picking time, as such, Avg Time Per Item will be phased out on March 31 2020.",
  "avg_time_per_picked_item_note": "Data in effect as of March 11, 2020. Data prior to this date may contain inaccuracies.",
  "weighted_five_hour_availability": "Availability (5 hour)",
  "pickup_rating": "Rating (% of Pickups)",
  "rating_5_as_percent": "5",
  "rating_4_as_percent": "4",
  "rating_3_as_percent": "3",
  "rating_2_as_percent": "2",
  "rating_1_as_percent": "1",
  "pickup_availability": "Availability",
  "two_hour_availability": "2 Hour",
  "five_hour_availability": "5 hour",
  "instacart": "Instacart",
  "privacy": "Privacy",
  "welcomeTour": "Pickup Welcome Tour",
  "security_and_trust": "Security & trust",
  "service_desk": "Service Desk",
  "terms": "Terms",
  "press": "Press",
  "help": "Help",
  "logout": "Log Out",
  "action.edit.bulk": "Edit ({count})",
  "action.save": "Save",
  "action.submit": "Submit",
  "enable": "Enable",
  "edit": "Edit",
  "share": "Share",
  "delete": "Delete",
  "disable": "Disable",
  "cancel": "Cancel",
  "message.bulkSelection": "{count} items selected",
  "button.clear": "clear",
  "button.clear.all": "clear all",
  "button.apply": "apply",
  "button.download": "download",
  "dropdown.label.allTypes": "All Types",
  "dropdown.label.allStatuses": "All Statuses",
  "label.searchByType": "Search by {type}",
  "label.search": "Search",
  "label.name": "Name",
  "label.rrc": "RRC/SKU",
  "label.upc": "UPC",
  "forms.savePrompt": "Save changes?",
  "forms.fieldError": "One or more fields have an error. Please check and try again.",
  "forms.save": "Save",
  "forms.discard": "Discard",
  "forms.apply": "Apply",
  "forms.validations.required": "Required Field",
  "forms.validations.pattern.postalCode": "Invalid Postal Code",
  "forms.validations.pattern.phoneNumber": "Invalid Phone Number",
  "forms.validations.pattern.email": "Invalid Email Address",
  "notification.request.done": "Your request is being processed.",
  "notification.request.failed": "We were unable to save your changes. Please try again later or contact support if the problem persists.",
  "notification.failed": "Something went wrong. Please try again later or contact support if the problem persists.",
  "reports.tlogs.mergeReportsRequest": "We've started merging transaction logs for the requested time range. We'll send you an email once it's ready.",
  "settings.internalTools.title": "Internal Tools",
  "footerBar.cancel": "Cancel",
  "orderType.all": "All Order Types",
  "orderType.delivery": "Delivery Orders",
  "orderType.pickup": "Pickup Orders",
  "orderType.rx": "Rx",
  "badge.new": "NEW",
  "badge.beta": "BETA",
  "app.halt.accountLimitedAccess.header": "No Access",
  "app.halt.accountLimitedAccess.content": "Your account is currently not assigned access to the Instacart Platform Portal. Please contact our support team for help.",
  "app.halt.authServiceAccountLimitedAccessInternal.header": "IPP Access: Action Required",
  "app.halt.authServiceAccountLimitedAccessInternal.content": "Your account has been created, but is not currently assigned access to any partners. Please use the `/ipp` command in any Slack channel to request the desired access. For more information see our access policy.",
  "app.halt.authServiceAccountLimitedAccessInternal.viewAccessPolicy": "View Access Policy",
  "app.halt.authServiceAccountLimitedAccessExternal.header": "Instacart Platform Portal Access: Action Required",
  "app.halt.authServiceAccountLimitedAccessExternal.content": "Your Instacart Platform Portal account has been created, but has not been assigned access to the platform. Please contact your support team to request access.",
  "app.halt.authServiceAccountLimitedAccessExternal.storefrontProContent": "Some accounts have access to private documentation but not to the platform. If you use Storefront Pro, open the Storefront Pro documentation and bookmark the link below. If you have been given access to partner-specific documentation, ask your Instacart representative for the link to the documentation site.",
  "app.halt.authServiceAccountLimitedAccessExternal.outsourceContent": "Some accounts have access to private documentation but not to the platform. Open the link below to view the documentation.",
  "app.halt.accountLimitedAccess.refreshAction": "Retry",
  "app.halt.terms.idpTitle": "IDP Terms and Conditions",
  "app.halt.terms.sitewideTitle": "Updated IPP Terms and Conditions",
  "app.halt.terms.agree": "I have read and agree to these terms",
  "app.halt.terms.continue": "Continue",
  "app.halt.terms.accepting": "Accepting...",
  "app.halt.terms.error": "There was an error recording terms acceptance. Please contact support.",
  'app.halt.terms.partnerDashboard.intro': 'By continuing, you agree to the updated versions of Instacart Partner Platform\'s:',
  'app.halt.terms.partnerDashboard.privacyPolicy': 'Privacy Policy',
  'app.halt.terms.partnerDashboard.termsOfUse': 'Terms of Use',
  "app.halt.actionRequired.header": "Action Required",
  "app.halt.actionRequired.content.emailVerification": "Your email needs to be verified before you can access the Instacart Platform Portal.",
  "app.halt.actionRequired.content.common": "Action is required for your account. Please contact our support team.",
  "app.halt.actionRequired.emailVerificationAction": "Request Verification Email",
  "app.halt.actionRequired.refreshAction": "Refresh",
  "app.admin.controlPanel.title": "Instacart Admin Controls",
  "app.admin.controlPanel.footer": 'With ❤️ from #team-retailer-tools',
  "app.admin.controlPanel.accountDetails.title": 'Logged in account details',
  "app.admin.controlPanel.accountDetails.email": 'Email',
  "app.admin.controlPanel.accountDetails.email.description": 'Hopefully it is obvious what this is.',
  "app.admin.controlPanel.accountDetails.id": 'Database ID',
  "app.admin.controlPanel.accountDetails.id.description": 'This is the primary key used to identify the account in the database. Generally speaking, this ID is not recommended to be used for referencing, but useful for some things.',
  "app.admin.controlPanel.accountDetails.uuid": 'UUID',
  "app.admin.controlPanel.accountDetails.uuid.description": 'This is a unique identifier for the account. This is the recommended ID to use for referencing an account.',
  "app.admin.controlPanel.setting.showNavAccessControls.title": 'Show navigation access control tooltips',
  "app.admin.controlPanel.setting.showNavAccessControls.description": 'Shows a small info icon ℹ️ next to each navigation entry. Each tooltip popup presents the access control configuration for that navigation entry. Useful for knowing what the required permissions, roulettes, entitlements, etc. are for the navigation entry to be visible.',
  "app.admin.controlPanel.setting.showContactHeader.title": 'Show contact header',
  "app.admin.controlPanel.setting.showContactHeader.description": 'If available, show owner contact information when browsing IPP.',

  "app.admin.contactHeader.tooltip.configuration": 'You can disable this header in the Admin Controls section of the navigation menu.',
  "app.admin.contactHeader.tooltip.help": 'You can reach out in the help channel for tool support and questions.',
  "app.admin.contactHeader.team": 'Team',
  "app.admin.contactHeader.slack": 'Help Slack Channel',
  "app.admin.contactHeader.oncall": 'On-Call',


  "app.admin.controlPanel.setting.forceCurrentICPlatformVersion.current": 'the retailer is seeing this version',
  "app.admin.controlPanel.setting.forceCurrentICPlatformVersion.title": 'Force current IC Platform Version: {version}',
  "app.admin.controlPanel.setting.forceCurrentICPlatformVersion.description": 'Forces an IC Platform Version to be used instead of using the regular mechanism, ignoring any user specific gating. Press the X button to return to the default behavior. Keep in mind the following:',
  "app.admin.controlPanel.setting.forceCurrentICPlatformVersion.note1": 'All internal users get access to all versions to match Storefront behavior, unless overridden here.',
  "app.admin.controlPanel.setting.forceCurrentICPlatformVersion.note2": "It only affects the UI layer on IPP, and not any underlying backends.",
  "app.admin.controlPanel.setting.forceCurrentICPlatformVersion.note3": 'This setting only lasts during the current session.',

  "app.admin.controlPanel.setting.forceCurrentICPlatformVersion.default": 'Default',

  "app.admin.home.title": "Instacart Platform Portal: Internal Admin",
  "app.admin.home.subtitle": "Please use the top navigation panel to access the various admin tools.",
  "app.admin.nav.links.home": "Admin Home",
  "app.admin.nav.links.accounts": "Account Management",
  "app.admin.nav.links.business": "Business Dashboard",
  "app.admin.nav.links.retailerDashboard": "Retailer Dashboard",
  "app.admin.nav.links.collections": "Collections",
  "app.admin.nav.links.logOut": "Log Out",
  "app.admin.nav.links.adminControls": "Admin controls",
  "app.admin.nav.links.myAccount": "My Account",
  "app.admin.nav.links.instacartAdmin": "Instacart Admin",
  "app.admin.nav.links.theGarden": "The Garden",
  "app.admin.nav.links.catalogAdmin": "Catalog Admin",
  "app.admin.nav.links.campaignManagement": "Campaign Management",
  "app.admin.nav.links.connectedStores": "Connected Stores",
  "app.admin.nav.links.financialHardening": "Financial Hardening",
  "app.admin.nav.links.lps": "Landing Page System",
  "app.admin.nav.links.brandExplorer": "Brand Explorer",
  "app.admin.nav.links.marketplaceOps": "Marketplace Ops",
  "app.admin.nav.links.retailerManagement": "Retailer Management",
  "app.admin.nav.links.recipesAdmin": "Recipes",
  'app.admin.nav.links.merchandising': 'Merchandising',
  'app.admin.nav.links.approvalsManagement': 'Approvals Management',
  'app.admin.nav.links.analytics': 'Analytics',
  "app.contractorPortal.nav.links.contractorPortal": "Contractor Portal",
  "app.contractorPortal.nav.links.home": "Contractor Portal Home",
  "app.contractorPortal.nav.links.recipes": "Recipes",
  "app.devPortal.nav.header": "Developer dashboard",
  "app.devPortal.nav.links.ftc": "Fulfillment Testing Center",
  "app.devPortal.nav.links.webhookConfiguration": "Webhook Configuration",
  "app.pagination.fetchError.header": "We've hit a snag",
  "app.pagination.fetchError.content": "There was an error when fetching  results for this page. If this persists, reload the page or check your connection.",
  "home.title": "Home",
  // Scalable Training
  "training.welcomeModal.title": "Welcome to Pickup!",
  "training.welcomeModal.button.remindMeLater": "Remind me later",
  "training.welcomeModal.button.confirm": "Take the tour",
  "training.welcomeModal.content.intro": "Take the welcome tour for a quick overview of Pickup features",
  "training.welcomeModal.content.feature1": "Track pickup orders in real-time",
  "training.welcomeModal.content.feature2": "Monitor metrics and download reports",
  "training.welcomeModal.content.feature3": "Get staffing insights and schedule shifts",
  "training.welcomeModal.content.feature4": "Configure and review pickup settings",
  "training.welcomeModal.content.outro": "Some of these features are available for managers on the {iOS} and {Android} shopper app.",
  // PickingPerformanceOverview
  "pickingPerformanceOverview.cta.toPickupAnalytics": "See pickup data",
  "pickingPerformanceOverview.understaffedHours": "Understaffed hours",
  "pickingPerformanceOverview.understaffedHours.tooltip": "Hours when staffing is less than the recommendation.",
  "pickingPerformanceOverview.overstaffedHours": "Overstaffed hours",
  "pickingPerformanceOverview.overstaffedHours.tooltip": "Hours when staffing is more than the recommendation.",
  "pickingPerformanceOverview.totalHours": "Total hours",
  "pickingPerformanceOverview.totalHours.tooltip": "Hours scheduled compared to hours recommended.",
  "pickingPerformanceOverview.scheduleGaps": "Schedule gaps",
  "pickingPerformanceOverview.scheduleGaps.tooltip": "Number of times zero devices are scheduled when the staffing recommendation is greater than zero.",
  "pickingPerformanceOverview.scheduledDevices": "Scheduled devices",
  "pickingPerformanceOverview.scheduledDevices.tooltip": "Devices that are on shift.",
  "pickingPerformanceOverview.matchRate": "Match rate",
  "pickingPerformanceOverview.matchRate.tooltip": "Hours of overlap between staffed picking hours and recommended picking hours divided by total recommended picking hours.",
  "insightsPortalDomain.nav.folder": "Insights Portal",
  'insightsPortalDomain.nav.storePerformance.folder': 'Store performance',
  'insightsPortalDomain.nav.dataAndAnalytics.folder': 'Data & Analytics',
  ...REPORTS_EN_US_TRANSLATIONS,
  // PickingPerformanceOverview
  "tour.a11y.exitStepButton": "End the tour",
  "tour.a11y.nextStepButton": "Go to next step",
  "tour.a11y.prevStepButton": "Go to prev step",
  "tour.label.nextStepButton": "Next",
  "tour.label.doneStepButton": "Done",
  "storefront.nav.title": "Storefront",
  // partner onboarding
  "onboarding.launchChecklist": "Launch checklist",
  "onboarding.storeManagement": "Store management",
  "onboarding.retailerProfile": "Retailer Profile",
  "staleDataNotification.header": "Working with stale data",
  "staleDataNotification.content": " An error occurred while fetching the latest data. We are showing you an older version of the data which may be out of date. If you need the latest data, you can reload the page or click the retry button below.",
  "staleDataNotification.action": "Retry",
  // scan and pay
  "scanandpay.title": "Scan and pay orders",
  "sites.selectToManage": "Select the Site you want to manage",
  "sites.notApplicable": "Not Applicable",
  "sites.loading": "Loading...",
  "sites.error": "Error!",
  "sites.unexpectedErrorDescription": "There was an unexpected error while fetching the data. Please contact support if this problem persists.",
  "sites.noSites": "No Sites!",
  "sites.noSitesAvailableForX": "There are no Sites available for {warehouseName}!",
  "sites.siteNotFound": "Site not found",
  "sites.selectASiteAbove": "Please select a Site above",
  "sites.selectASite": "Select a Site",
  "sites.tooltip.storeConfig": "Store-Config",
  "sites.tooltip.visibleToPartnersQuestion": "Site visible to partners?",
  "sites.tooltip.allowedToModifyPartners": "Partners allowed to modify data related to this site",
  "sites.tooltip.internalOnlyInfo": "(This tooltip is visible to internal employees only)",
  // connected stores's inventory intelligence
  "inventoryIntelligence.title": "Inventory Intelligence",
  "customers.title": "Customers",

  'apolloError.default': 'An error has occurred. Please try again later.',
  'apolloError.networkError': 'A network error has ocurred. Check your internet connection and try again.',
  'apolloError.UNDETERMINED_ACCOUNT': 'You have been logged out. Please log in again.',
  'apolloError.NOT_AUTHORIZED': 'You are not permitted to perform this action.',
  'apolloError.TOO_MANY_REQUESTS': 'You have been rate limited. Please try again later.',
  'sfxVersionFetch.error.title': 'Your access may be limited',
  'sfxVersionFetch.error.body': 'Due to a failure loading some of your information, your access may be limited. Please try again, and if the error persists, contact support.',
  'sfxVersionFetch.error.action': 'Retry',
  'retailerSatisfactionSurvey.shareFeedback': 'Share your feedback about {toolName}',
  'retailerSatisfactionSurvey.step': 'Step {current}/{total}',
  'retailerSatisfactionSurvey.ableToCompleteTask': 'Were you able to complete your task?',
  'retailerSatisfactionSurvey.howEasy': 'How easy was it to complete your task?',
  'retailerSatisfactionSurvey.tellUsMore': 'Tell us more about your answer',
  'retailerSatisfactionSurvey.submit': 'Submit',
  'retailerSatisfactionSurvey.back': 'Back',
  'retailerSatisfactionSurvey.sorry': "We're sorry you couldn't complete your task.{br}What would have helped?",
  'retailerSatisfactionSurvey.thankYou': 'Thank you for your feedback!',
  'retailerSatisfactionSurvey.thankYou1': 'We may reach out if we have follow up questions.',
  'retailerSatisfactionSurvey.thankYou2': 'You can',
  'retailerSatisfactionSurvey.thankYou3Link': 'read our knowledge base',
  'retailerSatisfactionSurvey.thankYou4': 'if you have additional questions.',
  'retailerSatisfactionSurvey.difficulty0': 'Very difficult',
  'retailerSatisfactionSurvey.difficulty1': 'Difficult',
  'retailerSatisfactionSurvey.difficulty2': 'Neutral',
  'retailerSatisfactionSurvey.difficulty3': 'Easy',
  'retailerSatisfactionSurvey.difficulty4': 'Very easy',
  'retailerSatisfactionSurvey.yes': 'Yes',
  'retailerSatisfactionSurvey.no': 'No',
  'retailerPicker.allBanners': 'All banners',
  'retailerPicker.selectBanner': 'Select a banner',
  'retailerPicker.selectBannerAndSite': 'Select a banner and site',
  'retailerPicker.search': 'Search',
  'retailerPicker.banners': 'Banners',
  'retailerPicker.selectBannerFirst': 'Select a banner first',
  'retailerPicker.availableSitesForBanner': 'Available sites for {currentRetailer}',
  'retailerPicker.emptyWindowSelectBanner': 'Please select a banner to view content',
  'retailerPicker.emptyWindowSelectBannerAndSite': 'Please select a banner and site to view content',
  'retailerPicker.retailerInsufficientPermission': "This feature is not applicable for {currentSelectedRetailer}",
  'retailerPicker.notApplicable': "Not applicable",
  'retailerPicker.inactive': "Inactive",
  'retailerMultiPicker.cancel': "Cancel",
  'retailerMultiPicker.apply': "Apply",
  'retailerMultiPicker.banners': "Banners",
  'retailerMultiPicker.sites': "Sites",
  'selectPartner.back': 'Back',
  'selectPartner.partnerLabel': 'Partner...',
  'selectPartner.partnerDescription': 'Select a partner to manage',
  'selectPartner.adminLabel': 'Instacart admin',
  'selectPartner.adminDescription': 'Manage internal admin-level capabilities',
  'selectPartner.selectPartnerTitle': 'Select partner',
  'selectPartner.mainTitle': 'Select organization',
  'selectPartner.mainSubTitle': 'Select the organization you want to manage',
  'selectPartner.recentlyVisited.recentlyVisited': 'Recently visited',
  'selectPartner.recentlyVisited.clearAll': 'Clear all',
  'selectPartner.partnersList.all': 'All',
  'selectPartner.partnersList.resultsFor': 'results for',
  'selectPartner.recentlyVisited.toolTipClear': 'Clear',
  'selectPartner.internalOnlyTag': 'Internal Only',
  'selectPartner.partnerTag': 'Partner',
  'selectPartner.banners': 'banners',
  'selectPartner.searchResults.banners': "banner(s)",
  'selectPartner.searchResults.match': "match",
  'selectPartner.searchResults.defaultSearchText': 'Search by partner name, banner name or banner id',
  'connect.title': 'Connect',
  'featureEducation.modal.maybeLater': 'Maybe later',
  'featureEducation.modal.feature.mfaLaunch.heading': 'Two-factor authentication (2FA) available!',
  'featureEducation.modal.feature.mfaLaunch.line1': 'As an account admin, you can now enable two-factor authentication (2FA) for all your users.',
  'featureEducation.modal.feature.mfaLaunch.line2': '2FA provides an additional layer of security by requiring a verification code when you sign in on a new device.',
  'featureEducation.modal.feature.mfaLaunch.line3': 'about 2FA and how to setup for your users.',
  'featureEducation.modal.feature.mfaLaunch.learnMore': 'Learn more',
  'featureEducation.modal.feature.mfaLaunch.buttonText': 'View security settings',
  'navV2.folder.analytics.title': 'Analytics',
  'navV2.folder.analytics.description': 'Review your storefront’s performance and influence business strategies.',
  'navV2.folder.analytics.storePerformance.title': 'Store performance',
  'navV2.folder.analytics.merchandising.title': 'Merchandising',
  'navV2.folder.analytics.marketing.title': 'Marketing',
  'navV2.folder.analytics.reportsAndLogs.title': 'Reports & Logs',
  'navV2.folder.catalog.title': 'Catalog',
  'navV2.folder.catalog.description': 'Manage the product details displayed on your storefronts.',
  'navV2.folder.merchandising.title': 'Merchandising',
  'navV2.folder.merchandising.description': 'Customize your digital storefront to differentiate yourself from your competitors.',
  'navV2.folder.marketing.title': 'Marketing',
  'navV2.folder.marketing.description': 'Create marketing strategies that appeal to your customers and boost sales.',
  'navV2.folder.operations.title': 'Operations',
  'navV2.folder.operations.description': 'Manage physical store settings and find information about your customers\' orders.',
  'navV2.folder.developer.title': 'Developer',
  'navV2.folder.developer.description': 'Simulate workflows, configure webhooks and manage keys.',
  'navV2.folder.operations.general.title': 'General',
  'navV2.folder.operations.customerSupport.title': 'Customer support',
  'navV2.folder.operations.labor.title': 'Labor',
  'navV2.userSettings.switchPartner': 'Switch partner',
  'navV2.userSettings.selectPartner': 'Select partner',
  'navV2.userSettings.instacartAdmin': 'Instacart Admin',
  'navV2.userSettings.documentation': 'Documentation',
  'navV2.userSettings.switchToNavV1': 'Switch to classic experience',
  'navV2.userSettings.switchToNavV2': 'Try the new experience',
  'navV2.userSettings.manageAccount': 'Manage account',
  'navV2.userSettings.users': 'Users',
  'retailerFeedback.userSurvey.page1.title': "Please rate your overall experience with the{br} Instacart Platform",
  'retailerFeedback.userSurvey.page1.option1': "Very unsatisfied",
  'retailerFeedback.userSurvey.page1.option2': "Unsatisfied",
  'retailerFeedback.userSurvey.page1.option3': "Neutral",
  'retailerFeedback.userSurvey.page1.option4': "Satisfied",
  'retailerFeedback.userSurvey.page1.option5': "Very satisfied",
  'retailerFeedback.userSurvey.page1.submit': "Submit",
  'retailerFeedback.userSurvey.page1.textAreaPlaceholder': 'Optional: Tell us how we can improve your experience',
  'retailerFeedback.userSurvey.page2.title': "Feedback submitted",
  'retailerFeedback.userSurvey.page2.subheading': 'Thank you!',
  'retailerFeedback.userSurvey.page2.body': 'Your feedback helps us improve!',
  'navV2.search.misc': 'Miscellaneous',
  'navV2.search.placeholder': 'Search...',
  'navV2.search.nothingFound': 'No results found',
  'navV2.search.button.tooltipWindows': 'Jump to page (Ctrl + K)',
  'navV2.search.button.tooltipMac': 'Jump to page (Cmd + K)',
  'navV2.search.placeHolderWindows': 'Jump to page... (Ctrl + K)',
  'navV2.search.placeHolderMac': 'Jump to page... (Cmd + K)',
  'userFeedback.button.text': 'Feedback',
  'userFeedback.survey.page1.title': 'Give feedback',
  'userFeedback.survey.page1.textArea.subheading': 'What\'s your feedback?',
  'userFeedback.survey.page1.textArea.helpText': 'Your feedback helps us improve our experience.',
  'userFeedback.survey.page1.textArea.supportText': 'If you require technical assistance or would like to file a bug report, ',
  'userFeedback.survey.page1.textArea.supportTextLink': 'contact our support team.',
  'userFeedback.survey.page1.textArea.placeholder': 'Enter your feedback',
  'userFeedback.survey.page1.checkbox.label': 'Instacart can contact me about this feedback',
  'userFeedback.survey.page1.secondaryButton': 'Cancel',
  'userFeedback.survey.page1.primaryButton': 'Submit',
  'userFeedback.survey.page1.errorMessage': 'There was an error recording the survey submission. Please try again: ',
  'userFeedback.survey.page2.title': 'Feedback submitted',
  'userFeedback.survey.page2.subheading': 'Thank you!',
  'userFeedback.survey.page2.body': 'Your feedback helps us improve!',
  'accessControl.disabledComponentTooltipText': 'You do not have permission to this',
  'accessControl.missingWriteAccessTooltipText': 'Your account is read-only',
  'betaEnvBanner.text': 'This is the beta environment. To go back to the main environment, disconnect from the engineering VPN node.',
} as const
