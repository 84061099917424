import React, {
  useState,
  type SetStateAction,
  type Dispatch,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from 'react'
import { type FunctionComponent } from 'react'
import { MenuBase, useTheme, type Theme } from '@retailer-platform/shared-components/src/tds'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { Button } from '@mantine/core'
import { SearchIcon, type Color } from '@instacart/ids-core'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { useOs } from '@mantine/hooks'
import { useIntl } from 'react-intl'
import { useDebouncedState } from '@retailer-platform/shared-components/src/hooks/useDebouncedState.hooks'
import { type NavMenuHierarchy } from '../../NavMenuHierarchy'
import { useTrackEvent } from '../../../../../utils/events/useTrackEvent.hook'
import { type ItemGroup, NavMenuItemGroup } from '../NavMenuItemGroup'
import { type SpotlightAction } from '../NavSearchButton'
import {
  formCategoriesIntoItemGroups,
  getNavigateToSearchResultData,
  getTrackEventCloseSearchBarData,
  getTrackEventNothingFoundData,
  getTrackEventOpenSearchBarData,
  getTrackEventUserSearchedData,
} from './NavSearchMenuUtilities'

export type Props = {
  navMenuHierarchy: NavMenuHierarchy
  searchActions: SpotlightAction[]
  buttonRef: React.RefObject<HTMLButtonElement>
  isSearchBarOpen: boolean
  onChangeSearchBarIsOpen: Dispatch<SetStateAction<boolean>>
}

const getButtonBackgroundColor = (isAdmin: boolean, isSearchBarOpen: boolean, theme: Theme) => {
  const partnerBackgroundColor = isSearchBarOpen
    ? theme.colors['systemGrayscale00']
    : theme.colors['brandPrimaryDark']

  const adminBackgroundColor = isSearchBarOpen
    ? theme.colors['systemGrayscale00']
    : theme.colors['systemGrayscale80']

  return isAdmin ? adminBackgroundColor : partnerBackgroundColor
}

const getButtonIconColor = (isAdmin: boolean, isSearchBarOpen: boolean, theme: Theme) => {
  const partnerIconColor = isSearchBarOpen
    ? theme.colors['brandPrimaryRegular']
    : 'systemGrayscale00'
  const adminIconColor = isSearchBarOpen ? 'systemGrayscale80' : 'systemGrayscale00'
  return isAdmin ? adminIconColor : partnerIconColor
}

const getButtonHoverColor = (
  isAdmin: boolean,
  isOpen: boolean,
  navMenuHierarchy: NavMenuHierarchy,
  theme: Theme
) => {
  const backgroundColor = getButtonBackgroundColor(isAdmin, isOpen, theme)
  return isOpen ? backgroundColor : navMenuHierarchy.navBarColorHover
}

export const NavSearchMenu: FunctionComponent<Props> = ({
  navMenuHierarchy,
  searchActions,
  buttonRef,
  isSearchBarOpen,
  onChangeSearchBarIsOpen,
}) => {
  const intl = useIntl()
  const [searchValue, debouncedSearchValue, setSearchValue, isSearchValuePending] =
    useDebouncedState(null, 2500)
  const [activeItemIndex, setActiveItemIndex] = useState(-1)
  const menuRef = useRef<HTMLDivElement>(null)
  const isAdmin = navMenuHierarchy.type === 'admin'
  const theme = useTheme()
  const trackEvent = useTrackEvent()

  const os = useOs()
  const placeHolderText =
    os === 'macos' || os === 'ios'
      ? intl.formatMessage({ id: 'navV2.search.placeHolderMac' })
      : intl.formatMessage({ id: 'navV2.search.placeHolderWindows' })

  const itemClickHandler = useCallback(() => {
    setSearchValue(null)
    setActiveItemIndex(-1)
  }, [setSearchValue, setActiveItemIndex])

  const categoryItemsGroups = useMemo(
    () => formCategoriesIntoItemGroups(searchActions, itemClickHandler),
    [searchActions, itemClickHandler]
  )

  const filteredItemGroups = useMemo(() => {
    if (!searchValue) {
      return categoryItemsGroups
    }
    const filteredGroups: ItemGroup[] = []

    categoryItemsGroups.forEach(group => {
      if (group.groupHeaderText.toLowerCase().includes(searchValue.toLowerCase())) {
        filteredGroups.push(group)
      } else {
        const filteredItems = group.items.filter(item =>
          item.label.toLowerCase().includes(searchValue.toLowerCase())
        )
        if (filteredItems.length > 0) {
          filteredGroups.push({ ...group, items: filteredItems })
        }
      }
    })

    return filteredGroups
  }, [categoryItemsGroups, searchValue])

  const totalItemCount = useMemo(
    () => filteredItemGroups.reduce((acc, group) => acc + group.items.length, 0),
    [filteredItemGroups]
  )

  const onSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value)
      if (event.target.value === '' || totalItemCount === 0) {
        setActiveItemIndex(-1)
      } else {
        setActiveItemIndex(0)
      }
    },
    [setSearchValue, setActiveItemIndex, totalItemCount]
  )

  const activeItem = useMemo(() => {
    let itemIndex = 0
    for (const group of filteredItemGroups) {
      for (const item of group.items) {
        if (itemIndex === activeItemIndex) {
          return item
        }
        itemIndex++
      }
    }
  }, [filteredItemGroups, activeItemIndex])

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      switch (event.key) {
        case 'Enter':
          if (activeItemIndex !== -1) {
            trackEvent(
              getNavigateToSearchResultData(
                searchValue,
                activeItem.label,
                activeItem.route,
                'enter keyboard'
              )
            )
            setSearchValue(null)
            onChangeSearchBarIsOpen(false)
            activeItem.onClick()
          }
          break
        // This to help user to navigate through the menu using arrow up or down
        case 'ArrowDown': {
          if (activeItemIndex < totalItemCount - 1) {
            setActiveItemIndex(activeItemIndex + 1)
          } else {
            setActiveItemIndex(0)
          }
          event.stopPropagation()
          event.preventDefault()
          break
        }
        case 'ArrowUp': {
          if (activeItemIndex > 0) {
            setActiveItemIndex(activeItemIndex - 1)
          } else {
            setActiveItemIndex(totalItemCount - 1)
          }
          event.stopPropagation()
          event.preventDefault()
          break
        }
      }
    },
    [
      activeItem,
      activeItemIndex,
      totalItemCount,
      setSearchValue,
      onChangeSearchBarIsOpen,
      searchValue,
      trackEvent,
    ]
  )

  const searchBarProps = {
    displaySearchBar: true,
    searchPlaceholderText: placeHolderText,
    onChange: onSearchChange,
    searchValue,
    onKeyDown: handleKeyDown,
    isAutoFocus: true,
  }
  const backgroundColor = getButtonBackgroundColor(isAdmin, isSearchBarOpen, theme)
  const hoverColor = getButtonHoverColor(isAdmin, isSearchBarOpen, navMenuHierarchy, theme)
  const iconColor = getButtonIconColor(isAdmin, isSearchBarOpen, theme)

  const handleClick = useCallback(
    (state: boolean) => {
      const target = window.event?.target as Node
      onChangeSearchBarIsOpen(state)
      if (state) {
        trackEvent(getTrackEventOpenSearchBarData())
      } else if (!menuRef.current?.contains(target)) {
        trackEvent(getTrackEventCloseSearchBarData(searchValue))
      }
    },
    [onChangeSearchBarIsOpen, searchValue, trackEvent]
  )

  const NavSearchBarButton = (
    <Button
      data-testid="nav-search-bar-button"
      css={{
        width: '48px',
        height: '48px',
        backgroundColor: backgroundColor,
        '&:hover': {
          backgroundColor: hoverColor,
        },
        ':focus-visible': {
          backgroundColor: theme.colors.brandPrimaryDark,
          color: theme.colors.brandPrimaryDark,
          outline: `2px solid ${theme.colors.systemGrayscale80}`,
          outlineOffset: '2px',
        },
        padding: '0px',
        position: 'relative',
        '&:active': {
          transform: 'none',
        },
      }}
      radius="0px"
      ref={buttonRef}
    >
      <SearchIcon
        data-testid="nav-search-bar-button-icon"
        size={24}
        color={iconColor as Color}
        css={{
          minWidth: '24px',
          minHeight: '24px',
        }}
      />
    </Button>
  )

  useEffect(() => {
    if (!isSearchValuePending && debouncedSearchValue) {
      if (totalItemCount === 0) {
        trackEvent(getTrackEventNothingFoundData(debouncedSearchValue))
      } else {
        trackEvent(getTrackEventUserSearchedData(debouncedSearchValue))
      }
    }
  }, [totalItemCount, debouncedSearchValue, trackEvent, isSearchValuePending])

  const onMouseEnter = useCallback(() => {
    setActiveItemIndex(-1)
  }, [setActiveItemIndex])

  return (
    <MenuBase
      onMenuStateChange={handleClick}
      isOpen={isSearchBarOpen}
      target={NavSearchBarButton}
      searchBarProps={searchBarProps}
      position="bottomEnd"
    >
      <div ref={menuRef}>
        <NavMenuItemGroup
          searchValue={searchValue}
          groups={filteredItemGroups}
          activeItem={activeItem}
          onMouseEnter={onMouseEnter}
        />
      </div>
    </MenuBase>
  )
}
