export enum Metrics {
  IMPRESSIONS = 'SUM_VISIT_IMPRESSIONS',
  ADD_TO_CARTS = 'SUM_VISIT_ADD_TO_CARTS',
  CART_CONVERSIONS = 'VISIT_CART_CONVERSION',
  GMV = 'SUM_PRODUCT_GMV',
  UNITS_SOLD = 'SUM_UNITS_SOLD',
  LAST_ETL_UPDATE = 'MAX_UPDATED_AT_DATE_TIME_UTC',
}

export enum Dimensions {
  DATE_PT = 'DATE_PT',
  WEEK_SAT_PT = 'WEEK_SAT_PT',
  WEEK_SUN_PT = 'WEEK_SUN_PT',
  WEEK_MON_PT = 'WEEK_MON_PT',
  WEEK_TUE_PT = 'WEEK_TUE_PT',
  WEEK_WED_PT = 'WEEK_WED_PT',
  WEEK_THU_PT = 'WEEK_THU_PT',
  WEEK_FRI_PT = 'WEEK_FRI_PT',
  MONTH_PT = 'MONTH_PT',
  COLLECTION = 'COLLECTION',
  COLLECTION_ID = 'COLLECTION_ID',
  COLLECTION_NAME = 'COLLECTION_NAME',
  DEVICES = 'PLATFORM',
  LAST_UPDATED = 'COLLECTION_LAST_UPDATED_UTC',
  INSIGHTS_PORTAL_ORDER_SOURCE = 'INSIGHTS_PORTAL_ORDER_SOURCE',
  RETAILER_COLLECTION_ID = 'RETAILER_COLLECTION_ID',
  START_DATE = 'START_DATE',
  WAREHOUSE = 'WAREHOUSE',
}

export enum GroupBys {
  COLLECTION_NAME = 'COLLECTION_NAME',
  COLLECTION_ID = 'COLLECTION_ID',
  RETAILER_COLLECTION_ID = 'RETAILER_COLLECTION_ID',
  START_DATE = 'START_DATE',
  LAST_UPDATED = 'COLLECTION_LAST_UPDATED_UTC',
  CREATED_BY = 'CREATED_BY',
  DATE_PT = 'DATE_PT',
  WEEK_SAT_PT = 'WEEK_SAT_PT',
  WEEK_SUN_PT = 'WEEK_SUN_PT',
  WEEK_MON_PT = 'WEEK_MON_PT',
  MONTH_PT = 'MONTH_PT',
  DEVICES = 'PLATFORM',
  CATALOG_PRODUCT_ID = 'CATALOG_PRODUCT_ID',
  RETAILER_PRODUCT = 'RETAILER_PRODUCT',
  PRODUCT_UPC_CODE = 'PRODUCT_UPC_CODE',
}
