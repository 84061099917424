import { type FunctionComponent, useCallback, useMemo, useRef, useState } from 'react'
import { Button, NotificationLevel, notify, spacing } from '@retailer-platform/shared-components'
import styled from '@emotion/styled'
import faker from 'faker'
import { useDomainMessages } from '../../utils/domain/intl.ts'
import { useDomainGoToPath } from '../../utils/domain/routing.ts'
import { type MdPlatformListFilters } from '../../types/mdPlatform.types.ts'
import {
  type AttributeManagementCategoryEnum,
  AttributeManagementCategoryEnumToGraphQLEnum,
  type AttributeManagementStep,
  AttributeManagementTypeEnum,
  GraphQLEnumToAttributeManagementCategoryEnum,
} from '../../types/attributeManagement.types.ts'
import {
  type Instacart_Catalog_Catflow_V1_Step,
  Instacart_Catalog_Catflow_V1_StepCategory,
  useListStepsQuery,
} from '../../api/index.ts'
import { ListFilterContainer } from './ListFilterContainer.tsx'
import { AttributeManagementListTable } from './AttributeManagementListTable.tsx'

export const DEFAULT_FILTER = {
  name: '',
  id: '',
  owner: '',
  hideRejectedVersions: false,
}
const Container = styled.div({
  flex: 1,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
})

const FilterRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: spacing.X20,
})

const FilterContainer = styled.div({
  flex: 1,
  marginRight: spacing.X20,
})

export type Action = (stepId: string) => React.ReactNode

interface Props {
  category: AttributeManagementCategoryEnum
  hideCreateButton?: boolean
  actions?: Action[]
}

const AttributeManagementList: FunctionComponent<Props> = ({
  category,
  hideCreateButton,
  actions,
}: Props) => {
  const i18n = useDomainMessages({
    title: 'catalogAdminDomain.attributeManagement.nav.title',
    createButton: 'catalogAdminDomain.attributeManagement.createButton',
    notifyErrorMsg: 'catalogAdminDomain.attributeManagement.notifyErrorMsg',
  })
  const [filters, setFilters] = useState<MdPlatformListFilters>(DEFAULT_FILTER)

  const idempotencyKey = useRef(faker.random.uuid())

  const goToCreate = useDomainGoToPath(
    'app-admin-catalog-admin-attribute-management-create',
    undefined,
    undefined,
    { category: category.valueOf() }
  )

  const { data, loading, error, called } = useListStepsQuery({
    variables: {
      clientId: 'catalog_camp_ipp',
      idempotencyKey: idempotencyKey.current,
      owner: filters.owner.length > 1 ? filters.owner : undefined,
      name: filters.name.length > 1 ? filters.name : undefined,
      stepCategory: AttributeManagementCategoryEnumToGraphQLEnum[category],
    },
    onError: e => {
      notify({
        contents: `Error fetching steps: ${e.message}`,
        level: NotificationLevel.Error,
      })
    },
    skip: !idempotencyKey.current,
  })

  const listData: AttributeManagementStep[] = useMemo(() => {
    if (loading || !called || error || !data) {
      return []
    }
    const steps = data?.instacart_catalog_catflow_v1_CatflowAPI_ListSteps?.steps || []
    return steps.map((step: Instacart_Catalog_Catflow_V1_Step) => ({
      id: step.id,
      name: step.name || '',
      type: AttributeManagementTypeEnum[step.type as keyof typeof AttributeManagementTypeEnum],
      description: step.description || '',
      owner: step.owner || '',
      category:
        GraphQLEnumToAttributeManagementCategoryEnum[
          step.stepCategory || Instacart_Catalog_Catflow_V1_StepCategory.Unknown
        ],
      versions: [],
    }))
  }, [data, loading, called, error])

  return (
    <Container>
      <FilterRow>
        <FilterContainer>
          <ListFilterContainer filters={filters} onChange={filters => setFilters(filters)} />
        </FilterContainer>
        {!hideCreateButton && (
          <Button variant={'primary'} onClick={() => goToCreate()}>
            {i18n.createButton}
          </Button>
        )}
      </FilterRow>
      <AttributeManagementListTable
        listData={listData}
        loading={loading}
        actions={actions}
      ></AttributeManagementListTable>
    </Container>
  )
}

export default AttributeManagementList
