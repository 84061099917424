import { type InsightsSchemaConfigs } from '../configuration/schema.configuration'
import { type InsightsSchemaConfigsV2 } from '../configuration/schema.configurationV2'

// If someone is able to get tests passing with the types housed here in this file, feel free to move them back
export { GroupByTypes as InsightsGroupByTypes } from './groupBy.types'
export { FormatterType as InsightsFormatterType } from './formatter.types'

export type InsightsFormatterFunction = (val: string | number) => string

export enum InsightsMetricDesiredDirection {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}

export type InsightsConfiguredSchemas = keyof InsightsSchemaConfigs
export type InsightsConfiguredSchemasV2 = keyof InsightsSchemaConfigsV2
