/**
 * Maintaining a list of errors that we want to ignore from being sent to any monitoring providers
 * This should be used sparingly, and only for errors that we know we can't do anything about.
 *
 * Each entry should have some comment explaining why it's being ignored
 */
export const IGNORED_ERRORS = [
  /* This error is emitted whenever the user opens the partner / account menu in the header
   * It is benign and difficult to fix due to it coming from an IDS component.  Chrome and Firefox dont even
   * show this in the dev console.
   * https://stackoverflow.com/a/50387233
   */
  'ResizeObserver loop completed with undelivered notifications.',
]

export const IGNORED_REGEX_ERRORS = [
  // filter out error that is caused by link validation/ pre-fetch rendering from email clicks
  // https://github.com/DataDog/browser-sdk/issues/2715
  /Object Not Found Matching Id:\d+, MethodName:\w+, ParamCount:\d+/,
]

export const IGNORED_UNHANDLED_ERRORS = [
  {
    message: `Uncaught "Empty reason"`,
    stack: 'No stack, consider using an instance of Error',
  },
  {
    message: `Uncaught "Empty reason"`,
    stack: '',
  },
  {
    message: '',
    stack: '',
  },
]
