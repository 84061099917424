import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'

export const retailerFundedMarketingTargetingAccessControl: DomainAccessControlConfig = [
  {
    permissions: [
      Permission.RetailerFundedMarketingViewAccess,
      Permission.RetailerFundedMarketingModifyAccess,
    ],
  },
]
