import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { ViewType } from '@retailer-platform/dashboard/gin-and-tonic'
import { DomainRoute } from '../utils/domain/routingComponents'

const RetailerCampaignCreatePage = lazy(
  () =>
    import(
      /* webpackChunkName: "RetailerCampaignCreatePage" */
      '../pages/retailer-campaigns/create/RetailerCampaignCreatePage'
    )
)
const RetailerCampaignEditPage = lazy(
  () =>
    import(
      /* webpackChunkName: "RetailerCampaignEditPage" */
      '../pages/retailer-campaigns/edit/RetailerCampaignEditPage'
    )
)
const RetailerCampaignViewPage = lazy(
  () =>
    import(
      /* webpackChunkName: "RetailerCampaignEditPage" */
      '../pages/retailer-campaigns/view/RetailerCampaignViewPage'
    )
)
// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const retailerFundedMarketingV2Router = (
  <DomainRoute route="campaigns">
    <DashSwitch>
      <DomainRoute
        exact
        route="campaigns-create"
        component={RetailerCampaignCreatePage}
        scopePicker={{
          titles: ['retailerFundedMarketingDomain.create.title'],
          selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
          storeConfigOperationIntent: 'view',
        }}
      />
      <DomainRoute exact route="campaigns-edit" component={RetailerCampaignEditPage} />
      <DomainRoute exact route="campaigns-view" component={RetailerCampaignViewPage} />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
