import React, { type FunctionComponent, useMemo } from 'react'
import { Marker, type MarkerVariants } from '@retailer-platform/shared-components/src/tds'
import { DetectionStatusState } from '../../../api'
import { useDomainMessages } from '../../domain/intl.ts'

interface Props {
  state?: DetectionStatusState
}

export const MdPlatformVersionState: FunctionComponent<React.PropsWithChildren<Props>> = ({
  state,
}) => {
  const i18n = useDomainMessages({
    inReview: 'catalogAdminDomain.mdPlatformList.table.reviewStates.inReview',
    testing: 'catalogAdminDomain.mdPlatformList.table.reviewStates.testing',
    rejected: 'catalogAdminDomain.mdPlatformList.table.reviewStates.rejected',
    approved: 'catalogAdminDomain.mdPlatformList.table.reviewStates.approved',
  })

  const stateValue = useMemo(() => {
    const currentState = state ?? DetectionStatusState.DetectionStatusStateUnknown

    let translatedState: string
    let variant: MarkerVariants
    switch (currentState) {
      case DetectionStatusState.DetectionStatusStateAdminAudit:
        translatedState = i18n.inReview
        variant = 'neutral'
        break
      case DetectionStatusState.DetectionStatusStateRunningAutomatedTests:
        translatedState = i18n.testing
        variant = 'alert'
        break
      case DetectionStatusState.DetectionStatusStateRejected:
        translatedState = i18n.rejected
        variant = 'detrimental'
        break
      default:
        translatedState = i18n.approved
        variant = 'success'
        break
    }
    return { translatedState, variant }
  }, [i18n, state])

  if (state == null) return null
  return <Marker variant={stateValue.variant} text={stateValue.translatedState} />
}
